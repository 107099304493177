import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { refresh_token } from '../../main_components/tokens/tokenrefresh';
import { get_managmnt_route } from '../../services/configuration/configuration';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CustomDialog } from 'react-st-modal';
import AlertResponse from "../../main_components/alerts/alertResponse";
import CREATE from "../../imgComponents/create.png";
import WARNING from '../../main_components/alerts/warning';
import { OP_ROUTE } from './tools/op_route';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from '../../main_components/methods_v2/export';
import RENDER_ROW_ACTION_MENU_ITEMS from './tools/render_row_action_menuItems';
import "./supervision.css";
import MODAL_TABLE from '../../main_components/modal/modal_Table';
import NotificationSystem from 'react-notification-system';
import { BREADCRUMBS } from '../../main_components/pagination/breadcrumbs';
import { update_null_identifiers } from '../../main_components/methods_v2/auxiliar_funtion';

export const MANAGEMENT_ROUTE = () => {

const notificationSystemRef = useRef();
const [data, setData] = useState([]);
const [open, setopen]=useState(false);
const [modalT, setmodalT] = useState(false);
const [isLoading, setIsLoading] = useState(false);
const [rowSelection, setRowSelection] = useState({});
const [total_rows, settotal_rows] = useState("");

  useEffect(() => {
    handleSubmit();
  }, [])

  function alertas(msj,status){

    if(status==="warning"){
      notificationSystemRef.current.addNotification({
        message: <WARNING msj={msj} view={true}></WARNING>,
        level: "success",
        position: "br",
        autoDismiss: 30,
      });
    }else if(status===true){
      notificationSystemRef.current.addNotification({
        message:<AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br', 
        autoDismiss: 10, 
      });  
    }else{
      notificationSystemRef.current.addNotification({
        message:<AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br', 
        autoDismiss: 60, 
      });
    }  
  }

  
  const handleSubmit = async() =>{
       try{
        setIsLoading(true);
        var rf_token = await refresh_token();
        if(rf_token===true){
            setTimeout(async() => {
                try{
                    const d = await get_managmnt_route();
                    console.log(d);
                    var ndt = update_null_identifiers(d?.data, update_identifiers);
                    setData(ndt);
                    setIsLoading(false);
                }catch(err){
                    console.log(err);
                    setIsLoading(false);
                }
            }, 1500);  
        }else{}
       }catch(err){
        console.log(err);
       } 
  }

  const columns = useMemo(
    () => [
      {
        id: "route",
        header: "Ruta",
        accessorKey:"route",
        muiTableBodyCellProps:{
          align: "center"
        }
      },
      {
        id: "supervisor_name",
        header: "Supervisor Asignado",
        accessorKey:"supervisor_name",
        muiTableBodyCellProps:{
          align: "justify"
        },
        size: 400
      },
      {
        id: "date_register",
        header: "Fecha Registro",
        accessorKey:"date_register",
        muiTableBodyCellProps:{
          align: "justify"
        },
        size: 300
      }
    ],
    []
  );
  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };
  
  
const update_identifiers =
[
  { route: 'string' },
  { supervisor_name: 'string' },
  { date_register: 'String' },
 
];



  return (
    <>
     <BREADCRUMBS niveles={
                [
                  {label: "GESTIÓN DE RUTAS", path: null},
                ]
              }
      cards_dashboard={true}
      total_rows={total_rows !== "" ? total_rows : 0}
      filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
      visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
      total={data?.length}  
    ></BREADCRUMBS>
    <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <MODAL_TABLE
    open={true}
    message={""}
    modalGeneral={false}
    modalT={modalT}
  ></MODAL_TABLE>
    <div className='new_table_v2' style={{"width":"98%", margin: "0 auto"}}>
<MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        onStateChange={handleStateChange}
        enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            Header: () => (
              <div>
                <img
                style={{cursor:"pointer"}}
                title="Registrar"
                src={CREATE}
                className=""
                onClick={async () => {
                  const result = await CustomDialog(
                    <OP_ROUTE
                        alertas={alertas}
                        setmodalT={setmodalT}
                        handleSubmit={handleSubmit}
                        operation={"CREATE"}
                    ></OP_ROUTE>,
                    {
                      className: "custom-modal",
                      title: "Registrar",
                      showCloseIcon: true,
                      isCanClose:false,
                    }
                  );
                }}
              ></img>
              </div>
            ),
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
            size: 80
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            settotal_rows(table);
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
         renderRowActionMenuItems={({ row, closeMenu }) => [
              <RENDER_ROW_ACTION_MENU_ITEMS
                closeMenu={closeMenu}
                setmodalT={setmodalT}
                row={row}
                handleSubmit={handleSubmit}
                refresh_token={refresh_token}
                alertas={alertas}
                setreconsult={isLoading}
              />
            ]}
           renderToolbarInternalActions={({ table }) => (
             <RENDER_TOOLBAR_INTERNAL_ACTIONS
               table={table} 
               file_name={"Gestión de Rutas"}
               update_identifiers={update_identifiers}
               settotal_rows={settotal_rows}
             />
           )
           }
      />
    </div>
    </>
  )
}
