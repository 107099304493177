//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
//ESTILOS
import "../../components_cfdi/estilo.css";
import '../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import "./weektly_s.css";
import TextField from "@mui/material/TextField";
import { SelectColumnFilter } from "../../components_cfdi/filters";
import { get_semaphore } from "../../services/reports/reports";
import { pagination } from "../../main_components/pagination/pagination";
import TableContainer from "../../main_components/Table/TableContainer";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { Box } from '@mui/material';
import { calculate_total } from "../../main_components/methods_v2/auxiliary_functions";
import { SEARCH_FILTER_WEB_MOVIL } from "../../main_components/methods_v2/search_filter_web_movil";

const SUMMARY_WEEKLY_V2 = () => {
  
const notificationSystemRef = useRef();
const [data, setData] = useState([]);
const [datasemaforo, setdatasemaforo]=useState([]);
const [open, setopen]=useState(false);
const [modalT, setmodalT] = useState(false);
const [modalGeneral, setmodalGeneral] = useState(false);
const [isLoading, setIsLoading] = useState(false);
const [rowSelection, setRowSelection] = useState({});
const [date, setdate]=useState("");
const [weeks, setweeks]=useState(0);
const plantilla = [
  { code_pos: 'string' },
  { deleted_logical_pos: 'string' },
  { name_pos: 'string' },
  { name_route: 'string' },
  { total: 'number' }
]
const [update_identifiers, setupdate_identifiers] = useState([]);
const [datatotal, setdatatotal]=useState([]);
const [gt, setgt]=useState([]);
const [total_rows, settotal_rows] = useState("");

function alertas(msj,status){
  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}


useEffect(() => {
  handlesemaforo();
}, [])


const handlesemaforo=async()=>{
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_semaphore();
          const semaphores = (d === null ? [] : d.data.semaphores);
          
          semaphores.map((item)=>{
            if (item.minimum !== 0) {
              item.minimum /= 4;
              item.max /= 4;
            }else{
              item.max /= 4;
            }
          })

          console.log(semaphores);
          setdatasemaforo(semaphores);
        }catch(err){
          console.log(err);
          setdatasemaforo([]);
        }
      }, 1000);
    }else{}
  } catch (err) {
    console.log(err);
  }
}


const handleSubmit = async () => {
  
  if(date==="" || weeks===0){
    alertas("Valores Faltantes",false);
    return null;
  }
  
    setmodalT(true);
    setopen(true);
    setData([]);

    try {
      var token_rf = await refresh_token ();
        if(token_rf===true){
          setTimeout(async() => {
            try{
              var d = await pagination(3, "sumaryWeek", date, weeks);
              const ndata = d.flatMap((result) => result?.report || []);
              setgt(ndata?.map(obj=>obj.total).filter(d=>d!==null));
              
              const dtt = await totaldinamico(ndata);
              numerocolumnas(dtt);
              
              const newIdentifiers = Array.from({ length: weeks }, (_, index) => ({
                [`Semana_${index + 1}`]: 'number'
              }));
              const arraynuevo = [...plantilla, ...newIdentifiers];
              
              setupdate_identifiers(arraynuevo);  
              setData(ndata===null?[] : ndata);
              setopen(false);
              setmodalGeneral(false);
              setmodalT(false);
            }catch(err){
              setData([]);
              setopen(false)
              setmodalGeneral(false);
              setmodalT(false);
            }
           
          }, 1000);
        }else{} 
    } catch (err) {
      console.log(err); 
    }
};
const [columnsx, setcolumnsx] = useState([{ header: "Semanas" }]);


const totaldinamico = async(d) => {
  const result = [];
  for (let i = 1; i <= weeks; i++) {
      const semana = d.filter(item => item[`Semana_${i}`]);
     result[`Semana_${i}`] = semana.map(item => item[`Semana_${i}`]);
  }
  return result;
}


const numerocolumnas = (dtt) => {

  const newColumns = Array.from({ length: weeks }, (v, i) => ({
  id: `Semana_${i + 1}`,
  header: `Semana ${i + 1}`,
  accessorKey: `Semana_${i + 1}`,
  Cell: ({ cell }) => {
    return(
    <Box
      component="span"
      sx={(theme) => ({
        backgroundColor: theme.palette.success.dark,
        borderRadius: '0.25rem',
        color: '#fff',
        maxWidth: '9ch',
        p: '0.25rem',
      })}
    >
      {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) : cell.getValue()?.toLocaleString?.('es-MX', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}
    </Box>
  )},
  muiTableBodyCellProps: {
    align: 'right',
  },
  muiTableFooterCellProps: {
    align: 'right',
  },
  Footer: (d) => {
    const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj[`Semana_${i + 1}`])
    return (
      <div style={{ textAlign: "right" }}>
        <div>{Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
        }).format(calculate_total(subtotal))}</div>
        <div>{Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
        }).format(calculate_total(dtt[`Semana_${i + 1}`]))}</div>
      </div>
    )
  },   
  }));

  setcolumnsx([...newColumns.reverse()]);
};

const columns = useMemo(
  () => [
    {
      id: "code_pos",
      header: "Código",
      accessorKey:"code_pos",
      Footer: () => (
        <div>
          <div>Sub Total: </div>
          <div>Gran Total: </div>
        </div>
      ),
    },
    {
      id: "deleted_logical_pos",
      header:"Vigente",
      accessorKey: "deleted_logical_pos",
      accessorFn:(d)=>{
        return d.deleted_logical_pos===true?"NO":"SI";
      },
      // Filter: SelectColumnFilter,
    },
    {
      id: "name_pos",
      header: "Punto de Venta",
      accessorKey:"name_pos"
    },
    {
      id: "name_route",
      header: "Ruta",
      accessorKey: "name_route",
      //Filter: SelectColumnFilter,
    },
    ...columnsx,
    {
      id: "total",
      header: "Total",
      accessorKey: "total",
      Cell: ({ cell }) => {
        return(
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '9ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : cell.getValue()?.toLocaleString?.('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      )},
      muiTableBodyCellProps: {
        align: 'right',
      },
      muiTableFooterCellProps: {
        align: 'right',
      },
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.total)
        return (
          <div style={{ textAlign: "right" }}>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(subtotal))}</div>
            <div>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(calculate_total(gt))
            }</div> 
          </div>
        )
      },   
    },

     {
       id: "semaforo",
       header: "Semaforo",
       accessorKey: "semaforo",
       accessorFn: (d)=>{
         const item = datasemaforo.find((item) => 
         d.total >= (item.minimum===0?item.minimum:item.minimum*weeks) 
         && 
         d.total <= (item.max*weeks)
       );
         return (
            item.color_hex==="#ff0000"? "🔴" : 
              item.color_hex === "#43e000" ? "🟢" :
                item.color_hex === "#fff700" ? "🟡" : 
                  "Sin semáforo")
       },
       Cell: ({ cell }) => {
        return (
          <div className="semaforo-svg-anul">
            {cell.getValue()}
          </div>
        )
      },
      muiTableBodyCellProps:{
        align: "center"
      }
     }
    ],
    [datasemaforo,columnsx, gt, datatotal]
);


const handleStateChange = (newState) => {
  const filteredRowsCount = newState.filteredRowModel
  settotal_rows(filteredRowsCount);
};



  
const [minimenu, setminimenu]=useState(false);
const [openmini, setopenmini]=useState(false);

 useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 480) {
        console.log('Screen width is less than 480px');
        setminimenu(true);
      } else {
        setminimenu(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const filters_custom = (
    <div className="tools-sum-week">
            <div className="sumweek1">
                  <input
                        type="text"
                        className="fi" 
                        required
                        style={{m: 1.5, width:"100%"}}
                        onFocus={(e) => {
                          e.currentTarget.type = "date";
                          e.currentTarget.focus();
                        }}
                      placeholder="Fecha"
                      onChange={(event) => setdate(event.target.value)}
                  ></input>
                
            </div>

            <div className="sumweek2">
              <TextField
                  label="Número Semanas"
                  value={weeks}
                  type={"number"}
                  required
                  sx={{ m: 1.5, width: "100%" }}
                  onChange={(event) =>{setweeks(event.target.value)}} 
                  InputLabelProps={{ shrink: true }}
              ></TextField>
            </div>
            <div className="sumweek3">
              <button className="button" id="buttonpackageb" onClick={handleSubmit} type="button">
                  Buscar
              </button>
            </div>
          </div>
  );


return (
    <>
<div className="TableSummaryAnual">
  <div >         
  <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <MODAL_TABLE
    open={true}
    message={""}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></MODAL_TABLE>
    <>

    <BREADCRUMBS niveles={
      [
        {label:"REPORTE SUMARIA SEMANAL", path: null}, 
      ]
    }
      cards_dashboard={true}
      total_rows={total_rows !== "" ? total_rows : 0}
      filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
      visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
      total={data?.length}      
    ></BREADCRUMBS>
      <SEARCH_FILTER_WEB_MOVIL
          minimenu={minimenu} 
          setminimenu={setminimenu}
          openmini={openmini} 
          setopenmini={setopenmini}
          filterMovComponent={filters_custom}
          renderTopToolbarCustomActionsFilters={true}
        ></SEARCH_FILTER_WEB_MOVIL> 
      <div className="new_table_v2">            
      <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        onStateChange={handleStateChange}
        enableColumnFilterModes
        enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
        muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            settotal_rows(table);
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderTopToolbarCustomActions={({ }) => {
          if(minimenu===false){
            return(
            <>
              {filters_custom}
            </>
            )
          }
        }}
         renderToolbarInternalActions={({ table }) => (
           <RENDER_TOOLBAR_INTERNAL_ACTIONS
             table={table} 
             file_name={"Consultar Sumaria Semanal"}
             update_identifiers={update_identifiers}
             settotal_rows={settotal_rows}
           />
         )
        }
      />
      </div>
      </>           
    </div>
   </div>
    </>
  );
}

export default SUMMARY_WEEKLY_V2;
