//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import { CustomDialog, Confirm } from "react-st-modal";
import NotificationSystem from "react-notification-system";
import Button from "@mui/material/Button";

//COMPONENTES
import TableContainer from "../../main_components/Table/TableContainer";
import { get_daily_staff } from "../../services/reports/reports";
import { delete_daily_staff } from "../../services/reports/reports";
import CREATE_UPDATE from "./tools/create_update";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import AlertResponse from "../../main_components/alerts/alertResponse";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import SubTableContainer from "../../main_components/Table/SubTableContainer";
import { delete_products } from "../../services/inventory/inventory";
import { recovery_products } from "../../services/inventory/inventory";
import { SelectColumnFilter } from "../../components_cfdi/filters";
import { date_current } from "../../main_components/date/date";
import { previous_date } from "../../main_components/date/date";
import ReenvioCorreo from "../../components_cfdi/invoice/consult_invoice/report_email/send_invoice";
import { get_routes } from "../../services/cashclosing/cashclosing";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import SAVE from "../../imgComponents/save.png";
import DELETE from "../../imgComponents/borrar.png";
import CREATE from "../../imgComponents/create.png";
import VER from "../../imgComponents/reconsultaTable.png";

import TAX from "./tools/tax";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import REPORT from "../../imgComponents/iconos/icono-1.png";

import { get_daily_staff_user } from "../../services/reports/reports";

const DAILY_STAFF = (props) => {
  const [data, setData] = useState([]);
  const [modalT, setmodalT] = useState(true);
  const [menssage, setMenssage] = useState(true);
  const [ff, setff] = useState(date_current);
  const [fi, setfi] = useState(previous_date);

  var permisosUsuario = sessionStorage.getItem("roles_user");
  var permisoUsuarioReplace = permisosUsuario?.replace(/ /g, "");
  var permisos = permisoUsuarioReplace?.split(",");
  var permisoconf = permisos?.filter((x) => x === "ROLE_CONFIGURATION");

  console.log(props.subtabla);
  const notificationSystemRef = useRef();

  const handleSubmit = async (a, b) => {
    setmodalT(true);
    setMenssage("Productos...");

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            console.log("entra dato");
            const dt = await get_daily_staff(fi, ff);
            setData(dt.data);
            setmodalT(false);
          } catch (err) {
            setData([]);
            setMenssage("");
            setmodalT(false);
          }
        }, 1000);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handle_alert = (msj, status) => {
    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: "success",
        position: "br",
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
    }
  };

  const handle_delete = async (id) => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            await delete_daily_staff(id);
            handle_reconsult(true, "Producto eliminado correctamente");
            handle_alert("Producto eliminado correctamente", true);
          } catch (err) {
            handle_alert(JSON.stringify(err?.response?.data), false);
          }
        }, 1000);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handle_reconsult = (a, b) => {
    if (a) {
      handleSubmit(a, b);
    } else if (a === false) {
      handle_alert(b, a);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {
          return (
            <img
              alt=""
              title="Crear asignación"
              src={CREATE}
              className="AddDatapointer"
              onClick={async () => {
                const result = await CustomDialog(
                  <CREATE_UPDATE
                    id_route_fk={"SELECCIONA UNA RUTA"}
                    date={"Selecciona una fecha"}
                    route={"SELECCIONA UNA RUTA"}
                    id_seller_employee_fk={"SELECCIONA UN USUARIO"}
                    id_seller_asst_employee_fk={"SIN USUARIO"}
                    id_dealer_employee_fk={"SELECCIONA UN USUARIO"}
                    id_dealer_asst_employee_fk={"SIN USUARIO"}
                    method={"CREATE"}
                    alertas={handle_alert}
                    setmodalT={setmodalT}
                    handleSubmit={handleSubmit}
                  ></CREATE_UPDATE>,
                  {
                    className: "modalProduct",
                    title: "Crear asignación",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );
                // eslint-disable-next-line no-lone-blocks
                {
                  handle_reconsult(result?.validate, result?.msj);
                }
              }}
            ></img>
          );
        },
        Cell: ({ row }) => {
          return (
            <div>
              <img
                alt=""
                title="Actualizar asignación"
                src={SAVE}
                onClick={async () => {
                  const result = await CustomDialog(
                    <CREATE_UPDATE
                      subtabla={props.subtabla}
                      id={row.original.id}
                      date={row.original.date}
                      id_route_fk={row.original.id_route_fk}
                      route={row.original.route}
                      id_seller_employee_fk={row.original.id_seller_employee_fk}
                      id_seller_asst_employee_fk={
                        row.original.id_seller_asst_employee_fk
                      }
                      id_dealer_employee_fk={row.original.id_dealer_employee_fk}
                      name_dealer_employee_fk={
                        row.original.name_dealer_employee
                      }
                      name_seller_employee_fk={
                        row.original.name_seller_employee
                      }
                      id_dealer_asst_employee_fk={
                        row.original.id_dealer_asst_employee_fk
                      }
                      method={"UPDATE"}
                      alertas={handle_alert}
                      setmodalT={setmodalT}
                      handleSubmit={handleSubmit}
                    ></CREATE_UPDATE>,
                    {
                      className: "modalProduct",
                      title: "Actualizar producto",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  // eslint-disable-next-line no-lone-blocks
                  {
                    handle_reconsult(result?.validate, result?.msj);
                  }
                }}
                className="centerText, taxes"
              ></img>

              <img
                alt=""
                title="Eliminar asignación"
                src={DELETE}
                className="centerText, taxes"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar esta asignación de ruta?",
                    "Eliminar Asignación de rutas",
                    "Si",
                    "No"
                  );
                  if (result) {
                    handle_delete(row.original.id);
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
      },

      {
        Header: "Fecha",
        accessor: "date",
      },
      {
        Header: "Ruta",
        accessor: "route",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Vendedor",
        accessor: "name_seller_employee",
      },
      {
        Header: "Ayudante de vendedor",
        accessor: "name_seller_asst_employee",
      },
      {
        Header: "Repartidor",
        accessor: "name_dealer_employee",
      },
      {
        Header: "Ayudante de repartidor",
        accessor: "name_dealer_asst_employee",
      },
    ],
    []
  );

  return (
    <div>
      <BREADCRUMBS
        niveles={[
          { label: "ASIGNACIÓN DE PERSONAL", path: null },
          permisoconf.length === 0
            ? ""
            : { label: "REGISTRO DE COMISIONES", path: "/commissions" },
        ]}
        new_version={true}
        modul="CONSULTAR ASIGNACIÓN DE PERSONAL V2"
        path="/daily_staff_v2"  
      ></BREADCRUMBS>

      <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>

      <div className="containerExpenseReport2">
        <div className="expen-1 color-1">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha inicial"
            onChange={(event) => setfi(event.target.value)}
          ></input>
        </div>
        <div className="expen-2 color-2">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha Final"
            onChange={(event) => setff(event.target.value)}
          ></input>
        </div>

        <div className="expen-4 color-5 topSelect">
          <img
            alt=""
            src={REPORT}
            className="reportInvoice"
            title="GENERAR REPORTE"
            style={{cursor:"pointer"}}
            onClick={async () => {
              await CustomDialog(
                <ReenvioCorreo report={"commissions"}></ReenvioCorreo>,
                {
                  className: "custom-modal",
                  title:
                    'Nota: SEPARA LOS CORREOS ELECTRÓNICOS A TRAVÉS DE UNA "," SI DESEAS ENVIAR MÁS DE UN CORREO ELECTRÓNICO',
                  showCloseIcon: true,
                }
              );
            }}
          ></img>
        </div>

        <div className="expen-3 color-5">
          <Button
            sx={{ width: "100%" }}
            variant="contained"
            onClick={handleSubmit}
          >
            Buscar
          </Button>
        </div>
      </div>

      <div className="TableTaxes TableProduct">
        <div>
          <TableContainer
            columns={columns}
            data={data}
            exportar={true}
            nametable={"Tabla Asignación de Personal"}
            style={{ overflowx: 10 }}
            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }}>
                  <TAX />
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DAILY_STAFF;
