//PAQUETERIAS
import { useEffect, useState, useMemo } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
//COMPONENTES
import { MaterialUISwitch } from "../../main_components/material_switch/switch";
import { get_warehouse } from "../../services/inventory/inventory";
import { get_warehouse_existence } from "../../services/inventory/inventory";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import "./stock.css";
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";
import { SEARCH_FILTER_WEB_MOVIL } from "../../main_components/methods_v2/search_filter_web_movil";

const STOCK_V2 = () => {
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([])
  const [open, setOpen] = useState(true);
  const [menssage, setMenssage] = useState(true);
  const [warehouse, setwarehouse] = useState([]);
  const [warehouse_name, setwarehouse_name] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [filtersColumsView, setfiltersColumsView] = useState(true);
  const [total_rows, settotal_rows] = useState("");


  const handle_warehouse = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const dt = await get_warehouse();
            const data = dt.data;
            var dtAdd = {
              id: -1,
              warehouse_name: "ENTRADA",
            };
            data.push(dtAdd);
            setwarehouse(data);
            handleSubmit();
          } catch (err) {
            setwarehouse([]);
          }
        }, 1000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };


  const handleSubmit = async (event) => {
    event?.preventDefault();
    setOpen(true);
    setMenssage("Productos...");
    setData([]);
    setIsLoading(true);
    setopenmini(false);

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const dt = await get_warehouse_existence(warehouse_name);
            var ndt = update_null_identifiers(dt?.data, update_identifiers);
            setData(ndt?.sort((a, b) => b.quantity - a.quantity));
            setData2(ndt?.sort((a, b) => b.quantity - a.quantity));
            setOpen(false);
            setIsLoading(false);
          } catch (err) {
            setData([]);
            setMenssage("");
            setIsLoading(false);
          }
        }, 1000);
      }
    } catch (err) {
      console.log(err);
    }
  };


  const fechaActual = new Date();
  const anio = fechaActual.getFullYear();
  const mes = String(fechaActual.getMonth() + 1).padStart(2, '0'); // Sumamos 1 al mes ya que los meses en JavaScript van de 0 a 11
  const dia = String(fechaActual.getDate()).padStart(2, '0');
  const fechaFormateada = `${anio} / ${mes} / ${dia}`;


  useEffect(() => {
    handle_warehouse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns = useMemo(
    () => [
      {
        id: "code",
        header: "Código",
        accessorKey: "code",
        size: 300,
        muiTableBodyCellProps: {
          align: "center"
        }
      },
      {
        id: "description",
        header: "Descripción",
        accessorKey: "description",
        size: 400,
      },
      {
        header: "Cantidad",
        accessorKey: "quantity",
        size: 300,
        muiTableBodyCellProps: {
          align: "center"
        }
      },

    ],
    []
  );

  const update_identifiers =
    [
      { code: 'string' },
      { description: 'string' },
      { quantity: 'String' },
    ];

  const filtrar = () => {
    setData(data2.filter(item => item.quantity !== 0));
  }

  const handlechecked = (event) => {

    if (event.target.checked) {
      setData(data2);
    } else {
      setData(data2.filter(item => item.quantity !== 0));
    }
    setfiltersColumsView(event.target.checked);
  };

  
  const [minimenu, setminimenu]=useState(false);
  const [openmini, setopenmini]=useState(false);

   useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 480) {
          console.log('Screen width is less than 480px');
          setminimenu(true);
        } else {
          setminimenu(false);
        }
      };
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    const filters_custom = (
      <>
      <div className="containerStockv2">
        <div className="pdc-1 color-1">
          <TextField
            select
            label="Origen"
            child
            value={warehouse_name}
            sx={{ m: 1.5, width: "90%" }}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            SelectProps={{
              native: true,
            }}
            onChange={(e) => setwarehouse_name(e.target.value)}

          >
            {warehouse?.map((option) => (
              <option
                key={option?.id}
                value={option?.id}
                selected={option?.id}
              >
                {option?.warehouse_name}
              </option>
            ))}
          </TextField>
        </div>
        <div className="pdc-2 existProduct">
          <Button
            sx={{ width: "100%" }}
            variant="contained"
            onClick={handleSubmit}
          >
            Buscar
          </Button>
        </div>
        <div className="pdc-3">
          <div className="">
            <FormControlLabel
              control={
                <MaterialUISwitch
                  sx={{ m: 1 }}
                  onChange={handlechecked}
                  checked={filtersColumsView}
                />
              }
              label={
                filtersColumsView === true
                  ? "Ocultar productos sin inventario" : "Visualizar productos sin inventario"
              }
            />
          </div>
        </div>
      </div>
    </>
    );

  return (
    <div >
      <div>

      </div>
      <BREADCRUMBS niveles={
        [
          { label: "CONSULTAR ALMACEN DE PRODUCTOS", path: null },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>
      <br></br>
        <SEARCH_FILTER_WEB_MOVIL
          minimenu={minimenu} 
          setminimenu={setminimenu}
          openmini={openmini} 
          setopenmini={setopenmini}
          filterMovComponent={filters_custom}
          renderTopToolbarCustomActionsFilters={true}
        ></SEARCH_FILTER_WEB_MOVIL> 
       

      <div className="new_table_v2">
        <div >
          <MaterialReactTable
            columns={columns}
            data={data}
            enableGrouping
            enablePinning
            enableFacetedValues
            enableStickyHeader
            enableStickyFooter
            enableRowPinning
            onStateChange={handleStateChange}
            enableColumnFilterModes
            enableRowActions={false}
            enableRowSelection
            getRowId={(row) => row?.id}
            onRowSelectionChange={setRowSelection}
            enableTooltips={false}
            localization={MRT_Localization_ES}
            state={{ rowSelection, isLoading: isLoading }}
            enableColumnResizing
            enableColumnPinning
            enableColumnOrdering
            enableExpandAll={false}
            enableColumnDragging={false}
            initialState={{ showGlobalFilter: true, showColumnFilters: true }}
            muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
            muiSkeletonProps={{ animation: 'pulse', height: 28 }}
            rowVirtualizerInstanceRef
            rowVirtualizerOptions={{ overscan: 5 }}
            columnVirtualizerOptions={{ overscan: 2 }}
            muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
            positionToolbarAlertBanner="bottom"
            paginationDisplayMode='pages'
            rowPinningDisplayMode='sticky'
            layoutMode="grid"
            muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
            muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
            muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
            displayColumnDefOptions={{
              'mrt-row-pin': {
                enableHiding: true,
              },
              'mrt-row-actions': {
                enableHiding: true,
              },
              'mrt-row-expand': {
                enableHiding: true,
              },
              'mrt-row-select': {
                enableHiding: true,
              }
            }}
            muiTableBodyRowDragHandleProps={({ table }) => ({
              onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                  data.splice(
                    hoveredRow.index,
                    0,
                    data.splice(draggingRow.index, 1)[0],
                  );
                  setData([...data]);
                }
              },
            })}
            muiTableBodyRowProps={
              ({ row, table }) => {
                settotal_rows(table);
                const { density } = table.getState();
                return {
                  sx: {
                    height: row.getIsPinned()
                      ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                      }px`
                      : undefined,
                  },
                };
              }
            }
            renderTopToolbarCustomActions={({ }) => {
              if(minimenu===false){
                return(
                <>
                  {filters_custom}
                </>
                )
              }
            }}

            renderToolbarInternalActions={({ table }) => (
              <RENDER_TOOLBAR_INTERNAL_ACTIONS
                table={table} file_name={"Almacén de productos"}
                update_identifiers={update_identifiers}
                settotal_rows={settotal_rows}
              />
            )
            }
          />
        </div>
      </div>
    </div>
  );
};

export default STOCK_V2;
