//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { CustomDialog, Confirm } from "react-st-modal";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

//COMPONENTES

import TableContainerData from "../table_movements";
import { get_warehouse_existence } from "../../../services/inventory/inventory";
import { create_warehouse_movements } from "../../../services/inventory/inventory";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { get_warehouse } from "../../../services/inventory/inventory";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import Autocomplete from "@mui/material/Autocomplete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";

import {
  GENERATE_COLUMNS,
  SHOWDATA,
} from "../../../main_components/Table/generate_columns";
import { COLUMS_SHOW } from "../../../main_components/Table/ColumsShow";
import MOVEMENTS from "../movements";
//ESTILOS
import "./movements.css";
//IMAGENES/ICONOS
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { get_detil_warehouse } from "../../../services/inventory/inventory";
import { MaterialUISwitch } from "../../../main_components/material_switch/switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import LOAD from "../../../imgComponents/actualizar.png";


import steps4 from "./tour/tour4.json"
import steps5 from "./tour/tour5.json"

export const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const WAREHOUSE_MOVEMENTS = () => {
  const [dataTable, setDataTable] = useState([]);
  const [dataTable_search, setDataTable_search] = useState([]);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [reference, setreference] = useState("");
  const [note, setnote] = useState("");
  const [modalT, setmodalT] = useState(true);
  const [originalData] = useState(dataTable);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [quantity, setquantity] = useState(0);
  const [origin, setorigin] = useState(0);
  const [destination, setdestination] = useState(0);
  const [almacenO, setalmacenO] = useState(0);
  const [almacenD, setalmacenD] = useState(0);
  const [filtersColumsView, setfiltersColumsView] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [tableData_data, setTableData_data] = useState([]);
  const [flag_move, setflag_move] = useState(false);
  const [run, setRun] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [cont, setcont] = useState(0);

  const [stepselect, setstepselect] = useState([]);
  const [valid, setvalid] = useState(false);


  useEffect(() => {
    if (run === false) {
      console.log(run);
      console.log(stepselect.length);

      if (stepselect.length === 5 || stepselect.length === 2) {
        setvalid(true);
      }

      if (stepselect.length === 2) {
        setRun(true);
      }
    }
  }, [run]);
  const handleClickIcon = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleClickIconTimeout = (d) => {
    setstepselect(d);
    console.log(d);
    console.log(d);
    setTimeout(() => {
      setCurrentStep(0);
    }, 100);
    setCurrentStep(0);
    setstepselect(d);
  };


  const handleProductChange = (event, newValue) => {
    setSelectedProduct(newValue);
    handleClickIcon();
  };

  const handleQuantityChange = (event) => {
    if (sessionStorage.getItem("origin") === "-1") {
      setSelectedProduct((prevProduct) => ({
        ...prevProduct,
        quantity: Number(event.target.value),
      }));
    } else {
      function validarDato(datoUsuario, datoOrigen) {
        if (datoUsuario === "0") {
          return datoUsuario;
        } else if (datoUsuario.startsWith("0") && datoUsuario.length > 1) {
          return datoUsuario.slice(1);
        } else if (datoUsuario <= datoOrigen) {
          return Number(datoUsuario);
        } else {
          const nuevoDato = datoUsuario.slice(0, -1);
          return validarDato(nuevoDato, datoOrigen);
        }
      }

      var quantity_data = validarDato(
        event.target.value,
        selectedProduct.origin
      );
      console.log(tableData_data);
      console.log(quantity_data);
      setSelectedProduct((prevProduct) => ({
        ...prevProduct,
        quantity: quantity_data,
      }));

      console.log(selectedProduct);
    }
  };

  function actualizarObjetoOriginal(
    dataConCantidadesNumericas,
    selectedProduct,
    dataTable
  ) {
    const mapaObjetos = new Map();

    for (const objeto of dataConCantidadesNumericas) {
      const codigo = objeto.code;
      if (mapaObjetos.has(codigo)) {
        const quantity = parseInt(objeto.quantity, 10);
        if (!isNaN(quantity)) {
          mapaObjetos.get(codigo).quantity += quantity;
        }
      } else {
        mapaObjetos.set(codigo, { ...objeto });
      }
    }

    const codigoB = selectedProduct?.code;
    if (mapaObjetos.has(codigoB)) {
      const quantityB = parseInt(selectedProduct?.quantity, 10);
      if (!isNaN(quantityB)) {
        mapaObjetos.get(codigoB).quantity += quantityB;
      }
    } else {
      mapaObjetos.set(codigoB, { ...selectedProduct });
    }

    const objetosFusionados = [...mapaObjetos.values()];

    const nuevoObjetoMap = new Map(
      objetosFusionados.map((item) => [item.code, item])
    );

    const objetoOriginalActualizado = dataTable.map((item) => {
      const newItem = nuevoObjetoMap.get(item.code);
      return newItem ? newItem : item;
    });

    return objetoOriginalActualizado;
  }

  function restarCantidad(objeto1, objeto2) {
    const indice = objeto1.findIndex(
      (elemento) => elemento.code === objeto2[0].code
    );
    console.log(indice);
    if (indice !== -1) {
      objeto1[indice].quantity -= objeto2[0].quantity;
      console.log(objeto1[indice].quantity);
      console.log(objeto2[0].quantity);
    }

    return objeto1;
  }

  const handleAddToTable = () => {
    if (selectedProduct) {
      console.log(selectedProduct);
      console.log("_____________________________________");
      console.log(tableData_data);
      console.log(selectedProduct);
      const dataConCantidadesNumericas = tableData_data.map((objeto) => ({
        ...objeto,
        quantity: parseInt(objeto.quantity, 10),
      }));
      console.log(dataConCantidadesNumericas);
      console.log("_____________________________________");

      var objetoOriginalActualizado = actualizarObjetoOriginal(
        dataConCantidadesNumericas,
        selectedProduct,
        dataTable
      );
      function sumarCantidadPorCodigo(principal, nuevoDato) {
        const codigo = nuevoDato.code;
        const sumaCantidad = principal
          ?.filter((item) => item.code === codigo)
          ?.reduce((total, item) => Number(total) + Number(item.quantity), 0);

        return sumaCantidad;
      }

      const suma = sumarCantidadPorCodigo(
        dataConCantidadesNumericas,
        selectedProduct
      );
      console.log(suma);
      console.log(Number(selectedProduct.quantity));
      console.log(Number(selectedProduct.origin));

      if (
        suma + Number(selectedProduct.quantity) >
        Number(selectedProduct.origin)
      ) {
        if (sessionStorage.getItem("origin") === "-1") {
          setTableData_data((prevData) => [...prevData, selectedProduct]);
          setDataTable(objetoOriginalActualizado);
        } else {
          alert(
            "No se puede agregar este dato. La suma de cantidad supera el origen."
          );
          console.log(suma);
        }
      } else {
        setTableData_data((prevData) => [...prevData, selectedProduct]);
        setDataTable(objetoOriginalActualizado);
      }

      setSelectedProduct(null);
    }
  };
  const handleQuantity_table = (event) => {
    console.log(tableData_data);

    var nquuantity = tableData_data.filter((d) => d.quantity !== "");
    console.log(nquuantity);
    var cantidad = nquuantity
      .map((a) => Number(a.quantity))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);
    setquantity(cantidad);
  };

  useEffect(() => {
    handleQuantity_table();
  }, [tableData_data]);
  const handleRemoveFromTable = (productToRemove) => {
    setTableData_data((prevData) =>
      prevData.filter((product) => product !== productToRemove)
    );
    console.log(productToRemove);

    var filterdta = tableData_data.filter(
      (product) => product === productToRemove
    );
    console.log(filterdta);
    const dataConCantidadesNumericas = filterdta.map((objeto) => ({
      ...objeto,
      quantity: parseInt(objeto.quantity, 10),
    }));
    const dataConCantidadesNumericas2 = dataTable.map((objeto) => ({
      ...objeto,
      quantity: parseInt(objeto.quantity, 10),
    }));
    console.log(dataTable);
    console.log(dataConCantidadesNumericas);
    var objetoOriginalActualizado = restarCantidad(
      dataConCantidadesNumericas2,
      dataConCantidadesNumericas
    );
    console.log(objetoOriginalActualizado);
    setDataTable(objetoOriginalActualizado);
  };

  const handleSubmit = async (event) => {
    event?.preventDefault();
    setmodalT(true);
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const dt = await get_warehouse_existence(
              sessionStorage.getItem("origin")
            );
            const dt2 = await get_warehouse_existence(
              sessionStorage.getItem("destination")
            );
            setData(dt.data);
            setData2(dt2.data);
            const ndatadestination = dt2.data.map((e) => ({
              destination: e.quantity,
            }));
            if (sessionStorage.getItem("origin") === "-1") {
              const ndata = dt2.data.map((e, index) => ({
                code: e.code,
                id: e.id_products_fk,
                description: e.description,
                origin: 0,
                quantity: 0,
                destination: e.quantity,
              }));

              ndata.sort((a, b) => b.origin - a.origin);
              setDataTable(ndata);
              setDataTable_search(ndata);
            } else {
              const ndata = dt.data.map((e, index) => ({
                code: e.code,
                id: e.id_products_fk,
                description: e.description,
                origin: e.quantity,
                quantity: 0,
                destination: ndatadestination[index].destination,
              }));
              ndata.sort((a, b) => b.origin - a.origin);
              setDataTable(ndata);
              setDataTable_search(ndata);
            }

            setmodalT(false);
          } catch (err) {
            setDataTable([]);
          }
        }, 1000);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handle_warehouse = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const dt = await get_warehouse();
            const data = dt.data;
            var dtAdd = {
              id: -1,
              warehouse_name: "ENTRADA",
            };
            var dtAdd2 = {
              id: -2,
              warehouse_name: "SELECCIONA UN ALMACEN",
            };

            data.push(dtAdd);
            data.push(dtAdd2);
            const origenWarehose = data.filter(
              (d) => d.id === Number(sessionStorage.getItem("origin"))
            );
            const destinationWarehose = data.filter(
              (d) => d.id === Number(sessionStorage.getItem("destination"))
            );
            setalmacenO(origenWarehose[0]?.warehouse_name);
            setalmacenD(destinationWarehose[0]?.warehouse_name);
          } catch (error) { }
        }, 1000);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handle_move_all_product = () => {
    setflag_move(true);
    const ndata = data.map((e, index) => ({
      code: e.code,
      description: e.description,
      origin: e.quantity,
      id: e.id_products_fk,
      quantity: e.quantity,
      destination: data2[index]?.quantity,
    }));
    ndata.sort((a, b) => b.origin - a.origin);

    setDataTable(ndata);
    var ntest = ndata.filter((d) => d.quantity !== "");
    var cantidad = ntest
      .map((a) => Number(a.quantity))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);
    setquantity(cantidad);
  };

  const handle_create_movement = async () => {
    setmodalT(true);
    const ndt = dataTable
      .filter((d) => d.quantity && d.quantity !== 0)
      .map((d) => {
        var dt = {
          id: d.id,
          quantity: d.quantity,
        };
        return dt;
      });

    var env_data = {
      origin: sessionStorage.getItem("origin"),
      destination: sessionStorage.getItem("destination"),
      note: note,
      reference: reference,
      movement: ndt,
    };

    console.log(env_data);

    if (ndt.length > 0) {
      setmodalT(true);
      try {
        var rf_token = await refresh_token();
        if (rf_token === true) {
          setTimeout(async () => {
            try {
              await create_warehouse_movements(env_data);
            } catch (err) {
              alert(JSON.stringify(err.response));
            } finally{
              setmodalT(false);
              window.location = "/movements";
            }
          }, 1000);
        } else {
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("Realiza almenos un movimiento");
    }
  };

  const handle_detail_warehouse = async (id) => {
    try {
      const isvaild = await refresh_token();
      if (isvaild) {
        setTimeout(async () => {
          try {
            const dt = await get_detil_warehouse(id);
            dataTable.forEach((productoPrincipal) => {
              const productoSecundario = dt.data.find(
                (producto) => producto.code === productoPrincipal.code
              );

              if (productoSecundario) {
                if (productoSecundario.quantity <= productoPrincipal.origin) {
                  productoPrincipal.quantity = productoSecundario.quantity;
                } else {
                  console.log("supera el dato");
                  if (sessionStorage.getItem("origin") === "-1") {
                    productoPrincipal.quantity = productoSecundario.quantity;
                    console.log("es origen");
                  } else {
                    productoPrincipal.quantity = 0;
                  }
                }
              }
            });
            var dta = dataTable;
            console.log(dta);
            var cantidad = dta
              .map((a) => Number(a.quantity))
              .reduce((previous, current) => {
                return previous + current;
              }, 0);
            setquantity(cantidad);
            console.log(cantidad);
            setDataTable(dta);
          } catch (err) {
            console.log([]);
          }
        }, 1500);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleSubmit();
    handle_warehouse();
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: () => {
          function load_data() {
            window.location = "/werehouse_movements";
          }
          return (
            <img
              alt=""
              title="Volver al estado original"
              onClick={() => {
                load_data();
              }}
              src={LOAD}
            ></img>
          );
        },
        Cell: ({ row }) => {
          return row.index + 1;
        },
      },

      {
        id: "code",
        Header: "Código",
        accessor: "code",
      },
      {
        id: "description",
        Header: "Descripción",
        accessor: "description",
      },
      {
        Header: "Cantidad",

        Cell: (row) => {
          const [quantity_data, setquantity_data] = useState(
            row?.row?.original?.quantity
          );
          const [activar, setactivar] = useState(false);

          const editcell = (e) => {
            setflag_move(true);
            setactivar(true);
            if (sessionStorage.getItem("origin") === "-1") {
              dataTable[row.row.index] = {
                code: row.row.original.code,
                description: row.row.original.description,
                origin: row.row.original.origin,
                quantity: e.target.value,
                id: row.row.original.id,
                destination: row.row.original.destination,
              };
              var ntest = dataTable.filter((d) => d.quantity !== "");
              var cantidad = ntest
                .map((a) => Number(a.quantity))
                .reduce((previous, current) => {
                  return previous + current;
                }, 0);

              setquantity(cantidad);
              var dt = dataTable;
              setquantity_data(e.target.value);
              setDataTable(dt);
            } else {
              if (e.target.value > 0) {
                dataTable[row.row.index] = {
                  code: row.row.original.code,
                  description: row.row.original.description,
                  origin: row.row.original.origin,
                  id: row.row.original.id,
                  quantity: validarDato(
                    e.target.value,
                    row.row.original.origin
                  ),
                  destination: row.row.original.destination,
                };

                function eliminarDatosRepetidos(datosPrincipales, nuevoDato) {
                  const codigo = nuevoDato.code;
                  const nuevosDatos = datosPrincipales.filter(
                    (item) => item.code !== codigo
                  );

                  return nuevosDatos;
                }

                var new_data = dataTable[row.row.index];
                console.log(new_data);
                console.log(tableData_data);
                const nuevoArregloPrincipal = eliminarDatosRepetidos(
                  [...tableData_data],
                  new_data
                );

                var np = eliminarDatosRepetidos(tableData_data, new_data);

                nuevoArregloPrincipal[row.row.index] = {
                  code: row.row.original.code,
                  description: row.row.original.description,
                  origin: row.row.original.origin,
                  id: row.row.original.id,
                  quantity: validarDato(
                    e.target.value,
                    row.row.original.origin
                  ),
                  destination: row.row.original.destination,
                };

                console.log(np);
                console.log(nuevoArregloPrincipal);

                np.push(nuevoArregloPrincipal[0]);
                console.log(np);

                console.log(e.target.value);
                var ntest = dataTable.filter((d) => d.quantity !== "");
                var cantidad = ntest
                  .map((a) => Number(a.quantity))
                  .reduce((previous, current) => {
                    return previous + current;
                  }, 0);
                setquantity(cantidad);
                var ndt = dataTable;
                var table_resumen = np;
                setquantity_data(
                  validarDato(e.target.value, row.row.original.origin)
                );
                console.log(table_resumen);
                setDataTable(ndt);
              } else {
                console.log("entra dato");
                dataTable[row.row.index] = {
                  code: row.row.original.code,
                  description: row.row.original.description,
                  origin: row.row.original.origin,
                  id: row.row.original.id,
                  quantity: validarDato(0, row.row.original.origin),
                  destination: row.row.original.destination,
                };

                var ntest = dataTable.filter((d) => d.quantity !== "");
                console.log(row.row.original.origin);
                var cantidad = ntest
                  .map((a) => Number(a.quantity))
                  .reduce((previous, current) => {
                    return previous + current;
                  }, 0);
                setquantity(cantidad);
                var ndt = dataTable;

                setquantity_data(validarDato(0, row.row.original.origin));
                setDataTable(ndt);
              }
            }

            function validarDato(datoUsuario, datoOrigen) {
              if (datoUsuario <= datoOrigen) {
                return Number(datoUsuario);
              } else {
                const nuevoDato = datoUsuario.slice(0, -1);
                return validarDato(nuevoDato, datoOrigen);
              }
            }
          };

          return (
            <div>
              <input
                value={
                  dataTable[row.row.index].quantity === ""
                    ? Number(quantity_data)
                    : Number(dataTable[row.row.index].quantity)
                }
                className="step-3 step-5 step-14 step-16"
                onClick={(e) => {
                  console.log(stepselect.length);

                  if (stepselect.length === 3) {
                    handleClickIcon();
                  } else {
                    if (stepselect.length === 5) {
                      handleClickIcon();
                    } else {
                      console.log(stepselect.length);
                    }
                  }
                }}
                onChange={(e) => {
                  editcell(e);
                  if (run === true) {
                    handleClickIcon()
                  }
                }}
              ></input>
            </div>
          );
        },
      },
      {
        id: "Origen",
        Header: "Origen",
        accessor: "origin",
        Cell: ({ value }) => <div className="step-2 step-6">{value}</div>,
      },
      {
        id: "destination",
        Header: "Destino",
        accessor: "destination",
      },
    ],
    [dataTable, tableData_data, currentStep]
  );
  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setDataTable((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          console.log(columnId);
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  useEffect(() => {
    setSkipPageReset(false);

    setorigin(
      dataTable
        .map((a) => Number(a.origin))
        .reduce((previous, current) => {
          return previous + current;
        }, 0)
    );
    setdestination(
      dataTable
        .map((a) => Number(a.destination))
        .reduce((previous, current) => {
          return previous + current;
        }, 0)
    );
  }, [dataTable]);

  useEffect(() => {
    setDataTable(dataTable);
  }, [dataTable]);



  console.log(stepselect?.length);

  return (
    <div>
      <BREADCRUMBS
        niveles={[
          { label: "MOVIMIENTO DE PRODUCTOS", path: "/movements" },
          {
            label: "GENERAR MOVIMIENTO POR TABLA",
            path: null,
          },
        ]}
      ></BREADCRUMBS>



      <div>
        <h3>
          MOVIMIENTO DEL ALMACÉN: {almacenO} AL ALMACÉN: {almacenD}
        </h3>
      </div>
      <MODAL_TABLE open={modalT} modalT={modalT}></MODAL_TABLE>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between", // Distribución proporcional
          height: "100%", // Esto es opcional según tus necesidades
          width: "95%",
          margin: "0 auto",
        }}
      >
        <div style={{ flex: 5 }}>
          <ValidationTextField
            label="Referencia"
            placeholder="Referencia"
            variant="outlined"
            sx={{ m: 2, width: "98%" }}
            defaultValue="Success"
            name="capital_regime"
            value={reference}
            onChange={(e) => {
              const value = e.target.value;
              if (typeof value === "string") {
                setreference(value.toUpperCase());
              } else {
                setreference(value);
              }
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div style={{ flex: 5 }}>
          <ValidationTextField
            label="Nota"
            placeholder="Nota"
            variant="outlined"
            sx={{ m: 2, width: "96%" }}
            defaultValue="Success"
            name="capital_regime"
            value={note}
            onChange={(e) => {
              const value = e.target.value;
              if (typeof value === "string") {
                setnote(value.toUpperCase());
              } else {
                setnote(value);
              }
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between", // Distribución proporcional
          height: "100%", // Esto es opcional según tus necesidades
          width: "95%",
          margin: "0 auto",
        }}
      >
        <div style={{ flex: 4 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between", // Distribución proporcional
              height: "100%", // Esto es opcional según tus necesidades
              width: "95%",
              margin: "0 auto",
            }}
          >
            <div style={{ flex: 9.9 }}>
              <Button
                sx={{ width: "95%" }}
                className="step-1"
                disabled={
                  sessionStorage.getItem("origin") === "-1"
                    ? true
                    : filtersColumsView === true
                      ? false
                      : true
                }
                variant="contained"
                onClick={() => {
                  handle_move_all_product();
                  handleClickIcon();
                }}
              >
                Mover Todo el Producto
              </Button>
            </div>


          </Box>
        </div>
        <div style={{ flex: 4 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "100%",
              width: "95%",
              margin: "0 auto",
            }}
          >
            <div style={{ flex: 9.9 }}>
              <Button
                sx={{ width: "95%" }}
                variant="contained"
                className="step-8"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de relizar este movimiento?",
                    "Realizar movimiento",
                    "Si",
                    "No"
                  );
                  if (result) {
                    handle_create_movement();
                  } else {
                  }
                }}
              >
                Crear Movimiento
              </Button>
            </div>


          </Box>
        </div>
        <div style={{ flex: 1 }}>
          <Button
            sx={{ width: "95%" }}
            variant="contained"
            onClick={async () => {
              const result = await CustomDialog(
                <MOVEMENTS copy={true}></MOVEMENTS>,
                {
                  className: "modalProduct",
                  title: "Movimiento de producto",
                  showCloseIcon: true,
                  isCanClose: false,
                }
              );
              if (result !== undefined) {
                handle_detail_warehouse(result);
              } else {
                console.log("se cancelo");
              }
            }}
          >
            Copiar Movimiento
          </Button>
        </div>
        <div style={{ flex: 1 }}>
          <FormControlLabel
            disabled={flag_move}
            className="step-4 step-7 step-15 step-17 step-19"
            control={
              <MaterialUISwitch
                className="step-13"
                sx={{ m: 1 }}
                onChange={(e) => {
                  setfiltersColumsView(e.target.checked);
                  console.log(stepselect.length);
                  if (stepselect.length === 5) {
                    handleClickIconTimeout(steps4);
                  } else {
                    if (stepselect.length === 2) {
                      handleClickIconTimeout(steps5);
                    } else {
                      if (stepselect.length === 3) {
                        handleClickIconTimeout(steps5);
                      } else {
                        handleClickIcon();
                      }
                    }
                  }
                }}
                checked={filtersColumsView}
              />
            }
            label={
              filtersColumsView === true
                ? "Movimiento manual"
                : "Movimiento por Tabla"
            }
          />
        </div>
      </Box>

      <br></br>
      <div style={{ display: filtersColumsView === true ? "none" : "" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between", // Distribución proporcional
            height: "100%", // Esto es opcional según tus necesidades
            width: "95%",
            margin: "0 auto",
          }}
        >
          <div
            style={{
              flex: 7,
              paddingLeft: "15px",
              paddingBottom: run === true ? "30px" : "0px",
            }}
            className="step-9"
          >
            <Autocomplete
              id="product-search"
              options={dataTable_search}
              getOptionLabel={(option) =>
                `${option.code} - ${option.description}`
              }
              value={selectedProduct}
              onChange={handleProductChange}
              sx={{ width: "98%" }}
              renderInput={(params) => (
                <TextField {...params} label="Buscar producto" />
              )}
            />
          </div>
          <div
            style={{ flex: 1.5, paddingBottom: run === true ? "30px" : "0px" }}
          >
            <TextField
              id="Origen"
              label="Origen"
              disabled={selectedProduct === null ? true : false}
              type="number"
              className="step-10"
              value={selectedProduct ? selectedProduct.origin : 0}
              sx={{ width: "95%" }}
            />
          </div>
          <div
            style={{ flex: 1.5, paddingBottom: run === true ? "30px" : "0px" }}
          >
            <TextField
              id="quantity"
              label="Cantidad"
              disabled={selectedProduct === null ? true : false}
              type="number"
              className="step-11"
              value={selectedProduct ? selectedProduct.quantity : 0}
              onChange={(e) => {
                handleClickIcon();
                handleQuantityChange(e);
              }}
              sx={{ width: "95%" }}
            />
          </div>
          <div
            style={{ flex: 1.4, paddingBottom: run === true ? "30px" : "0px" }}
          >
            <Button
              disabled={
                selectedProduct === null
                  ? true
                  : Number(selectedProduct.quantity) <= 0
                    ? true
                    : false
              }
              variant="contained"
              className="step-12"
              onClick={() => {
                handleAddToTable();
                handleClickIcon();
              }}
              sx={{ width: "95%" }}
              onBlur={() => handleQuantity_table()}
            >
              Agregar
            </Button>
          </div>
        </Box>

        <br></br>

        <TableContainer
          style={{ width: "95%", margin: "0 auto" }}
          component={Paper}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ color: "white" }}>Código</TableCell>
                <TableCell style={{ color: "white" }}>Descripción</TableCell>
                <TableCell style={{ color: "white" }}>Cantidad</TableCell>
                <TableCell style={{ color: "white" }}>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData_data.map((product, index) => (
                <TableRow key={index}>
                  <TableCell>{product.code}</TableCell>
                  <TableCell>{product.description}</TableCell>
                  <TableCell>{product.quantity}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      className="step-18"
                      onClick={() => {
                        handleRemoveFromTable(product);
                        handleClickIcon();
                      }}
                    >
                      Eliminar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell></TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Total:</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>{quantity}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div style={{ display: filtersColumsView === true ? "" : "none" }}>
        <div className="TableTaxes">
          <div>
            <TableContainerData
              columns={columns}
              data={dataTable}
              style={{ overflowx: 10 }}
              updateMyData={updateMyData}
              skipPageReset={skipPageReset}
              quantity={quantity}
              origin={origin}
              destination={destination}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WAREHOUSE_MOVEMENTS;
