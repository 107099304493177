import img1 from "../../imgComponents/chat-smit.png";

export const LOADING_CHAT = () => {
  return (
    <div className='chats-portada'>
        <div className='container-chat-portada'>
        <div className='text-portada'>
            <h2>Te damos la Bienvenida a Chat Smit</h2>
            <p>Envia y recibe mensajes</p>
        </div>
        <img src={img1} alt=''></img>
            <p>Versión Alpha</p>
        </div>     
    </div>
  )
}
