//PAQUETERIAS
import { useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import NotificationSystem from "react-notification-system";
//COMPONENTES
import { refresh_token } from '../../main_components/tokens/tokenrefresh';
import { custom_multiple_filter, calculate_total } from '../../main_components/methods_v2/auxiliary_functions';
import { formattedCurrentDate, formattedPreviousDate } from "../../main_components/date/day";
import SEARCH_FILTERS from './tools/shearch_filter';
import MODAL_TABLE from '../../main_components/modal/modal_Table';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from '../../main_components/methods_v2/export';
import RETURNS_SALES from './tools/returns_sales';
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import RENDER_ROW_ACTION_MENU_ITEMS from './tools/render_row_action_menuItems';
import AlertResponse from '../../main_components/alerts/alertResponse';
import { reports_pag } from '../../services/reports/reports_pag';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import { get_price_load } from '../../services/inventory/inventory';
import PRODUCT_LOAD_RETURNS_V2 from './product_load_returns';
import PRODUCT_LOAD_SALES_V2 from './product_load_sales';
import { SEARCH_FILTER_WEB_MOVIL } from '../../main_components/methods_v2/search_filter_web_movil';
import { isNumber } from 'lodash';
const PRODUCT_LOAD_V2 = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [ff, setff] = useState(dayjs(formattedCurrentDate));
  const [fi, setfi] = useState(dayjs(formattedCurrentDate));
  const notificationSystemRef = useRef();
  const [modalT, setmodalT] = useState(false);
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [total_rows, settotal_rows] = useState("");
  const [query_filters, setquery_filters] = useState({});
  const [data_original, setdata_original] = useState([]);
  const [sales_data, set_sales_data] = useState([]);
  const [sales_returns, set_sales_returns] = useState([]);
  const [sales_data_original, set_sales_data_original] = useState([]);
  const [sales_returns_original, set_sales_returns_original] = useState([]);
  const [select_route, setselect_route] = useState("1");
  const [select_vendor, setselect_vendor] = useState("");
  const [name_vendor, setname_vendor] = useState("");
  const [name_route, setname_route] = useState("");
  const [msj_data, setmsjdata] = useState("");
  const [code_product, set_code_product] = useState("");

  const update_identifiers =
    [
      { code: "string" },
      { name: "string" },
      { sales_products: "number" },
      { returns_products: "number" },
      { sales_products_nt: "number" },
      { total_products: "number" },
      { inventory_products: "number" },
    ];

  const handleSubmit = async () => {
    setmodalT(true);
    setIsLoading(true);
    setData([]);
    set_sales_data([]);
    set_sales_returns([]);
    set_code_product("");

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var d = await get_price_load(fi.format('YYYY-MM-DD'), ff.format('YYYY-MM-DD'), select_route, select_vendor);
            console.log(d.data?.message);
            setmsjdata(d.data?.message);
            set_sales_data(d === null ? [] : d?.data?.sales);
            set_sales_data_original(d === null ? [] : d?.data?.sales);
            set_sales_returns(d === null ? [] : d?.data?.returns);
            set_sales_returns_original(d === null ? [] : d?.data?.returns);
            const productDictionary = {};
            d.data?.sales_products?.forEach((product) => {
              productDictionary[product.code] = {
                ...productDictionary[product.code],
                sales_products: product?.quantity,
                name: product?.product_name,
              };
            });

            d.data?.returns_products?.forEach((product) => {
              console.log(product?.quantity);

              productDictionary[product.code] = {
                ...productDictionary[product.code],
                returns_products: product?.quantity,
                name: product?.product_name,
              };
            });

            const inventory = d.data?.inventory?.filter(
              (d) => d.code !== "1155585588"
            );

            inventory?.forEach((product) => {
              productDictionary[product.code] = {
                ...productDictionary[product.code],
                inventory_products: product?.quantity,
                name: product?.product_name,
              };
            });

            const join_data = {
              join_data: Object.keys(productDictionary)
                .map((code) => ({
                  code,
                  ...productDictionary[code],
                }))
                .sort((a, b) => a.code.localeCompare(b.code)),
            };

            const dta = join_data.join_data.slice().sort((a, b) => {
              const aSales =
                a.sales_products !== undefined
                  ? a.sales_products
                  : Number.POSITIVE_INFINITY;
              const bSales =
                b.sales_products !== undefined
                  ? b.sales_products
                  : Number.POSITIVE_INFINITY;
              return aSales - bSales;
            });

            setData(dta);
            console.log(select_route)
            console.log(select_vendor)
            console.log(d?.data?.sales)
            setdata_original(dta);
            setmodalGeneral(false);
            setmodalT(false);
          } catch (err) {
            setData([]);
            setmodalGeneral(false);
            setmodalT(false);
          } finally {
            setIsLoading(false);
          }
        }, 1000);
      } else {
      }
    } catch (err) {
      console.log(err);

    }
  };


  const handle_filter_product_sales = () => {
    if (JSON.stringify(data) === JSON.stringify(data_original.filter((d) => d.sales_products !== undefined))) {
      setData(data_original);
    } else {
      var negative_inventory = data_original.filter((d) => d.inventory_products > 0).filter((d => {
        return isNumber(d.sales_products) ? d.sales_products : 0 - isNumber(d.returns_products) ? d.returns_products : 0 - isNumber(d.inventory_products) ? d.inventory_products : 0 < 0
      }));
      var negative_inventory2 = data_original.filter((d) => d.inventory_products > 0)
      console.log(negative_inventory2)

      console.log(negative_inventory)
      setData(data_original.filter((d) => d.sales_products !== undefined));
    }

  }


  const filter_Sales = (c) => {
    const ventasConDetalleFiltrado = [];
    const returnConDetalleFiltrado = [];

    for (const venta of sales_data_original) {
      const detallesVenta = venta.details;
      const detalleFiltrado = detallesVenta.find(
        (detalle) => detalle.code === c
      );

      if (detalleFiltrado) {
        ventasConDetalleFiltrado.push(venta);
      }
    }

    if (ventasConDetalleFiltrado.length > 0) {
      const ventasConDetalleAgrupado = {
        productoCodigo: c,
        ventas: [],
      };

      for (const ventaFiltrada of ventasConDetalleFiltrado) {
        ventasConDetalleAgrupado.ventas.push(ventaFiltrada);
      }

      console.log("Ventas con el producto de código", c);
      console.log(ventasConDetalleAgrupado);

      set_sales_data(ventasConDetalleAgrupado?.ventas);
      set_code_product(ventasConDetalleAgrupado?.productoCodigo);
    } else {
      console.log(`No se encontraron ventas con el producto de código ${c}`);
      set_sales_returns([]);

    }

    for (const venta of sales_returns_original) {
      const detallesVenta = venta.details;
      const detalleFiltrado = detallesVenta.find(
        (detalle) => detalle.code === c
      );

      if (detalleFiltrado) {
        returnConDetalleFiltrado.push(venta);
      }
    }

    if (returnConDetalleFiltrado.length > 0) {
      const retunsConDetalleAgrupado = {
        productoCodigo: c,
        return: [],
      };

      for (const ventaFiltrada of returnConDetalleFiltrado) {
        retunsConDetalleAgrupado.return.push(ventaFiltrada);
      }

      console.log("returns con el producto de código", c);
      console.log(retunsConDetalleAgrupado);

      set_sales_returns(retunsConDetalleAgrupado?.return);

    } else {
      console.log(
        `No se encontraron los retuns con el producto de código ${c}`
      );
      set_sales_returns([]);
    }
  };

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  const handle_success_filter = (a, b, c) => {
    // handle_get_sales(a, b);
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={c} view={true}></AlertResponse>,
      level: "success",
      position: "br",
      autoDismiss: 10,
    });
  };


  useEffect(() => {
    if (total_rows !== "") {
      var countVisibleColumns = total_rows?.getState()?.columnVisibility;
      if (countVisibleColumns && Object.keys(countVisibleColumns).length > 0) {
        console.log(countVisibleColumns?.length);
        console.log(countVisibleColumns);
        var elementosFalse = Object?.entries(countVisibleColumns)
          .filter(([clave, valor]) => valor === false);
        var cantidadFalse = elementosFalse.length;
        console.log(cantidadFalse)
      } else {
        console.log("countVisibleColumns is an empty object");
      }
    }
  }, [total_rows]);



  const columns = useMemo(
    () => [
      {
        header: "Código",
        size: 200,
        id: "code",
        accessorKey: "code",
        Cell: ({ row }) => {
          return (
            <p
              onClick={() => filter_Sales(row.original.code)}
              style={{
                background: row.original?.code === code_product ? "#00ff4c" : "",
                cursor: "pointer",
              }}
            >
              {row.original.code}
            </p>
          );
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        header: "Producto",
        id: "name",
        accessorKey: "name",
        size: 300,
        Footer: (d) => {
          return (
            <div style={{ textAlign: "justify", margin: "0 auto" }}>
              <div>Sub total</div>
              <div>Total</div>
            </div>
          )
        },
        muiTableBodyCellProps: {
          align: 'justify',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },

      },
      {
        header: "Vendido",
        id: "sales_products",
        accessorKey: "sales_products",
        accessorFn: (d) => {
          return d.sales_products === undefined ? 0 : d.sales_products;
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.sales_products) ? 0 : obj.sales_products)
          const total = data.map(obj => isNaN(obj.sales_products) ? 0 : obj.sales_products)

          return (
            <div style={{ textAlign: "center", margin: "0 auto" }}>
              <div>{calculate_total(subtotal)}</div>
              <div>{calculate_total(total)}</div>
            </div>
          )
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        header: "Devolución",
        size: 200,
        id: "returns_products",
        accessorKey: "returns_products",
        accessorFn: (d) => {
          return d.returns_products === undefined ? 0 : d.returns_products;
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.returns_products) ? 0 : obj.returns_products);
          const total = data.map(obj => isNaN(obj.returns_products) ? 0 : obj.returns_products)

          return (
            <div style={{ textAlign: "center", margin: "0 auto" }}>
              <div>{calculate_total(subtotal)}</div>
              <div>{calculate_total(total)}</div>
            </div>
          )
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },

      },
      {
        header: "Venta Neta",
        id: "sales_products_nt",
        accessorKey: "sales_products_nt",
        size: 200,
        accessorFn: (d) => {
          return (
            (isNaN(Number(d?.sales_products)) === true
              ? 0
              : Number(d?.sales_products)) -
            (isNaN(Number(d?.returns_products)) === true
              ? 0
              : Number(d?.returns_products))
          );
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.sales_products) ? 0 : obj.sales_products)
          const subtotal2 = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.returns_products) ? 0 : obj.returns_products)

          const total = data.map(obj => isNaN(obj.sales_products) ? 0 : obj.sales_products)
          const total2 = data.map(obj => isNaN(obj.returns_products) ? 0 : obj.returns_products)

          return (
            <div style={{ textAlign: "center", margin: "0 auto" }}>
              <div>{calculate_total(subtotal) - calculate_total(subtotal2)}</div>
              <div>{calculate_total(total) - calculate_total(total2)}</div>
            </div>
          )
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        header: "Inv. Final",
        accessorKey: "inventory_products",
        id: "inventory_products",
        size: 200,
        accessorFn: (d) => {
          return d.inventory_products === undefined ? "Sin carga" : d.inventory_products;
        },
        Cell: ({ row }) => {
          return row.original?.inventory_products === undefined ? (
            <p style={{ color: "red" }}>Sin Carga</p>
          ) : (
            row.original?.inventory_products
          );
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.inventory_products) ? 0 : obj.inventory_products)
          const total = data.map(obj => isNaN(obj.inventory_products) ? 0 : obj.inventory_products)
          return (
            <div style={{ textAlign: "center", margin: "0 auto" }}>
              <div>{calculate_total(subtotal)}</div>
              <div>{calculate_total(total)}</div>
            </div>
          )
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        header: "Entrega",
        accessorKey: "total_products",
        id: "total_products",
        size: 200,
        accessorFn: (d) => {
          return (
            (isNaN(Number(d?.sales_products)) === true
              ? 0
              : Number(d?.sales_products)) -
            (isNaN(Number(d?.returns_products)) === true
              ? 0
              : Number(d?.returns_products)) -
            (isNaN(Number(d?.inventory_products)) === true
              ? 0
              : Number(d?.inventory_products))
          );
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.sales_products) ? 0 : obj.sales_products)
          const subtotal2 = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.returns_products) ? 0 : obj.returns_products)
          const subtotal3 = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.inventory_products) ? 0 : obj.inventory_products)

          const total = data.map(obj => isNaN(obj.sales_products) ? 0 : obj.sales_products)
          const total2 = data.map(obj => isNaN(obj.returns_products) ? 0 : obj.returns_products)
          const total3 = data.map(obj => isNaN(obj.inventory_products) ? 0 : obj.inventory_products)

          return (
            <div style={{ textAlign: "center", margin: "0 auto" }}>
              <div>{calculate_total(subtotal) - calculate_total(subtotal2) - calculate_total(subtotal3)}</div>
              <div>{calculate_total(total) - calculate_total(total2) - calculate_total(total3)}</div>
            </div>
          )
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]

  );


  useEffect(() => {
    // handle_get_sales();
  }, []);



  const [minimenu, setminimenu] = useState(false);
  const [openmini, setopenmini] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 480) {
        console.log('Screen width is less than 480px');
        setminimenu(true);
      } else {
        setminimenu(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const filters_custom = (
    <>
      <SEARCH_FILTERS
        fi={fi}
        setfi={setfi}
        ff={ff}
        setff={setff}
        select_route={select_route}
        setselect_route={setselect_route}
        setselect_vendor={setselect_vendor}
        select_vendor={select_vendor}
        handleSubmit={handleSubmit}
        filter_Sales={filter_Sales}
        handle_filter_product_sales={handle_filter_product_sales}
        setname_vendor={setname_vendor}
        setname_route={setname_route}
      ></SEARCH_FILTERS>
    </>
  );

  return (
    <div style={{ width: "100%", margin: "0 auto" }} className='new_table_v2'>

      <BREADCRUMBS
        niveles={
          [
            { label: "CARGA DE PRODUCTOS" },
          ]
        }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
        aviso={true}

      ></BREADCRUMBS>

      <MODAL_TABLE
        open={true}
        message={""}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      <SEARCH_FILTER_WEB_MOVIL
        minimenu={minimenu}
        setminimenu={setminimenu}
        openmini={openmini}
        setopenmini={setopenmini}
        filterMovComponent={filters_custom}
        renderTopToolbarCustomActionsFilters={true}
      ></SEARCH_FILTER_WEB_MOVIL>
      <div className="todoaaaa"
        style={minimenu === true ? { "diplay": "" } : { "display": "none" }}
      >
        <span class="closebtn" onClick={() => setmsjdata("")}>
          &times;
        </span>
        <div
          className="alertm"
          style={{
            display: msj_data?.length === 0 ? "none" : "inline-block",
          }}
        >
          {msj_data}
        </div>
      </div>

      <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        onStateChange={handleStateChange}
        enableExpandAll={false}
        enableColumnDragging={false}
        initialState={{ showGlobalFilter: true, showColumnFilters: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
        muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important", } }}
        muiTableFooterCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}

        muiExpandButtonProps={({ row }) => ({
          children: row?.original?.returns?.length === undefined ? <span title='SIN DEVOLUCIÓNES'><ExpandMoreIcon></ExpandMoreIcon></span> : row?.original?.returns?.length === 0 ? <span title='SIN DEVOLUCIÓNES'><ExpandMoreIcon></ExpandMoreIcon></span> : <span title='DETALLES DE DOVOLUCIONES'><ExpandMoreIcon></ExpandMoreIcon></span>,
        })}

        muiTableBodyRowDragHandleProps={({ table }) => ({
          onDragEnd: () => {
            const { draggingRow, hoveredRow } = table.getState();
            if (hoveredRow && draggingRow) {
              data.splice(
                hoveredRow.index,
                0,
                data.splice(draggingRow.index, 1)[0],
              );
              setData([...data]);
            }
          },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            settotal_rows(table);

            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }


        renderTopToolbarCustomActions={({ }) => {
          if (minimenu === false) {
            return (
              <>
                {filters_custom}
              </>
            )
          }
        }}
        renderToolbarInternalActions={({ table, idenx }) => (
          <RENDER_TOOLBAR_INTERNAL_ACTIONS
            key={idenx}
            share_filter={true}
            product_load={true}
            camarista={true}
            query_filters={query_filters}
            settotal_rows={settotal_rows}
            handle_success_filter={handle_success_filter}
            total_rows={total_rows !== "" ? total_rows : 0}
            table={table}
            file_name={"CARGA DE PRODUCTOS DEL " + fi.format('YYYY-MM-DD') + " AL " + ff.format('YYYY-MM-DD')}
            date={fi.format('YYYY-MM-DD')}
            name_route={name_route}
            name_vendor={name_vendor}
            update_identifiers={update_identifiers}
          />
        )
        }
      />
      <br />
      <PRODUCT_LOAD_SALES_V2 data={sales_data}></PRODUCT_LOAD_SALES_V2>
      <br />
      <PRODUCT_LOAD_RETURNS_V2 data={sales_returns}></PRODUCT_LOAD_RETURNS_V2>
      {/* </LocalizationProvider> */}
    </div >
  );
};

export default PRODUCT_LOAD_V2;