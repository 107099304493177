import "./newchat.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import img1 from "../../../imgComponents/iconos/loading.gif";
import { useState } from "react";

export const NEWCHAT = (props) => {

const select = (a) =>{
    props.select(a?.email, a?.fullusername);
    props.setnewchat(false);
}

props?.datauser?.sort((a, b) => a.fullusername.localeCompare(b.fullusername));



  return (
    
    <div className='new_chat_container_mss'>
        <div className='title-new-chat-mss'>
            <ArrowBackIcon onClick={()=>{props.setnewchat(false)}}></ArrowBackIcon>
            <h2 className=''>Nuevo chat</h2>
        </div>
        {/* <div className="">
            <input className="" placeholder="Buscar usuario" onChange={(event)=>{handlesearch(event)}}>
            </input>
        </div> */}
        {
           props?.datauser?.length>0?
            <div className='contactos-mss-chat'>
            {
                props?.datauser?.map(item=>{
                    if(item.id===7777){
                        return (
                            <div className='contact-ind-mss smitchat' onClick={()=>{select(item)}}>
                                <div className='div-grid-contact-ind'>
                                    <div className='circle'></div>
                                    <div className='info-contac'>
                                        <p>{item.fullusername}</p>
                                    </div>
                                </div>
                                <div className='line'></div>
                            </div>
                        )
                    }else{
                        return (
                            <div className='contact-ind-mss' onClick={()=>{select(item)}}>
                                <div className='div-grid-contact-ind'>
                                    <div className='circle'></div>
                                    <div className='info-contac'>
                                        <p>{item.fullusername}</p>
                                    </div>
                                </div>
                                <div className='line'></div>
                            </div>
                        )    
                    }                    
                })
            }
        </div>:
        <div className='loading-contc'>
            <img src={img1} alt=''></img>
            <p className='pppp'>Cargando contactos...</p>
        </div>
        }
       
    </div>
  )
}
