import { useEffect, useRef } from 'react'
import iconopdf from "../../../imgComponents/pdf dowload.png";
import iconogs from "../../../imgComponents/export-gs.png";
import iconfiledow from "../../../imgComponents/archivo-des.png";
import "./history.css";


export const HISTORY_CONVERSATION = (props) => {

const commentsEndRef = useRef(null);

const scrollToBottom = () => {
    setTimeout(() => {
        commentsEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 1000);
}

useEffect(() => {
    scrollToBottom();
}, [props.conversation_data]);

      

  return (
    <div className='conversation'>
            {
              props.conversation_data.map((item, itemIndex)=>(
                <div className="conv-chats" key={itemIndex}>
                  <p className='fecha-conversation'>{item.fecha}</p>
                  {
                    item?.conversation?.map((conv, index)=>{
                    
                    var filenameurl = "";
                    var tipofile = "";
                    var link  = false;
                    var mensajeConSaltosDeLinea = "";
                    
                    if(conv?.tipo==="text"){
                        mensajeConSaltosDeLinea = conv?.mensaje.replace(/\n/g, '<br>');
                        const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([^\s]+\.[^\s]+)/;
                        link = urlRegex.test(conv?.mensaje);
                    }else if(conv?.tipo==="archivo"){
                      const urlParts = conv?.mensaje.split('/');
                      filenameurl = urlParts[urlParts.length - 1];
                      const ext = filenameurl?.split(".");
                      tipofile = ext[1];
                    }
                    return(  
                      conv.origen === props?.od?(
                      <div className='messages-container' ref={commentsEndRef}>
                        <div key={index} className='mss-emisor'>
                          {  
                            conv.tipo==="text"?
                              link===true?
                              <a className="mensaje-link" 
                                href={conv?.mensaje}
                                target="_blank"
                                rel="noopener noreferrer"
                                >{conv?.mensaje}</a>:
                                <p className="text-n" dangerouslySetInnerHTML={{ __html: mensajeConSaltosDeLinea }}></p>:
                            conv?.tipo==="image"?
                              (<img src={conv.mensaje}></img>):
                            conv?.tipo ==="audio"?
                              <audio src={conv?.mensaje} controls className='reproductor-audio-smit-chat'/>:
                            conv?.tipo==="archivo"?
                              <div className='mensaje-files'>
                                {
                                  tipofile==="pdf"?
                                    <>
                                      <img src={iconopdf}></img>
                                      <a href={conv.mensaje} download>
                                          {filenameurl}
                                      </a>
                                    </>:
                                  tipofile==="xlsx" || tipofile==="csv"?
                                    <>
                                      <img src={iconogs}></img>
                                      <a href={conv.mensaje} download>
                                        {filenameurl}
                                      </a>
                                    </>:
                                    <>
                                      <img src={iconfiledow}></img>
                                      <a href={conv.mensaje} download>
                                        {filenameurl}
                                      </a>
                                    </>
                                }
                              </div>
                              :
                            <></>
                          }
                          <p className='fecha'>{conv.hora}</p>
                        </div>
                      </div>):
                      (<div className='messages-container' ref={commentsEndRef}>
                        <div key={index} className='mss-receptor'>
                        {  
                            conv.tipo==="text"?
                              link===true?
                              <a className="mensaje-link" 
                                href={conv?.mensaje}
                                target="_blank"
                                rel="noopener noreferrer"
                                >{conv?.mensaje}</a>:
                                <p className="text-n" dangerouslySetInnerHTML={{ __html: mensajeConSaltosDeLinea }}></p>:
                            conv?.tipo==="image"?
                              (<img src={conv.mensaje}></img>):
                            conv?.tipo ==="audio"?
                              <audio src={conv?.mensaje} controls className='reproductor-audio-smit-chat'/>:
                            conv?.tipo==="archivo"?
                              <div className='mensaje-files'>
                                {
                                  tipofile==="pdf"?
                                    <>
                                      <img src={iconopdf}></img>
                                      <a href={conv.mensaje} download>
                                          {filenameurl}
                                      </a>
                                    </>:
                                  tipofile==="xlsx" || tipofile==="csv"?
                                    <>
                                      <img src={iconogs}></img>
                                      <a href={conv.mensaje} download>
                                        {filenameurl}
                                      </a>
                                    </>:
                                    <>
                                      <img src={iconfiledow}></img>
                                      <a href={conv.mensaje} download>
                                        {filenameurl}
                                      </a>
                                    </>
                                }
                              </div>
                              :
                            <></>
                          }
                          <p className='fecha'>{conv.hora}</p>
                        </div>
                      </div>)
                    )})
                  }  
                </div>
              ))
            }
            </div>
  )
}
