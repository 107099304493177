//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import { CustomDialog } from "react-st-modal"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, } from "react-google-maps";
import NotificationSystem from 'react-notification-system';
import MODAL_TABLE from "../../main_components/modal/modal_Table";
//COMPONENTES

//ESTILOS
import "../../components_cfdi/estilo.css";
import '../componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
import "./tablepoitofsalews.css";
//IMAGENES/ICONOS
import Spinner from "../../imgComponents/S.gif";
import TableContainer from "../../main_components/Table/TableContainer";
import Modal_Table from "../../main_components/modal/modal_Table";
import ADD from "../../imgComponents/agregar.png";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { SelectColumnFilter } from "../../components_cfdi/filters";
import { get_pos_without_sector } from "../../services/businesspartners/businesspartners";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import ADD_POINT_SALE_SECTOR from "./tools/add_point_sale_sector";


const POINT_OF_SALE_WITHOUT_SECTOR = () => {

  const notificationSystemRef = useRef();
  const [datap, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [MensajeBusqueda, setMensajeBusqueda] = React.useState("");
  const [open, setopen] = useState(false);
  var entrada = 0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);

  useEffect(() => {
    handleSubmit();
  }, []);


  const MapWithAMarker = withScriptjs(withGoogleMap(props =>

    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: datap[0]?.lat, lng: datap[0]?.lng }}
    >
      {
        datap.map((item) => (
          <Marker
            //label={item.code}
            position={{ lat: item.lat, lng: item.lng }}
            title={item.code + "-" + item.name}
            icon={{
              fillOpacity: 1,
              rotation: 0,
              strokeWeight: 1,
              strokeColor: "#ffffff",
              scale: 2,
              animation: window.google.maps.Animation.DROP,
              draggable: true
            }}
          />
        ))
      }
    </GoogleMap>
  ));
  const columns = useMemo(
    () => [
      {
        Header: "Acciones",
        Cell: ({ valor, row }) => {

          return (
            <div>
              <img
                src={ADD}
                onClick={() => {
                  CustomDialog(
                    <div>
                      <ADD_POINT_SALE_SECTOR handleSubmit={handleSubmit} row={row} setmodalT={setmodalT} />
                    </div>,
                    {
                      className: "modalTItle",
                      title: "Agregar Punto de Venta a Sector",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                }
                }

                title="Agregar a Sector"
                alt=""
              ></img>
            </div>
          );
        },
      },
      {
        Header: "Código",
        accessor: "code"
      },
      {
        Filter: SelectColumnFilter,
        Header: "Ruta",
        accessor: "route"
      },
      {
        Header: "Nombre",
        accessor: "name"
      },
      {
        Header: "Latitud",
        accessor: "lat"
      },
      {
        Header: "Longitud",
        accessor: "lng"
      },

      {
        Filter: SelectColumnFilter,
        Header: "Frecuencia",
        accessor: "frequency"
      },
      {
        Filter: SelectColumnFilter,
        Header: "Días de visita",
        accessor: "visit_days"
      }
    ], []
  );

  const handleSubmit = async () => {
    if (entrada === 0) {
      setmenssage("Consultando Puntos de Venta sin Sector...")
      setmodalGeneral(true);
      setmodalT(true);
      entrada = entrada + 1;
    } else {
      setmodalT(true);
    }
    setData([]);
    setopen(true);
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var d = await get_pos_without_sector();
            setData(d === null ? [] : d.data.pointSaleList);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
          } catch (err) {
            setData([]);
            setopen(false)
            setmodalGeneral(false);
            setmodalT(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>

      <div className="containeritems py-2">

        <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>

        <Modal_Table
          open={true}
          message={menssage}
          modalGeneral={modalGeneral}
          modalT={modalT}
        ></Modal_Table>

        <BREADCRUMBS niveles={
          [
            { label: "CONSULTAR PUNTOS DE VENTA", path: "/consult_point_of_sale" },
            { label: "PUNTOS DE VENTA SIN SECTOR", path: null },
          ]
        }
          new_version={true}
          modul="CONSULTAR PUNTOS DE VENTA SIN SECTOR V2"
          path={"/consult_point_of_sale_without_sector_v2"}

        ></BREADCRUMBS>
        <br /><br /><br />

      </div>

      <div className="TablePointOfSaleWS">
        <div >
          <div style={{ width: "98%", height: "100%", paddingLeft: "20px" }}>


            <MODAL_TABLE modalGeneral={isLoading} modalT={isLoading} message={MensajeBusqueda || "Buscando..."}></MODAL_TABLE>

            <>
              <div className="tableposwiths">
                <StickyTable>
                  <TableContainer
                    paginado={"Puntos de Venta sin Sector"}
                    consultas={"Puntos de Venta sin Sector Consultados"}
                    nametable={"Puntos de Venta sin Sector"}
                    exportar={true}
                    columns={columns}
                    data={datap}
                    style={{ overflowx: 10 }}
                    Gmodal={open}

                  />
                </StickyTable>
              </div>
              <br></br>
              <br /> <br />
              <MapWithAMarker
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBOh51zw12N4kb7yDY4pp-6gM7xVaFterc&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />

            </>

          </div>
        </div>
      </div>
    </>
  );
}

export default POINT_OF_SALE_WITHOUT_SECTOR;
