//PAQUETERIAS
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDialog } from "react-st-modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
//COMPONENTES
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { get_route } from "../../../services/configuration/configuration";
import { get_employess } from "../../../services/humanResources/humanResources";
import { create_daily_staff } from "../../../services/reports/reports";
import { update_daily_staff } from "../../../services/reports/reports";
import { get_daily_staff_user } from "../../../services/reports/reports";
//IMAGENES

//ESTILOS
import "./daily_staff.css";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";

const op1 = [
  { value: "CLIENTE", label: "CLIENTE" },
  { value: "PROVEEDOR", label: "PROVEEDOR" },
  { value: "AMBOS", label: "AMBOS" },
];

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const CREATE_UPDATE = (props) => {
  const [validate_selecctroute, setvalidate_selecctroute] = useState(true);
  const [validate_date, setvalidate_date] = useState(true);
  const [validate_selectuser01, setvalidate_selectuser01] = useState(true);
  const [validate_selectuser02, setvalidate_selectuser02] = useState(true);


  console.log(props);

  const dialog = useDialog();
  const [detail_product, setdetail_product] = useState("");
  const [fr, setfr] = useState(props.date);
  const [routes, setroutes] = useState([]);
  const [employes, setemployes] = useState([]);
  const [employes02, setemployes02] = useState([]);
  const [employes03, setemployes03] = useState([]);
  const [employes04, setemployes04] = useState([]);
  const [employes05, setemployes05] = useState([]);

  const [users, setusers] = useState([]);


  const [selectRoute, setselectRoute] = useState(props.id_route_fk);
  const [selectRouteName, setselectRouteName] = useState(props.route);

  const [selectuser01, setselectuser01] = useState(props.name_dealer_employee_fk);
  const [selectuser01_id, setselectuser01_id] = useState(props.id_seller_employee_fk);

  const [selectuser02, setselectuser02] = useState(props.id_seller_asst_employee_fk===null?"SIN USUARIO":props.id_seller_asst_employee_fk);
  const [selectuser03, setselectuser03] = useState(props.name_seller_employee_fk);
  const [selectuser03_id, setselectuser03_id] = useState(props.id_dealer_employee_fk);

  const [selectuser04, setselectuser04] = useState(props.id_dealer_asst_employee_fk===null?"SIN USUARIO":props.id_dealer_asst_employee_fk);

  

  const valid1 = () => {
    fr !== "Selecciona una fecha" ? setvalidate_date(true) : setvalidate_date(false);
    isNaN(Number(selectRoute)) !== true  ? setvalidate_selecctroute(true) : setvalidate_selecctroute(false);
    isNaN(Number(selectuser01)) !== true  ? setvalidate_selectuser01(true) : setvalidate_selectuser01(false);
    isNaN(Number(selectuser03)) !== true  ? setvalidate_selectuser02(true) : setvalidate_selectuser02(false);
  };
  
  const handle_router = async () => {
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            const dt = await get_route();
            const data = dt.data.routes;
            await get_employess();
            var dtAdd = {
              id:"SELECCIONA UNA RUTA",
              route: "SELECCIONA UNA RUTA",
            };
            data.push(dtAdd);
            console.log(data);
            setroutes(data);
           
          } catch (error) {
            setroutes([]);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };
  const handle_employess = async () => {
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            const dt = await get_employess();
            const data = dt.data?.map((d) => {
              return {
                id: d.id,
                fullusername:
                  d.name + " " + d.first_last_name + " " + d.second_last_name + " - "+d.job_title,
              };
            });
      
            var dtAdd = {
              id: "SELECCIONA UN USUARIO",
              fullusername: "SELECCIONA UN USUARIO",
            };
            var dtAdd2 = {
              id: "SIN USUARIO",
              fullusername: "SIN USUARIO",
            };
            data.push(dtAdd);
            data.push(dtAdd2);
            setemployes(data);
            if(props.method==="UPDATE"){
              setemployes(data);
              setemployes02(data);
              setemployes03(data);
              setemployes04(data);
            }
          } catch (error) {
            setemployes([]);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  const handelType = (event) => {
    setselectRoute(event?.target?.value);
    var routen=routes.filter((d)=>d.id===Number(event.target.value));
    console.log(routes);
    console.log(routen)
    console.log(routen[0]?.route);
    setselectRouteName(routen[0]?.route)
    console.log(users);
    console.log(event?.target?.value)

    var dt=users.filter((d)=>d.idRoute===Number(event?.target?.value));
    console.log(dt);
    setselectuser01(dt[0]?.name_first_user);
    setselectuser01_id(dt[0]?.id_first_employee);
    setselectuser03(dt[0]?.name_second_user);
    setselectuser03_id(dt[0]?.id_second_employee);
    console.log(employes?.filter((e) => e.id !== dt[0]?.id_first_employee))
    var dta = employes?.filter((e) => e.id !== dt[0]?.id_first_employee);
    setemployes02(dta);
    setemployes03(dta);
    setemployes04(dta?.filter((e) => e.id !== dt[0]?.id_second_employee));

  };

  const handle_user01 = (event) => {
    setselectuser01_id(event?.target?.value)
    console.log(event?.target?.value);
    var dta = employes?.filter((e) => e.id !== Number(event?.target?.value));
    setemployes02(dta);
    
  };
  const handle_user02 = (event) => {
    setselectuser02(event?.target?.value);
    var dta;
    if(Number(event?.target?.value)===999999999999){
       dta = employes02;
       setemployes03(dta);

    }else{
      dta = employes02?.filter((e) => e.id !== Number(event?.target?.value));
      setemployes03(dta);

    }
  };
  const handle_user03 = (event) => {
    setselectuser03_id(event?.target?.value);
    console.log(event.target.value)
    var dta;
    if(Number(event?.target?.value)===999999999999){
      dta = employes03;
   }else{
     dta = employes03?.filter((e) => e.id !== Number(event?.target?.value));
   }

    setemployes04(dta);
    
  };
  const handle_user04 = (event) => {
    setselectuser04(event?.target?.value)
    var dta;
    if(Number(event?.target?.value)===999999999999){
      dta = employes04;
   }else{
     dta = employes04?.filter((e) => e.id !== Number(event?.target?.value));
   }
    setemployes05(dta);
  };


  const handle_get_user = async(id)=>{
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            var dt= await get_daily_staff_user();
            console.log(dt.data)
            setusers(dt.data.parametersList);
          } catch (err) {
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  }

  useEffect(() => {
    handle_employess();
    handle_get_user();
    handle_router();
  }, []);

  var dta = {
    date: fr,
    id_route: Number(selectRoute),
    route: selectRouteName,
    id_seller: Number(selectuser01_id),
    id_seller_asst: selectuser02==="SIN USUARIO"?null:selectuser02==="SELECCIONA UN USUARIO"?null:Number(selectuser02),
    id_dealer: Number(selectuser03_id),
    id_dealer_asst: selectuser04==="SIN USUARIO"?null:selectuser04==="SELECCIONA UN USUARIO"?null:Number(selectuser04),

  };
  console.log(dta);

  const handle_create_product = async () => {
    var dta = {
      date: fr,
      id_route: Number(selectRoute),
      route: selectRouteName,
      id_seller: Number(selectuser01_id),
      id_seller_asst: selectuser02==="SIN USUARIO"?null:selectuser02==="SELECCIONA UN USUARIO"?null:Number(selectuser02),
      id_dealer: Number(selectuser03_id),
      id_dealer_asst: selectuser04==="SIN USUARIO"?null:selectuser04==="SELECCIONA UN USUARIO"?null:Number(selectuser04),

    };
    console.log(dta);

    if (
      fr !== "Selecciona una fecha" &&
      isNaN(Number(selectRoute)) !== true &&
      isNaN(Number(selectuser01_id)) !=="" &&
      isNaN(Number(selectuser03_id)) !=="" 

    ) {
      props?.setmodalT(true);
      console.log("no deberia de pasar");
      try{
        var rf_token = await refresh_token();
        if(rf_token===true){
          try {
            if (props.method === "CREATE") {
              await create_daily_staff(dta);
              props?.setmodalT(false);

              dialog.close({
                validate: true,
                msj: "Empleados asignado correctamente",
              });
              props?.handleSubmit();
              props?.alertas("Empleados asignados correctamente", true);
            } else {
              await update_daily_staff(dta,props.id);
              dialog.close({
                validate: true,
                msj: "Asignación de empleados actualizada",
              });
              props?.handleSubmit();
              props?.alertas("Asignación de empleados actualizada", true);
            }
          } catch (err) {
            props?.setmodalT(false);
            props?.alertas("Error al realizar la operación", false);
            catchErrorModul("Asignación de Rutas", JSON.stringify(dta), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
            dialog.close({
              validate: false,
              msj: JSON.stringify(err.response?.data),
            });
          }
        }else{}
      }
      catch(err){
        console.log(err);
      }  
    } else {
      valid1();
    }
  };
  return (
    <div >
      <div style={{ display: detail_product }}>
        <div className="containerdailystaf">
          <div className="bp-1n">
            <label className="register-asig-label">Fecha *</label>
            <input
              type="text"
              required
              className="fExpense2"
              onClick={(e) => {
                e.currentTarget.type = "date";
                e.currentTarget.focus();
              }}
              placeholder={props.date}
              onChange={(event) => setfr(event.target.value)}
            ></input>

            <br />
            <p className="validate_data">
              {validate_date === false ? "Selecciona una fecha" : ""}
            </p>
            
          </div>

          <div className="bp-2">
            <TextField
              select
              label="Ruta"
              child
              required
              value={selectRoute}
              sx={{ m: 1.5, width: "100%" }}
              onChange={handelType}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {routes?.map((option) => (
                <option
                  key={option?.id}
                  value={option?.id}
                  selected={option?.id}
                >
                  {option?.route}
                </option>
              ))}
            </TextField>
            <p className="validate_data">
              {validate_selecctroute === false ? "Selecciona una ruta" : ""}
            </p>
          </div>
          <div className="bp-3">

        


            <TextField
              select
              child
              label="Vendedor asignado"
              value={selectuser01_id}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(e)=>handle_user01(e)}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {employes?.map((option) => (
                <option key={option.id} value={option.id} selected={option.id}>
                  {option.fullusername}
                </option>
              ))}
            </TextField>
          </div>

          <div className="bp-4">
            <TextField
              select
              child
              label="Ayudante de vendedor"
              value={selectuser02}
              sx={{ m: 1.5, width: "100%" }}
              onChange={handle_user02}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {employes02?.map((option) => (
                <option key={option.id} value={option.id} selected={option.id}>
                  {option.fullusername}
                </option>
              ))}
            </TextField>

          </div>

          <div className="bp-5">
    
          <TextField
              select
              child
              label="Reparto asignado"
              value={selectuser03_id}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(e)=>handle_user03(e)}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {employes03?.map((option) => (
                <option key={option.id} value={option.id} selected={option.id}>
                  {option.fullusername}
                </option>
              ))}
            </TextField>

          </div>

          <div className="bp-6">
            <TextField
              select
              child
              label="Ayudante de reparto"
              value={selectuser04}
              sx={{ m: 1.5, width: "100%" }}
              onChange={handle_user04}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {employes04?.map((option) => (
                <option key={option.id} value={option.id} selected={option.id}>
                  {option.fullusername}
                </option>
              ))}
            </TextField>
          </div>

          <div className="bp-7">
            <Button
              onClick={handle_create_product}
              sx={{ m: 2, width: "100%" }}
              variant="contained"
              style={{ background: "green" }}
            >
              {props.method === "CREATE"?"CREAR":"ACTUALIZAR"}
            </Button>
          </div>

          <div className="bp-8">
            <Button
              onClick={() => {
                dialog.close();
              }}
              sx={{ m: 2, width: "100%" }}
              variant="contained"
              style={{ background: "red" }}
            >
              CANCELAR
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CREATE_UPDATE;
