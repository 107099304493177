import axios from 'axios';
import config from "../../config";

export const put_new_message = async (data, identificador) => {
    const url = `https://236p9wqdg1.execute-api.us-east-1.amazonaws.com/prod/chat_history_conversation?id=${identificador}`;
    try {
        const response = await axios.put(url, data, 
            {headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            }});
        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error);
    }
};
export const get_new_message = async (identificador, identificador2, date) => {
    const url = `https://236p9wqdg1.execute-api.us-east-1.amazonaws.com/prod/chat_history_conversation?id=${identificador}&email_destino=${identificador2}&date=${date}`;
    try {
        const response = await axios.get(url, 
            {headers: {
               Authorization: sessionStorage.getItem("getIdTokenRefresh"),
               "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            }});
        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error);
    }
};
export const get_conversation_users = async (id) => {
    const url = `https://236p9wqdg1.execute-api.us-east-1.amazonaws.com/prod/chat_history_users?id=${id}`;
    try {
        const response = await axios.get(url, 
            {headers: {
                Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
                "Content-Type": "application/json",
            }});
        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error);
    }
};



export const put_config_user = async (id, data) => {
    const url = `https://236p9wqdg1.execute-api.us-east-1.amazonaws.com/prod/config_user_chat?id=${id}`;
    try {
        const response = await axios.put(url, data, 
            {headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            }});
        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error);
    }
};


export const get_config_user = async (id) => {
    const url = `https://236p9wqdg1.execute-api.us-east-1.amazonaws.com/prod/config_user_chat?id=${id}`;
    try {
        const response = await axios.get(url, 
            {headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            }});
        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error);
    }
};


export const put_message_multimedia = async (id, data) => {
    const url = `https://236p9wqdg1.execute-api.us-east-1.amazonaws.com/prod/chat_history_conversation/chat_multimedia?id=${id}`;
    try {
        const response = await axios.put(url, data, 
            {headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            }});
        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error);
    }
};




export const get_online_users = async () => {
    const url = `https://236p9wqdg1.execute-api.us-east-1.amazonaws.com/prod/online_users`;
    try {
        const response = await axios.get(url, 
            {headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            }});
        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error);
    }
};

//grupos de chat smit

export const post_chat_smit_group = async (data) => {
    const url = `https://236p9wqdg1.execute-api.us-east-1.amazonaws.com/prod/chat_smit_group`;
    try {
        const response = await axios.post(url, data, 
            {headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            }});
        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const get_chat_smit_group = async (id) => {
    const url = `https://236p9wqdg1.execute-api.us-east-1.amazonaws.com/prod/chat_smit_group?id=${id}`;
    try {
        const response = await axios.get(url, 
            {headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            }});
        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const add_participants_chat_group = async (id, data) => {
    const url = `https://236p9wqdg1.execute-api.us-east-1.amazonaws.com/prod/chat_smit_group/member?id=${id}`;
    try {
        const response = await axios.put(url, data, 
            {headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            }});
        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const delete_participants_chat_group = async (idgroup, id) => {
    const url = `https://236p9wqdg1.execute-api.us-east-1.amazonaws.com/prod/chat_smit_group/member?id_group=${idgroup}&id=${id}`;
    try {
        const response = await axios.delete(url, 
            {headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            }});
        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const get_history_pagination = async (ido, idd)=>{
    const url = `https://236p9wqdg1.execute-api.us-east-1.amazonaws.com/prod/chat_history_conversation/pagination`;
    try {
        const response = await axios.get(url, 
            {
                params:{
                    ido: ido,
                    idd: idd
                }
            },
            {headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            }});
        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error);
    }
}