//PAQUETERIAS
import {CustomDialog} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
import { useEffect, useMemo, useState, useRef} from 'react'
import MapContainer from './tools/mapcontainer_v2';
import { refresh_token  } from '../../main_components/tokens/tokenrefresh';
import TextField from "@mui/material/TextField";
import TableContainer from './TableContainer';
import { StickyTable } from "react-sticky-table";
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import { get_pos_sector_vtr, get_route_list, get_sectors } from '../../services/businesspartners/businesspartners';
import MODAL_TABLE from '../../main_components/modal/modal_Table';
import { get_sales_in_real_time } from '../../services/reports/reports';
import { BREADCRUMBS } from '../../main_components/pagination/breadcrumbs';
import DETAILS_SALES_REAL from './tools/details_sales_real';
//ESTILOS
import "./salesreal.css";
//IMAGENES
import marker1 from "../../imgComponents/marker1.png";
import marker2 from "../../imgComponents/marker2.png";
import marker3 from "../../imgComponents/marker3.png";
import marker4 from "../../imgComponents/marker4.png";
import marker5 from "../../imgComponents/marker5.png";
import marker6 from "../../imgComponents/marker6.png";
import lupa from "../../imgComponents/lupa.png";

import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import { MAPBOX } from "./tools/mapbox";
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";

const SalesReal_V2 = () => {


useEffect(()=>{
  handleroute();
},[]);

const [markers, setmarkers]=useState([]);
const [markers2, setmarkers2]=useState([]);
const [data, setdata]=useState([]);
const [fecha, setfecha]=useState(null);
const [nameroute, setnameroute]=useState(null);
const [bandera, setbandera]=useState(false);

const [routedata, setroutedata]=useState([]);
const [open, setopen]=useState(false);
const notificationSystemRef = useRef(); 
const [modalT, setmodalT] = useState(false);
const [total, settotal]=useState(0.0);

const [isLoading, setIsLoading] = useState(false);
const [rowSelection, setRowSelection] = useState({});
const [reconsult, setreconsult] = useState(false);
const [total_rows, settotal_rows] = useState("");
const [datasector, setdatasector]=useState([]);
const [dataosect, setdataosect] = useState([]);
const [idsec, setidsec] = useState("");
const [dataposs, setdataposs] = useState([]);


const [datasv, setdatasv] = useState([]);


function alertas(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}
const handleroute =async () => {
  try {
    var rf_token=await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_route_list();
          setroutedata(d === null ? [] : d)     
        }catch(err){
          console.log(err);
          alertas("Error al obtener información de rutas", false);
        }
      }, 1000);
    }else{}
  } catch (err) {
    console.log(err);
  }
}
const handleSubmit = async () => {

  setmodalT(true);
  setopen(true);
  setdata([]);
  setmarkers2([]);
  setmarkers([]);
  setdatasv([]);
  setidsec("vacio");

  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {   
        try{
          var d = await get_sales_in_real_time(nameroute, fecha);
          const jsonordenado = d === null ? [] : d.data.points_of_sale.sort((a, b) => {
          handlesumatotal(d===null?[]:d.data.points_of_sale);
           const aDate = new Date(a.sales[0].date);
            const bDate = new Date(b.sales[0].date);
            return aDate - bDate;
          });
          const jsn = jsonordenado.flatMap(item => {
            return item.sales.map(sale => {
              return {
                ...item,
                sales: [sale],
              }
            })
          });
          const jsonew = jsn.map((item, index) => {
            return { ...item, ordenvisita: (index+1)};
          });
          var ndt = update_null_identifiers(jsonew, update_identifiers);
          newjson(ndt === null ? [] : ndt);
          setdata(ndt === null ? [] :ndt);
          setbandera(true);
          setmodalT(false);
          setopen(false);
        }catch(err){
          console.log(err);
          setopen(false);
          setdata([]);
          setmodalT(false);
          console.log("error al obtener data de rutas");
        }
      }, 1000);
    }else{}
  } catch (err) {
    console.log(err);
  } 
}

function handlesumatotal(pos){
  var tt = 0;
  pos?.map((item)=>{
    item?.sales?.map((it)=>{
      tt = tt + it.total;
    })
  })
  settotal(tt);
}

const sectors = async() =>{

  try{
    var rf_t = await refresh_token();
    if(rf_t){
      setTimeout(async() => {
        try{ 
          const d = await get_sectors();
          console.log(d);
          setdatasector(d?.data?.sectors);
          setdataosect(d?.data?.sectors);

        }catch(err){  
          console.log(err);
        }
      }, 1000);
    }
  }catch(err){
    console.log(err);
  }
}
function newjson(n){

  const puntosventa = n.map(obj => {
    return { 
      code: obj.code,
      name: obj.name,
      lat: obj.lat, 
      lng: obj.lng, 
      ordenvisita: obj.ordenvisita,
      tipo:1,
    };
  });
  const allSales = n.reduce((acc, obj) => {
  return acc.concat(obj.sales.map(item =>
      ({ 
        ticket: item.ticket,
        name_pos: item.name_pos,
        lat: item.lat, 
        lng: item.lng, 
        code_pos: item.code_pos,
        ordenvisita: obj.ordenvisita,
        scan: item.scan,
        total: item.total,
        total_returns: item.total_returns,
        id_sale_type_fk: item.id_sale_type_fk,
        saletype: item.saletype,
        tipo: item.saletype==="NO VENTA" || item.saletype=== "OTRO"?3:(obj.cabinets.length===0?5:(item.scan===null||""?4:2)),
        cabinets: obj.cabinets,
        details: item.details,
      })));
  }, []);

const result = puntosventa.concat(allSales);

setmarkers(result);
setmarkers2(result);
 
}
const handleStateChange = (newState) => {
  const filteredRowsCount = newState.filteredRowModel
  settotal_rows(filteredRowsCount);
};
useEffect(() => {
  if(total_rows === "" || total_rows === undefined || total_rows === null){   
  }else{
    if(total_rows?.getFilteredRowModel()?.rows?.length === data?.length){
      newjson(data);
    }else{
      const originalList = total_rows?.getFilteredRowModel()?.rows?.map(row => row?.original);
      newjson(originalList);
    }
  }
}, [total_rows]);




const columns = useMemo(
  () => [
    {
      id: "ordenvisita",
      header:"Visita",
      accessorKey:"ordenvisita"
    },
    {
      id: "code",
      header:"Código",
      accessorKey:"code"
    },
    {
      id:"date",
      header:"Fecha",
      accessorKey: "date",
      accessorFn: (d)=>{
        return d?.sales[0]?.date
      }
    },
    {
      id: "name",
      header:"Punto de Venta",
      accessorKey:"name"
     },
    {
      id: "ticket",
      header:"Tickets",
      accessorKey: "ticket",
      accessorFn: (d)=>{
        return d?.sales[0]?.ticket
       }
    },
    {
      id: "economic_number",
       header:"Congelador",
       accessorKey: "economic_number",
       accessorFn: (d)=>{
      var congeladores="";
        for(var i=0; i<d.cabinets.length;i++){
          congeladores=congeladores + d.cabinets[i].economic_number+" ";
        }
      return congeladores;
       }
    },
    {
      id: "scan",
       header:"Con. Escaneado",
       accessorKey: "scan",
       accessorFn: (d)=>{
        var escaneado="";
        for(var i=0; i<d.sales.length;i++){
          escaneado=escaneado + d.sales[i].scan+" ";
        }
        return escaneado;
       }
    },
    {
      id: "saletype",
      header:"Tipo",
      accessorKey: "saletype",
      accessorFn: (d)=>{
        if(d.sales[0]?.saletype==="NO VENTA" || d.sales[0]?.saletype==="OTRO"){
          return "NOTA";
        }else if(d.cabinets.length===0){
          return "SIN CONGELADOR";
        }else if(d.sales[0].scan===null||""){
          return "SIN ESCANEO";
        }else{
          return "VENTA";
        }
      },
    },
    {
      id: "marker",
      header:"Marker",
      accessorKey: "marker",
      accessorFn:(d)=>{
        if(d.sales[0]?.saletype==="NO VENTA" || d.sales[0]?.saletype==="OTRO"){
          return <img src={marker3} alt=''></img>;
        }else if(d.cabinets.length===0){
          return <img src={marker5} alt=''></img>
        }else if(d.sales[0].scan===null||""){
          return <img src={marker4} alt=''></img>
        }else{
          return <img src={marker2} alt=''></img>
        }
      }
    }
    ],
    []
);



const columns2 = useMemo(
  () => [
    {
      id: "code",
      header:"Código",
      accessorKey:"code",
      muiTableBodyCellProps: {
        align: "center"
      }
    },
    {
      id: "name",
      header: "Nombre",
      accessorKey: "name",
      size: 400
    },
    {
      id: "lat",
      accessorKey: "lat",
      header: "Latitud"
    },
    {
      id: "lng",
      accessorKey: "lng",
      header: "Longitud"
    }
    ],
    []
);




useEffect(() => {
  sectors();
}, [])

const handlesubsect = async() => {
  try{
    var rff = await refresh_token();
    if(rff){
      setTimeout(async() => {
        try{
          setmodalT(true);
  
          const d = await get_pos_sector_vtr(idsec);
          setdataposs(d?.data?.points_of_sale);
          handlejoinmarker(d?.data?.points_of_sale);
          setmodalT(false);
        }catch(err){
          console.log(err);
          setmodalT(false);
  
        }
      }, 1000);
    }
  }catch(err){
    console.log(err);
  }
}

const handlejoinmarker = (dat) =>{

  const unmatchedPoints = dat.filter(itemDat => 
    !markers2.some(itemMarker => itemMarker.code === itemDat.code)
  );
   const transformedPoints = unmatchedPoints.map(item => ({
    code: item.code,
    lat: item.lat,
    lng: item.lng,
    name: item.name,
    ordenvisita: 0,
    tipo: 9 
  }));
  console.log(markers2);
  console.log(transformedPoints);


  const d = [...markers2, ...transformedPoints]
  setmarkers(d);
  setdatasv(transformedPoints);
}


const update_identifiers =
[
  { ordenvisita: 'string' },
  { code: 'string' },
  { date: 'String' },
  { name: 'string' },
  { ticket: 'string' },
  { economic_number: 'string'},
  { scan: 'string' },
  { saletype: 'string' },
  // { marker: 'string' }
];

const update_identifiers2 =
[
  { code: 'string' },
  { name: 'string' },
  { lat: 'String' },
  { lng: 'String' }
];


  return (
    <div>
       <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
            <MODAL_TABLE
              open={true}
              message={""}
              modalGeneral={false}
              modalT={modalT}
            ></MODAL_TABLE> 

      <BREADCRUMBS niveles={
      [
        {label:"REPORTE VENTAS EN TIEMPO REAL", path: null}, 
      ]
    }
       cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length} 
    ></BREADCRUMBS>
    
      <div className='grid_real_sales'>
        <div className='realsales1'>
           <input
              type="text"
              className="fecha1"
              onFocus={(e) => {
                e.currentTarget.type = "date";
                e.currentTarget.focus();
              }}
              placeholder="Fecha"
            onChange={(event) => setfecha(event.target.value)}
            ></input>
        </div>
        <div className='realsales2'>
        <TextField
          select
          required
          label="Ruta"
          InputLabelProps={{shrink: true,}}
          SelectProps={{native: true,}}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => {
            setnameroute(event.target.value)
            const d = dataosect?.filter(item => item.route == event.target.value?.toString());
            setdatasector(d);
          }}
      >
          <option key={0} value={"vacio"} selected>
              {"Seleccione Ruta"}
          </option>
          {
            routedata?.data?.parametersList?.map((item)=>(
              <option key={item.idRoute} value={item.route}>
                {item.route}
              </option>
            ))
          }
      </TextField>
        </div>
        <div className='realsales3'>
        <button className="button" id="buttonpackageb" onClick={handleSubmit} type="button">
            Buscar
        </button>
        </div>
        <div className="realsales4">
        <TextField
          select
          label="Sector"
          value={idsec}
          InputLabelProps={{shrink: true,}}
          SelectProps={{native: true,}}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setidsec(event.target.value)}
      >
          <option key={0} value={"vacio"} selected>
              {"Seleccione Sector"}
          </option>
          {
            datasector?.map((item)=>(
              <option key={item.id} value={item.id}>
                {item?.number_sector} - {item.name_sector}
              </option>
            ))
          }
      </TextField>
        </div>
        <div className='realsales5'>
          <button className="button" id="buttonpackageb" onClick={handlesubsect} type="button">
              Aplicar
          </button>
        </div>

        <div className='realsales6'>
          <p>Total Ventas: {total.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}</p>
        </div>
      </div>
      <div className='tableventastreal'>
      <table>
        <tr>
          <td>
            <img src={marker1} alt=''></img>
            <p>Punto de Venta</p>
          </td>
          <td>
            <img src={marker2} alt=''></img>
            <p>Ventas</p>
          </td>
          <td>
            <img src={marker3} alt=''></img>
            <p>Notas</p>
          </td>
          <td>
            <img src={marker4} alt=''></img>
            <p>Sin escaneo</p>
          </td>
          <td>
            <img src={marker5} alt=''></img>
            <p>Sin congelador</p>
          </td>
          <td>
            <img src={marker6} alt=""></img>
            <p>No Visitado</p>
          </td>
        </tr>
      </table>
  </div>
      {
        bandera===true?<MapContainer markers={markers}></MapContainer>:""
       // bandera===true?<MAPBOX markers={markers}></MAPBOX>:""
      }

  <div className="TableSalesTimeReal">
    <div >       
      <>
      <div className="new_table_v2">
      <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        onStateChange={handleStateChange}
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setdata([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            settotal_rows(table);
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
          renderRowActionMenuItems={({ row, closeMenu }) => [
            <RENDER_ROW_ACTION_MENU_ITEMS
             closeMenu={closeMenu}
             setmodalT={setmodalT}
             row={row}
             handleSubmit={handleSubmit}
             setreconsult={setreconsult}
             refresh_token={refresh_token}
             alertas={alertas}
            />
          ]}
        renderToolbarInternalActions={({ table }) => (
           <RENDER_TOOLBAR_INTERNAL_ACTIONS
             table={table} 
             file_name={"Cuentas Contables"}
             update_identifiers={update_identifiers}
             settotal_rows={settotal_rows}
           />
         )
         }
      />
      </div>
      </>   
      <div className="new_table_v2">
         <h2>Puntos de Venta sin Visitar</h2>

         <MaterialReactTable
        columns={columns2}
        data={datasv}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        onStateChange={handleStateChange}
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        // enableRowActions
        // enableRowSelection
        getRowId={(row) => row?.id}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               datasv.splice(
                 hoveredRow.index,
                 0,
                 datasv.splice(draggingRow.index, 1)[0],
               );
               setdatasv([...datasv]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderToolbarInternalActions={({ table }) => (
          <RENDER_TOOLBAR_INTERNAL_ACTIONS
            table={table} 
            file_name={"Puntos de Venta sin Visitar"}
            update_identifiers={update_identifiers2}
          />
        )
        }
      />
      </div>
    </div>
    </div>
    </div>
  );
}


export default SalesReal_V2