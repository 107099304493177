//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import { CustomDialog, Confirm } from "react-st-modal";
import { StickyTable } from "react-sticky-table";

//COMPONENTES
import { SelectColumnFilter, MultiSelectColumnFilter } from "../../components_cfdi/filters";
import SEARCH_FILTERS from "../../main_components/Table/filter_shear";
import FormControlLabel from "@mui/material/FormControlLabel";
//ESTILOS
import "../../main_components/Table/tablecontainer.css";
import "./dms.css";

//IMAGENES

import { COLUMS_SHOW } from "../../main_components/Table/ColumsShow";
import { MaterialUISwitch } from "../../main_components/material_switch/switch";
import { formattedCurrentDate, formattedPreviousDate } from "../../main_components/date/day";
import NotificationSystem from 'react-notification-system';
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import DOWNLOAD from "../../imgComponents/zip.png"
import DOWNLOAD2 from "../../imgComponents/correoElectronico.png"

//COMPONENTES
import TableContainer from "../../main_components/Table/TableContainer";

import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import clients from "../../imgComponents/icons/clients.png"
import freezers from "../../imgComponents/icons/freezers.png"
import store from "../../imgComponents/icons/store.png"
import sales from "../../imgComponents/icons/sales.png"
import tickets from "../../imgComponents/icons/tickets.png"
import INTERFAZ_CLIENTS from "../Interface/clients";
import INTERFAZ_ROUTES from "../Interface/routes";
import INTERFAZ_CABINETS from "../Interface/cabinets";
import INTERFAZ_SALES from "../Interface/sales";
import INTERFAZ_TICKETS from "../Interface/tickets";
import { base64toBlobzip } from "../../main_components/donwloadfuncion/download";
import { get_packages, dowload_packages, send_packages } from "../../services/unilever/unilever";
import { CakeOutlined } from "@material-ui/icons";
const CONSULT_DMS = () => {

  const [data, setData] = useState([]);
  const [ff, setff] = useState(formattedCurrentDate);
  const [fi, setfi] = useState(formattedPreviousDate);
  const [rfc, setRFC] = useState("");
  const [sdn, setSDN] = useState("");
  const [cancel, setCancel] = useState(null);
  const [tipodoc, setTipoDoc] = useState("");
  const [reconsult, setreconsult] = useState(false);
  const [filtersColumsView, setfiltersColumsView] = useState(true);
  var entrada = 0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [fullscreen_table, setfullscreen_table] = useState(true);

  const notificationSystemRef = useRef();
  const tableContainerRef = useRef(null);
  const toggleFullscreen = () => {
    const element = tableContainerRef.current;
    if (!document.fullscreenElement && element.requestFullscreen) {
      element.requestFullscreen().then(() => {
        element.classList.add('fullscreen');
        setfullscreen_table(false)
      });
    } else if (document.exitFullscreen) {
      document.exitFullscreen().then(() => {
        element.classList.remove('fullscreen');
        setfullscreen_table(true)

      });
    }
  };


  const handleSubmit = async (event) => {
    event?.preventDefault();

    if (entrada === 0) {
      setmodalGeneral(true);
      setmenssage("Consultando Paquetes");
    }
    setmodalT(true);
    setData([]);
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var dt = await get_packages(fi, ff);
            setData(dt.data.records);
            console.log(dt.data.records)
            setmodalGeneral(false);
            setmodalT(false);
          } catch (err) {
            setData([]);
            setmodalGeneral(false);
            setmodalT(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  const handle_seend = async (id) => {

    setmodalT(true);
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var dt = await send_packages(id);
            setmodalGeneral(false);
            setmodalT(false);
            alert("Paquete enviado correctamente")
            window.location="/consult_DMS"
          } catch (err) {
            setmodalGeneral(false);
            setmodalT(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };


  const handle_download = async (id, name) => {
    setmodalT(true);
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var dta = await dowload_packages(id);
            var dt = dta.data.file;
            var blob = base64toBlobzip(dt);
            const blobUrl = URL.createObjectURL(blob);
            const downloadLink = document.createElement("a");
            downloadLink.href = blobUrl;
            downloadLink.download = name;
            downloadLink.click();
            setmodalT(false);
          } catch (err) {
          }
          finally {
            setmodalGeneral(false);
            setmodalT(false);
          }

        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);





  const formatDeliverName = (deliverName) => {
    const formattedName = deliverName.replace(/\.txt,/g, '.txt,\n').replace(/,/g, ',\n');
    return formattedName;
  };

  const columns = useMemo(
    () => [

      {
        Header: "Acciones",
        id: "Acciones",
        Cell: ({ valor, row }) => {
          return (
            <div>
              <img
                alt=""
                src={DOWNLOAD}
                title="Descargar Paquete"
                className="download_P"
                onClick={() =>
                  handle_download(row.original.id, row.original.package_name)
                }
              ></img>
              <img
                alt=""
                src={DOWNLOAD2}
                title="Enviar paquete Paquete"
                className="download_P"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de enviar este paquete?",
                    "Evío de paquete DMS",
                    "Si",
                    "No"
                  );

                  if (result) {
                    handle_seend(row.original.id);
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
        maxWidth: 100,
        width: 100,
        className: "justify_data",

      },

      {
        Header: "Paquete",
        accessor: "package_name",
        id: "name_pos",
        maxWidth: 330,
        width: 330,
        className: "justify_data"
      },

      {
        id: "state",
        Header: "Estado",
        accessor: "state",
        className: "center_data"
      },

      {
        Header: "Reportes",
        accessor: (row) => formatDeliverName(row.deliver_name),
        id: "ticket",
        maxWidth: 100,
        width: 100,
        className: "justify_data_font-size",
      },

      {
        Header: "Generado",
        accessor: "date_register",
        id: "invoice_date",
        className: "center_data",

        Cell: ({ cell: { value } }) => {
          const formattedDate = value.substring(0, 19);
          return formattedDate;
        },
      },

      {
        Header: "Envío",
        accessor: "send_date",
        id: "send_date",

        className: "center_data",
        Cell: ({ cell: { value } }) => {
          if (value) {
            console.log("+" + value)
            const formattedDate = value.substring(0, 19);
            return formattedDate;
          } else {
            console.log(value)
            return ""; // Otra acción por defecto si value no está definido
          }
        },
      },

      {
        Header: "interface_type",
        accessor: "interface_type",
        id: "interface_type",
        maxWidth: 100,
        width: 100,
        className: "justify_data",
      },
      {
        Header: "Usuario",
        accessor: "fullusername",
        id: "fullusername",
        maxWidth: 100,
        width: 100,
        className: "justify_data",
      },


    ],
    []
  );




  const search_filters = [
    {
      slot: <input type="text" onFocus={(e) => { e.currentTarget.type = "date"; e.currentTarget.focus(); }} placeholder="Fecha inicial" onChange={(event) => setfi(event.target.value)}></input>,
      fr: null,
      display: null,
      padding: 5,
    },
    {
      slot: <input type="text" onFocus={(e) => { e.currentTarget.type = "date"; e.currentTarget.focus(); }} placeholder="Fecha Final" onChange={(event) => setff(event.target.value)}></input>,
      fr: null,
      display: null,
      padding: 5,
    },
    {
      slot: <button style={{ width: "100%" }} className="button" onClick={handleSubmit} type="button">Buscar</button>,
      fr: null,
      display: null,
      padding: null,
    },
    {
      slot: "",
      fr: null,
      display: null,
      padding: null,
    },
    {
      slot: "",
      fr: null,
      display: null,
      padding: null,
    },

    {
      slot: <FullscreenIcon title="Pantalla completa" style={{ cursor: "pointer" }} onClick={toggleFullscreen}></FullscreenIcon>,
      fr: 0.2,
      padding: null,
      display: null,
    },


  ];
  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setfullscreen_table(true);
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  return (
    <div ref={tableContainerRef}
    >
      <BREADCRUMBS niveles={
        [
          { label: "CONSULTAR PAQUETES DMS", path: null },
        ]
      }
      ></BREADCRUMBS>
      <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>
      <SEARCH_FILTERS search_filters={search_filters}></SEARCH_FILTERS>
      <div>
        <div className="tableDMS">
          <TableContainer
            nametable={`Facturas del ${fi} al ${ff}`}
            zipfacturas={false}
            columns={columns}
            fullscreen_table={fullscreen_table}
            data={data}
            style={{ overflowx: 10 }}
            consultas={"Facturas"}
            exportar={true}
            Grantotal={false}
            hpdf={2}
            function_ext={
              <img alt="" src={clients} className="reportInvoice" title="GENERAR INTERFAZ DE CLIENTES"
                onClick={async () => {
                  await CustomDialog(<INTERFAZ_CLIENTS ></INTERFAZ_CLIENTS>,
                    { className: "custom-modal", title: 'Interfaz de Clientes', showCloseIcon: true, });
                }}
              ></img>
            }
            function_ext2={<img
              alt=""
              src={store}
              title="GENERAR INTERFAZ DE RUTAS"
              className="reportInvoice"
              onClick={async () => {
                await CustomDialog(<INTERFAZ_ROUTES ></INTERFAZ_ROUTES>,
                  { className: "custom-modal", title: 'Interfaz de Rutas', showCloseIcon: true, });
              }}
            ></img>}
            function_ext3={<img
              alt=""
              src={freezers}
              title="GENERAR INTERFAZ DE CONGELADORES"
              className="reportInvoice"
              onClick={async () => {
                await CustomDialog(<INTERFAZ_CABINETS ></INTERFAZ_CABINETS>,
                  { className: "custom-modal", title: 'Interfaz de Congeladores', showCloseIcon: true, });
              }}
            ></img>}

            function_ext4={<img
              alt=""
              src={sales}
              title="GENERAR INTERFAZ DE VENTAS"
              className="reportInvoice"
              onClick={async () => {
                await CustomDialog(<INTERFAZ_SALES ></INTERFAZ_SALES>,
                  { className: "custom-modal", title: 'Interfaz de Ventas', showCloseIcon: true, });
              }}
            ></img>}

            function_ext5={<img
              alt=""
              src={tickets}
              title="GENERAR INTERFAZ DE TICKETS"
              className="reportInvoice"
              onClick={async () => {
                await CustomDialog(<INTERFAZ_TICKETS ></INTERFAZ_TICKETS>,
                  { className: "custom-modal", title: 'Interfaz de Tickets', showCloseIcon: true, });
              }}
            ></img>}

            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }} className="">

                </div>
              );
            }}
          />
        </div>
      </div>

    </div>

  );
};

export default CONSULT_DMS;

