import "./newchat.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import img1 from "../../imgComponents/iconos/loading.gif";
import { useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
export const NEWCHAT = (props) => {

const select = (a) =>{
    props.select(a?.email, a?.fullusername);
    props.setcontact(false);
    setFilterKeyword("");
    props.setdatauser(props?.datauseraux);
}

props?.datauser?.sort((a, b) => a.fullusername.localeCompare(b.fullusername));

const [filterKeyword, setFilterKeyword] = useState('');

const filtrarchat2 = (e) =>{
    const lowercasedKeyword = e.toLowerCase();
    setFilterKeyword(e);
    const filteredResult = props?.datauseraux?.filter(item=> item?.fullusername?.toLowerCase().includes(lowercasedKeyword));

    console.log(props?.datauseraux);
    console.log(filteredResult)

    props.setdatauser(filteredResult);
  }
  

  return (
    
    <div className='new_chat_container_mss'>
        <div className='title-new-chat-mss'>
            <ArrowBackIcon onClick={()=>{props.setcontact(false)}}></ArrowBackIcon>
            <h2 className=''>Nuevo chat</h2>
        </div>
        <div className="filtrar-nuevos-contactos">
            <div className='search-user-mini'>
                            <SearchIcon></SearchIcon>
                            <input placeholder='Buscar un chat'
                                value={filterKeyword}
                                onChange={(e)=>filtrarchat2(e.target.value)}
                            >
                            </input>
            </div>
        </div>
        {
           props?.datauser?.length>0?
            <div className='contactos-mss-chat'>
            {
                props?.datauser?.map((item, index)=>{
                    if(item.id===7777){
                        return (
                            <div className='contact-ind-mss smitchat' onClick={()=>{select(item)}} key={index}>
                                <div className='div-grid-contact-ind'>
                                    <div className='circle'></div>
                                    <div className='info-contac'>
                                        <p>{item.fullusername}</p>
                                    </div>
                                </div>
                                <div className='line'></div>
                            </div>
                        )
                    }else{
                        return (
                            <div className='contact-ind-mss' onClick={()=>{select(item)}} key={index}>
                                <div className='div-grid-contact-ind'>
                                    <div className='circle'></div>
                                    <div className='info-contac'>
                                        <p>{item.fullusername}</p>
                                    </div>
                                </div>
                                <div className='line'></div>
                            </div>
                        )    
                    }                    
                })
            }
        </div>:

        props?.datauseraux?.length>0?
        <div className="">
            <p className="pppp">Contacto No Encontrado</p>
        </div>:
        <div className='loading-contc'>
            <img src={img1} alt=''></img>
            <p className='pppp'>Cargando contactos...</p>
        </div>
        }
       
    </div>
  )
}
