//Iconos material-ui
import {
  NoteAdd,
  Payment,
  FindInPage,
  BrandingWatermark,
  LocalShipping,
  Group,
  AirlineSeatReclineNormal,
  LocationOn,
  BarChart,
  InsertChartOutlined,
  Description,
  Public,
  Assignment,
  PieChart,
  ListAlt,
  AssignmentReturn,
  LocalAtm,
  TrendingDown,
  SupervisorAccount,
  PersonAdd,
  Redeem,
  MonetizationOn,
  Business,
  Timeline,
  Category,
  Kitchen,
  LinearScale,
} from "@material-ui/icons";
import {
  ContentPasteGo,
  FactCheck,
  ManageAccounts,
  PersonSearch,
  PriceChange,
  RequestQuote,
  PriceCheck,
  ReceiptLong,
  Calculate,
  Summarize,
  AutoAwesomeMosaic,
  Plagiarism,
  PointOfSale,
  TravelExplore,
  Discount,
  Warehouse,
  Groups2,
  CurrencyExchange,
} from "@mui/icons-material";
import AddCardIcon from "@mui/icons-material/AddCard";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ListAltIcon from "@mui/icons-material/ListAlt";
import IcecreamIcon from "@mui/icons-material/Icecream";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import FormatLineSpacingIcon from "@mui/icons-material/FormatLineSpacing";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";

import * as style from "./style";
import * as icon from "./Iconos";
import { Icon } from "@material-ui/core";

export const arrStorage = (roles = [], userId) => {
  const arr = [
    //ACTIVOS
    {
      id: 101101,
      name: "Marcas y Modelos",
      ruta: "consult_brands_v2",
      restriccion: roles.ROLE_ASSETS,
      icono: (
        <Icon style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}>
          <BrandingWatermark style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}consult_brands_v2`),
      parent: "ACTIVOS",
      module_restriccion: [roles.ROLE_ASSETS],
    },
    {
      id: 101102,
      name: "Vehículos",
      ruta: "consult_vehicle_v2",
      restriccion: roles.ROLE_ASSETS,
      icono: (
        <Icon style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}>
          <LocalShipping style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}consult_vehicle_v2`),
      parent: "ACTIVOS",
      icon_parent: (
        <MonetizationOn style={style.iconStyle} sx={{ fontSize: 30 }} />
      ),
      module_restriccion: [roles.ROLE_ASSETS],
    },
    {
      id: 101103,
      name: "Congeladores",
      ruta: "consult_frezzers_v2",
      restriccion: roles.ROLE_CABINETS,
      icono: (
        <Icon style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}>
          <Kitchen style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}consult_frezzers_v2`),
      parent: "ACTIVOS",
      icon_parent: (
        <MonetizationOn style={style.iconStyle} sx={{ fontSize: 30 }} />
      ),
      module_restriccion: [roles.ROLE_CABINETS],
    },

    //ADDENDAS
    {
      id: 101104,
      name: "Addenda",
      ruta: "Addenda",
      restriccion: roles.ROLE_ADDENDAS,
      icono: (
        <Icon style={style.styleFavorite("#EC5A4B", "#E88177", "#B32315")}>
          <ManageAccounts style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}Addenda`),
      parent: "ADDENDA",
      module_restriccion: [roles.ROLE_ADDENDAS],
    },
    //BANCOS
    {
      id: 102101,
      name: "Corte de Caja",
      ruta: "cashclosing_v2",
      restriccion: roles.ROLE_BANK,
      icono: (
        <Icon style={style.styleFavorite("#4BA575", "#378059", "#1E5337")}>
          <PriceCheck style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}cashclosing_v2`),
      parent: "BANCO",
      module_restriccion: [roles.ROLE_BANK],
    },
    {
      id: 102102,
      name: "Deudas",
      ruta: "DEBTS",
      restriccion: roles.ROLE_BANK,
      icono: (
        <Icon style={style.styleFavorite("#4BA575", "#378059", "#1E5337")}>
          <AddCardIcon style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}DEBTS`),
      parent: "BANCO",
      module_restriccion: [roles.ROLE_BANK],
    },
    {
      id: 102103,
      name: "Pagos",
      ruta: "PAID",
      restriccion: roles.ROLE_BANK,
      icono: (
        <Icon style={style.styleFavorite("#4BA575", "#378059", "#1E5337")}>
          <LinearScale style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}PAID`),
      parent: "BANCO",
      module_restriccion: [roles.ROLE_BANK],
    },
    {
      id: 102189,
      name: "Antiguedad de Saldos",
      ruta: "old_balances",
      restriccion: roles.ROLE_BANK,
      icono: (
        <Icon style={style.styleFavorite("#4BA575", "#378059", "#1E5337")}>
          <LinearScale style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}old_balances`),
      parent: "BANCO",
      module_restriccion: [roles.ROLE_BANK],
    },
    //COLABORADORES
    {
      id: 103101,
      name: "Empleados",
      ruta: "consult_employees_v2",
      restriccion: roles.ROLE_HUMAN_RESOURCES,
      icono: (
        <Icon style={style.styleFavorite("#A5702C", "#E79023", "#B66600")}>
          <Group style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}consult_employees_v2`),
      parent: "COLABORADORES",
      module_restriccion: [roles.ROLE_HUMAN_RESOURCES],
    },
    {
      id: 103102,
      name: "Conductores",
      ruta: "consult_driver_v2",
      restriccion: roles.ROLE_HUMAN_RESOURCES,
      icono: (
        <Icon style={style.styleFavorite("#A5702C", "#E79023", "#B66600")}>
          <AirlineSeatReclineNormal style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}consult_driver_v2`),
      parent: "COLABORADORES",
      module_restriccion: [roles.ROLE_HUMAN_RESOURCES],
    },
    {
      id: 103103,
      name: "Asignación de Personal",
      ruta: "daily_staff_v2",
      restriccion: roles.ROLE_HUMAN_RESOURCES,
      icono: (
        <Icon style={style.styleFavorite("#A5702C", "#E79023", "#B66600")}>
          <GroupAddIcon style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}daily_staff_v2`),
      parent: "COLABORADORES",
      module_restriccion: [roles.ROLE_HUMAN_RESOURCES],
    },
    //FINANZAS
    {
      id: 104101,
      name: "Cuentas Contables",
      ruta: "consult_accounting_account_v2",
      restriccion: roles.ROLE_FINANCE,
      icono: (
        <Icon style={style.styleFavorite("#E9E934", "#E8E827", "#E8BA0F")}>
          <Calculate style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}consult_accounting_account_v2`),
      parent: "FINANZAS",
      module_restriccion: [roles.ROLE_FINANCE],
    },
    {
      id: 104102,
      name: "Cuentas de Pago",
      ruta: "payment_accounts",
      restriccion: roles.ROLE_FINANCE,
      icono: (
        <Icon style={style.styleFavorite("#E9E934", "#E8E827", "#E8BA0F")}>
          <Calculate style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}payment_accounts`),
      parent: "FINANZAS",
      module_restriccion: [roles.ROLE_FINANCE],
    },
    //REPORTES
    {
      id: 105101,
      name: "Detalles de Venta",
      ruta: "NewDashboard_v2",
      restriccion: roles.ROLE_SALES,
      icono: (
        <Icon style={style.styleFavorite("#CCF0ED", "#8CB7B4", "#6F9391")}>
          <ReceiptLong style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}NewDashboard_v2`),
      parent: "REPORTES",
      module_restriccion: [roles.ROLE_SALES],
    },
    {
      id: 105102,
      name: "Mapa de Rutas",
      ruta: "rutas",
      restriccion: roles.ROLE_SALES,
      icono: (
        <Icon style={style.styleFavorite("#CCF0ED", "#8CB7B4", "#6F9391")}>
          <LocationOn style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}rutas`),
      parent: "REPORTES",
      module_restriccion: [roles.ROLE_SALES],
    },
    {
      id: 1051034,
      name: "Reporte de Ventas",
      ruta: "report_sales",
      restriccion: roles.ROLE_SALES,
      icono: (
        <Icon style={style.styleFavorite("#CCF0ED", "#8CB7B4", "#6F9391")}>
          <Summarize style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}report_sales`),
      parent: "REPORTES",
      module_restriccion: [roles.ROLE_SALES],
    },
    {
      id: 105103,
      name: "Reporte de Ventas por Ruta",
      ruta: "consult_route_sales_report",
      restriccion: roles.ROLE_SALES,
      icono: (
        <Icon style={style.styleFavorite("#CCF0ED", "#8CB7B4", "#6F9391")}>
          <Summarize style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}consult_route_sales_report`),
      parent: "REPORTES",
      module_restriccion: [roles.ROLE_SALES],
    },
    {
      id: 105104,
      name: "Reporte de Ventas en Tiempo Real",
      ruta: "Consult_report_sale_real_v2",
      restriccion: roles.ROLE_SALES,
      icono: (
        <Icon style={style.styleFavorite("#CCF0ED", "#8CB7B4", "#6F9391")}>
          <BarChart style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}Consult_report_sale_real_v2`),
      parent: "REPORTES",
      module_restriccion: [roles.ROLE_SALES],
    },
    {
      id: 105105,
      name: "Reporte Sumaria Anual",
      ruta: "summary_annual_v2",
      restriccion: roles.ROLE_BANK,
      icono: (
        <Icon style={style.styleFavorite("#CCF0ED", "#8CB7B4", "#6F9391")}>
          <InsertChartOutlined style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}summary_annual_v2`),
      parent: "REPORTES",
      module_restriccion: [roles.ROLE_SALES],
    },
    {
      id: 105106,
      name: "Sumaria Semanal",
      ruta: "summary_weekly_v2",
      restriccion: "",
      icono: (
        <Icon style={style.styleFavorite("#CCF0ED", "#8CB7B4", "#6F9391")}>
          <LinearScale style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}summary_weekly_v2`),
      parent: "REPORTES",
      module_restriccion: [],
    },

    //COMPRAS
    {
      id: 106101,
      name: "Consultar Documentos Enviados",
      ruta: "consultaFacturasProvedores_v2",
      restriccion: roles.ROLE_BUYS,
      icono: (
        <Icon style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}>
          <Description style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}consultaFacturasProvedores_v2`),
      parent: "COMPRAS",
      module_restriccion: [roles.ROLE_BUYS, roles.ROLE_EXPENSE],
    },
    {
      id: 106102,
      name: "Enviar Documentos",
      ruta: "validador",
      restriccion: roles.ROLE_BUYS,
      icono: (
        <Icon style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}>
          <AssignmentReturn style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}validador`),
      parent: "COMPRAS",
      module_restriccion: [roles.ROLE_BUYS, roles.ROLE_EXPENSE],
    },
    {
      id: 1061021,
      name: "Consultar Proveedores",
      ruta: "supplier_v2",
      restriccion: roles.ROLE_BUYS,
      icono: (
        <Icon style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}>
          <AssignmentReturn style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}supplier_v2`),
      parent: "COMPRAS",
      module_restriccion: [roles.ROLE_BUYS, roles.ROLE_EXPENSE],
    },
    {
      id: 106103,
      name: "Gastos no Deducibles",
      ruta: "expenses_v2",
      restriccion: roles.ROLE_BUYS,
      icono: (
        <Icon style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}>
          <PriceChange style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}expenses_v2`),
      parent: "COMPRAS",
      module_restriccion: [roles.ROLE_BUYS, roles.ROLE_EXPENSE],
    },
    {
      id: 106104,
      name: "Gastos del Extranjero",
      ruta: "expensesExtranjero_v2",
      restriccion: roles.ROLE_BUYS,
      icono: (
        <Icon style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}>
          <Public style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}expensesExtranjero_v2`),
      parent: "COMPRAS",
      module_restriccion: [roles.ROLE_BUYS, roles.ROLE_EXPENSE],
    },
    {
      id: 106105,
      name: "Reporte de Gastos",
      ruta: "expensesReport_v2",
      restriccion: roles.ROLE_BUYS,
      icono: (
        <Icon style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}>
          <Assignment style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}expensesReport_v2`),
      parent: "COMPRAS",
      module_restriccion: [roles.ROLE_BUYS, roles.ROLE_EXPENSE],
    },
    {
      id: 106106,
      name: "Reporte de Gastos Agrupados",
      ruta: "expensesGrup_v2",
      restriccion: roles.ROLE_BUYS,
      icono: (
        <Icon style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}>
          <PieChart style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}expensesGrup_v2`),
      parent: "COMPRAS",
      module_restriccion: [roles.ROLE_BUYS, roles.ROLE_EXPENSE],
    },
    {
      id: 106107,
      name: "Pólizas",
      ruta: "policy_v2",
      restriccion: roles.ROLE_POLICY,
      icono: (
        <Icon style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}>
          <ListAlt style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}policy_v2`),
      parent: "COMPRAS",
      module_restriccion: [roles.ROLE_BUYS, roles.ROLE_EXPENSE],
    },
    {
      id: 106108,
      name: "Catálogo de Gastos",
      ruta: "catalog_expense_concept_v2",
      restriccion: roles.ROLE_EXPENSE_ADMINISTRATIVOS,
      icono: (
        <Icon style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}>
          <AutoAwesomeMosaic style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}catalog_expense_concept_v2`),
      parent: "COMPRAS",
      module_restriccion: [roles.ROLE_BUYS, roles.ROLE_EXPENSE],
    },
    {
      id: 106109,
      name: "Regla de Gastos",
      ruta: "consult_rules_sending_expenses_v2",
      restriccion: roles.ROLE_EXPENSE_ADMINISTRATIVOS,
      icono: (
        <Icon style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}>
          <LocalAtm style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(
        `${userId}consult_rules_sending_expenses_v2`
      ),
      parent: "COMPRAS",
      module_restriccion: [roles.ROLE_BUYS, roles.ROLE_EXPENSE],
    },
   
    //FACTURAS
    {
      id: 107101,
      name: "Carta Porte",
      ruta: "cartaporte",
      restriccion: roles.ROLE_CARTA_PORTE,
      icono: (
        <Icon style={style.styleFavorite("#FF4003", "#FF6433", "#CD3200")}>
          <ContentPasteGo style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}cartaporte`),
      parent: "FACTURAS",
      module_restriccion: [
        roles.ROLE_INVOICING,
        roles.ROLE_CARTA_PORTE,
        roles.ROLE_COMPLEMENTO_PAGO,
      ],
    },
    {
      id: 107102,
      name: "Consultar Conceptos",
      ruta: "consult_concept_v2",
      restriccion: roles.ROLE_INVOICING,
      icono: (
        <Icon style={style.styleFavorite("#FF4003", "#FF6433", "#CD3200")}>
          <FindInPage style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}consult_concept_v2`),
      parent: "FACTURAS",
      module_restriccion: [
        roles.ROLE_INVOICING,
        roles.ROLE_CARTA_PORTE,
        roles.ROLE_COMPLEMENTO_PAGO,
      ],
    },
    {
      id: 107103,
      name: "Consultar Facturas",
      ruta: "invoice_v2",
      restriccion: roles.ROLE_INVOICING,
      icono: (
        <Icon style={style.styleFavorite("#FF4003", "#FF6433", "#CD3200")}>
          <FactCheck style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}invoice_v2`),
      parent: "FACTURAS",
      module_restriccion: [
        roles.ROLE_INVOICING,
        roles.ROLE_CARTA_PORTE,
        roles.ROLE_COMPLEMENTO_PAGO,
      ],
    },
    {
      id: 107104,
      name: "Consultar Facturas PPD",
      ruta: "FacturasPPD_v2",
      restriccion: roles.ROLE_INVOICING,
      icono: (
        <Icon style={style.styleFavorite("#FF4003", "#FF6433", "#CD3200")}>
          <Plagiarism style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}FacturasPPD_v2`),
      parent: "FACTURAS",
      module_restriccion: [
        roles.ROLE_INVOICING,
        roles.ROLE_CARTA_PORTE,
        roles.ROLE_COMPLEMENTO_PAGO,
      ],
    },

    {
      id: 107105,
      name: "Consultar Impuestos",
      ruta: "consult_taxes_v2",
      restriccion: roles.ROLE_INVOICING,
      icono: (
        <Icon style={style.styleFavorite("#FF4003", "#FF6433", "#CD3200")}>
          <CurrencyExchange style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}consult_taxes_v2`),
      parent: "FACTURAS",
      module_restriccion: [
        roles.ROLE_INVOICING,
        roles.ROLE_CARTA_PORTE,
        roles.ROLE_COMPLEMENTO_PAGO,
      ],
    },

    {
      id: 107106,
      name: "Consultar Nómina",
      ruta: "nomina",
      restriccion: roles.ROLE_NOMINA,
      icono: (
        <Icon style={style.styleFavorite("#FF4003", "#FF6433", "#CD3200")}>
          <Payment style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}nomina`),
      parent: "FACTURAS",
      module_restriccion: [
        roles.ROLE_INVOICING,
        roles.ROLE_CARTA_PORTE,
        roles.ROLE_COMPLEMENTO_PAGO,
      ],
    },
    {
      id: 107107,
      name: "Realizar Factura",
      ruta: "GenerarFactura",
      restriccion: roles.ROLE_INVOICING,
      icono: (
        <Icon style={style.styleFavorite("#FF4003", "#FF6433", "#CD3200")}>
          <NoteAdd style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}GenerarFactura`),
      parent: "FACTURAS",
      module_restriccion: [
        roles.ROLE_INVOICING,
        roles.ROLE_CARTA_PORTE,
        roles.ROLE_COMPLEMENTO_PAGO,
      ],
    },
    {
      id: 107108,
      name: "Realizar Complemento de Pago",
      ruta: "pago",
      restriccion: roles.ROLE_COMPLEMENTO_PAGO,
      icono: (
        <Icon style={style.styleFavorite("#FF4003", "#FF6433", "#CD3200")}>
          <RequestQuote style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}pago`),
      parent: "FACTURAS",
      module_restriccion: [
        roles.ROLE_INVOICING,
        roles.ROLE_CARTA_PORTE,
        roles.ROLE_COMPLEMENTO_PAGO,
      ],
    },
    {
      id: 107109,
      name: "Reporte de Pagos",
      ruta: "consult_payment_report_v2",
      restriccion: roles.ROLE_INVOICING,
      icono: (
        <Icon style={style.styleFavorite("#FF4003", "#FF6433", "#CD3200")}>
          <RequestQuote style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}consult_payment_report_v2`),
      parent: "FACTURAS",
      module_restriccion: [roles.ROLE_INVOICING],
    },
    //SOCIO DE NEGOCIOS
    {
      id: 108101,
      name: "Consultar Descuento",
      ruta: "consult_discounts_v2",
      restriccion: roles.ROLE_BUSINESS_PARTNER_DISCOUNT,
      icono: (
        <Icon style={style.styleFavorite("#373B44", "#9B23A3", "#F3904F")}>
          <TrendingDown style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}consult_discounts_v2`),
      parent: "SOCIO DE NEGOCIOS",
      module_restriccion: [roles.ROLE_BUSINESS_PARTNER],
    },
    {
      id: 108102,
      name: "Consultar Promociones",
      ruta: "consult_promotions_v2",
      restriccion: roles.ROLE_BUSINESS_PARTNER_PROMOTIONS,
      icono: (
        <Icon style={style.styleFavorite("#373B44", "#9B23A3", "#F3904F")}>
          <Discount style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}consult_promotions_v2`),
      parent: "SOCIO DE NEGOCIOS",
      module_restriccion: [roles.ROLE_BUSINESS_PARTNER],
    },
    {
      id: 108103,
      name: "Consultar puntos de ventas",
      ruta: "consult_point_of_sale_v2",
      restriccion: roles.ROLE_BUSINESS_PARTNER_POINT_OF_SALE,
      icono: (
        <Icon style={style.styleFavorite("#373B44", "#9B23A3", "#F3904F")}>
          <PointOfSale style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}consult_point_of_sale_v2`),
      parent: "SOCIO DE NEGOCIOS",
      module_restriccion: [roles.ROLE_BUSINESS_PARTNER],
    },
    {
      id: 108104,
      name: "Consultar Sectores",
      ruta: "consult_sectors_v2",
      restriccion: roles.ROLE_BUSINESS_PARTNER_SECTOR,
      icono: (
        <Icon style={style.styleFavorite("#373B44", "#9B23A3", "#F3904F")}>
          <TravelExplore style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}consult_sectors_v2`),
      parent: "SOCIO DE NEGOCIOS",
      module_restriccion: [roles.ROLE_BUSINESS_PARTNER],
    },
    {
      id: 108105,
      name: "Consultar Socio de Negocios",
      ruta: "bussines_partnert_v2",
      restriccion: roles.ROLE_BUSINESS_PARTNER_BUSINESS_PARTNER,
      icono: (
        <Icon style={style.styleFavorite("#373B44", "#9B23A3", "#F3904F")}>
          <PersonSearch style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}bussines_partnert_v2`),
      parent: "SOCIO DE NEGOCIOS",
      module_restriccion: [roles.ROLE_BUSINESS_PARTNER],
    },
    {
      id: 108106,
      name: "Recuperar Socio de Negocios",
      ruta: "consult_recovery_partner_v2",
      restriccion: roles.ROLE_BUSINESS_PARTNER_RECOVER,
      icono: (
        <Icon style={style.styleFavorite("#373B44", "#9B23A3", "#F3904F")}>
          <SupervisorAccount style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}consult_recovery_partner_v2`),
      parent: "SOCIO DE NEGOCIOS",
      module_restriccion: [roles.ROLE_BUSINESS_PARTNER],
    },
    {
      id: 108107,
      name: "Registro General y Punto de Venta",
      ruta: "register_general",
      restriccion: roles.ROLE_BUSINESS_PARTNER_GENERAL_REGISTRATION,
      icono: (
        <Icon style={style.styleFavorite("#373B44", "#9B23A3", "#F3904F")}>
          <PersonAdd style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}register_general`),
      parent: "SOCIO DE NEGOCIOS",
      module_restriccion: [roles.ROLE_BUSINESS_PARTNER],
    },
    //UNILEVER
    {
      id: 109101,
      name: "Reporte de bonificaciones",
      ruta: "consult_report_bonuses_v2",
      restriccion: roles.ROLE_UNILEVER,
      icono: (
        <Icon style={style.styleFavorite("#0072C6", "#0F1AC8", "#1E2A3A")}>
          <Redeem style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}consult_report_bonuses_v2`),
      parent: "UNILEVER",
      module_restriccion: [roles.ROLE_UNILEVER],
    },
    {
      id: 109102,
      name: "DMS",
      ruta: "DMS_V2",
      restriccion: roles.ROLE_UNILEVER,
      icono: (
        <Icon style={style.styleFavorite("#0072C6", "#0F1AC8", "#1E2A3A")}>
          <Redeem style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}DMS_V2`),
      parent: "UNILEVER",
      module_restriccion: [roles.ROLE_UNILEVER],
    },
    
    //CONFIGURACION
    {
      id: 110101, 
      name: "Almacén",
      ruta: "TableWarehouse",
      restriccion: roles.ROLE_CONFIGURATION,
      icono: (
        <Icon style={style.styleFavorite("#535962", "#818181", "#393E47")}>
          <Warehouse style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}TableWarehouse`),
      parent: "CONFIGURACIÓN",
      module_restriccion: [roles.ROLE_CONFIGURATION],
    },
    {
      id: 110102,
      name: "Empresa",
      ruta: "edit_company",
      restriccion: roles.ROLE_CONFIGURATION,
      icono: (
        <Icon style={style.styleFavorite("#535962", "#818181", "#393E47")}>
          <Business style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}edit_company`),
      parent: "CONFIGURACIÓN",
      module_restriccion: [roles.ROLE_CONFIGURATION, roles.ROLE_ADMIN],
    },
    {
      id: 110103,
      name: "Roles",
      ruta: "consult_roles",
      restriccion: roles.ROLE_ADMIN,
      icono: (
        <Icon style={style.styleFavorite("#535962", "#818181", "#393E47")}>
          <Category style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}Consult_route`),
      parent: "CONFIGURACIÓN",
      module_restriccion: [roles.ROLE_CONFIGURATION, roles.ROLE_ADMIN],
    },
    {
      id: 110104,
      name: "Rutas",
      ruta: "Consult_route",
      restriccion: roles.ROLE_CONFIGURATION,
      icono: (
        <Icon style={style.styleFavorite("#535962", "#818181", "#393E47")}>
          <Timeline style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}Consult_route`),
      parent: "CONFIGURACIÓN",
      module_restriccion: [roles.ROLE_CONFIGURATION, roles.ROLE_ADMIN],
    },
    {
      id: 110105,
      name: "Usuarios",
      ruta: "consult_users",
      restriccion: roles.ROLE_CONFIGURATION,
      icono: (
        <Icon style={style.styleFavorite("#535962", "#818181", "#393E47")}>
          <Groups2 style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}consult_users`),
      parent: "CONFIGURACIÓN",
      module_restriccion: [roles.ROLE_CONFIGURATION, roles.ROLE_ADMIN],
    },
    // SOPORTE
    {
      id: 111101,
      name: "Capacitación",
      ruta: "consult_capacitation",
      restriccion: "",
      icono: (
        <Icon style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}>
          <OndemandVideoIcon style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}consult_capacitation`),
      parent: "SOPORTE",
      module_restriccion: [roles.ROLE_USERS],
    },
    {
      id: 111102,
      name: "Centro de ayuda",
      ruta: "help_center_v2",
      restriccion: "",
      icono: (
        <Icon style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}>
          <HelpCenterIcon style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}help_center_v2`),
      parent: "SOPORTE",
      module_restriccion: [roles.ROLE_USERS],
    },
    //INVENTARIOS
    {
      id: 112101,
      name: "Productos",
      ruta: "products_v2",
      restriccion: "",
      icono: (
        <Icon style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}>
          <IcecreamIcon style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}products_v2`),
      parent: "INVENTARIOS",
      module_restriccion: [roles.ROLE_INVENTORY],
    },
    {
      id: 112102,
      name: "Linea de productos",
      ruta: "products_line_v2",
      restriccion: "",
      icono: (
        <Icon style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}>
          <FormatLineSpacingIcon style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}products_line_v2`),
      parent: "INVENTARIOS",
      module_restriccion: [roles.ROLE_INVENTORY],
    },
    {
      id: 112103,
      name: "Almacén de Productos",
      ruta: "stock_v2",
      restriccion: "",
      icono: (
        <Icon style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}>
          <WarehouseIcon style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}stock_v2`),
      parent: "INVENTARIOS",
      module_restriccion: [roles.ROLE_INVENTORY],
    },
    {
      id: 112104,
      name: "Movimiento de productos",
      ruta: "movements_v2",
      restriccion: "",
      icono: (
        <Icon style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}>
          <ExitToAppIcon style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}movements_v2`),
      parent: "INVENTARIOS",
      module_restriccion: [roles.ROLE_INVENTORY],
    },
    {
      id: 112105,
      name: "Lista de precios",
      ruta: "price_list_v2",
      restriccion: "",
      icono: (
        <Icon style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}>
          <ListAltIcon style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}price_list_v2`),
      parent: "INVENTARIOS",
      module_restriccion: [roles.ROLE_INVENTORY],
    },
    {
      id: 112106,
      name: "Carga de productos",
      ruta: "product_load_v2",
      restriccion: "",
      icono: (
        <Icon style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}>
          <LinearScale style={style.styleFigure} />
        </Icon>
      ),
      forStorage: localStorage.getItem(`${userId}product_load_v2`),
      parent: "INVENTARIOS",
      module_restriccion: [roles.ROLE_INVENTORY],
    },
  ];
  return arr;
};
