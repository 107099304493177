import React, { useEffect, useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import { refresh_token } from '../../main_components/tokens/tokenrefresh';
import { add_participants_chat_group, delete_participants_chat_group, get_chat_smit_group, get_conversation_users, get_history_pagination, get_new_message, get_online_users, post_chat_smit, post_chat_smit_group } from '../../services/chat/chat';
import { get_users } from '../../services/configuration/configuration';
import { BANDEJA } from './bandeja';
import { CONVERSATION_NOW } from './conversation';
import ContactsIcon from '@mui/icons-material/Contacts';
import { NEWCHAT } from './newchat';
import SearchIcon from '@mui/icons-material/Search';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import notmp3 from "./notificacion.mp3";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import GroupsIcon from '@mui/icons-material/Groups';
import { BOT_FC } from '../bot/bot';

export const CHATMINI = (props) => {

  const [open, setopen]=useState(false);
  const [databandeja, setdatabandeja]=useState([]);
  const [contact, setcontact]=useState(false);
  const [filterKeyword, setFilterKeyword] = useState('');
  const [socket, setsocket]=useState(null);
  const [conversation_data, set_conversation_data]=useState([]);
  const od = props?.tipo==="soporte"?props?.origen:props?.origen?.attributes?.email;
  const [data, setdata]=useState([]);
  const [datauser, setdatauser]=useState([]);
  const [datauseraux, setdatauseraux]=useState([]);
  const [close, setclose]=useState(true);
  const audioRef = useRef(null);  
  const [dates, setdates]=useState([]);
  const[notificacion, setnotificacion]=useState(false);


  const get_num_handlemessage = async() =>{
    try{
      var rf_token = await refresh_token();
      if(rf_token === true){
        try{
          const nc = await get_history_pagination ( props?.tipo==="soporte" ? props?.origen : props?.origen?.attributes?.email, localStorage.getItem("id_chat_destino_smit"));
          if(nc?.data.length===0){
            console.log("No hay información");
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const day = String(currentDate.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            localStorage.setItem("date_chat_mini", formattedDate);
          }else{
            const sortedDates = [...nc?.data].sort((a, b) => new Date(b) - new Date(a));
            setdates(sortedDates);
            get_hanlemessage(sortedDates[0])
            localStorage.setItem("date_chat_mini", sortedDates[0]);
          }

        }catch(err){
          console.log(err);
        }
      }else{}
    }catch(err){
      console.log(err);
    }
  }


const handleReceiveMessage = (message) => {
  console.log(message);
  reconsult();
  get_user_chat(props?.tipo==="soporte"?"soporte@herramientasinformaticas.com": props?.origen?.attributes?.email);
  if(open===false){
    setnotificacion(true);
    // const audio = new Audio(notmp3);
    // audio?.play();  
    if (audioRef.current) {
      audioRef.current.play().catch(error => {
        console.error('Error al intentar reproducir el sonido:', error.message);
      });;
    }
  }

};

const reconsult = () =>{
  get_hanlemessage(localStorage.getItem("date_chat_mini"));
}

useEffect(() => {
  
  const sockkets = () =>{
    const newsocket = new WebSocket(`wss://ivilfu3by6.execute-api.us-east-1.amazonaws.com/chat_smit?email=${props?.tipo === "soporte"?props?.origen:props?.origen?.attributes?.email}&name=${props?.tipo==="soporte"?"soporte":props?.origen?.attributes?.name}`);
    
    newsocket.addEventListener('open', (event) => {
      console.log('WebSocket connection opened');
      get_user_chat(props?.tipo==="soporte"?"soporte@herramientasinformaticas.com": props?.origen?.attributes?.email);
      get_user_company();
    });
    newsocket.addEventListener("message", event => {
      console.log("Mensaje recibido:", event.data);
      handleReceiveMessage(event.data);
    });
    newsocket.addEventListener("close", event => {
      console.log("Conexión cerrada:", event);
      newsocket.close();
      setTimeout(() => {
        sockkets();
      }, 1500);
    });
    setsocket(newsocket);
    setdatabandeja(props?.usuarios);

    const handleBeforeUnload = () => {
      newsocket.close();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }

  sockkets();
}, []);


  
  const get_hanlemessage=async(date)=>{
    console.log(date);
    try{
      var rf_token = await refresh_token(); 
      if(rf_token===true){
    
          try{  
            const d = await get_new_message(
              props?.tipo==="soporte" ? props?.origen : props?.origen?.attributes?.email, 
              localStorage.getItem("id_chat_destino_smit"),
              date
            ); 
            d.data.sort((a, b) => {
              const dateA = new Date(a.fecha);
              const dateB = new Date(b.fecha); 
              return dateA - dateB;
            });
            set_conversation_data(d?.data); 
          
          }catch(err){
            console.log(err);
          }
      }else{}
    }catch(err){
      console.log(err);
    }
  }
  const get_user_chat=async (id)=>{
    try{
      var rf_token = await refresh_token();
      if(rf_token === true){
          try{
              const d = await get_conversation_users(id);
              d.data.sort((a, b) => {
                const dateA = new Date(`${a.fecha}T${a.hora}`);
                const dateB = new Date(`${b.fecha}T${b.hora}`);
                return dateB - dateA;
              });
    
              setdata(d?.data);
              setdatabandeja(d?.data);
          }catch(err){
            console.log(err);
          }
      }else{}
    }catch(err){
      console.log(err);
    }
  }
  
  const get_user_company = async() =>{
    try{
        const d = await get_users();
        const dlast = d?.data?.users?.filter(item => item.email !== (props.origen === "soporte" ? props.origen : props?.origen?.attributes?.email));      
  
        const datanew = {
          id: 7777,
          username: "SoporteSmit",
          accountnonexpired: true,
          accountnonlocked: true,
          credentialsnonexpired: true,
          editable: true,
          email: "soporte@herramientasinformaticas.com",
          failedattempts: 0,
          fullusername: "Soporte Smit",
          id_company_fk: 1,
          job: "SOPORTE",
          phone: "2727033141",
          user_enable: true, 
        }
      dlast[dlast.length] = datanew;
      setdatauser(dlast); 
      setdatauseraux(dlast);
    }catch(err){
        console.log(err);
    } 
  }


const[name, setname]=useState("");

  
const select = (id, name)=>{
    setname(name);
    setclose(false);
    set_conversation_data([]);  
    localStorage.setItem("id_chat_destino_smit", id);
    localStorage.setItem("name_chat_destino_smit", name);
    get_num_handlemessage();
}
  
  useEffect(() => {
    get_user_chat(props?.tipo==="soporte"?"soporte@herramientasinformaticas.com": props?.origen?.attributes?.email);
    get_user_company();
  }, [])

  const send_message_socket = async(mss) => {

    if (socket.readyState === WebSocket.OPEN) {
        const messageObject = {
          action: 'sendMessage',
          message: mss,
          "email-origen": props?.tipo==="soporte"? props?.origen : props?.origen?.attributes?.email,
          "email-destino": localStorage.getItem("id_chat_destino_smit")
        };
        socket.send(JSON.stringify(messageObject));
    } else {
      console.log('WebSocket is not in the OPEN state. Message not sent.');
    }
}

const filtrarchat = (e) =>{
  const lowercasedKeyword = e.toLowerCase();
  setFilterKeyword(e);
  const filteredResult = data.filter(item=> item?.name_destino?.toLowerCase().includes(lowercasedKeyword));
  setdatabandeja(filteredResult);
}



const create_group = async() =>{

  var data = {
    name_group: "Pruebas jez",
    participants: ["jez@gmail.com", "orlando@gmail.com"],
    id: props?.tipo==="soporte"? props?.origen : props?.origen?.attributes?.email
  }
  try{
    var rf_token = await refresh_token();
    if(rf_token){
        try{
          const d = await post_chat_smit_group(data);
          console.log(d);
        }catch(err){
          console.log(err);
        }
    }else{}
  }catch(err){
    console.log(err);
  }
}
const get_handle_groups = async()=>{
  try{
    var rf_token = await refresh_token();
    if(rf_token){
      setTimeout(async() => {
        try{
          const d = await get_chat_smit_group(props?.tipo==="soporte"? props?.origen : props?.origen?.attributes?.email);
          console.log(d);
        }catch(err){
          console.log(err);
        }
      }, 1500);
    }else{}
  }catch(err){
    console.log(err);
  }

}
const add_part_group = async()=>{
  
  var data = {
    participants: [
      {
        "id_user":"nuevo2@gmail.com", 
        "ban": true 
      },
      {
        "id_user": "Yordi@gmail.com",
        "ban": false,
      }
    ],
  }
  const id = "ada2329e-c7c0-4113-9491-b4f492f1c590";
  
  try{
    var rf_token = await refresh_token();
    if(rf_token){
      setTimeout(async() => {
        try{
          const d = await add_participants_chat_group(id, data);
          console.log(d);
        }catch(err){
          console.log(err)
        }
      }, 1000);
    }else{}
  }catch(err){
    console.log(err)
  }
}
const delete_participant= async()=>{
  
  const id = "ada2329e-c7c0-4113-9491-b4f492f1c590";
  const deluser = "israel.serrano@herramientasinformaticas.com"
  
  try{
    var rf_token = await refresh_token();
    if(rf_token){
      setTimeout(async() => {
        try{
          const d = await delete_participants_chat_group(id, deluser);
          console.log(d);
        }catch(err){
          console.log(err)
        }
      }, 1000);
    }else{}
  }catch(err){
    console.log(err)
  }
}
const [groupban, setgroupban]=useState(false);
const [botinterface, setbotinterface]=useState(false);

  return (
    <div>
        <div className='chat-mini'>
        {
          botinterface ===  true?
          <div className='seccion-bot'>
              <BOT_FC setbotinterface={setbotinterface}></BOT_FC>
          </div>:
          groupban === true?
          <div className='botones-group'>
              <button onClick={()=>setgroupban(false)}>Cerrar</button>
              <button onClick={()=>create_group()}>Crear grupo</button>
              <button onClick={()=>get_handle_groups()}>Consultar grupos</button>
              <button onClick={()=>delete_participant()}>Eliminar Participante</button>
              <button onClick={()=>add_part_group()}>Agregar participantes</button>
          </div>:
          open===true?
          <div className='chat-interface-mini'>
            {
                contact===true?
                  <div className=''>
                    <NEWCHAT 
                      setcontact={setcontact} 
                      select={select} 
                      datauser={datauser} 
                      setdatauser={setdatauser}
                      datauseraux={datauseraux}  
                    ></NEWCHAT>
                  </div>:
                  close===false?
                    <CONVERSATION_NOW
                          name={name} 
                          conversation_data={conversation_data} 
                          od={od} 
                          setclose={setclose}
                          origen={props?.tipo === "soporte" ? props?.origen : props?.origen?.attributes?.email}  
                          usuario_origen={props?.tipo === "soporte" ? "smit" : props?.origen?.attributes?.name}
                          destino={localStorage.getItem("id_chat_destino_smit")}
                          usuario_destino={localStorage.getItem("name_chat_destino_smit")}
                          id = {props?.tipo==="soporte"? props?.origen : props?.origen?.attributes?.email}
                          get_hanlemessage={reconsult}
                          send_message_socket={send_message_socket}
                          get_user_chat={get_user_chat}
                          setopen={setopen}
                    ></CONVERSATION_NOW>
                    :
                    <div className='bandeja-mini-chat'>
                      <div className='header-chat'>
                          <div className='title-chat-smit-mini'>
                            Chat Smit
                          </div>
                          <div className='actions-menu-chat-mini'>
                            <span title='Activar Bot Smit'><SmartToyIcon onClick={()=>{setbotinterface(true)}}/></span>:
                            {/* <span title="Grups"><GroupsIcon onClick={()=>setgroupban(true)}></GroupsIcon></span> */}
                            <span title='Nuevo Mensaje'><ContactsIcon onClick={()=>setcontact(true)}/></span>
                            <span title='Cerrar Chat'><CloseIcon onClick={()=>setopen(false)} title={"Cerrar Chat"}></CloseIcon></span>
                          </div>
                            
                        </div>
                        <div className='search-user-mini'>
                            <SearchIcon></SearchIcon>
                            <input 
                                placeholder='Buscar un chat'
                                value={filterKeyword}
                                onChange={(e)=>filtrarchat(e.target.value)}
                            >
                            </input>
                      </div>
                        <div className='body-chat'>
                        {
                          databandeja?.map((item, index)=>(
                              <>
                              <div 
                                className='conversations chat-selected-mini'
                                key={index}
                                onClick={()=>{select(item?.destino, item?.name_destino)}}
                              >
                              {
                                  <BANDEJA item={item}></BANDEJA>
                              } 
                              </div> 
                              </>
                          ))
                        } 
                        </div>  
                    </div>
                  }  
          </div>:
          <div className='icono-chat-mini' onClick={()=>{setopen(true); setnotificacion(false)}}>
            {
              notificacion===true?<CircleNotificationsIcon className='notification'/>:<></>
            }
            <MapsUgcIcon></MapsUgcIcon>
            {/* <audio ref={audioRef} src={notmp3} style={{display:"none"}}/> */}
          </div>
        }
      </div>
    </div>
  )
}
