//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { StickyTable, Row, Cell } from "react-sticky-table";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
//COMPONENTES
import SubTableContainer from "../SubTableContainer";
import ViewDetail_Detail_No_Sales from "./ViewDetail_Detail_No_Sales";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "../../components_cfdi/table.css";
import "../../components_Expenses/expense.css";

//IMAGENES
import Spinner from "../../imgComponents/S.gif";
import reconsultaTable from "../../imgComponents/reconsultaTable.png";

import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { no_sales_clasification_frezz } from "../../services/reports/dashboard";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function ViewDetail_Frez({ datei,datef,route,id_detail,pointSale,address}) {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);


  
  const [open, setOpen] = useState(false);
  const [equality, setEquality] = useState("");
  const [success_msg, setSuccess_msg] = useState("none");
  const [error_msg, setError_msg] = useState("none");
  const [Reconsulta, setReconsulta] = useState(false);
  const [ReconsultaDta, setReconsultaDta] = useState(false);

  const dialog = useDialog();

  


  const handleReconsulta = (reconsultar) => {
    if (reconsultar === true) {
      handleSubmit2();
    } else {
      console.log("no se reconsulto");
    }
  };


  const handleSubmit2 = async (event) => {
    event?.preventDefault();
    setReconsulta(true);

    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await no_sales_clasification_frezz(sessionStorage.getItem("clasificacionNoSales").replaceAll(' ', '_')?.replaceAll("/","-"), id_detail);
            const data = d === null?[] : d.data;
            setData(data);
            const cargos = data.filter((item) => item.efect === "CARGO");
            const abonos = data.filter((item) => item.efect === "ABONO");
    
            const sumCargos = cargos
              .map((cargoSum) => parseFloat(cargoSum.ammount))
              .reduce((previous, current) => {
                return previous + current;
              }, 0);
    
            const sumAbonos = abonos
              .map((abonoSum) => parseFloat(abonoSum.ammount))
              .reduce((previous, current) => {
                return previous + current;
              }, 0);
            console.log("==>cargos<==");
            console.log(sumCargos);
            console.log("==>abonos<==");
            console.log(sumAbonos);
            if (sumCargos - sumAbonos === 0) {
              setEquality("Los datos cuadran");
              console.log("Los datos cuadran");
              setSuccess_msg("");
              setError_msg("none");
            } else {
              setEquality("Los datos no cudran");
              console.log("Los datos no cudran");
              setError_msg("");
              setSuccess_msg("none");
            }
            setReconsulta(false);
          }catch(err){
            console.log(err);
            setData([]);
            setReconsulta(false);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }

    /*
    axios
      .get(
        `https://zaqp4ihdn9.execute-api.us-east-1.amazonaws.com/prod/no-sales/${sessionStorage.getItem("clasificacionNoSales").replaceAll(' ', '_')?.replaceAll("/","-")}/${id_detail}/freezers`,
        {
        
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const data = response.data;
        setData(data);
        const cargos = data.filter((item) => item.efect === "CARGO");
        const abonos = data.filter((item) => item.efect === "ABONO");

        const sumCargos = cargos
          .map((cargoSum) => parseFloat(cargoSum.ammount))
          .reduce((previous, current) => {
            return previous + current;
          }, 0);

        const sumAbonos = abonos
          .map((abonoSum) => parseFloat(abonoSum.ammount))
          .reduce((previous, current) => {
            return previous + current;
          }, 0);
        console.log("==>cargos<==");
        console.log(sumCargos);
        console.log("==>abonos<==");
        console.log(sumAbonos);
        if (sumCargos - sumAbonos === 0) {
          setEquality("Los datos cuadran");
          console.log("Los datos cuadran");
          setSuccess_msg("");
          setError_msg("none");
        } else {
          setEquality("Los datos no cudran");
          console.log("Los datos no cudran");
          setError_msg("");
          setSuccess_msg("none");
        }

        setReconsulta(false);
      })
      .catch((err) => {
        setData([]);
        setReconsulta(false);
      });*/
  };



  useEffect(() => {
    handleSubmit2();
  }, []);

  const columns = useMemo(
    () => [

    

      

      
      {
        Header: "Número economico",
        accessor: "economic_number",
      },
      {
        Header: "Activo fijo",
        accessor: "fixed_asset",
      },
      {
        Header: "Modelo",
        accessor: "model_name",
      },
      {
        Header: "Marca",
        accessor: "brand_name",
      },

     
 
      
    ],
    []
  );

  if (Reconsulta === false) {
    return (
      <div>
        <div>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style2}>
              <div className="centermodaldescarga">
                <Typography
                  id="modal-modal-title"
                  
                  variant="h6"
                  component="h2"
                >
                  <img src={Spinner}></img>
                </Typography>
                <Typography
                  id="modal-modal-description"
                  
                  sx={{ mt: 2 }}
                >
                  {"Eliminando detalle..."}
                </Typography>
              </div>
            </Box>
          </Modal>
        </div>

        <div className="TableConsultarTodo2 TableDetailTIket">
          <div className="success-msg" style={{ display: success_msg }}>
            <h3>
              {pointSale}
              <br></br>
              {address}
            </h3>
          </div>
          <div className="error-msg" style={{ display: error_msg }}>
            <h2>
              <i className="fa fa-times-circle"></i>
              {equality}
            </h2>
          </div>
          <StickyTable>
            <SubTableContainer
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              renderRowSubComponent={(row) => {
                return (
                  <div style={{ padding: "20px" }}>
                    <ViewDetail_Detail_No_Sales 
                    verData={true}
                    datei={datei}
                    datef={datef}
                    id_detail={id_detail} 
                    />
                  </div>
                );
              }}
            />
          </StickyTable>
        </div>
      </div>
    );
  } else {
    return <div className="loader"></div>;
  }
}

export default ViewDetail_Frez;
