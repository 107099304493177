//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { get_brands } from "../../services/assets/assets";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
//ESTILOS
import "../../components_cfdi/estilo.css";
import '../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
import "./Brands.css";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./utilities/render_row_action_menuItems";
import MODELS_V2 from "../componentModeloF/models_v2";
import OPERATION_BRANDS from "./utilities/op_brands";
import CREATE from "../../imgComponents/create.png";
import { CustomDialog } from "react-st-modal";
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";


const BRANDS_V2= () => {
  
  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen]=useState(false);
  var entrada=0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  const [idmarca, setidmarca]=useState(null);
  const [total_rows, settotal_rows] = useState("");
  const [query_filters, setquery_filters] = useState({});
  const [query_filters_dt, setquery_filters_data] = useState("");


useEffect(()=>{
  handleSubmit();
// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);


const update_identifiers =
[
  { name: 'string' },
  { type: 'string' },
  { date_register: 'String' },
];


function alertas(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}

const handleSubmit = async (query_filters_flag, query_filters_data) => {
    
    setmodalT(true);
    setopen(true);
    setData([]);
    setIsLoading(true);
    
    try {
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            if(query_filters_flag){
              const d = await get_brands();
              setquery_filters(query_filters_data);
            }else{
              var d = await get_brands();
              var ndt = update_null_identifiers(d?.data?.brands, update_identifiers);
              setData(ndt);    
              setopen(false);
              setIsLoading(false);
              setmodalT(false);   
          }
          }catch(err){
            setData([]);
            setopen(false);
            setIsLoading(false);
            setmodalT(false);
          }     
        }, 1000);
      }else{}
    } catch (err) {
      console.log(err);
    } 
};
const handleStateChange = (newState) => {
  const filteredRowsCount = newState.filteredRowModel
  settotal_rows(filteredRowsCount);
};


const columns = useMemo(
  () => [
     {
       id: "name",
       header: "Nombre",
       accessorKey:"name",
       size: 400,
     },
     {
      id: "type",
       header: "Tipo",
       accessorKey: "type",
       filterVariant: 'select',
       size: 400,
       muiTableBodyCellProps:{
        align:'center'
       }
     },
     {
      id: "date_register",
      header: "Fecha Registro",
      accessorKey:"date_register",
      size: 400
    }
    
    ],
  []
);


const handle_success_filter = (a, b) => {
  handleSubmit(a, b);
  notificationSystemRef.current.addNotification({
    message: <AlertResponse msj={"Filtro Aplicado"} view={true}></AlertResponse>,
    level: "success",
    position: "br",
    autoDismiss: 10,
  });
}

useEffect(() => {
  if (total_rows === "") {
  } else {
    const getCurrentPath = () => {
      const search = window.location.search;
      const pathWithoutQuestionMark = search.startsWith('?') ? search.slice(1) : search;
      return pathWithoutQuestionMark;
    };

    const pathWithoutQuestionMark = getCurrentPath();
    setquery_filters_data(pathWithoutQuestionMark)
  }
}, [total_rows])



  return (
    <>
     <BREADCRUMBS niveles={
                [
                  {label: "CONSULTAR MARCAS", path: null},
                ]
              }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>

<div className='new_table_v2'>
  <div >         
  <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <MODAL_TABLE
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></MODAL_TABLE>
          <>
              
          <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        onStateChange={handleStateChange}
        enableColumnFilterModes
        enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            Header: () => (
              <div>
                <img
                title="Registrar Marca"
                src={CREATE}
                style={{"cursor":"pointer"}}
                className=""
                onClick={async () => {
                  const result = await CustomDialog(
                    <OPERATION_BRANDS
                    operation={"CREATE"} 
                    handleSubmit={handleSubmit}
                    alertas={alertas}
                    setmodalT={setmodalT}
                />,
                    {
                      className: "custom-modal",
                      title: "Registrar Marca",
                      showCloseIcon: true,
                      isCanClose:false,
                    }
                  );
                }}
              ></img>
              </div>
            ),
            enableHiding: true
          },
          'mrt-row-actions': {
            enableHiding: true,
            size: 75
          },
          'mrt-row-expand': {
            enableHiding: true,
            size: 75
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            settotal_rows(table);
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderDetailPanel={({ row }) => {
          if (row.getIsExpanded() === true) {
            setidmarca(row?.original?.id);
          }
          return(
            <MODELS_V2
              setmodalT={setmodalT}
              alertas={alertas}
              data={data}
              columns={columns}
              defaultPageSize={3}
              showPagination={false}
              id={row.original.id}
            />)     
        }}
         renderRowActionMenuItems={({ row, closeMenu }) => [
            <RENDER_ROW_ACTION_MENU_ITEMS
              closeMenu={closeMenu}
              setmodalT={setmodalT}
              setmenssage={setmenssage}
              setmodalGeneral={setmodalGeneral}
              row={row}
              handleSubmit={handleSubmit}
              setreconsult={setreconsult}
              refresh_token={refresh_token}
              alertas={alertas}
            />
          ]}
         renderToolbarInternalActions={({ table, index }) => (
           <RENDER_TOOLBAR_INTERNAL_ACTIONS
            table={table} 
            file_name={"Consultar Marcas"}
            update_identifiers={update_identifiers}
            settotal_rows={settotal_rows}
            query_filters={query_filters}
            query_filters_dt={query_filters_dt}
            handle_get_consult={handleSubmit}
            key={index}
            handle_success_filter={handle_success_filter}
            share_filter={true}
            total_rows={total_rows !== "" ? total_rows : 0}
           />
         )
         }
      />
          
          </>   
          </div>
        </div>
    </>
  );
}

export default BRANDS_V2;
