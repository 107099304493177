//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
//COMPONENTES
import TableContainer from "../../../../main_components/Table/TableContainer";
import { get_warehouse_existence } from "../../../../services/inventory/inventory";
import { create_warehouse_movements } from "../../../../services/inventory/inventory";
import MODAL_TABLE from "../../../../main_components/modal/modal_Table";
import { get_warehouse } from "../../../../services/inventory/inventory";
import { get_price_list_products } from "../../../../services/inventory/inventory";
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";
import { BREADCRUMBS } from "../../../../main_components/pagination/breadcrumbs";
import { update_price_list_products } from "../../../../services/inventory/inventory";
//ESTILOS

//IMAGENES/ICONOS

export const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});
const UPDATE_PRICE_LIST = () => {
  const [dataTable, setDataTable] = useState([]);
  
  const [modalT, setmodalT] = useState(true);
  const [originalData] = useState(dataTable);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [origin, setorigin] = useState(0);
  const [destination, setdestination] = useState(0);

  

  const handle_update_product = async () => {
    const ndt = dataTable.filter(d => d.price >= 0).map((d) => {
      var dt={
        code:d.code,
        price:d.price
      }
    return dt} );
    var send_price=ndt.filter((d) => d.price!=="");
    console.log(send_price)
    console.log("aaa")
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            if(send_price.length>0){
              await update_price_list_products(send_price,sessionStorage.getItem("price_list_products"));
              window.location="/price_list_products";
            }else{ 
              alert("La lista de precios no puede quedar vacia a la hora de modificar"
              )
            }
          } catch (err) {
            alert(JSON.stringify(err?.response))
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };



  const handle_get_product = async () => {
    setDataTable([]);
    setmodalT(true);
    try {
      const isvaild = await refresh_token();
      if (isvaild) {
        setTimeout(async () => {
          try {
            const dt = await get_price_list_products(sessionStorage.getItem("price_list_products"));
            setDataTable(dt.data);
            setmodalT(false);
          } catch (error) {
            setDataTable([]);
            setmodalT(false);
          }
          
        }, 1500);
      }
    } catch (err) {
      setDataTable([]);
      setmodalT(false);
    }
  };

  useEffect(() => {
    handle_get_product();
  }, []);




  const columns = useMemo(
    () => [

      {
        Header: "Código",
        accessor: "code",
      },
      {
        Header: "Producto",
        accessor: "product_name",
      },
      {
        Header: "Linea",
        accessor: "brand",
      },
      {
        Header: "Presentación",
        accessor: "minimum_sales_unit",
      },
      {
        Header: "Piezas",
        accessor: "pieces",
      },

      {
        id:"price",
        Header: ()=>{
          return(
            <div>
              <Button
                sx={{ width: "100%" }}
                variant="contained"
                onClick={() => handle_update_product()}
                style={{background:"green"}}
                title="Actualizar lista de precios"
              >
                ACTUALIZAR 
              </Button>
            </div>
          )
        },
        
        Cell:(row)=>{
          const [quantity_data, setquantity_data] = useState(row.row.original.price);

          const editcell=(e)=>{

            if(e.target.value>=0){
              dataTable[row.row.index] = {
                code: row.row.original.code,
                product_name: row.row.original.product_name,
                brand: row.row.original.brand,
                minimum_sales_unit: row.row.original.minimum_sales_unit,
                pieces: row.row.original.pieces,
                price: e.target.value,
              };

              console.log(dataTable);
              console.log(e.target.value);
             
              setDataTable(dataTable);
              setquantity_data(e.target.value);
            } else{
              setquantity_data("")
              dataTable[row.row.index] = {
                code: row.row.original.code,
                product_name: row.row.original.product_name,
                brand: row.row.original.brand,
                minimum_sales_unit: row.row.original.minimum_sales_unit,
                pieces: row.row.original.pieces,
                price: null,
              };
              console.log(dataTable);
              console.log(e.target.value);
              setDataTable(dataTable);
              setquantity_data(e.target.value);
            }
              
          }
          
          useEffect(()=>{

           
            console.log("entra el dato");
          },[dataTable])

          return (
            <div>
              <input type="number" value={quantity_data} onChange={(e)=>editcell(e)}></input>
            </div>
          );
        },
       

      },
     
    ],
    [dataTable]
  );
  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setDataTable((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          console.log(columnId);
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  useEffect(() => {
    console.log(dataTable)
    setSkipPageReset(false);
    var ntest = dataTable.filter((d) => d.quantity !== "");
    console.log(ntest);

    setorigin(
      dataTable
        .map((a) => Number(a.origin))
        .reduce((previous, current) => {
          return previous + current;
        }, 0)
    );
    setdestination(
      dataTable
        .map((a) => Number(a.destination))
        .reduce((previous, current) => {
          return previous + current;
        }, 0)
    );
    
  }, [dataTable]);

  const resetData = () => dataTable(originalData);

  return (
    <div >
     <BREADCRUMBS niveles={
            [
              {label: "LISTA DE PRECIOS", path:"/price_list"},
              {label: "PRODUCTOS", path:"/price_list_products"},
              {label: `MODIFICAR PRODUCTOS`, path: null}, 
            ]
          }
      ></BREADCRUMBS>
      <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>
      <div className="TableTaxes">
        <div >
          <TableContainer
            columns={columns}
            data={dataTable}
            style={{ overflowx: 10 }}
            updateMyData={updateMyData}
            skipPageReset={skipPageReset}
            origin={origin}
            destination={destination}
          />
        </div>
      </div>
    </div>
  );
};

export default UPDATE_PRICE_LIST;
