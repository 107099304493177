//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import {CustomDialog, Confirm} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { delete_brands, get_brands } from "../../services/assets/assets";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { SelectColumnFilter } from "../../components_cfdi/filters";
import TableContainer from "../../main_components/Table/TableContainer";
//ESTILOS
import "../../components_cfdi/estilo.css";
import '../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
import "./Brands.css";
//IMAGENES/ICONOS
import Delete from "../../imgComponents/borrar.png";
import CREATE from "../../imgComponents/create.png";
import Edit from "../../imgComponents/save.png";
import MODELS from "../componentModeloF/models";
import OPERATION_BRANDS from "./utilities/op_brands";

const BRANDS= () => {
  
  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen]=useState(false);
  var entrada=0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);

  const [idmarca, setidmarca]=useState(null);

useEffect(()=>{
  handleSubmit();
// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);

function alertas(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}

//proceso de consultar todos las marcas
const handleSubmit = async () => {
    if(entrada===0){
      entrada=entrada+1;
      setmenssage("Consultando Marcas...");
      setmodalGeneral(true);
      setmodalT(true);
    }else{
      setmodalT(true);
    }
    setopen(true);
    setData([]);
    try {
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            var d = await get_brands();
            setData(d === null ? [] : d.data.brands);    
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);   
          }catch(err){
            setData([]);
            setopen(false)
            setmodalGeneral(false);
            setmodalT(false);
          }     
        }, 1000);
      }else{}
    } catch (err) {
      console.log(err);
    } 
};

const columns = useMemo(
  () => [
    {
      Header:()=>(
        <div>
          <img
            id="crearRol"
            src={CREATE}
            onClick={() => {
               CustomDialog(               
             <div>
                <OPERATION_BRANDS operation={"CREATE"} handleSubmit={handleSubmit} alertas={alertas} setmodalT={setmodalT}></OPERATION_BRANDS>
             </div>,
           {
             className: "modalTItle",
             title:"Crear nueva Marca",
             showCloseIcon: true,
             isCanClose:false,
           }
       );
     }}
            title="Crear Marca"
            className=""
            alt=""
          ></img>
        </div>
      ),
      id: "Expand",
      Cell: ({ row }) => {  
  
        function guardardato(){  
          setidmarca(row.original.id);
         }
        return(
          <a {...row.getToggleRowExpandedProps()}>
            {row?.isExpanded ? <span title="Ocultar Detalles">🔼</span>: <span title="Mostrar Detalles" onClick={guardardato}>🔽</span>}
          </a>
          )
        },
      },
      {
      Header:"Acciones",
      Cell: ({ row }) => {
      const DeleteSector = async () => {  
      setmodalT(true);
      setData([]);
      setopen(true);
        try {
          var rf_token = await refresh_token();
          if(rf_token===true){
            setTimeout(async() => {
              try{
                const dt = await delete_brands(row.original.id);
                console.log(dt);
                alertas("Marca Eliminada", true);
                handleSubmit();
              }catch(err){
                console.log("error", err);
                setopen(false);
                if(err.response.status===409){
                  alertas("Aún quedan modelos asignados a la marca", false);
                }else{
                  alertas("No se pudo eliminar la Marca", false);
                }
                setmodalT(false);
                handleSubmit();
              }
            }, 1000);
          }else{}
        } catch (err) {
         console.log(err);
        }
      }
        return (
          <div>
            <img
              src={Delete}
              onClick={async() =>{
                  const result = await Confirm('¿Esta usted seguro de eliminar la Marca?','Eliminar Marca','Si','No');
                  if(result){
                    DeleteSector()
                    }
                  }            
                }
              
              title="Eliminar Marca"
              alt=""
            ></img>
              &nbsp;
            <img
              src={Edit}
              onClick={
                () => {
                CustomDialog(               
                 <div>
                    <OPERATION_BRANDS operation={"EDIT"} row={row} handleSubmit={handleSubmit} alertas={alertas} setmodalT={setmodalT}></OPERATION_BRANDS>
                 </div>,
               {
                 className: "modalTItle",
                 title:"Editar Marca",
                 showCloseIcon: true,
                 isCanClose:false,
               }
           ); 
         }
        }
              
              title="Editar Marca"
              alt=""
            ></img>
          </div>
        );
      },
    },
     {
       Header: "Nombre",
       accessor:"name"
     },
     {
       Header: "Tipo",
       accessor: "type",
       Filter: SelectColumnFilter,
     },
     {
      Header: "Fecha Registro",
      accessor:"date_register"
     }
    
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
);
  return (
    <>
     <BREADCRUMBS niveles={
                [
                  {label: "CONSULTAR MARCAS", path: null},
                ]
              }
        new_version={true}
        modul="CONSULTAR MARCAS Y MODELOS V2"
        path={"/consult_brands_v2"}
      ></BREADCRUMBS>
      <br/><br/><br/>

<div className="TableMarcas">
  <div >         
  <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <MODAL_TABLE
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></MODAL_TABLE>
          <>
          
          <StickyTable>
            <TableContainer
              paginado={"Marcas"}
              consultas={"Marcas Consultadas"}
              exportar={true}
              columns={columns}
              data={data}
              nametable={"Marcas"}
              style={{ overflowx: 10 }}
              renderRowSubComponent={row=>{
                return (
                  <div style={{ padding: "20px" }}>
                    <div className="subtablemodelo">
                    <MODELS
                      setmodalT={setmodalT}
                      alertas={alertas}
                      defaultPageSize={3}
                      showPagination={false}
                      id={idmarca}
                      
                    />
                    </div>
                  </div>
                )
              }}
              Gmodal={open}
            />
          </StickyTable>
          </>   
          </div>
        </div>
    </>
  );
}

export default BRANDS;
