//PAQUETERIAS
import { useEffect, useState, useMemo, useRef} from "react";
import { StickyTable} from "react-sticky-table";
import {CustomDialog, Confirm} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
import MODAL_TABLE from "../../main_components/modal/modal_Table";
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
//ESTILOS
import "../../components_cfdi/estilo.css";
import '../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
import "./catalogexpense.css";
//IMAGENES/ICONOS
import Delete from "../../imgComponents/borrar.png";
import Details from "../../imgComponents/lupa.png";
import CREATE from "../../imgComponents/create.png";
import Edit from "../../imgComponents/save.png";

import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import EditExpenseConcept from "./tools/op_expense_concept";
import { SelectColumnFilter } from "../../components_cfdi/filters";
import { delete_expense_concepts, get_expense_concepts } from "../../services/expenses/expenses";
import TableContainer from "../../main_components/Table/TableContainer";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";

const TableExpenseConcept= () => {

  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen]=useState(false);
  var entrada=0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);

  useEffect(()=>{
    handleSubmit(); 
  },[]);



function alertas(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}

//proceso de consultar el catalogo de conceptos de Gastos
const handleSubmit = async () => {
    if(entrada===0){
      entrada=entrada+1;
      setmenssage("Consultando Catálogo de Gastos");
      setmodalGeneral(true);
      setmodalT(true);  
    }else{
      setmodalT(true);
    }
    setopen(true);
    setData([]);
    
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await get_expense_concepts();
            const data = d.data.exp_income_rules;
            setData(data);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
          }catch(err){
            console.log("error", err);
            setData([]);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
          }     
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
};

const columns = useMemo(
  () => [
    {
      Header:()=>(
        <div>
          <img
            id="crearRol"
            src={CREATE}
            onClick={async () => {
              const result = await CustomDialog(               
             <div>
                <EditExpenseConcept operation={"CREATE"} handleSubmit={handleSubmit} alertas={alertas} setmodalT={setmodalT}/>
             </div>,
           {
             className: "modalTItle",
             title:"Crear Catálogo de Conceptos de Gastos",
             showCloseIcon: true,
             isCanClose:false,
           }
       );
     }}
            title="Crear Catálogo de Conceptos de Gastos"
            className="butonhov"
            alt=""
          ></img>
        </div>
      ),
      
      id: "Acciones",
      Cell: ({ row }) => {

      function Detalles(){
        console.log("entra en detalles");
        console.log(row.original);
        sessionStorage.setItem("id_catalogo",row.original.id);
        sessionStorage.setItem("nombre_catalogo",row.original.catalog_name)
        window.location="/consult_assigned_concepts";
      }

      const DeleteSector =async()=>{  

      setmodalT(true);
      setData([]);
      setopen(true);
      try{
        var rf_token = await refresh_token();
        if(rf_token===true){
          setTimeout(() => {
            try{
              const d = delete_expense_concepts(row.original.id);
              console.log(d);
              alertas("Catalogo de Conceptos de Gastos Eliminado", true);
              handleSubmit();
            }catch(err){
              console.log("error", err);
              setopen(false);
              alertas("Error, no se pudo eliminar el Catálogo de Conceptos de Gastos",false)
              handleSubmit();
            }  
          }, 1000);
        }else{} 
      }catch(err){
        console.log(err);
      }
      }
        return (
          <div>
            <img
              src={Delete}
              onClick={async() =>{
                  const result = await Confirm('¿Esta usted seguro de eliminar el Cátalogo de Conceptos de Gastos?','Eliminar Catálogo de Conceptos de Gastos','Si','No');
                  if(result){
                    DeleteSector()
                    }
                  }            
                }
              className="butonhov"
              title="Eliminar Catálogo de Conceptos de Gastos"
              alt=""
            ></img>
            <img
              src={Details}
              onClick={Detalles}
              className="butonhov"
              title="Conceptos asignados"
              alt=""
            ></img>
            <img
              src={Edit}
              onClick={
                async () => {
                  const result = await CustomDialog(               
                 <div>
                    <EditExpenseConcept operation={"EDIT"} row={row} handleSubmit={handleSubmit} alertas={alertas} setmodalT={setmodalT}></EditExpenseConcept>
                 </div>,
               {
                 className: "modalTItle",
                 title:"Editar Catálogo de Conceptos de Gastos",
                 showCloseIcon: true,
                 isCanClose:false,
               }
           ); 
         }
        }
              
              title="Editar Catálogo de Conceptos de Gastos"
              alt=""
              className="butonhov"
            ></img>
          </div>
        );
      },
    },
    {
      Header: "Concepto",
      accessor:"catalog_name"
    },
    {
      Filter: SelectColumnFilter,
      Header: "Estado",
      accessor: (d) =>{
        return d.enable===true?"Habilitado":"Deshabilitado";
      }
    },
    {
      Header: "Rol Asignado",
      accessor: "name_role"  
    },
    {
      Header:"Fecha Registrada",
      accessor:"date_register"
    }
    
    ],
    []
);
  return (
    <>
      <BREADCRUMBS niveles={
                [
                  {label: "CONSULTAR CATALOGO DE CONCEPTOS", path: null},
                ]
              }
              new_version={true}
              modul="CONSULTAR CATALOGO DE CONCEPTOS V2"
              path={"/catalog_expense_concept_v2"}
      ></BREADCRUMBS>
<br/><br/><br/>
<div className="TableCatalogoGastos">

<NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
<MODAL_TABLE
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></MODAL_TABLE>

  <div >            
          <>
          <StickyTable>
            <TableContainer
              paginado={"Catálogo de Gastos"}
              consultas={"Catálogos de Gastos Consultados"}
              nametable={"Catálogos de Gastos"}
              exportar={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              Gmodal={open}
            />
          </StickyTable>
          </>   
          </div>
        </div>
    </>
  );
}

export default TableExpenseConcept;
