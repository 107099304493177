import { useState } from 'react';
import { GoogleMap, withScriptjs, withGoogleMap, Marker, InfoWindow } from 'react-google-maps';
//IMAGENES
import marker1 from "../../../imgComponents/marker1.png";
import marker2 from "../../../imgComponents/marker2.png";
import marker3 from "../../../imgComponents/marker3.png";
import marker4 from "../../../imgComponents/marker4.png";
import marker5 from "../../../imgComponents/marker5.png";

function MAPCONTAINER({markers}) {

  const [selectedMarker, setSelectedMarker] = useState(null);

  console.log(markers);
  
 
  const bounds = new window.google.maps.LatLngBounds();
   markers.forEach((marker) => {
    if (
      Math.abs(marker.lng) <= 14 || Math.abs(marker.lat) >= 32 ||
      Math.abs(marker.lng) <= 86 || Math.abs(marker.lng) >= 118
    ) {
      // Si el marcador cumple con la condición, no lo agregamos a los bounds
      return;
    }
     bounds.extend({ lat: marker.lat, lng: marker.lng });
  });

  return (
    <GoogleMap
      defaultZoom={12}
      ref={(map) => {
        if (map && selectedMarker) {
          map.panTo({ lat: selectedMarker.lat, lng: selectedMarker.lng });
        } else if (map && bounds.getNorthEast() && bounds.getSouthWest()) {
          map.fitBounds(bounds);
        }
      }} 
    >
      {markers.map((marker) => (
        <Marker
          key={marker.id}
          position={{ lat: marker.lat, lng: marker.lng}}
          label={{ className: 'custom-label-gps', text: marker?.ordenvisita?.toString()}}
          icon = {{
            url: marker.tipo===1?marker1:(marker.tipo===3?marker3:(marker.tipo===4?marker4:(marker.tipo===5?marker5:marker2))),
            scale: 2,
            draggable: true
          }}
          onClick={() => setSelectedMarker(marker)}
         />
      ))}
      {selectedMarker && (
        <InfoWindow
          position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
          onCloseClick={() => setSelectedMarker(null)}
        >
          <div>
            {
              selectedMarker.tipo===1?
              <>
                <h3>Punto de Venta: {selectedMarker.code}</h3>
                {/* <p>{selectedMarker.name}</p> */}
              </>:
              <>
                <h3>Ticket: {selectedMarker.ticket}</h3>
                {/* <p>{selectedMarker.name}</p> */}
              </>
            }           
          </div>
        </InfoWindow>
      )}

    </GoogleMap>
  );
}

const WrappedMap = withScriptjs(withGoogleMap(MAPCONTAINER));

export default function Map({ markers }) {
  return (
    <div style={{ width: '95%', height: '100%', margin: "0 auto" }}>
      <WrappedMap 
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyBOh51zw12N4kb7yDY4pp-6gM7xVaFterc&callback=funtion.prototype`}
        loadingElement={<div style={{ height: '100%' }} />}
        containerElement={<div style={{ height: '400px' }} />}
        mapElement={<div style={{ height: '100%' }} />}
        markers={markers}
      />
    </div>
  );
}
