import React, { useEffect, useState } from "react";
import "../../../components_cfdi/estilo.css";
import "../../../components_cfdi/modal.css";
import "../../../components_cfdi/table.css";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { get_pointsale_cabinetbs, get_pointsale_sectors } from "../../../services/businesspartners/businesspartners";

function SubTable() {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);

  const handleSubmit = async(event) => {
    event?.preventDefault();
    //console.log("entra a consultar los congeladores");
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await get_pointsale_cabinetbs(sessionStorage.getItem("cabinetsBs"));
            console.log(d);
            setData(d===null?[]:d.data.cabinets);
          }catch(err){
            console.log(err);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
    
    /*
    axios
      .get(
        `https://m0fsi6ifd9.execute-api.us-east-1.amazonaws.com/development/pointsofsale/${sessionStorage.getItem(
          "cabinetsBs"
        )}/cabinets`,
        {
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
        setData(response.data.cabinets);
      })
      .catch((err) => {
        //console.log("error", err);
      });
      */
  };


  const handleSubmit2 = async(event) => {
    event?.preventDefault();
    //console.log("entra a consultar los congeladores");
    try{
      var rf_token = await refresh_token();
      if(rf_token === true){
        setTimeout(async() => {
          try{
            const d = await get_pointsale_sectors(sessionStorage.getItem("cabinetsBs"));
            console.log(d);
            setData2(d === null?[]:d.data.sectors);
          }catch(err){
            console.log("error", err);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  useEffect(() => {
    handleSubmit();
    handleSubmit2();

  }, []);

  return (
    <div className="TableConsultarTodo2">
      {data===null?<h3>Sin congelador asignado</h3>:
          <div>      
          <h3 className="Sct">Congeladores Asignados:</h3>
      <table>
        <thead>
          <tr>
            <th>Marca</th>
            <th>Modelo</th>
            <th>Número Económico</th>
            <th>Activo</th>
            <th>Serie</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={index}>
              <td>{item.brand}</td>
              <td>{item.model}</td>
              <td>{item.economic_number}</td>
              <td>{item.fixed_asset}</td>
              <td>{item.serial_number}</td>

            </tr>
          ))}
        </tbody>
      </table>
      </div>

      }
      <br></br>

      {data2===null?<h3>Sin sectores relacionados</h3>:
      <div>      
        <h3 className="Sct">Sectores Relacionados:</h3>
      
      <table>
        <thead>
          <tr>
            <th>No.</th>
            <th>Sector</th>
            <th>Orden del Sector</th>
            <th>Ruta</th>
            <th>Fecha de Registro</th>

          </tr>
        </thead>
        <tbody>
          {data2?.map((item, index) => (
            <tr key={index}>
              <td>{item.number_sector}</td>
              <td>{item.name_sector}</td>
              <td>{item.orderSector}</td>
              <td>{item.route}</td>
              <td>{item.date_register}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      }
    </div>
  );
}

export default SubTable;
