import { Backdrop, Box, Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import { Rating } from '@mui/material';
import { useDialog } from 'react-st-modal'
import { 
  get_supports, 
  updateSupport 
} from '../../services/supports/supports';
import { useEffect, useState } from 'react';
import './confirmarticket.css';
import { act } from '@testing-library/react';

export const ConfirmUpdateTicket = ({
  alertas= () => {},
  currentStatus = '',
  ticket = '',
  user_rate = null,
  user_rate_comment = null
}) => {
  
   const dialog =  useDialog();
   const [rate_message, setRate_message ] = useState('');
   const [rate_value, setRate_value ] = useState(0);
   const [casos, setcasos] = useState([])
   const [isloading, setisloading] = useState(false)

   const handleRateValue = (param = 0) => {
    setRate_value(param)
  }



   const handleRateChange = (event) => {
    setRate_message(event.target.value)
  }


  const handleStatusChange = async (calificacion, comentario) => {
      setisloading(true)
    
    if(currentStatus === 'OPEN' || currentStatus === "PROCESS" && (rate_value !== 0 && rate_value !== undefined
    && rate_message.trim() !== '' && rate_message.trim() !== null) ){
      const body = {
        status: currentStatus === 'OPEN' || currentStatus === "PROCESS" ? 'CLOSE' : 'OPEN',
        user_rate: currentStatus === 'OPEN' || currentStatus === "PROCESS" ? calificacion : null,
        user_rate_comment: currentStatus === 'OPEN' || currentStatus === "PROCESS" ? comentario : null
      }
        let casos = []
        try{
          await update_ticket(ticket, body)
          
          casos = await get_supports()
          dialog.close(casos)
          
        }
        catch(error){
          
          dialog.close(casos)
        }
        finally{
          setisloading(false)
        }      
      
    }
    else if(currentStatus === 'CLOSE'){
        const body = {
          status: currentStatus === 'OPEN' || currentStatus === "PROCESS" ? 'CLOSE' : 'OPEN'
        }
        let actualiza = []
        let casos = []
          try{
           actualiza =  await update_ticket(ticket, body)           
            
            casos = await get_supports()
            dialog.close(casos)
          }
          catch(error){
            alertas("Este ticket ya no se puede volver a abrir, si necesita soporte cree uno nuevo", false);
            dialog.close(casos)          
                   
          }
          finally{
            setisloading(false)
          }
    }
    else {
      setisloading(false)
        alert("Tu caso no puede ser cerrado sin calificar y comentar")
        dialog.close([]) 
    }
    setisloading(false)
       
  }

useEffect(() => {
 console.log('currentStatus', currentStatus)
}, [])



   const update_ticket = async (id="", body) => {
    let response = []
    let casos = []
    try {
          response = await updateSupport(id,body);
          console.log('response',response)
          if(response.status === 200 || response.status === 201){
            alertas("EL ESTADO DE SU TICKET FUE ACTUALIZADO", true);
            casos = await get_supports()            
            dialog.close(casos)
          }   
          else if(response.status === 409){
            alertas("ESTE TICKET YA NO PUEDE SER ACTUALIZADO", false);
          }     
      } 
   catch (err) {
      if(currentStatus==="OPEN"||currentStatus==="PROCESS"){
          if(err.response.status === 409){
            alertas("Error al cerrar el caso, no es el propietario del ticket o no cuenta con los permisos para realizar la acción", false);
          }else{
            alertas("Error al cerrar el caso, vuelva a intentarlo más tarde", false);
          }
        
      }else{
        alertas("Error al actualizar, verifique las veces que se ha reabierto", false);
      }
      dialog.close(casos);
    
      }  
  }
  
   
  return (
    <div className='confirmar-ticket-container'>
        {
        currentStatus  === 'OPEN' || currentStatus === "PROCESS"
        ? "Cerrando ticket..." : 
        "¿Está seguro que desea abrir nuevamente su ticket?"
        }
      
      <Backdrop className="backdrop" style={{ width:"100%", position: "absolute", backgroundColor: "white", zIndex: 10010 }} sx={{ color: '#fff' }}
          open={isloading}          
        >
             <CircularProgress  style={{display: "block", fontSize: "large"}} color="primary" />
              <Typography component="legend"> Cargando.. </Typography>              
        </Backdrop>

      <div className='rate-container' 
          style={{display: currentStatus === 'OPEN' || currentStatus === "PROCESS"
           ? 'flex' : 'none'}}>
          <Typography 
            component="legend"> 
              ¿Qu&eacute; le pareci&oacute; la atenci&oacute;n recibida?
         </Typography>
      
         <Rating  
            max={5} 
            style={{fontSize: "2.5rem", width: "max-content"}}
            onChange={(e) => handleRateValue(parseInt(e.target.value))}
            />
     
          <TextField
              multiline 
              minRows={2}
              maxRows={5}
              className='opinion'
              variant="outlined"
              label="Déjanos un comentario" 
              value={rate_message}
              placeholder="Déjanos un comentario" 
              inputProps={{ maxLength: 500 }}
              InputProps={{
                style:{minWidth: "400px", width: "max-content"}
              }}
              error={ rate_message.length > 500 }
              onChange={ (event)=> handleRateChange(event) }
           />

          </div>
    
      <Button
        variant="contained"
        style={{backgroundColor:"green", color: "white", fontWeight: "bold", margin: "10px"}}
        onClick={ () => handleStatusChange(  rate_value, rate_message )          }
      >
        {currentStatus === 'OPEN' || currentStatus === "PROCESS"? 'CALIFICAR' : 'ACEPTAR'}
      </Button>
</div>
  )
}
