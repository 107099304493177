//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import { CustomDialog, Confirm} from "react-st-modal"
import {withScriptjs,withGoogleMap,GoogleMap,Marker,} from "react-google-maps";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../../main_components/alerts/alertResponse";
import TableContainer from "../../../main_components/Table/TableContainer";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import { SelectColumnFilter } from "../../../components_cfdi/filters";
import { delete_pos_sector, get_pos_sector } from "../../../services/businesspartners/businesspartners";
//ESTILOS
import "../../../components_cfdi/estilo.css";
import '../../componentSectors/bonuses.css';
import "../../../components_cfdi/modal.css";
import "./pointsalesector.css";
//IMAGENES/ICONOS
import Delete from "../../../imgComponents/borrar.png";
import CREATE from "../../../imgComponents/create.png";
import MARKER from "../../../imgComponents/marker5.png";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import ADD_POINT_SALE_SECTOR from "./tools/add_point_sale";
import { UPLOAD_FILE_CSV } from "../../../main_components/Table/upload/upload";
import upload from "../../../imgComponents/upload.png";
import dataaux from "./tools/data.json";

const POINTS_OF_SALE_SECTOR = () => {
  
  const notificationSystemRef = useRef();
  const [datap, setData] = useState([]);
  const [open, setopen]=useState(false);
  var entrada=0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);

  useEffect(()=>{
    handleSubmit();
  },[]);

function alertas(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}

function sortJSON(data, key, orden) {
    return data.sort(function (a, b) {
        var x = a[key],
        y = b[key];
        if (orden === 'asc') {
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        }
        if (orden === 'desc') {
            return ((x > y) ? -1 : ((x < y) ? 1 : 0));
        }
    });
}

const handleSubmit = async () => {
    
  await refresh_token ();
    
  if(entrada===0){
    setmenssage("Consultando Puntos de Venta del Sector...")
    setmodalGeneral(true);
    setmodalT(true);
    entrada=entrada+1;
  }else{
    setmodalT(true);
  }
  setData([]);
  setopen(true);

  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_pos_sector();
          const data = d?.data?.points_of_sale;
          var dataorden = sortJSON(data,'orderSector','asc');      
          setData(d === null ? [] : dataorden);   
          setopen(false);
          setmodalGeneral(false);
          setmodalT(false);  
        }catch(err){
          setData([]);
          setopen(false)
          setmodalGeneral(false);
          setmodalT(false);
        }        
      }, 1000);
    }else{}
  } catch (err) {
    console.log(err);
  }
};

const MapWithAMarker = withScriptjs(withGoogleMap(props =>
   
  <GoogleMap
    defaultZoom={12}
    defaultCenter={{ lat: datap[0]?.lat, lng: datap[0]?.lng}}
  >   
{
  datap.map((item)=>(
    <Marker
      position={{lat: item.lat, lng: item.lng}}
      title={item.code+"-"+item.name}
        icon={{
          url: MARKER,
          fillOpacity: 1,
          rotation: 0,
          strokeWeight: 1,
          strokeColor: "#ffffff",
          scale: 2,   
          animation: window.google.maps.Animation.DROP,
          draggable: true
         }}
     />
  )) 
}
</GoogleMap>
));


const columns = useMemo(
  () => [
    {
      Header: ()=>(
      <div>
          <img
              id="crearsector"
              src={CREATE}
              onClick={async () => {
                const result = await CustomDialog(               
               <div>
                  <ADD_POINT_SALE_SECTOR handleSubmit={handleSubmit} alertas={alertas} setmodalT={setmodalT} id={sessionStorage.getItem("id_sector")} idRuta={sessionStorage.getItem("id_ruta")}/>
               </div>,
             {
               className: "modalpof",
               title: "Agregar punto de venta",
               showCloseIcon: true,
               isCanClose:false,
             }
         );
       }}
              title="Agregar punto de venta"
              alt=""
            ></img>
        </div>
      ),
      
      id: "Acciones",

      Cell: ({ row }) => {
        
    const Deletepointsalesector = async () => { 
     setmodalT(true);
     await refresh_token ();
     setData([]);
     setopen(true);
        try {
          var rf_token = await refresh_token();
          if(rf_token===true){
            setTimeout(async() => {
              const dt = await delete_pos_sector(row.original.idRelation);
              console.log(dt);
              alertas("Punto de Venta Eliminado", true);
              handleSubmit();          
            }, 1000);
          }else{}
        } catch (err) {
            console.log("error", err);
            setopen(false);
            setmodalT(false);
            alertas("Error, no se pudo eliminar el punto de venta", false);
            handleSubmit();
        }
      }
      
        return (
          <div>
            <img
              src={Delete}
              onClick={async() =>{
                  const result = await Confirm('¿Esta usted seguro de eliminar el punto de venta relaciado al sector?','Eliminar Sector','Si','No');
                  if(result){
                    Deletepointsalesector()
                    }
                  }            
                }
              className=""
              
              title="Eliminar Venta"
              alt=""
            ></img>   
          </div>
        );
      },
    },
    {
      Header:"Orden Sector",
      accessor:"orderSector"
    },
    {
      Header: "Código",
      accessor: "code",
    },
    {
      Header: "Nombre",
      accessor: "name",
    },
    {
      Header: "Latitud",
      accessor: "lat",
    },
    {
      Header: "Longitud",
      accessor: "lng",
    },
    {
      Filter: SelectColumnFilter,
      Header: "Ruta",
      accessor: "route",
    },
    ],
    []
);
  return (
    <>

<div className="containeritems py-2">

<NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>

<MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
  ></MODAL_TABLE>
      <BREADCRUMBS niveles={
            [
              {label: "CONSULTAR SECTORES", path:"/consult_sectors"},
              {label: `PUNTOS DE VENTA DEL SECTOR: ${sessionStorage.getItem("sectorname")}`, path: null}, 
            ]
          }
          new_version={true}
          modul="CONSULTAR PUNTOS DE VENTA RELACIONADOS AL SECTOR V2"
          path={"/consult_point_of_sale_sectior_v2"}
      ></BREADCRUMBS>
      <br/><br/><br/>

</div>

      <div className="TablePointSaleSector">
          <div className="Tablepossec">
            <div style={{ width: "98%", height: "100%", paddingLeft: "20px" }}>
          <>  
          <StickyTable>
              <img
                    src={upload}
                    className="icono-upload"
                    alt=""
                    title="importar csv"
                    onClick={async () =>{
                      await CustomDialog(
                        <UPLOAD_FILE_CSV modulo="sectores" id={sessionStorage.getItem("id_sector")} handleSubmit={handleSubmit} alertas={alertas} setmodalT={setmodalT}></UPLOAD_FILE_CSV>,
                      {
                        className: "modalmini",
                        title: "Subir CSV",
                        showCloseIcon: true,
                        isCanClose: false,
                      }
                      )
                    }}
                  >
              </img>
            <TableContainer
              paginado={"Puntos de Venta"}
              consultas={"Puntos de Venta Consultados"}
              nametable={"Puntos de Venta del Sector "+sessionStorage.getItem("sectorname")}
              exportar={true}
              columns={columns}
              data={datap}
              style={{ overflowx: 10 }}
              Gmodal={open}
            />
          </StickyTable>
        <div>
        <br/> <br/>
        <MapWithAMarker                                             
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBOh51zw12N4kb7yDY4pp-6gM7xVaFterc&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `400px`}} />}
          mapElement={<div style={{ height: `100%` }} />}
         />
        </div>
          </>          
          </div>
        </div>
      </div>
    </>
  );
}

export default POINTS_OF_SALE_SECTOR;
