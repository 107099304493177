//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import NotificationSystem from 'react-notification-system';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//COMPONENTES

import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { get_sales_deliveries } from "../../services/reports/reports";
import { SEARCH_FILTER_WEB_MOVIL } from "../../main_components/methods_v2/search_filter_web_movil";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';

import dayjs from 'dayjs';
import 'dayjs/locale/es-mx';
import {Box, Button} from '@mui/material';
import { formattedCurrentDate } from "../../main_components/date/day";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import AlertResponse from "../../main_components/alerts/alertResponse";
import "../../components_cfdi/estilo.css";
import '../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";

export const SALES_DELIVERIES = () => {

    
  const notificationSystemRef = useRef();
  const [open, setopen] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [total_rows, settotal_rows] = useState("");
  const [date, setdate] = useState(dayjs(formattedCurrentDate));
  
  useEffect(() => {
    handlesubmit();
  }, [])
  

  const handlesubmit = async() =>{
    try{
        setmodalT(true);
        setIsLoading(true);
        var refresh = await refresh_token();
        if(refresh){
            setTimeout(async() => {
                try{
                    const d = await get_sales_deliveries(date.format('YYYY-MM-DD'));
                    console.log(d);  
                    setIsLoading(false);
                    setmodalT(false);
                    setData(d?.data);  
                }catch(err){
                    console.log(err);
                    setmodalT(false);
                    setIsLoading(false);
                    alertas("La consulta no se pudo realizar; es posible que no existan registros en la fecha especificada.", false);
                }
            }, 1000);
        }
    }catch(err){
        console.log(err);
    }
  }


  const columns = useMemo(
    () => [
      {
        id: "route",
        header: "Ruta",
        accessorKey: "route",
        muiTableBodyCellProps: {
          align: 'center',
        }
      },
      {
        id: "prevent_sales",
        header: "Ventas Preventa",
        accessorKey: "prevent_sales",
        muiTableBodyCellProps: {
          align: 'center',
        }
      },
      {
        id: "prevent_not_sales",
        header: "No Ventas Preventa",
        accessorKey: "prevent_not_sales",
        muiTableBodyCellProps: {
          align: 'center',
        }
      },
      {
        id: "distribution_sales",
        header: "Ventas Reparto",
        accessorKey: "distribution_sales",
        muiTableBodyCellProps: {
          align: 'center',
        }
      },
      {
        id: "amount_sales",
        header: "Total de Entregas",
        accessorKey: "amount_sales",
        muiTableBodyCellProps: {
          align: 'center',
        }
      }
      
    ], []);
    const handleStateChange = (newState) => {
        const filteredRowsCount = newState.filteredRowModel
        settotal_rows(filteredRowsCount);
      };

    
  const filters_custom = (
    <div className="custom-year-search">
      <LocalizationProvider dateAdapter={AdapterDayjs} locale="es-mx">
        <DatePicker
          sx={{ width: 150, m: 1.5}}
          label="Fecha"
          value={date}
          onChange={(newValue) => setdate(newValue)}
          locale="es"
          format="DD/MM/YYYY"
        />
      </LocalizationProvider>  
      <FormControl required sx={{ width: "auto" }}>
        <Button variant="contained"
          sx={{ height: '35px' }}
          onClick={(e) => handlesubmit()}
        >
          CONSULTAR
        </Button>
      </FormControl> 
    </div>
  );

  
const [minimenu, setminimenu]=useState(false);
const [openmini, setopenmini]=useState(false);

 useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 480) {
        console.log('Screen width is less than 480px');
        setminimenu(true);
      } else {
        setminimenu(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  
  function alertas(msj, status) {

    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }
  
  return (
    <div className="new_table_v2" style={{"margin":"0 auto", width: "100%"}}>
        <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
        <BREADCRUMBS niveles={
        [
          { label: "COMPARACIÓN HORARIOS", path: null },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>
          <MODAL_TABLE
            open={true}
            message={menssage}
            modalGeneral={modalGeneral}
            modalT={modalT}
          ></MODAL_TABLE>
          <SEARCH_FILTER_WEB_MOVIL
            minimenu={minimenu} 
            setminimenu={setminimenu}
            openmini={openmini} 
            setopenmini={setopenmini}
            filterMovComponent={filters_custom}
            renderTopToolbarCustomActionsFilters={true}
        ></SEARCH_FILTER_WEB_MOVIL> 
          <>
            <MaterialReactTable
              columns={columns}
              data={data}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyHeader
              onStateChange={handleStateChange}
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              enableRowSelection
              enableRowActions
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              enableColumnDragging={false}
              initialState={{ showGlobalFilter: true, showColumnFilters: true }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  enableHiding: true,
                },
                'mrt-row-actions': {
                  enableHiding: true,
                  size: 80
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
              renderTopToolbarCustomActions={({ }) => {
                if(minimenu===false){
                  return(
                  <>
                    {filters_custom}
                  </>
                  )
                }
              }}
              renderRowActionMenuItems={({ row, closeMenu }) => [
                <RENDER_ROW_ACTION_MENU_ITEMS
                  closeMenu={closeMenu}
                  setmodalT={setmodalT}
                  setmenssage={setmenssage}
                  setmodalGeneral={setmodalGeneral}
                  row={row}
                  handleSubmit={handlesubmit}
                  refresh_token={refresh_token}
                  alertas={alertas}
                />
              ]}
            //   renderToolbarInternalActions={({ table, index }) => (
            //     <RENDER_TOOLBAR_INTERNAL_ACTIONS
            //       table={table}
            //       file_name={"Cuentas Contables"}
            //       update_identifiers={update_identifiers}
            //       query_filters={query_filters}
            //       query_filters_dt={query_filters_dt}
            //       handle_get_consult={handleSubmit}
            //       key={index}
            //       settotal_rows={settotal_rows}
            //       handle_success_filter={handle_success_filter}
            //       share_filter={true}
            //       total_rows={total_rows !== "" ? total_rows : 0}

            //     />
            //   )
            //   }
            />

          </>

    </div>
  )
}
