import AddCircleIcon from '@mui/icons-material/AddCircle';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import { useEffect, useRef, useState } from 'react';
import recordgif from "../../imgComponents/audio.gif";
import MicOffIcon from '@mui/icons-material/MicOff';
import SendIcon from '@mui/icons-material/Send';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { put_message_multimedia, put_new_message } from '../../services/chat/chat';
import { refresh_token } from '../../main_components/tokens/tokenrefresh';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { ReactMic } from 'react-mic';

import iconopdf from "../../imgComponents/pdf dowload.png";
import iconogs from "../../imgComponents/export-gs.png";
import iconfile from "../../imgComponents/archivo.png";

export const INPUTS_CHAT = (props) => {

  const [dataaudio, setdataaudio]=useState(null);
  const[record, setrecord]=useState(false);
  const audioRef = useRef(null);
  const[add, setadd]=useState(false);
  const[send, setsend]=useState(false);
  const[imageSrc, setImageSrc]=useState(null);
  const[fileSrc, setFileSrc]=useState(null)
  const[text, settext]=useState("");

  const[sendimg, setsendimg]=useState(false);
  const[sendfile, setsendfile]=useState(false);

  const[datafile, setdatafile]=useState({});

  useEffect(() => {
    if (dataaudio && dataaudio.blob) {
      const audioBlob = dataaudio.blob;
      const audioUrl = URL.createObjectURL(audioBlob);
      audioRef.current.src = audioUrl;
      
    }
}, [dataaudio])


const handletext = (e) =>{
    if(e.target.value === "" || e.target.value?.trim() === "" || text==="" || text?.trim() === ""){
        setsend(false);
        settext(e.target.value);
    }else{
        settext(e.target.value);
        setsend(true);
    }
}

const handlenewmessagefile = async()=>{

    const fechaActual = new Date();
    const year = fechaActual.getFullYear();
    const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
    const day = String(fechaActual.getDate()).padStart(2, '0');
    const hours = String(fechaActual.getHours()).padStart(2, '0');
    const minutes = String(fechaActual.getMinutes()).padStart(2, '0');
    const seconds = String(fechaActual.getSeconds()).padStart(2, '0');

    const fechaFormateada = `${year}-${month}-${day}`; 
    const horaFormateada = `${hours}:${minutes}:${seconds}`
    var data = {}
    var id ="";

      data ={
        fecha: fechaFormateada,
        origen : props?.data?.origen,
        usuario_origen: props?.data?.usuario_origen,
        destino: props?.data?.destino,
        usuario_destino: props?.data?.usuario_destino,
        hora: horaFormateada,
        tipo: datafile?.tipo,
        ext: datafile?.ext,
        name_archivo: datafile?.name_archivo,
        base64: datafile?.base64
      }
      id = props?.data?.id;
       try{
         var rf_token = await refresh_token();
         if(rf_token===true){
           setTimeout(async() => {
             try{
               const d = await put_message_multimedia(id,data);
               props?.data?.send_message_socket("");
               props?.data?.get_hanlemessage();
               props?.data?.get_user_chat(id);
               localStorage.setItem("date_chat_mini", fechaFormateada);
               
               setImageSrc("");
               setsendimg(false);
               setFileSrc("");
               setsendfile(false);
               setdatafile({});
             }catch(err){
               console.log(err);
             }
           }, 1000);
         }else{}
    
      }catch(err){ 
        console.log(err);
      }

}

const handlenewmessage = async()=>{
  
    const fechaActual = new Date();
    const year = fechaActual.getFullYear();
    const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
    const day = String(fechaActual.getDate()).padStart(2, '0');
    const hours = String(fechaActual.getHours()).padStart(2, '0');
    const minutes = String(fechaActual.getMinutes()).padStart(2, '0');
    const seconds = String(fechaActual.getSeconds()).padStart(2, '0');
  
    const fechaFormateada = `${year}-${month}-${day}`; 
    const horaFormateada = `${hours}:${minutes}:${seconds}`
  
    var data = {}
    var id ="";
  
      data ={
        fecha: fechaFormateada,
        origen : props?.data?.origen,
        usuario_origen: props?.data?.usuario_origen,
        destino: props?.data?.destino,
        usuario_destino: props?.data?.usuario_destino,
        hora: horaFormateada,
        message: text,
        tipo: "text"
      }
      id = props?.data?.id;
  
    if (text.trim() !== "") {
      settext("");
      setsend(false);
      try{
        var rf_token = await refresh_token();
        if(rf_token===true){
          setTimeout(async() => {
            try{
              const d = await put_new_message(data, id);
              props?.data?.send_message_socket(data.message);
              props?.data?.get_hanlemessage();
              props?.data?.get_user_chat(id);
              localStorage.setItem("date_chat_mini", fechaFormateada);
            }catch(err){
              console.log(err);
            }
          }, 1000);
        }else{}
    
      }catch(err){ 
        console.log(err);
      }
    }else{
      
    }
  }

  const sendmessage = () =>{
    if(sendimg===true || sendfile===true){
        handlenewmessagefile()
    }else if(dataaudio===null){
      handlenewmessage();
    }else{
      handlenewmessageaud();
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (!e.shiftKey) {  
        e.preventDefault();
        sendmessage();
      }
    }
  }

  const fileInputRef = useRef(null);
  const fileInputRef2 = useRef(null);
  
  const handleDivClick = () => {
    fileInputRef.current.click();
  }; 
  const handleDivClick2 = () =>{
    fileInputRef2.current.click();
  }

  const handlefileupload = (e) => {
    const selectedFile = e.target.files[0];
    if(selectedFile===undefined){
     setdatafile({});
    }else{
    const d = selectedFile?.name.split(".");
    if (selectedFile) {
        const reader = new FileReader();
        // props.setfilesrc(d[0]);
        // props.setImageSrc("");
        // props.setaudiosrc("");
        // props.settiposend("archivo");  
        setadd(false);
        setsendimg(false);
        setsendfile(true);
        setImageSrc("");
        setFileSrc(d[0]);

        reader.onload = (event) => {
            const base64 = event.target.result;
            setdatafile({
              "tipo":"archivo",
              "ext": d[1],
              "base64": base64,
              "name_archivo": d[0]
            })
        };
        reader.readAsDataURL(selectedFile);
    } 
   }
  };
  
const handleImageUpload = (e) => {
    const selectedFile = e.target.files[0];
    if(selectedFile===undefined){
     props.setdataimg({});
    }else{
    const d = selectedFile?.name.split(".");
    
    if (selectedFile) {
        const reader = new FileReader();
        const imageUrl = URL.createObjectURL(selectedFile);
        setadd(false);
        setsendimg(true);
        setsendfile(false);
        setImageSrc(imageUrl);
        setFileSrc("");
        // props.settiposend("img");  
        // props.setfilesrc("");
        // props.setaudiosrc("");
  
        reader.onload = (event) => {
            const base64Image = event.target.result;
            setdatafile({
              "tipo":"image",
              "ext": d[1],
              "base64": base64Image,
              "name_archivo": d[0]
            })
        };
        reader.readAsDataURL(selectedFile);
    } 
   }
};
const handlenewmessageaud = async () => {


  const fechaActual = new Date();
  const year = fechaActual.getFullYear();
  const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
  const day = String(fechaActual.getDate()).padStart(2, '0');
  const hours = String(fechaActual.getHours()).padStart(2, '0');
  const minutes = String(fechaActual.getMinutes()).padStart(2, '0');
  const seconds = String(fechaActual.getSeconds()).padStart(2, '0');
  const fechaFormateada = `${year}-${month}-${day}`; 
  const horaFormateada = `${hours}:${minutes}:${seconds}`
  
  var id = "";
  var audib64 = "";
  const blob = dataaudio.blob;
  
  try {
    audib64 = await convertirBlobABase64(blob);
  } catch (error) {
    console.error('Error al convertir Blob a base64:', error);
  }
  var data ={
    fecha: fechaFormateada,
    origen : props?.data?.origen,
    usuario_origen: props?.data?.usuario_origen,
    destino: props?.data?.destino,
    usuario_destino: props?.data?.usuario_destino,
    hora: horaFormateada,
    tipo: "audio",
    ext: "mp3",
    name_archivo: `${fechaFormateada}-${horaFormateada}-audio`,
    base64: audib64
  }
  id = props?.data?.id;

  try{
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          const d = await put_message_multimedia(id,data);
          props?.data?.send_message_socket("");
          props?.data?.get_hanlemessage();
          props?.data?.get_user_chat(id);
          localStorage.setItem("date_chat_mini", fechaFormateada);
          setdataaudio(null);
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}

 }catch(err){ 
   console.log(err);
 }
};

const convertirBlobABase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const result = fileReader.result;
      resolve(result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };

    fileReader.readAsDataURL(blob);
  });
};

const onStop = (recordedBlob) => {
  console.log('recordedBlob is: ', recordedBlob);
  setdataaudio(recordedBlob);
};

  return (
    <>
    {
        add ===true?
        <div className='multimedia-select-chat'>
            <div className='iconos-add-functions'>
              <div className=''>
                <CreateNewFolderIcon onClick={handleDivClick2}/>
                <input
                    type='file'
                    onChange={(e) => handlefileupload(e)}
                    ref={fileInputRef2}
                    style={{ display: 'none' }}
                  />
              </div>
              <div className=''>
                <AddPhotoAlternateIcon 
                    onClick={()=>{handleDivClick();}}>
                </AddPhotoAlternateIcon>
                <input
                    type='file'
                    accept='.jpg, .jpeg, .png' // Acepta archivos JPG y PNG
                    onChange={(e) => handleImageUpload(e)}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                />
              </div>
            </div> 
        </div>:<></>
    }
    {
        imageSrc===null || imageSrc===""?
        <></>:
        <div className='upload-img-mini' 
          onKeyPress={(e)=>handleKeyPress(e)
      }>
            <img src={imageSrc}></img>
            <HighlightOffIcon onClick={
                ()=>{
                    setImageSrc(""); 
                    setsendimg(false);
                    }
                }></HighlightOffIcon>
        </div>
    }
    {
      fileSrc===null || fileSrc===""?<></>:
        <div className='upload-file-mini'>
          {
            datafile?.ext==="pdf"||datafile?.ext==="PDF"?
            <>
              <img src={iconopdf} alt=''></img>
              <p>{datafile?.name_archivo}.{datafile?.ext}</p>
            </>:
            datafile?.ext==="xlsx" || datafile?.ext === "csv"?
            <>
              <img src={iconogs} alt=''></img>
              <p>{datafile?.name_archivo}.{datafile?.ext}</p>
            </>:
            <>
              <img src={iconfile} alt=''></img>
              <p>{datafile?.name_archivo}.{datafile?.ext}</p>
            </>
          }

          <HighlightOffIcon onClick={
              ()=>{
                setFileSrc("");
                setsendfile(false);
              }}>
          </HighlightOffIcon>
        </div>
    }
    <div className="inputs-options">
        <div className="icnos">
            {
              sendimg===true || sendfile===true ?<></> : <AddCircleIcon onClick={()=>setadd(!add)}/>
            }
        </div>
        <div className="input-component">
            {
                record===true?
                <div className='record-voice'>
                    <img src={recordgif} alt=''></img>
                </div>
                :
                dataaudio===null?         
                <textarea 
                    placeholder='Escribe aquí' 
                    value={text}
                    disabled={
                        sendimg===true || sendfile === true ? true:false
                    }
                    onChange={
                        (e)=>handletext(e)
                    } 
                    onKeyPress={
                        (e)=>handleKeyPress(e)
                    }
                ></textarea>:
                <div className='upload-audio-mp3-mini'>
                    <audio ref={audioRef} controls className='reproductor-audio-smit'/>
                      <HighlightOffIcon onClick={
                          ()=>{
                            // setImageSrc("");
                            // settiposend("text");
                            // setfilesrc("");
                            // setaudiosrc("");
                            setdataaudio(null);
                          }}>
                      </HighlightOffIcon>
                </div>
                
            }
            
        </div>
        <div className="sent-chat-voice">
            {
               sendimg===true || sendfile===true ?
                 <SendIcon onClick={()=>sendmessage()}/>:   
               send===true?
                 <SendIcon onClick={()=>sendmessage()}/>:
                dataaudio===null?  
                  record===true?
                    <MicOffIcon onClick={()=>setrecord(false)}/>:
                    <KeyboardVoiceIcon onClick={()=>setrecord(true)}/>:
                 <SendIcon onClick={()=>sendmessage()}/>
                               
            }  
        </div>
        <ReactMic
          record={record}
          className="sound-wave"
          onStop={onStop}
          // onData={(recordedData) => console.log('chunk of real-time data is: ', recordedData)}
          strokeColor="#000000"
          backgroundColor="black"
      />
    </div>    
    </>
  )
}



