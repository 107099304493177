//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//COMPONENTES
import dayjs from 'dayjs';
import 'dayjs/locale/es-mx';
import {Box, Button} from '@mui/material';
import { formattedCurrentDate } from "../../../main_components/date/day";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";

export const DETAILS_SALES_C = (props) => {
    const [open, setopen] = useState(false);
    const [data, setData] = useState(props?.row?.original?.details);
    const [isLoading, setIsLoading] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [total_rows, settotal_rows] = useState("");
    const [date, setdate] = useState(dayjs(formattedCurrentDate));

    useEffect(() => {
      handlejoin();
    }, [])

    const monthMapping = {
      january: "Enero",
      february: "Febrero",
      march: "Marzo",
      april: "Abril",
      may: "Mayo",
      june: "Junio",
      july: "Julio",
      august: "Agosto",
      september: "Septiembre",
      october: "Octubre",
      november: "Noviembre",
      december: "Diciembre"
    };

    const handlejoin = () =>{

      console.log(props?.row?.original)
      const dat = props?.row?.original;

      const d = Object.keys(dat)
        .filter(key => key.startsWith('details_'))
        .map(key => {
          const monthKey = key.replace('details_', '').toLowerCase();
          const monthInSpanish = monthMapping[monthKey];

          console.log(dat[key])
          return {
            month: monthInSpanish,
            sum_pos_january: dat[key].sum_pos_january,
            count_pos_january: dat[key].count_pos_january,
            avg_pos_january: dat[key].avg_pos_january,
            sum_route_january: dat[key].sum_route_january,
            count_route_january: dat[key].count_route_january,
            avg_route_january: dat[key].avg_route_january

          };
        });
      console.log(d);
    }



    const columns = useMemo(
        () => [
          {
            id: "month",
            header: "Mes",
            accessorKey: "month",
            muiTableBodyCellProps: {
              align: 'left',
            },
            Cell: ({ cell }) => {
              const monthInEnglish = cell.getValue().toLowerCase();
              return monthMapping[monthInEnglish] || cell.getValue();
            },
            size: 150
          },
          {
            id: "count_pos",
            header: "Ventas PV",
            accessorKey: "count_pos",
            muiTableBodyCellProps: {
              align: 'center',
            },
            
          },
          {
            id: "sum_pos",
            header: "Total de Ventas PV",
            accessorKey: "sum_pos",
            Cell: ({ cell }) => {
              return(
              <Box
                component="span"
                sx={(theme) => ({
                  backgroundColor: theme.palette.success.dark,
                  borderRadius: '0.25rem',
                  color: '#fff',
                  maxWidth: 'auto',
                  p: '0.25rem',
                })}
              >
                {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                  style: 'currency',
                  currency: 'MXN',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) : cell.getValue()?.toLocaleString?.('es-MX', {
                  style: 'currency',
                  currency: 'MXN',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Box>
            )},
            muiTableBodyCellProps: {
              align: 'right',
            },
          },
          {
            id: "avg_pos",
            header: "Promedio de Ventas PV",
            accessorKey: "avg_pos",
            Cell: ({ cell }) => {
              return(
              <Box
                component="span"
                sx={(theme) => ({
                  backgroundColor: theme.palette.success.dark,
                  borderRadius: '0.25rem',
                  color: '#fff',
                  maxWidth: 'auto',
                  p: '0.25rem',
                })}
              >
                {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                  style: 'currency',
                  currency: 'MXN',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) : cell.getValue()?.toLocaleString?.('es-MX', {
                  style: 'currency',
                  currency: 'MXN',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Box>
            )},
            muiTableBodyCellProps: {
              align: 'right',
            },
          },
          {
            id: "count_route",
            header: "Ventas por Ruta",
            accessorKey: "count_route",
            muiTableBodyCellProps: {
              align: 'center',
            }
          },
          {
            id: "sum_route",
            header: "Total de Ventas por Ruta",
            accessorKey: "sum_route",
            Cell: ({ cell }) => {
              return(
              <Box
                component="span"
                sx={(theme) => ({
                  backgroundColor: theme.palette.success.dark,
                  borderRadius: '0.25rem',
                  color: '#fff',
                  maxWidth: 'auto',
                  p: '0.25rem',
                })}
              >
                {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                  style: 'currency',
                  currency: 'MXN',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) : cell.getValue()?.toLocaleString?.('es-MX', {
                  style: 'currency',
                  currency: 'MXN',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Box>
            )},
            muiTableBodyCellProps: {
              align: 'right',
            },
          },
          {
            id: "avg_route",
            header: "Promedio de Ventas por Ruta",
            accessorKey: "avg_route",
            Cell: ({ cell }) => {
              return(
              <Box
                component="span"
                sx={(theme) => ({
                  backgroundColor: theme.palette.success.dark,
                  borderRadius: '0.25rem',
                  color: '#fff',
                  maxWidth: 'auto',
                  p: '0.25rem',
                })}
              >
                {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                  style: 'currency',
                  currency: 'MXN',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) : cell.getValue()?.toLocaleString?.('es-MX', {
                  style: 'currency',
                  currency: 'MXN',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Box>
            )},
            muiTableBodyCellProps: {
              align: 'right',
            },
          }
          
        ], []);
        const handleStateChange = (newState) => {
            const filteredRowsCount = newState.filteredRowModel
            settotal_rows(filteredRowsCount);
          };

        
    
  const update_identifiers =
  [
    { month: 'string' },
    { count_pos: 'string' },
    { sum_pos: 'string' },
    { avg_pos: 'string' },
    { count_route: 'string' },
    { sum_route: 'string' },
    { avg_route: 'string' }
  ];


    
  
  return (
    <>
    <div className="" style={{marginTop: "20px", position: "relative", width: "98%"}}>
    <BREADCRUMBS 

      niveles={
        [
          { label: "DETALLES", path: null },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>
    </div>
     
    
    <div className="new_table_v2" style={{"width":"96%", margin: "0 auto"}}>

        <MaterialReactTable
              columns={columns}
              data={data}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyHeader
              onStateChange={handleStateChange}
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              enableRowSelection
              // enableRowActions
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              enableColumnDragging={false}
              initialState={{ showGlobalFilter: true, showColumnFilters: true }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  enableHiding: true,
                },
                'mrt-row-actions': {
                  enableHiding: true,
                  size: 80
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }    
              renderToolbarInternalActions={({ table, index }) => (
                <RENDER_TOOLBAR_INTERNAL_ACTIONS
                  table={table}
                  file_name={"Detalles de Ventas en Promedio"}
                  update_identifiers={update_identifiers}
                  key={index}
                  settotal_rows={settotal_rows}
                  total_rows={total_rows !== "" ? total_rows : 0}
                />
              )
              }
            />
    </div>
    </>
  )
}
