//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
//ESTILOS
import "../../components_cfdi/estilo.css";
import '../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import "./weektly_s.css";
import TextField from "@mui/material/TextField";
import { SelectColumnFilter } from "../../components_cfdi/filters";
import { get_semaphore } from "../../services/reports/reports";
import { pagination } from "../../main_components/pagination/pagination";
import TableContainer from "../../main_components/Table/TableContainer";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
 
const ConsultSummaryWeektly = () => {
  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [datasemaforo, setdatasemaforo]=useState([]);
  const [open, setopen]=useState(false);
  const [modalT, setmodalT] = useState(false);
  const [modalGeneral, setmodalGeneral] = useState(false);

function alertas(msj,status){
  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}


useEffect(() => {
  handlesemaforo();
}, [])


const handlesemaforo=async()=>{
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_semaphore();
          const semaphores = (d === null ? [] : d.data.semaphores);
          
          semaphores.map((item)=>{
            if (item.minimum !== 0) {
              item.minimum /= 4;
              item.max /= 4;
            }else{
              item.max /= 4;
            }
          })

          console.log(semaphores);
          setdatasemaforo(semaphores);
        }catch(err){
          console.log(err);
          setdatasemaforo([]);
        }
      }, 1000);
    }else{}
  } catch (err) {
    console.log(err);
  }
}

const [date, setdate]=useState("");
const [weeks, setweeks]=useState(0);

const handleSubmit = async () => {
  
  if(date==="" || weeks===0){
    alertas("Valores Faltantes",false);
    return null;
  }
  
  setmodalT(true);
    setopen(true);
    setData([]);
    try {
      var token_rf = await refresh_token ();
        if(token_rf===true){
          setTimeout(async() => {
            try{
              var d = await pagination(3, "sumaryWeek", date, weeks);
              const ndata = d.flatMap((result) => result?.report || []);
              console.log(ndata);
              numerocolumnas();
              setData(ndata===null?[] : ndata);
              setopen(false);
              setmodalGeneral(false);
              setmodalT(false);
            }catch(err){
              setData([]);
              setopen(false)
              setmodalGeneral(false);
              setmodalT(false);
            }
           
          }, 1000);
        }else{} 
    } catch (err) {
      console.log(err); 
    }
};
const [columnsx, setcolumnsx] = useState([{ Header: "Semanas" }]);

const numerocolumnas = () => {

  const newColumns = Array.from({ length: weeks }, (v, i) => ({
  Header: `Semana ${i + 1}`,
  accessor: (d) => {
    return d[`Semana ${i + 1}`]
      ? Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
        }).format(d[`Semana_${i + 1}`])
      : Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
        }).format(d[`Semana_${i + 1}`]);
  },
  }));

  setcolumnsx([...newColumns.reverse()]);
};


const columns = useMemo(
  () => [
    {
      Header: "Código",
      accessor:"code_pos",
    },
    {
      Header:"Vigente",
      accessor:(d)=>{
        return d.deleted_logical_pos===true?"NO":"SI";
      },
      Filter: SelectColumnFilter,
    },
    {
      Header: "Punto de Venta",
      accessor:"name_pos"
    },
    {
      Header: "Ruta",
      accessor: "name_route",
      Filter: SelectColumnFilter,
    },
    ...columnsx,
    {
      Header: "Total",
      accessor: (d) => {
        return d.total
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total);
      },
    },
    {
      Header: "Semaforo",
      accessor: (d)=>{
        const item = datasemaforo.find((item) => 
        d.total >= (item.minimum===0?item.minimum:item.minimum*weeks) 
        && 
        d.total <= (item.max*weeks)
      );
        return (item ? <div className="semaforo-celda" style={{"backgroundColor": item.color_hex}}/>:"Sin semáforo")
      }
    }
    ],
    [datasemaforo,columnsx]
);


return (
    <>
<div className="TableSummaryAnual">
  <div >         
  <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <MODAL_TABLE
    open={true}
    message={""}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></MODAL_TABLE>
    <>

    <BREADCRUMBS niveles={
      [
        {label:"REPORTE SUMARIA SEMANAL", path: null}, 
      ]
    }
      new_version={true}
      modul="CONSULTAR SUMARIA SEMANAL V2"
      path="/summary_weekly_v2"
    ></BREADCRUMBS>
    <div className="gridweek">

      <div className="week1">
            <input
                  type="text"
                  className="fi" 
                  required
                  style={{"width":"100%"}}
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                placeholder="Fecha"
                onChange={(event) => setdate(event.target.value)}
            ></input>
           
      </div>

      <div className="week2">
        <TextField
            label="Número Semanas"
            value={weeks}
            type={"number"}
            required
            sx={{ m: 1.5, width: "100%" }}
            onChange={(event) =>{setweeks(event.target.value)}} 
            InputLabelProps={{ shrink: true }}
        ></TextField>
      </div>
      <div className="week3">
        <button className="button" id="buttonpackageb" onClick={handleSubmit} type="button">
            Buscar
        </button>
      </div>
    </div>
      <div className="Tableweek">            
        <StickyTable>
            <TableContainer
              paginado={"Ventas"}
              consultas={"Ventas Consultadas"}
              exportar={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              Gmodal={open}
              Grantotal={true}
              GrantotalCabeceras={["Vigente", "Punto de Venta", "Ruta", "Semaforo"]}
              nametable={"Reporte Sumaria Semanal"}
              />
          </StickyTable>
      </div>
      </>           
    </div>
   </div>
    </>
  );
}

export default ConsultSummaryWeektly;
