import React,{useState,useEffect} from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, TextField, MenuItem, InputLabel,Box,styled,IconButton,Typography, } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { ValidationTextField } from '../lable/labe';
import { Props } from './interface/interface';
import CircularProgress from '@mui/material/CircularProgress'; 
import CloseIcon from '@mui/icons-material/Close';
import { SelectChangeEvent } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
// Estilos personalizados (sin cambios)
const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        borderRadius: '8px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    },
}));



const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    backgroundColor: '#0c4683',
    color: 'white',
    padding: '16px 24px',
    fontSize: '1.25rem',
    fontWeight: 500,
}));

const StyledDialogTitleError = styled(DialogTitle)(({ theme }) => ({
    backgroundColor: '#e9a952',
    color: 'white',
    padding: '16px 24px',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    display: 'flex', // Agregamos flexbox
    alignItems: 'center', // Alinear los ítems verticalmente
    '& p': {
        paddingTop:'18px',
        fontWeight: 'bold',
        marginLeft:'26px',
        color:'white',
        position:'absolute'
    },
}));








const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
    borderRadius: '10px', // Bordes redondeados
    width: 'auto', // Ajusta el ancho
    height: '35px', // Ajusta la altura

    '& fieldset': {
        borderColor: '#e0e0e0',
        borderWidth: '1px',
    },
    '&:hover fieldset': {
        borderColor: '#6200ee',
    },
    '&.Mui-focused fieldset': {
        borderColor: '#6200ee',
    },
    },
    '& .MuiInputLabel-root': {
        color: 'black',
        fontWeight: 'bold',
        fontSize: '14px', // Tamaño de la etiqueta
        marginBottom: '4px',
        transform: 'translate(12px, -13px) scale(1)', // Alinear etiqueta encima
    },
    '& .MuiInputBase-input': {
        padding: '15px 14px', // Ajustar el padding interno
        '&::placeholder': {
            color: '#bdbdbd',
            opacity: 1, // Color del placeholder
    },
    },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: '10px', // Bordes redondeados como el TextField
        width: 'auto', // Ajustar el ancho
        height: '30px', // Ajustar la altura
        '& fieldset': {
            borderColor: '#e0e0e0',
            borderWidth: '1px',
        },
        '&:hover fieldset': {
            borderColor: '#6200ee',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#6200ee',
        },
    },
    '& .MuiInputLabel-root': {
        color: 'black',
        fontWeight: 'bold',
        fontSize: '14px',
        marginBottom: '4px',
        transform: 'translate(12px, -12px) scale(1)',
    },
    '& .MuiInputBase-input': {
        padding: '18px 14px',
        '&::placeholder': {
            color: '#bdbdbd',
            opacity: 1,
        },
    },
}));

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
    color: 'black',        // Color negro
    fontWeight: 'bold',    // Fuente en negrita
    fontSize: '18.5px',      // Tamaño de fuente 14px
    top: '-7px',
    left:"-2px"

}));





const StyledButtonCancel = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    padding: '8px 16px',
}));

const StyledButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    padding: '10px 16px',
    border: '1.9px outset rgba(.12, 0, 0, 0.1) !important;'
}));


const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px', // Bordes redondeados como el TextField
      height: '30px', // Ajustar la altura
      '& fieldset': {
        borderColor: '#e0e0e0',
        borderWidth: '1px',
      },
      '&:hover fieldset': {
        borderColor: '#6200ee',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#6200ee',
      },
    },
    '& .MuiInputLabel-root': {
      color: 'black',
      fontWeight: 'bold',
      fontSize: '14px', // Tamaño de la etiqueta
      marginBottom: '4px',
      transform: 'translate(12px, -13px) scale(1)', // Alinear etiqueta encima
    },
    '& .MuiInputBase-input': {
      padding: '13px 14px', // Ajustar el padding interno
      '&::placeholder': {
        color: '#bdbdbd',
        opacity: 1, // Color del placeholder
      },
    },
  }));


const GENERAL_FORM: React.FC<Props> = (props) => {
    const { open, onClose, isNew, title_create, title_update, data_form, FormData,original_data,size,method_delete,message_delete,identifier_data, handleProcessedFormat, onSave, validateField,updatedFormData, } = props;
    const [formData, setFormData] = useState<typeof FormData>(original_data);
    const [loading, setLoading] = useState(false); // Simulación de carga

    const [errors, setErrors] = useState<Record<string, string>>({});


    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent | Dayjs | null,
        accessor?: string
    ) => {
        if (dayjs.isDayjs(e) || e === null) {
          // Caso para el DatePicker
            const name = accessor as string;
            const value = e ? e.format('YYYY-MM-DD') : ''; // Puedes ajustar el formato si lo necesitas
            setFormData(updatedFormData(name, value, formData));
            setErrors((prev) => ({ ...prev, [name]: '' }));
        } else {
          // Caso para los demás eventos
            const { name, value } = e.target;
            setFormData(updatedFormData(name, value, formData));
            setErrors((prev) => ({ ...prev, [name]: '' }));
        }
    };

    const handleSubmit = () => {
        let validationErrors: Record<string, string> = {};
    
        if (formData && typeof formData === 'object') {
            Object.keys(formData).forEach(key => {
                const error = validateField(key, Number(formData[key]));
                if (error) {
                    validationErrors[key] = error;
                }
            });
        }
    
        if (Object.keys(validationErrors).length > 0) {
            console.log(validationErrors);
            setErrors(validationErrors);
        } else {
            const processedData = handleProcessedFormat(formData);

            console.log(processedData);
            console.log(isNew);
            console.log(identifier_data);

            onSave(processedData, isNew, identifier_data);
        }
    };

    const handle_delete = () => {
            onSave({}, 'delete', identifier_data);
    };


    useEffect(() => {
        if (open) {
            setFormData(original_data);
        }
    }, [open, original_data]);

    return (
        <StyledDialog 
        open={open}  
        onClose={onClose}
        fullWidth
        maxWidth={false}
        PaperProps={{
            style: { width: size, margin: '0 auto' }, 
        }}
        >
            
            {method_delete ? ( 
                <StyledDialogTitleError>
                <WarningIcon></WarningIcon> <p>ELIMINAR</p>
                    <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'white',
                        }}
                    ><CloseIcon /></IconButton>
                </StyledDialogTitleError>   
                ) : (
                <StyledDialogTitle>
                    {isNew ? title_create : title_update}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'white',
                        }}
                    ><CloseIcon /></IconButton>
                </StyledDialogTitle>
            
            )}

            

                <DialogContent  sx={{marginTop:"10px"}}>
                {method_delete ? (
                    <Typography >
                        {message_delete}
                    </Typography>
                ) : (
                    <Grid container spacing={1}>
                        {data_form.map((field) => (
                            <Grid item xs={12} sm={field.sm} style={{ paddingTop: "22px" }} key={field.accessor}>
                                {field.type === "date" ? 
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <StyledDatePicker
                                            label={field.label}
                                            value={formData?.[field.accessor]}
                                            onChange={(newValue) => {
                                                // Verificar que newValue sea de tipo Dayjs o null
                                                if (dayjs.isDayjs(newValue) || newValue === null) {
                                                    handleChange(newValue, field.accessor);
                                                }
                                            }}
                                            format="DD/MM/YYYY" 
                                            slotProps={{
                                                textField: {
                                                fullWidth: true,
                                                error: !!errors?.[field.accessor],
                                                helperText: errors?.[field.accessor],
                                                }
                                            }}
                                            />
                                    </LocalizationProvider>
                                    : field.type === "select" ?
                                        <FormControl sx={{ width: '100%' }}>
                                        <StyledInputLabel id="demo-simple-select-required-label">{field.name_select}</StyledInputLabel>
                                        <Select
                                            labelId="demo-simple-select-required-label"
                                            id="demo-simple-select-required"
                                            sx={{ height: '35px' }}
                                            value={formData?.[field.accessor]}
                                            name={field.accessor}
                                            onChange={(e) => handleChange(e)} 
                                            input={<StyledSelect />}
                                            >
                                            {loading && (
                                                <MenuItem disabled>
                                                    <CircularProgress size={20} thickness={4} />
                                                </MenuItem>
                                            )}
                                            {!loading && field.data_select.map((select_option: any, index: number) => (
                                                <MenuItem key={`${select_option.id}-${index}`} value={select_option.value}>
                                                    {select_option.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
            
                                    : field.type === "autocomplete" ?
                                        <div></div> 
                                    :
                                    field.type === "checkbox" ?
                                        <div></div> 
                                    :
                                    <StyledTextField
                                        label={field.label}
                                        required
                                        placeholder={field.label}
                                        fullWidth
                                        key={field.accessor}
                                        variant="outlined"
                                        name={field.accessor}
                                        value={formData?.[field.accessor]}
                                        type={field.type || "text"}
                                        onChange={(e) => handleChange(e)} 
                                        error={!!errors?.[field.accessor]}
                                        helperText={errors?.[field.accessor]}
                                    />
                                }
                            </Grid>
                        ))}
                    </Grid>
                )}

                {method_delete ? ( 
                    <Box display="flex" justifyContent="flex-end" gap={2} mt={1}>
                        <StyledButtonCancel variant="outlined" onClick={onClose}>
                            ABORTAR
                        </StyledButtonCancel>
                        <StyledButton variant="contained" onClick={handle_delete} sx={{ backgroundColor: '#e9a952',fontWeight:'bold' }}>
                            ELIMINAR  
                        </StyledButton>
                    </Box>  
                    ) : (
                    <Box display="flex" justifyContent="flex-end" gap={2} mt={1}>
                        <StyledButtonCancel variant="outlined" onClick={onClose}>
                            CANCELAR
                        </StyledButtonCancel>
                        <StyledButton variant="contained" onClick={handleSubmit} sx={{ backgroundColor: '#1976d2' }}>
                            {isNew ? 'CREAR' : 'ACTUALIZAR'}
                        </StyledButton>
                    </Box>
                )}

                </DialogContent>
        </StyledDialog>

    );
};

export default GENERAL_FORM;
