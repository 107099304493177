//PAQUETERIAS
import { useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import NotificationSystem from "react-notification-system";
import dayjs from 'dayjs';
//COMPONENTES
import { refresh_token } from '../../../main_components/tokens/tokenrefresh';
import { custom_multiple_filter, calculate_total } from '../../../main_components/methods_v2/auxiliary_functions';
import { date_current, previous_date } from "../../../main_components/date/date";
import SEARCH_FILTERS from './tools/shearch_filter';
import MODAL_TABLE from '../../../main_components/modal/modal_Table';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from '../../../main_components/methods_v2/export';
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import RENDER_ROW_ACTION_MENU_ITEMS from './tools/render_row_action_menuItems';
import AlertResponse from '../../../main_components/alerts/alertResponse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import { get_cashclosing } from '../../../services/cashclosing/cashclosing';
import SPECIAL_ACTIONS from './tools/special_actions';
import { SEARCH_FILTER_WEB_MOVIL } from '../../../main_components/methods_v2/search_filter_web_movil';

const CONSULT_CASHCLOSING_V2 = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [modalT, setmodalT] = useState(false);
  const [fi, setfi] = useState(dayjs(previous_date));
  const [ff, setff] = useState(dayjs(date_current));
  const [data, setData] = useState([]);
  const [range_dates, setrange_dates] = useState([]);
  const [usStateList, setusStateList] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [query_filters, setquery_filters] = useState({});
  const [menssage, setmenssage] = useState("");
  const [total_rows, settotal_rows] = useState("");

  const [g_gifts, setg_gifts] = useState(0);
  const [g_payed, setg_payed] = useState(0);
  const [g_invoiced, setg_invoiced] = useState(0);
  const [g_credit, setg_credit] = useState(0);

  const [g_secundary_sales, setg_secundary_sales] = useState(0);
  const [g_not_payed, setg_not_payed] = useState(0);
  const [g_cash_secondary_sales, setg_cash_secondary_sales] = useState(0);
  const [g_subtotal1, setg_subtotal1] = useState(0);
  const [g_total_sales, setg_total_sales] = useState(0);
  const [query_filters_dt, setquery_filters_data] = useState("");

  const notificationSystemRef = useRef();

  const update_identifiers =
    [
      { bonus: "number" },
      { cash: "number" },
      { cash_secondary_sales: "number" },
      { close: "string" },
      { credit: "number" },
      { date_register: "string" },
      { deleted_logical: "string" },
      { enable: "string" },
      { fdate: "string" },
      { gifts: "number" },
      { idate: "string" },
      { invoiced: "number" },
      { not_payed: "number" },
      { payed: "number" },
      { returns: "number" },
      { route: "string" },
      { secundary_sales: "number" },
      { subtotal1: "number" },
      { total_sales: "number" },
      { "route-list": "string" }
    ];

  const update_null_identifiers = (original_obj, identifiers_to_update) => {
    return original_obj.map((item) => {
      for (const identifier in item) {
        if (item.hasOwnProperty(identifier)) {
          const isToUpdate = identifiers_to_update.some((update) => update[identifier] !== undefined);
          if (isToUpdate && item[identifier] === null) {
            item[identifier] = typeof identifiers_to_update.find((update) => update[identifier]) === 'number' ? 0 : '';
          }
        }
      }
      return item;
    });
  };

  const handle_get_cashclosing = async (query_filters_flag, query_filters_data) => {
    setData([]);
    setIsLoading(true);
    try {
      var dt;
      var dta;
      if (query_filters_flag) {
        setfi(dayjs(query_filters_data.di));
        setff(dayjs(query_filters_data.df));
        dt = await get_cashclosing(query_filters_data.di, query_filters_data.df);
        setquery_filters({ di: query_filters_data.di, df: query_filters_data.df });

      } else {
        setquery_filters({ di: fi.format('YYYY-MM-DD'), df: ff.format('YYYY-MM-DD') });
        dt = await get_cashclosing(fi.format('YYYY-MM-DD'), ff.format('YYYY-MM-DD'));
      }
      const dataorder = dt.data.sort((a, b) => a.id < b.id ? 1 : a.id > b.id ? -1 : 0);
      dta = update_null_identifiers(dataorder, update_identifiers);
      const list = Array.from(new Set(dta?.map(obj => obj.route)));
      setusStateList(list);
      console.log(dta);
      setg_invoiced(dta?.map(obj => obj.invoiced).filter(d => d !== null));
      setg_payed(dta?.map(obj => obj.payed).filter(d => d !== null));
      setg_not_payed(dta?.map(obj => obj.not_payed).filter(d => d !== null));
      setg_gifts(dta?.map(obj => obj.gifts).filter(d => d !== null));
      setg_secundary_sales(dta?.map(obj => obj.secundary_sales).filter(d => d !== null));
      setg_cash_secondary_sales(dta?.map(obj => obj.cash_secondary_sales).filter(d => d !== null));
      setg_cash_secondary_sales(dta?.map(obj => obj.cash_secondary_sales).filter(d => d !== null));
      setg_cash_secondary_sales(dta?.map(obj => obj.cash_secondary_sales).filter(d => d !== null));
      setg_subtotal1(dta?.map(obj => obj.subtotal1).filter(d => d !== null));
      setg_total_sales(dta?.map(obj => obj.total_sales).filter(d => d !== null));
      setg_credit(dta?.map(obj => obj.credit).filter(d => d !== null));
      setData(dta);
      const uniqueDates = [...new Set(dta.map((d) => d.date_register.split(' ')[0]))];
      console.log(uniqueDates)
      setrange_dates(uniqueDates)

    } catch (err) {
      console.log("error", err);
      setData([]);
    } finally {
      setIsLoading(false)
    }
  };

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  const handle_success_filter = (a, b, c) => {
    handle_get_cashclosing(a, b);
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={c} view={true}></AlertResponse>,
      level: "success",
      position: "br",
      autoDismiss: 10,
    });
  };


  const columns = useMemo(
    () => [

      {
        id: "close",
        header: "Ventas Cerradas",
        accessorKey: "close",
        accessorFn: (d) => {
          return (d?.close === true ? "SI" : "NO")
        },
        size: 190,
        muiTableBodyCellProps: {
          align: 'center',
        },
        enableColumnDragging: false
      },

      {
        id: "date_register",
        header: "F. Registro",
        accessorKey: "date_register",
        size: 190,
        // filterVariant: 'multi-select',
        // filterSelectOptions: usStateList,
        // filtferFn: custom_multiple_filter,
        // enableColumnFilterModes: false,
        Footer: () => (
          <div>
            <div>Sub Total: </div>
            <div>Gran Total: </div>
          </div>
        ),
        muiTableBodyCellProps: {
          align: 'center',
        },
        enableColumnDragging: false

      },
      {
        id: "route-list",
        header: "Ruta",
        accessorKey: "route-list",
        accessorFn: (d) => { return d.route },
        size: 145,
        filterVariant: 'multi-select',
        enableFacetedValues: true,
        filtferFn: custom_multiple_filter,
        enableColumnFilterModes: false,
        muiTableBodyCellProps: {
          align: 'center',
        },
        enableColumnDragging: false

      },
      {
        id: "idate",
        header: "F. Inicial",
        accessorKey: "idate",
        size: 190,
        muiTableBodyCellProps: {
          align: 'center',
        },
        enableColumnDragging: false
      },
      {
        id: "fdate",
        header: "F. Final",
        accessorKey: "fdate",
        size: 190,
        muiTableBodyCellProps: {
          align: 'center',
        },
        enableColumnDragging: false

      },
      {
        id: "invoiced",
        header: "Facturado",
        accessorKey: "invoiced",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '20ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.invoiced)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_invoiced))}</div>
            </div>
          )
        },
        enableColumnDragging: false
      },
      {
        id: "payed",
        header: "Pagado",
        accessorKey: "payed",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '20ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.payed)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_payed))}</div>
            </div>
          )
        },
        enableColumnDragging: false

      },
      {
        id: "not_payed",
        header: "No pagado",
        accessorKey: "not_payed",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '20ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.not_payed)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_not_payed))}</div>
            </div>
          )
        },
        enableColumnDragging: false

      },
      {
        id: "gifts",
        header: "Obsequios",
        accessorKey: "gifts",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '20ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.gifts)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_gifts))}</div>
            </div>
          )
        },
        enableColumnDragging: false

      },

      {
        id: "credit",
        header: "Crédito",
        accessorKey: "credit",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '20ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.credit)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_credit))}</div>
            </div>
          )
        },
        enableColumnDragging: false

      },

      {
        id: "secundary_sales",
        header: "Venta Secundaria de Crédito",
        accessorKey: "secundary_sales",
        size: 300,
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '20ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.secundary_sales)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_secundary_sales))}</div>
            </div>
          )
        },
        enableColumnDragging: false

      },
      {
        id: "cash_secondary_sales",
        header: "Venta Secundaria de Contado",
        accessorKey: "cash_secondary_sales",
        size: 300,
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '20ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.cash_secondary_sales)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_cash_secondary_sales))}</div>
            </div>
          )
        },
        enableColumnDragging: false

      },

      {
        id: "subtotal1",
        header: "Total Venta Propia",
        size: 250,
        accessorKey: "subtotal1",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '20ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.subtotal1)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_subtotal1))}</div>
            </div>
          )
        },
        enableColumnDragging: false

      },

      {
        id: "total_sales",
        header: "Venta Total",
        accessorKey: "total_sales",
        size: 200,
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '20ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.total_sales)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_total_sales))}</div>
            </div>
          )
        },
        enableColumnDragging: false

      },
    ],
    [g_gifts, g_secundary_sales, g_cash_secondary_sales, data, usStateList, g_total_sales, g_invoiced, g_not_payed, g_payed, g_subtotal1],
  );



  // useEffect(() => {
  //   handle_get_cashclosing();
  // }, []);

  useEffect(() => {
    if (total_rows === "") {
    } else {
      const getCurrentPath = () => {
        const search = window.location.search;
        const pathWithoutQuestionMark = search.startsWith('?') ? search.slice(1) : search;
        return pathWithoutQuestionMark;
      };

      const pathWithoutQuestionMark = getCurrentPath();
      setquery_filters_data(pathWithoutQuestionMark)
    }
  }, [total_rows])

  
  const [minimenu, setminimenu]=useState(false);
  const [openmini, setopenmini]=useState(false);
   useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 480) {
          console.log('Screen width is less than 480px');
          setminimenu(true);
        } else {
          setminimenu(false);
        }
      };
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    const filters_custom = (
      <SEARCH_FILTERS
      fi={fi}
      setfi={setfi}
      ff={ff}
      setff={setff}
      handle_get_sales={handle_get_cashclosing}
      range_dates={range_dates}
      data={data}
    ></SEARCH_FILTERS>
    );
  return (
    <div style={{ width: "100%", margin: "0 auto" }} className='new_table_v2'>
      <BREADCRUMBS niveles={
        [
          { label: "CORTE DE CAJA" },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>
      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      <SEARCH_FILTER_WEB_MOVIL
          minimenu={minimenu} 
          setminimenu={setminimenu}
          openmini={openmini} 
          setopenmini={setopenmini}
          filterMovComponent={filters_custom}
          renderTopToolbarCustomActionsFilters={true}
        ></SEARCH_FILTER_WEB_MOVIL> 
      <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        onStateChange={handleStateChange}
        enableExpandAll={false}
        initialState={{
          showGlobalFilter: true, showColumnFilters: true, enableColumnDragging: false
        }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
        muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important", color: "white" } }}
        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            Header: () => (
              <div>
                <SPECIAL_ACTIONS
                ></SPECIAL_ACTIONS>
              </div>
            ),
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}

        muiExpandButtonProps={({ row }) => ({
          children: row?.original?.returns?.length === undefined ? <span title='SIN DEVOLUCIÓNES'><AssignmentLateIcon></AssignmentLateIcon></span> : row?.original?.returns?.length === 0 ? <span title='SIN DEVOLUCIÓNES'><AssignmentLateIcon></AssignmentLateIcon></span> : <span title='DETALLES DE DOVOLUCIONES'><ExpandMoreIcon></ExpandMoreIcon></span>,
        })}

        muiTableBodyRowDragHandleProps={({ table }) => ({
          onDragEnd: () => {
            const { draggingRow, hoveredRow } = table.getState();
            if (hoveredRow && draggingRow) {
              data.splice(
                hoveredRow.index,
                0,
                data.splice(draggingRow.index, 1)[0],
              );
              setData([...data]);
            }
          },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            settotal_rows(table);

            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }

        renderRowActionMenuItems={({ row, closeMenu }) => [
          <RENDER_ROW_ACTION_MENU_ITEMS
            key={row.original.id}
            closeMenu={closeMenu}
            setmodalT={setmodalT}
            setmenssage={setmenssage}
            setmodalGeneral={setmodalGeneral}
            row={row}
            refresh_token={refresh_token}
            handle_success_filter={handle_success_filter}
          />
        ]}

        renderTopToolbarCustomActions={({ }) => {
          if(minimenu===false){
            return(
              <>{filters_custom}</>
            )
          }

        }}
        renderToolbarInternalActions={({ table, idenx }) => (
          <RENDER_TOOLBAR_INTERNAL_ACTIONS
            key={idenx}
            share_filter={true}
            query_filters={query_filters}
            settotal_rows={settotal_rows}
            handle_success_filter={handle_success_filter}
            total_rows={total_rows !== "" ? total_rows : 0}
            table={table} file_name={"FACTURAS DEL " + fi.format('YYYY-MM-DD') + " AL " + ff.format('YYYY-MM-DD')}
            update_identifiers={update_identifiers}
            query_filters_dt={query_filters_dt}
            handle_get_consult={handle_get_cashclosing}
          />
        )
        }
      />
      {/* </LocalizationProvider> */}
    </div >
  );
};

export default CONSULT_CASHCLOSING_V2;