//PAQUETERIAS
import { useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import NotificationSystem from "react-notification-system";
//COMPONENTES
import { refresh_token } from '../../main_components/tokens/tokenrefresh';
import { formattedCurrentDate, formattedPreviousDate } from "../../main_components/date/day";
import SEARCH_FILTERS from './tools/shearch_filter';
import MODAL_TABLE from '../../main_components/modal/modal_Table';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from '../../main_components/methods_v2/export';
import RETURNS_SALES from './tools/returns_sales';
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import RENDER_ROW_ACTION_MENU_ITEMS from './tools/render_row_action_menuItems';
import AlertResponse from '../../main_components/alerts/alertResponse';
import { reports_pag } from '../../services/reports/reports_pag';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import { calculate_total } from '../../main_components/methods_v2/auxiliary_functions';

import { get_price_load } from '../../services/inventory/inventory';
import { CustomDialog } from "react-st-modal";
import DETAIL_SALE from './detail/detail';
import VIEW from "../../imgComponents/lupa.png"
const PRODUCT_LOAD_RETURNS_V2 = (props) => {
  const [data, setData] = useState(props.data);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [ff, setff] = useState(dayjs(formattedCurrentDate));
  const [fi, setfi] = useState(dayjs(formattedPreviousDate));
  const notificationSystemRef = useRef();
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);

  const [total_rows, settotal_rows] = useState("");
  const [query_filters, setquery_filters] = useState({});


  const update_identifiers =
    [
      { date: "string" },
      { scan: "string" },
      { latitude: "string" },
      { longitude: "string" },
      { note: "number" },
      { discount: "number" },
      { tax: "number" },
      { subtotal: "number" },
      { total: "number" },
      { ticket: "string" },
      { special_folio_number: "string" },
      { uuid: "string" },
      { route: "string" },
      { device_date: "string" },
      { downloadable: "string" },
      { synchronized: "string" },
      { delivered: "string" },
      { deleted_logical: "string" },
      { date_register: "string" },
      { bill: "string" },
      { externdoc: "string" },
      { close: "string" },
      { folio_dms: "string" },
      { no_invoice: "string" },
      { reference1: "string" },
      { reference2: "string" },
      { reference3: "string" },
      { folio_invoice: "string" },
      { name_business_partner: "string" },
      { rfc_business_partner: "string" },
      { name_pos: "string" },
      { code_pos: "string" },
      { generate_debt_pos: "string" },
      { pay_condition_pos: "string" },
      { debt: "number" },
      { payed: "number" },
      { name_person_user: "string" },
      { saletype: "string" },
      { payment_method: "string" },
      { rows: "string" },
      { complete: "string" },
      { paper: "string" },
      { invoice_date: "string" },
      { cancelled: "string" },
      { type_user_of_register: "string" },
      { ticket: "string" },
      { code_pos: "string" },
      { code_partner: "string" },
      { route: "string" },
      { scan: "string" }
    ];


  const columns = useMemo(
    () => [
      {
        header: "Ac.",
        id: "Acciones",
        size: 60,
        Cell: ({ row }) => {
          return (
            <div>
              <img
                src={VIEW}
                alt='DETALLES'
                onClick={async () => {
                  CustomDialog(
                    <DETAIL_SALE
                      sale_retun={"Devolución"}
                      ticket={row.original.ticket}
                      name_pos={row.original.name_pos}
                      subtotal={row.original.subtotal}
                      discount={row.original.discount}
                      tax={row.original.tax}
                      total={row.original.total}
                      debt={row.original.debt}
                      payed={row.original.payed}
                      payment_method={row.original.payment_method}
                      scan={row.original.scan}
                      note={row.original.note}
                      name_business_partner={row.original.name_business_partner}
                      rfc_business_partner={row.original.rfc_business_partner}
                      code_pos={row.original.code_pos}
                      details={row.original.details}
                      test={row.original}
                      lng={row.original.longitude}
                      lat={row.original.latitude}
                      lng_pos={row.original.longitude_pos}
                      lat_pos={row.original.latitude_pos}

                    ></DETAIL_SALE>,
                    {
                      className: "modalTItle",
                      title: "Detalles",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                }}
                className="img-product"
                title="Ver detalles"
              ></img>
            </div>
          );
        },

      },
      {
        header: "Fecha",
        accessorKey: "date",
      },
      {
        header: "Ticket",
        accessorKey: "ticket",
      },
      {
        header: "Tipo",
        accessorKey: "type",
      },

      {
        header: "Cliente",
        accessorKey: "name_business_partner",
      },
      {
        header: "Punto de venta",
        accessorKey: "name_pos",
      },
      {
        header: "Tipo de venta",
        accessorKey: "saletype",
      },
      {
        header: "Total",
        accessorKey: "total",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.total) ? 0 : obj.total)
          const total = data?.map(obj => isNaN(obj.total) ? 0 : obj.total)
          return (
            <div >
              <div>{calculate_total(subtotal).toLocaleString('es-Mx', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}</div>
              <div>{calculate_total(total).toLocaleString('es-Mx', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}</div>
            </div>
          )
        },
      },
      {
        header: "Usuario",
        accessorKey: "name_person_user",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );


  useEffect(() => {
    setData(props.data)
  }, [props.data]);

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };


  return (
    <div style={{ width: "100%", margin: "0 auto" }} className='new_table_v2'>
      <BREADCRUMBS
        niveles={
          [
            { label: "DEVOLUCIONES" },
          ]
        }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
        modul="CONSULTAR_FACTURAS_V1"
        path="/consultaCFDI"
        cards_secundary={true}

      ></BREADCRUMBS>
      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}

      <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        // enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        onStateChange={handleStateChange}
        enableExpandAll={false}
        enableColumnDragging={false}
        initialState={{ showGlobalFilter: true, showColumnFilters: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
        muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}

        muiExpandButtonProps={({ row }) => ({
          children: row?.original?.returns?.length === undefined ? <span title='SIN DEVOLUCIÓNES'><ExpandMoreIcon></ExpandMoreIcon></span> : row?.original?.returns?.length === 0 ? <span title='SIN DEVOLUCIÓNES'><ExpandMoreIcon></ExpandMoreIcon></span> : <span title='DETALLES DE DOVOLUCIONES'><ExpandMoreIcon></ExpandMoreIcon></span>,
        })}

        muiTableBodyRowDragHandleProps={({ table }) => ({
          onDragEnd: () => {
            const { draggingRow, hoveredRow } = table.getState();
            if (hoveredRow && draggingRow) {
              data.splice(
                hoveredRow.index,
                0,
                data.splice(draggingRow.index, 1)[0],
              );
              setData([...data]);
            }
          },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            settotal_rows(table);

            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }

        renderToolbarInternalActions={({ table, idenx }) => (
          <RENDER_TOOLBAR_INTERNAL_ACTIONS
            key={idenx}
            share_filter={true}
            query_filters={query_filters}
            settotal_rows={settotal_rows}
            total_rows={total_rows !== "" ? total_rows : 0}
            table={table} file_name={"FACTURAS DEL " + fi.format('YYYY-MM-DD') + " AL " + ff.format('YYYY-MM-DD')}
            update_identifiers={update_identifiers}
          />
        )
        }
      />
      {/* </LocalizationProvider> */}
    </div >
  );
};

export default PRODUCT_LOAD_RETURNS_V2;