import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../imgComponents/save.png";
import Delete from "../../../imgComponents/borrar.png";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import CREATE_UPDATE from "./create_update";
import { delete_products, recovery_products } from "../../../services/inventory/inventory";
import VER from "../../../imgComponents/reconsultaTable.png";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const handle_delete =async (id) =>{
        props.setmodalT(true);
        try{
             var rf_token = await refresh_token();
             if(rf_token===true){
               setTimeout(async() => {
                 try{
                   const d = await delete_products(id);
                   console.log(d);
                   props.alertas("Producto Eliminado", true);
                   props.setmodalT(false);
                   props.handleSubmit();
                 }catch(err){
                   console.log("error", err);
                   props.setmodalT(false);
                   props.alertas("Error, no se pudo eliminar la cuenta contable", false);
                 }
               }, 1000);
             }else{}
           }catch(err){
            console.log(err);
           }
    }

    
  const handle_recovery = async(id)=>{
    props.setmodalT(true);
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try {
            const d = await recovery_products(id)
            props?.setmodalT(false);
            props?.handleSubmit();
            props?.alertas("Producto recuperado correctamente", true);
          } catch (err) {
            props?.setmodalT(false);
            props?.alertas(JSON.stringify(err?.response?.data),false);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  }
   

    return (
        <div>
            <MenuItem
                key={1}
                onClick={ async() =>{
                    props.closeMenu();
                    const result = await Confirm('¿Esta usted seguro de eliminar el producto?','Eliminar Productos','Si','No');
                    if (result) {
                        if(props?.row.original.enable === false){
                            handle_delete(props?.row.original.id)
                        }
                        else{
                            props?.alertas("El producto debe estar marcado como no disponible", false)
                        }
                    }else {}
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Delete}
                        title="Eliminar Producto"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Producto
            </MenuItem>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <CREATE_UPDATE
                        subtabla={props.subtabla}
                        id={props?.row.original.id}
                        name_line={props?.row.original.id_products_line_fk}
                        code={props?.row.original.code}
                        description={props?.row.original.description}
                        enable={props?.row.original.enable}
                        method={"UPDATE"}
                        name_supplier={props?.row.original.name_supplier}
                        id_supp={props?.row.original.id_supp}
                        product_name={props?.row.original.product_name}
                        barcode={props?.row.original.barcode}
                        type={props?.row.original.type}
                        product_line_data={props?.row.original.product_line_data}
                        only_promo={props?.row.original.only_promo}
                        cost={props?.row.original.cost}
                        pieces={props?.row.original.pieces}
                        clave_prod_serv={props?.row.original.clave_prod_serv}
                        clave_unidad={props?.row.original.clave_unidad}
                        clave_unidad_2={props?.row.original.clave_unidad}
                        weight={props?.row.original.weight}
                        iva0={props?.row.original.iva0}
                        iva16={props?.row.original.iva16}
                        ieps8={props?.row.original.ieps8}
                        minimum_sales_unit={props?.row.original.minimum_sales_unit}
                        minimum_sales_unit2={props?.row.original.minimum_sales_unit_2}
                        unit={props?.row.original.unit}
                        unit_dms={props?.row.original.min_sales_unit_dms}
                        unit_conversion={props?.row.original.unit_conversion}
                        brand={props?.row.original.brand}
                        corrugado={props?.row.original.corrugado}
                        boxed_parts={props?.row.original.boxed_parts}
                        ></CREATE_UPDATE>,
                    {
                        className: "custom-modal-3",
                        title: "EDITAR PRODUCTO",
                        showCloseIcon: true,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Editar Producto"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Producto
            </MenuItem>
            <MenuItem
                key={3}
                style={{display:sessionStorage.getItem("ROLE_INVENTORY_PRODUCTS")==="ROLE_INVENTORY_PRODUCTS"?props?.row.original.deleted_logical===true?"":"none":"none"}}       
                onClick={ async () =>{
                    props.closeMenu();
                    const result = await Confirm(
                        "¿Esta usted seguro de recuperar este producto?",
                        "Recuperar Producto",
                        "Si",
                        "No"
                        );
                        if (result) {
                        handle_recovery(props?.row.original.id)
                        } else {}  
                    }
                }
                sx={{ m: 0 }}
            >   
                <ListItemIcon>
                    <img
                        alt=""
                        src={VER}
                        title="Recuperar Producto"
                        className=""
                    ></img>
                </ListItemIcon>
                Recuperar Producto
            </MenuItem>
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
