import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../imgComponents/save.png";
import Delete from "../../../imgComponents/borrar.png";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { delete_drivers } from "../../../services/humanResources/humanResources";
import Put_driver from "../put_driver";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const DeleteSector =async () =>{
        props.setmodalT(true);
        props.setreconsult(true);
        try{
             var rf_token = await refresh_token();
             if(rf_token===true){
               setTimeout(async() => {
                 try{
                   const d = await delete_drivers(props?.row?.original?.id);
                   console.log(d);
                   props.alertas("Empleado Eliminado", true);
                   props.setmodalT(false);
                   props.setreconsult(false);
                   props.handleSubmit();
                 }catch(err){
                   console.log("error", err);
                   props.setmodalT(false);
                   props.setreconsult(false);
                   props.alertas("Error, no se pudo eliminar la cuenta contable", false);
                   props.handleSubmit();
                 }
               }, 1000);
             }else{}
           }catch(err){
            console.log(err);
           }
    }
   

    return (
        <div>
            <MenuItem
                key={1}
                onClick={ async() =>{
                    props.closeMenu();
                    const result = await Confirm('¿Esta usted seguro de eliminar el empleado ?','Eliminar Empleado','Si','No');
                    if(result){
                        DeleteSector()
                        }
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Delete}
                        title="Eliminar Empleado"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Empleado
            </MenuItem>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <Put_driver
                            License_num={props?.row.original.license_num}
                            Num_reg_id_trib={props?.row.original.num_reg_id_trib}
                            Id={props?.row.original.id}
                            Rfc={props?.row.original.rfc}
                            Type={props?.row.original.type}
                            Transport_parts={props?.row.original.transport_parts}
                            Fiscal_residence={props?.row.original.fiscal_residence}
                            Ruta={props?.row.original.id_route_fk}
                            Driver_name={props?.row.original.driver_name}
                            setmodalT={props?.setmodalT}
                            handleSubmit={props?.handleSubmit}
                            alertas={props?.alertas}
                        ></Put_driver>,
                    {
                        className: "custom-modal-2",
                        title: "EDITAR CONDUCTOR",
                        showCloseIcon: true,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Editar Empleado"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Empleado
            </MenuItem>
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
