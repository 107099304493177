import React from 'react';
import axios from 'axios';

export const TEXTEXTRACT = () => {

    const enviarSolicitud = async () => {
        try {
          const url = 'https://vision.googleapis.com/v1/files:asyncBatchAnnotate';
      
          const requestBody = {
            "requests": [
              {
                "inputConfig": {
                  "gcsSource": {
                    "uri": "CLOUD_STORAGE_FILE_URI"
                  },
                  "mimeType": "application/pdf"
                },
                "features": [
                  {
                    "type": "FEATURE_TYPE"
                  }
                ],
                "outputConfig": {
                  "gcsDestination": {
                    "uri": "CLOUD_STORAGE_BUCKET"
                  },
                  "batchSize": 1
                }
              }
            ]
          };
      
          const response = await axios.post(url, requestBody);
          console.log(response.data);
        } catch (error) {
          console.error('Error al enviar la solicitud:', error);
        }
      };

  return (
    <div>
        <button onClick={()=>enviarSolicitud()}>Agregar Imagen</button>
    </div>
  )
}
