//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

//COMPONENTES
import CREATE_POLICY from "./tools/create_policy";
import SubTable from "./detail/consult_datail_policy";
import UPDATE_POLICY from "./tools/update_policy";
import { get_policy,delete_policy } from "../../services/expenses/expenses";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
//ESTILOS
// import "../../componentsCFDI/estilo.css";
import "../expense.css";
// import "../../componentsCFDI/modal.css";
//IMAGENES/ICONOS
import SAVE from "../../imgComponents/save.png";
import DELETE from "../../imgComponents/borrar.png";
import Spinner from "../../imgComponents/S.gif";
import CREATE from "../../imgComponents/create.png";
import ADD from "../../imgComponents/add.png";
import { date_current, previous_date } from "../../main_components/date/date";
import TableContainer from "../../main_components/Table/TableContainer";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CONSULT_POLICY = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(true);
  const [menssage, setMenssage] = useState("");
  const [ff, setff] = useState(date_current);
  const [fi, setfi] = useState(previous_date);

  const handleSubmit = async (event) => {
    event?.preventDefault();
    
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setOpen(true);
        setMenssage("Consultando pólizas...");
    
        try {
          var dta = await get_policy(fi, ff);
          const data = dta?.data?.sort((a, b) =>
            a.id < b.id ? 1 : a.id > b.id ? -1 : 0
          );
          setData(data);
          setOpen(false);
          setMenssage("");
        } catch (err) {
          console.log("error", err);
          setData([]);
          setOpen(false);
          setMenssage("");
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleReconsulta = (reconsultar) => {
    if (reconsultar === true) {
      handleSubmit();
    } else {
      console.log("no se reconsulto");
    }
  };

  const handle_delete_policy = async (id)=>{
    
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        try {
          await delete_policy(id);
          setOpen(false);
          setMenssage("");
          handleSubmit();
        } catch (err) {
          console.log("error", err);
          setOpen(false);
          setMenssage("");
          console.log();
          alert("Error.," + err.response.data.errorMessage);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  }

  
  
  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {
          return (
            <img
              title="Crear Póliza"
              src={CREATE}
              alt=""
              onClick={async () => {
                const result = await CustomDialog(<CREATE_POLICY></CREATE_POLICY>, {
                  className: "modalTItle",
                  title: "Crear Póliza",
                  showCloseIcon: true,
                  isCanClose: false,
                });
                {
                  handleReconsulta(result);
                }
              }}
            ></img>
          );
        },
        Cell: ({ row }) => {
          function guardardato() {
            sessionStorage.setItem("id_policy_subtable", row.original.id);
          }

          function Denegado() {
            alert("No tienes el rol necesario para ver los detalles del gasto");
          }

          if (
            sessionStorage.getItem("PERMISOVERDETALLESGASTO") ===
            "ROLE_EXPENSE_ADMINISTRATIVOS"
          ) {
            return (
              <a
                title="Consultar impuestos del concepto"
                {...row.getToggleRowExpandedProps()}
              >
                {row?.isExpanded ? (
                  <span title="Consultar detalles de la póliza">🔼</span>
                ) : (
                  <span
                    title="Consultar detalles de la póliza"
                    onClick={guardardato}
                  >
                    🔽
                  </span>
                )}
              </a>
            );
          } else {
            return (
              <span title="Consultar detalles del gasto" onClick={Denegado}>
                🔽
              </span>
            );
          }
        },
      },

      {
        Header: "Acciones",
        id: "Acciones",

        Cell: ({ valor, row }) => {
         
          return (
            <div>
              <img
                title="Inyectar Póliza"
                src={ADD}
                alt=""
                onClick={async () => {
                  const result = await CustomDialog(
                    <UPDATE_POLICY
                      codCocept={row.original.concept}
                      codType={row.original.type}
                      codId={row.original.id}
                      codAction={"INJECTION"}
                      codFecha={row.original.export_date}
                    ></UPDATE_POLICY>,
                    {
                      className: "modalTItle",
                      title: "Editar Póliza",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  {
                    handleReconsulta(result);
                  }
                }}
                className="centerText, taxes"
              ></img>

              <img
                title="Editar Póliza"
                src={SAVE}
                alt=""
                onClick={async () => {
                  if (row.original.modificable === true) {
                    const result = await CustomDialog(
                      <UPDATE_POLICY
                        codCocept={row.original.concept}
                        codType={row.original.type}
                        codId={row.original.id}
                        codAction={"UPDATE"}
                        codFecha={row.original.export_date}
                      ></UPDATE_POLICY>,
                      {
                        className: "modalTItle",
                        title: "Editar Póliza",
                        showCloseIcon: true,
                        isCanClose: false,
                      }
                    );
                    {
                      handleReconsulta(result);
                    }
                  } else {
                    alert("Esta póliza ya no se puede modificar.");
                  }
                }}
                className="centerText, taxes"
              ></img>

              <img
                title="Eliminar Póliza"
                src={DELETE}
                alt=""
                className="centerText, taxes"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar esta póliza?",
                    "Eliminar Póliza",
                    "Si",
                    "No"
                  );
                  if (result) {
                    handle_delete_policy(row.original.id);
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
      },
      {
        Header: "No.",
        accessor: "id",
      },
      {
        Header: "Concepto",
        accessor: "concept",
      },
      {
        Header: "Tipo",
        accessor: "type",
      },
      {
        Header: "Fecha",
        accessor: "export_date",
      },
      {
        Header: "Estado",
        accessor: "status",
      },
      {
        Header: "Orden de descarga",
        accessor: "selection_order",
      },
      {
        Header: "Modificable",
        accessor: (d) => {
          return d.modificable === true ? "✅" : "❌";
        },
      },
    ],
    []
  );

  return (
    <div >
      <MODAL_TABLE open={true} modalT={open}></MODAL_TABLE>
      <BREADCRUMBS niveles={
                [
                  {label: "CONSULTAR POLIZAS", path: null},
                ]
              }
        new_version={true}
        modul="CONSULTAR POLIZAS V2"
        path="/policy_v2" 
      ></BREADCRUMBS>

      <div class="containerExpense">
        <div class="expen-1 color-1">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha inicial"
            onChange={(event) => setfi(event.target.value)}
          ></input>
        </div>

        <div class="expen-2 color-2">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha Final"
            onChange={(event) => setff(event.target.value)}
          ></input>
        </div>

        <div class="expen-3 color-5">
          <Button
            sx={{ width: "100%" }}
            variant="contained"
            onClick={handleSubmit}
          >
            Buscar
          </Button>
        </div>
      </div>

      <div className="TableReport PolicyTable">
        <div style={{ overflowX: "auto" }}>
          <TableContainer
            exportar={true}
            columns={columns}
            nametable={"Tabla Polizas"}
            data={data}
            style={{ overflowx: 10 }}
            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }}>
                  <SubTable row={row} verData={true} />
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CONSULT_POLICY;
