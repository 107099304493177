import { Component } from "react";
import { AuthState } from "@aws-amplify/ui-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Get_bussinesP from "../pages/business_partner/getBusiness_parther";
import CfdiTable from "../pages/consult_invoice/consult_invoces";
import GENERATE_INVOICE from "../components_cfdi/invoice/generate_invoice/generate_invoice";
import BookData from "../nav/Data.json";
import BookData02 from "../nav/Data02.json";
import BookData03 from "../nav/Data03.json";
import BookData04 from "../nav/Data04.json";
import UpdateBusnes from "../pages/business_partner/editBusnes";
import "../form_business_partner/resp.css";
import "../Login/style.css";
import PAGO from "../components_cfdi/invoice/payment/pago";
import SetValidador from "../pages/providers/Validador";
import SetfacturaPPD from "../pages/consult_invoice/consult_invocesPPD";
import SetConsultarFacturasProveedores from "../pages/providers/ConsultarFacturasProvedores";
import SetConsultaTaxes from "../pages/consult_taxes/consult_taxes";
import SetTableBonuses from "../pages/unilever/reportBonification";
import SetEditAddBonuses from "../pages/unilever/editAddBonuses";
import SetConsulConfigureTaxes from "../pages/consult_taxes/consult_configure_tax";
import CONSUL_CONCEPT from "../components_cfdi/invoice/concepts/consult_concept";
import SetReportRoutes from "../pages/routes/reportRoutes";
import NewDashboard from "../pages/newDasboard/dashboard";
import SetSectors from "../pages/business_partner/sectors/setSectors";
import SetDiscounts from "../pages/business_partner/discounts/setDiscounts";
import SetPromotions from "../pages/business_partner/promotions/setPromotions";
import setPointsOfSale from "../pages/business_partner/pointsofsale/setPointsOfSale";
import SetRegisterGeneral from "../pages/business_partner/registerGeneral/setRegisterGeneral";
import SetBanc from "../pages/banco/setBanc";
import Consult_cut_box_sale from "../component_bank/cashclosing/cashclosing/cahsclosing_sale/consult_cashclosing_sale";
import TableRoles from "../componentConfiguration/componentRoles/TableRoles";
import EditCompany from "../componentConfiguration/componentCompany/EditCompany";
import TableAccountingAccount from "../componentaccount/TableAccountingAccount";
import TableUsers from "../componentConfiguration/componentUsers/TableUsers";
import TableExpenseConcept from "../components_Expenses/ComponentExpenseConcept/TableExpenseConcept";
import Consult_drivers from "../componentHumanResources/drivers/consult_drivers";
import PAID from "../component_bank/debts_and_paid/paid";
import DEBTS from "../component_bank/debts_and_paid/debts";
import EXPENSES_FOREING from "../components_Expenses/expenses/foreign/foreign";
import EXPENSES_NON_DEDUCTIBLE from "../components_Expenses/expenses/non_deductible/non_deductible";
import REPORT_EXPENSE from "../components_Expenses/expenses/report/report_expense";
import CONSULT_GROUP_EXPENSE from "../components_Expenses/expenses/grup_expense/consult_group_expense";
import EXPENSES_GROUP_DETAIL from "../components_Expenses/expenses/grup_expense/detail/group_details";
import CONSULT_POLICY from "../components_Expenses/policy/consult_policy";
import Consult_cut_box_sale_report from "../component_bank/cashclosing/cashclosing/report/Consult_cut_box_sale_report";
import TableRouteSalesReport from "../componentsNewDashboard/componentSalefourRoute/TableRouteSalesReport";
import Consult_annual_summary from "../componentsNewDashboard/componentSumaria/Consult_annual_summary";
import SalesReal from "../componentsNewDashboard/componentVentasTReal/SalesReal";
import ConsultEmployees from "../componentHumanResources/componentEmployees/ConsultEmployees";
import Consult_vehicle from "../componentAssets/componentVehicle/consult_vehicle";
import TableSemaphore from "../componentsNewDashboard/componentSemaphore/TableSemaphore";
import { MenuDrawer } from "../main_components/menu/PermanentMenu";
import { CssBaseline } from "@material-ui/core";
import TableFreezer from "../componentAssets/componentFreezer/TableFreezer";
import { Capacitacion } from "../componentCapacitation/Videos";
import PRODUCTS_LINE from "../components_inventory/producst_line/producst_line";
import PRODUCTS from "../components_inventory/products/products";
import MOVEMENTS from "../components_inventory/movements/movements";
import WAREHOUSE_MOVEMENTS from "../components_inventory/movements/tools/warehouse_movement";
import STOCK from "../components_inventory/stock/stock";
import ConsultSummaryWeektly from "../componentsNewDashboard/componentSumariaWeektly/ConsultSummaryWeektly";
import MovementWarehouseCabinets from "../componentAssets/componentFreezer/MovementWarehouseCabinets";
import fondo from "../img-login/fondo-OD2-Act.jpg";
import fc from "../img-login/fondo.png";
import PRICE_LIST from "../components_inventory/price_list/price_list";
import CONSULT_DETAIL_PRICE_LIST from "../components_inventory/price_list/detail/consult_detail_price_list";
import UPDATE_PRICE_LIST from "../components_inventory/price_list/detail/tools/update_list";
import ADD_PRODUCT from "../components_inventory/price_list/detail/tools/add_product";
import DAILY_STAFF from "../componentsNewDashboard/daily_staff/daily_staff";
import ASSIGNED_CONCEPTS from "../components_Expenses/ComponentExpenseConcept/detail/assigned_concepts";
import POINTS_OF_SALE_SECTOR from "../componentSociosNegocios/componentSectors/details/point_of_sale_sector";
import RULES_SE from "../components_Expenses/componentRulesSendingExpenses/rules_se";
import BRANDS from "../componentAssets/componentMarcaF/brands";
import WAREHOUSES from "../componentConfiguration/componentWarehouse/warehouse";
import ROUTES from "../componentConfiguration/componentRutas/routes";
import POINT_OF_SALE_WITHOUT_SECTOR from "../componentSociosNegocios/Componentpointsofsalewithoutsector/point_of_sale_without_sector";
import RECOVERY_PARTNER from "../componentSociosNegocios/componentRecoverPartner/recovery_partner";
import { ADD_SALES_BONIFICACIONES } from "../componentBonificaciones/componentVentasBonificaciones/add_sales_bonificaciones";
import BUSINESS_PARTNER_QR from "../component_businessPartner/business_partner/tools/business_partner_qr";
import SALES_DEBTS from "../component_bank/debts_and_paid/sales_debts";
import SUPPLIER from "../component_businessPartner/business_partner/supplier/supplier";
import { ViewCases } from "../componentsSupports/consultCases/Bucarcasos";
import { DASHBOARD } from "./tools/dashboard";
import COMMISSIONS from "../componentsNewDashboard/daily_staff/commissions/commissions";
import { PROFILE } from "../component_profile/profile";
import PRODUCT_LOAD from "../components_inventory/product_load/product_load";
import { PAYMENT_REPORT } from "../components_cfdi/invoice/payments_report/payment_report";
import { MAP_RELATION_INVOICE } from "../components_cfdi/invoice/map_relation/map_relation";
import WAREHOUSE_MOVEMENTS_LIST from "../components_inventory/movements/tools/warehouse_movement_list";
import { MERCADO_STUDY } from "../study_mercado/mercado";
import CONSULT_DMS from "../component_unilever/DMS/consult_DMS";
import CONSULT_ROUTES from "../component_unilever/Interface/clients";
import PRICE_LIST_REPORT from "../components_inventory/price_list/report/price_list";
import { Hub } from "aws-amplify";
import { Auth } from "aws-amplify";
import { refresh_token_init } from "../main_components/tokens/tokenrefresh_init";
import { ASSETS_SAMSARA } from "../component_samsara/assets_samsara";
import DMS_V2 from "../component_unilever/DMS_V2/DMS_V2";
import { CHAT } from "../chat_support/chat";
import ACCOUNTING_ACCOUNT_V2 from "../componentaccount/Accounting_Account_v2";
import DISCOUNTS_V2 from "../componentSociosNegocios/componentDescuentos/Discounts_v2";
import ELECTRONIC_ACCOUNTING from "../components_sat/Electronic_Accounting";
import SECTORS_V2 from "../componentSociosNegocios/componentSectors/Sectors_v2";
import POINT_OF_SALE_SECTOR_V2 from "../componentSociosNegocios/componentSectors/details/Point_of_Sale_sector_v2";
import PROMOTIONS_V2 from "../componentSociosNegocios/componentpromotions/Promotions_v2";
import RECOVERY_PARTNER_V2 from "../componentSociosNegocios/componentRecoverPartner/recovery_partner_v2";
import POINT_OF_SALE_WITHOUT_SECTOR_V2 from "../componentSociosNegocios/Componentpointsofsalewithoutsector/point_of_sale_without_sector_v2";
import EMPLOYEES_V2 from "../componentHumanResources/componentEmployees/ConsultEmployees_v2";
import POINTS_OF_SALE_V2 from "../componentSociosNegocios/componentOfSale/Point_of_sale_v2";
import BRANDS_V2 from "../componentAssets/componentMarcaF/brands_v2";
import CATALOG_EXPENSE_CONCEPT_V2 from "../components_Expenses/ComponentExpenseConcept/Catalog_Expense_Concept_v2";
import ASSIGNED_CONCEPTS_V2 from "../components_Expenses/ComponentExpenseConcept/detail/assigned_concepts_v2";
import RULES_SE_V2 from "../components_Expenses/componentRulesSendingExpenses/rules_se_v2";
import COMMISSIONS_V2 from "../componentsNewDashboard/daily_staff/commissions/commissions_v2";
import ROUTES_V2 from "../componentConfiguration/componentRutas/routes_v2";
import WAREHOUSES_V2 from "../componentConfiguration/componentWarehouse/warehouse_v2";
import VEHICLES_V2 from "../componentAssets/componentVehicle/consult_vehicle_v2";
import DRIVERS_v2 from "../componentHumanResources/drivers/consult_drivers_v2";
import SUMMARY_ANNUAL_V2 from "../componentsNewDashboard/componentSumaria/Consult_annual_summary_v2";
import SUMMARY_WEEKLY_V2 from "../componentsNewDashboard/componentSumariaWeektly/Summary_weektly_v2";
import DAILY_STAFF_V2 from "../componentsNewDashboard/daily_staff/daily_staff_v2";
import PRODUCTS_V2 from "../components_inventory/products/products _v2";
import DASHBOARD_CLIENTS_V2 from "../componentsNewDashboard/newDashboard_v2";
import { Autorization } from "../componentDrive/Autorization";
import { ViewCases_v2 } from "../componentsSupports/consultCases/Bucarcasos_v2";
import BUSINESS_PARTNERT_TABLE from "../component_businessPartner/business_partner/consult_business_partner_v2";
import Freezer_v2 from "../componentAssets/componentFreezer/TableFreezer_v2";
import MovementWarehouseCabinets_v2 from "../componentAssets/componentFreezer/MovementWarehouseCabinets_v2";
import STOCK_V2 from "../components_inventory/stock/stock_v2";
import EXPENSES_NON_DEDUCTIBLE_V2 from "../components_Expenses/expenses/non_deductible/non_deductible_v2";
import EXPENSES_FOREING_V2 from "../components_Expenses/expenses/foreign/foreign_v2";
import ConsultarFacturasProvedores_v2 from "../components_Expenses/supplier/consultarFacturasProvedores_v2";
import CARTA_PORTE_V2 from "../components_cfdi/invoice/cartaporte/cartaporte_v2";
import INVOICE_V2 from "../components_cfdi/invoice/consult_invoice/consult_invoice2";
import OLD_BALANCES_REPORT from "../component_bank/debts_and_paid/old_balances_report";
import CONSULT_POLICY_V2 from "../components_Expenses/policy/consult_policy_v2";
import REPORT_EXPENSE_V2 from "../components_Expenses/expenses/report/report_expense_v2";
import CONSULT_GROUP_EXPENSE_V2 from "../components_Expenses/expenses/grup_expense/consult_group_expense_v2";
import EXPENSES_GROUP_DETAIL_V2 from "../components_Expenses/expenses/grup_expense/detail/group_details_v2";
import REPORT_SALES from "../componentsNewDashboard/report_sales/report_sales";
import { PAYMENT_REPORT_V2 } from "../components_cfdi/invoice/payments_report/payment_report_v2";
import SUPPLIER_v2 from "../component_businessPartner/business_partner/supplier/supplier_v2";
import TableTaxes_V2 from "../components_cfdi/invoice/componentsTaxes/Consult_Tax_v2";
import TableConfigureTaxes_v2 from "../components_cfdi/invoice/componentsTaxes/Consult_Configure_Tax_v2";
import CONSUL_CONCEPT_V2 from "../components_cfdi/invoice/concepts/consult_concept_v2";
import SalesReal_V2 from "../componentsNewDashboard/componentVentasTReal/SalesReal_v2";
import CONSULT_CASHCLOSING_V2 from "../component_bank/cashclosing/cashclosing/consult_cashclosing _v2";
import CONSULT_CASHCLOSING_SALE_V2 from "../component_bank/cashclosing/cashclosing/cahsclosing_sale/consult_cashclosing_sale_v2";
import CONSULT_CASHCLOSING_SALE_REPORT_V2 from "../component_bank/cashclosing/cashclosing/report/consult_cashclosing_sale_report_v2";
import PRODUCT_LOAD_V2 from "../components_inventory/product_load/product_load_v2";
import TableBonificaciones_V2 from "../componentBonificaciones/TableBonificaciones_v2";
import { ADD_SALES_BONIFICACIONES_V2 } from "../componentBonificaciones/componentVentasBonificaciones/add_sales_bonificaciones_v2";
import PRODUCTS_LINE_V2 from "../components_inventory/producst_line/producst_line_v2";
import Vppd_v2 from "../components_cfdi/invoice/consult_invoice/consult_invoice_PPD_v2";
import PRICE_LIST_v2 from "../components_inventory/price_list/price_list_v2";
import CONSULT_DETAIL_PRICE_LIST_V2 from "../components_inventory/price_list/detail/consult_detail_price_list_v2";
import ADD_PRODUCT_V2 from "../components_inventory/price_list/detail/tools/add_product_v2";
import UPDATE_PRICE_LIST_V2 from "../components_inventory/price_list/detail/tools/update_list_v2";
import MOVEMENTS_v2 from "../components_inventory/movements/movements_v2";
import WAREHOUSE_MOVEMENTS_V2 from "../components_inventory/movements/tools/warehouse_movement_v2";
import DOMY_DATA from "../componentAssets/domy/domy";
import TableEditAddSales_V2 from "../componentBonificaciones/EditVentasBonificaciones/TableEditAddSales_v2";
import { TEXTEXTRACT } from "../textextrac/textextrac";
import { CENSO } from "../censo/censo";
import { DATASYNC } from "../datasync/datasync";
import { createTheme, ThemeProvider, GlobalStyles } from "@mui/material";
import AnchorTemporaryDrawer from "./search_filters";
import {
  MANAGEMENT_ROUTE,
  SupervisionRoute,
} from "../componentConfiguration/componentSupervisorRoute/supervisionRoute";
import { PAYMENT_ACCOUNTS } from "../component_bank/payment_accounts/payment_accounts";
import { ReporteGMaps_v2 } from "../componentsNewDashboard/ComponentRoutes/ReporteGMaps_v2";
import { USER_MANUAL } from "../componentCapacitation/donwload_pdf_manual";
import { REPORT_ROUTE_8 } from "../report_erick/report";
import { REPORT_ROUTE_FULL } from "../report_erick/report_full";
import { REPORT_BONICACIONES } from "../component_unilever/report_bonificaciones/Report_Bonificaciones";
import { PATCH_NOTES_HOME } from "../patch-notes/patch_notes_home";
import { VERSION_DESCRIPTION } from "../patch-notes/versions/1/version_1.0.7";
// import { COMPARATIVE } from "../componentsNewDashboard/componentComparativeReports/comparative";
import { SALES_DELIVERIES } from "../componentsNewDashboard/componentSalesDeliveries/SalesDeliveries";
import { COMPARATIVE } from "../componentsNewDashboard/componentComparativeReports/Comparative";
import PAY_CONDITION from "../component_bank/pay_condition/pay_condition";
import PRODUCT_LOAD_NEW_METHOD from "../components_inventory/product_load/product_new_method";

//Carta Porte
import WAYBILL from "../components_cfdi/invoice/cartaporte/waybill";

// import MODAL_MOVIL from "./modal_movil";

const theme = createTheme({
  palette: {
    mode:
      sessionStorage.getItem("dark_mode") === "dark_mode" ? "dark" : "light",
    primary: {
      main: "#81980f",
    },
    secondary: {
      main: "#00bcd4",
    },
  },
});

export class index extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    // const listener = (data) => {
    //   console.log(data?.payload?.event);
    //   if (data?.payload?.event === "signOut") {
    //     sessionStorage.clear();
    //     window.location = "/";
    //   }

    //   if (data?.payload?.event === "signIn") {
    //     console.log(data?.payload.data.signInUserSession.accessToken.jwtToken);

    //     console.log(data);
    //     sessionStorage.setItem(
    //       "getAccesTokenRefresh",
    //       data?.payload?.data?.signInUserSession?.accessToken?.jwtToken
    //     );
    //     sessionStorage.setItem(
    //       "getIdTokenRefresh",
    //       data?.payload?.data?.signInUserSession?.idToken?.jwtToken
    //     );
    //     setTimeout(async () => {
    //       await refresh_token_init();
    //     }, 300);
    //   }
    // };

    //   console.log(data?.payload?.event);
    //   if(data?.payload?.event==="signOut"){
    //     sessionStorage.clear();
    //     window.location="/";
    //   }

    //   if(data?.payload?.event==="signIn"){
    //     console.log(data?.payload.data.signInUserSession.accessToken.jwtToken)

    //     console.log(data)
    //     sessionStorage.setItem("getAccesTokenRefresh",data?.payload?.data?.signInUserSession?.accessToken?.jwtToken);
    //     sessionStorage.setItem("getIdTokenRefresh",data?.payload?.data?.signInUserSession?.idToken?.jwtToken);
    //     setTimeout(async () => {
    //       await refresh_token_init();
    //     }, 300);
    //   }

    // };

    // Hub.listen("auth", listener);

    sessionStorage.setItem(
      "getAccesTokenRefresh",
      this?.props?.User?.["signInUserSession"]?.["accessToken"]?.["jwtToken"]
    );
    sessionStorage.setItem(
      "getIdTokenRefresh",
      this?.props?.User?.["signInUserSession"]?.["idToken"]?.["jwtToken"]
    );

    sessionStorage.setItem(
      "id_user",
      this?.props?.User?.["attributes"]?.["custom:id_user"]
    );
    sessionStorage.setItem(
      "roles_user",
      this?.props?.User?.["attributes"]?.["custom:roles"]
    );
    sessionStorage.setItem(
      "email_uset",
      this?.props?.User?.["attributes"]?.["email"]
    );
    localStorage.setItem(
      "email_user",
      this?.props?.User?.["attributes"]?.["email"]
    );

    var permisosUsuario = this?.props?.User?.["attributes"]?.["custom:roles"];
    var permisoUsuarioReplace = permisosUsuario?.replace(/ /g, "");
    var permisos = permisoUsuarioReplace?.split(",");
    if (this.props.AuthState === AuthState.SignedIn) {
      const roles = {
        ROLE_INVENTORY: permisos?.includes("ROLE_INVENTORY") ? " " : "none",
        ROLE_EXPENSE_RULES: permisos?.includes("ROLE_EXPENSE_RULES")
          ? " "
          : "none",
        ROLE_BUSINESS_PARTNER_PROMOTIONS: permisos?.includes(
          "ROLE_BUSINESS_PARTNER_PROMOTIONS"
        )
          ? " "
          : "none",
        ROLE_CABINET_DECAPITALIZED: permisos?.includes(
          "ROLE_CABINET_DECAPITALIZED"
        )
          ? " "
          : "none",
        ROLE_EXPENSE_EXTRANJERO: permisos?.includes("ROLE_EXPENSE_EXTRANJERO")
          ? " "
          : "none",
        ROLE_PERFECT_STORE: permisos?.includes("ROLE_PERFECT_STORE")
          ? " "
          : "none",
        ROLE_COMPLEMENTO_PAGO_SHOW_FOLIO: permisos?.includes(
          "ROLE_COMPLEMENTO_PAGO_SHOW_FOLIO"
        )
          ? " "
          : "none",
        ROLE_BUYS_PAYMENT_ACCOUNTS: permisos?.includes(
          "ROLE_BUYS_PAYMENT_ACCOUNTS"
        )
          ? " "
          : "none",
        ROLE_REPORT_NO_SALES: permisos?.includes("ROLE_REPORT_NO_SALES")
          ? " "
          : "none",
        ROLE_EXPENSE_VENDEDOR: permisos?.includes("ROLE_EXPENSE_VENDEDOR")
          ? " "
          : "none",
        ROLE_EXPENSE_DIRECTIVOS: permisos?.includes("ROLE_EXPENSE_DIRECTIVOS")
          ? " "
          : "none",
        ROLE_BUSINESS_PARTNER_BUSINESS_PARTNER: permisos?.includes(
          "ROLE_BUSINESS_PARTNER_BUSINESS_PARTNER"
        )
          ? " "
          : "none",
        ROLE_BUSINESS_PARTNER_POINT_OF_SALE: permisos?.includes(
          "ROLE_BUSINESS_PARTNER_POINT_OF_SALE"
        )
          ? " "
          : "none",
        ROLE_CABINETS: permisos?.includes("ROLE_CABINETS") ? " " : "none",
        ROLE_SALES: permisos?.includes("ROLE_SALES") ? " " : "none",
        ROLE_BUSINESS_PARTNER_SECTOR: permisos?.includes(
          "ROLE_BUSINESS_PARTNER_SECTOR"
        )
          ? " "
          : "none",
        ROLE_BUSINESS_PARTNER_GENERAL_REGISTRATION: permisos?.includes(
          "ROLE_BUSINESS_PARTNER_GENERAL_REGISTRATION"
        )
          ? " "
          : "none",
        ROLE_BUSINESS_PARTNER_RECOVER: permisos?.includes(
          "ROLE_BUSINESS_PARTNER_RECOVER"
        )
          ? " "
          : "none",
        ROLE_BUSINESS_PARTNER_DISCOUNT: permisos?.includes(
          "ROLE_BUSINESS_PARTNER_DISCOUNT"
        )
          ? " "
          : "none",
        ROLE_ASSETS: permisos?.includes("ROLE_ASSETS") ? " " : "none",
        ROLE_BUSINESS_PARTNER: permisos?.includes("ROLE_BUSINESS_PARTNER")
          ? ""
          : "none",
        ROLE_CANCEL_INVOICE: permisos?.includes("ROLE_CANCEL_INVOICE")
          ? "ROLE_CANCEL_INVOICE"
          : "DENEGADO",
        ROLE_REPORT_SALES_MANAGEMENT: permisos?.includes(
          "ROLE_REPORT_SALES_MANAGEMENT"
        )
          ? "ROLE_REPORT_SALES_MANAGEMENT"
          : "DENEGADO",
        ROLE_BANK: permisos?.includes("ROLE_BANK") ? " " : "none",
        ROLE_FINANCE: permisos?.includes("ROLE_FINANCE") ? " " : "none",
        ROLE_BUYS: permisos?.includes("ROLE_BUYS") ? " " : "none",
        ROLE_CONFIGURATION: permisos?.includes("ROLE_CONFIGURATION")
          ? " "
          : "none",
        ROLE_VEHICLES: permisos?.includes("ROLE_VEHICLES") ? " " : "none",
        ROLE_EXPENSE: permisos?.includes("ROLE_EXPENSE") ? " " : "none",
        ROLE_EXPENSE_ADMINISTRATIVOS: permisos?.includes(
          "ROLE_EXPENSE_ADMINISTRATIVOS"
        )
          ? " "
          : "none",
        ROLE_POLICY: permisos?.includes("ROLE_POLICY")
          ? "ROLE_POLICY"
          : "DENEGADO",
        ROLE_POLICY_ACCESS: permisos?.includes("ROLE_POLICY") ? " " : "none",
        PERMISOVERDETALLESGASTO: permisos?.includes(
          "ROLE_EXPENSE_ADMINISTRATIVOS"
        )
          ? "ROLE_EXPENSE_ADMINISTRATIVOS"
          : "DENEGADO",
        ROLE_INVOICING: permisos?.includes("ROLE_INVOICING")
          ? "ROLE_INVOICING"
          : "none",
        ROLE_INVOICINGACCES: permisos?.includes("ROLE_INVOICING") ? "" : "none",
        ROLE_NOMINA: permisos?.includes("ROLE_NOMINA") ? " " : "none",
        ROLE_CARTA_PORTE: permisos?.includes("ROLE_CARTA_PORTE") ? " " : "none",
        ROLE_REPORT_SALES: permisos?.includes("ROLE_REPORT_SALES")
          ? " "
          : "none",
        ROLE_COMPLEMENTO_PAGO: permisos?.includes("ROLE_COMPLEMENTO_PAGO")
          ? " "
          : "none",
        ROLE_UNILEVER: permisos?.includes("ROLE_UNILEVER") ? " " : "none",
        ROLE_ADMIN: permisos?.includes("ROLE_ADMIN") ? "" : "none",
        ROLE_EXPORT_INVOICE: permisos?.includes("ROLE_EXPORT_INVOICE")
          ? "ROLE_EXPORT_INVOICE"
          : "none",
        ROLE_ADDENDAS: permisos?.includes("ROLE_ADDENDAS") ? " " : "none",
        ROLE_HUMAN_RESOURCES: permisos?.includes("ROLE_HUMAN_RESOURCES")
          ? " "
          : "none",
        ROLE_USER: permisos?.includes("RULE_USER") ? " " : "none",
        ROLE_INVENTORY_PRODUCTS: permisos?.includes("ROLE_INVENTORY_PRODUCTS")
          ? "ROLE_INVENTORY_PRODUCTS"
          : "none",
        ROLE_INVENTORY_MOVEMENTS: permisos?.includes("ROLE_INVENTORY_MOVEMENTS")
          ? "ROLE_INVENTORY_MOVEMENTS"
          : "none",
        ROLE_INVENTORY_PRODUCTS_LINE: permisos?.includes(
          "ROLE_INVENTORY_PRODUCTS_LINE"
        )
          ? "ROLE_INVENTORY_PRODUCTS_LINE"
          : "none",
        ROLE_SUPERVISOR: permisos?.includes("ROLE_SUPERVISOR") ? "none" : "",
      };

      sessionStorage.setItem("PERMISOCANCEL", roles.ROLE_CANCEL_INVOICE);
      sessionStorage.setItem(
        "ROLE_REPORT_SALES_MANAGEMENT",
        roles.ROLE_REPORT_SALES_MANAGEMENT
      );
      sessionStorage.setItem(
        "ROLE_INVENTORY_PRODUCTS",
        roles.ROLE_INVENTORY_PRODUCTS
      );
      sessionStorage.setItem(
        "ROLE_INVENTORY_MOVEMENTS",
        roles.ROLE_INVENTORY_MOVEMENTS
      );
      sessionStorage.setItem(
        "ROLE_INVENTORY_PRODUCTS_LINE",
        roles.ROLE_INVENTORY_PRODUCTS_LINE
      );

      sessionStorage.setItem("PERMISOPOLICY", roles.ROLE_POLICY);
      sessionStorage.setItem(
        "PERMISOVERDETALLESGASTO",
        roles.PERMISOVERDETALLESGASTO
      );
      sessionStorage.setItem("ROLE_INVOICING", roles.ROLE_INVOICING);
      sessionStorage.setItem("exportPermis", roles.ROLE_EXPORT_INVOICE);
      sessionStorage.setItem("ROLE_SUPERVISOR", roles.ROLE_SUPERVISOR);

      const role_admin = "ROLE_ADMIN";
      var permisoTotal = permisos?.filter((x) => x === role_admin);
      if (permisoTotal?.length === 1) {
        sessionStorage.setItem("PERMISOCANCEL", "ROLE_CANCEL_INVOICE");
        sessionStorage.setItem("exportPermis", "ROLE_EXPORT_INVOICE");
        sessionStorage.setItem("ROLE_COMPLEMENTO_PAGO_SHOW_FOLIO", "");
      }
      var navergacion01 = roles.ROLE_BUSINESS_PARTNER === " " ? true : false;
      var navergacion02 = roles.ROLE_INVOICING !== "none" ? true : false;
      var navergacion03 = roles.ROLE_CARTA_PORTE === " " ? true : false;
      var navergacion04 = roles.ROLE_COMPLEMENTO_PAGO === " " ? true : false;

      if (navergacion01 === true) {
        var navergar01 = BookData02;
      } else {
        var navergar01 = { link: "", title: "" };
      }
      if (navergacion02 === true) {
        var navergar02 = BookData;
      } else {
        var navergar02 = { link: "", title: "" };
      }
      if (navergacion03 === true) {
        var navergar03 = BookData03;
      } else {
        var navergar03 = { link: "", title: "" };
      }

      if (navergacion04 === true) {
        var navergar04 = BookData04;
      } else {
        var navergar04 = { link: "", title: "" };
      }

      const array3 = navergar01.concat?.(navergar02, navergar03, navergar04);

      const onSideBarStatic = () => {
        const sidebar = document.getElementById("tc");
        if (sidebar && sidebar.style) {
          sidebar.style.width = "calc(100% - 220px)";
          sidebar.style.left = "200px";
          sidebar.style.right = "20px";
        }
      };

      const onSideBarNoStatic = () => {
        const sidebar = document.getElementById("tc");

        if (sidebar && sidebar.style) {
          sidebar.style.width = "calc(100% - 105px)";
          sidebar.style.left = "85px";
          sidebar.style.right = "20px";
        }
      };

      const onSideBarHide = () => {
        const sidebar = document.getElementById("tc");
        if (sidebar && sidebar.style) {
          sidebar.style.width = "100%";
          sidebar.style.left = "1%";
          sidebar.style.right = "1%";
        }
      };

      const closeSession = () => {
        this.props.SetAuthState(AuthState.SignedOut);
      };

      const userEmail = this?.props?.User?.["attributes"]?.["email"];
      const userId = this?.props?.User?.["attributes"]?.["custom:id_user"];
      const name = this?.props?.User?.["attributes"]?.["name"];

      localStorage.setItem("user_name", name);

      return (
        <>
          <ThemeProvider
            theme={
              sessionStorage.getItem("dark_mode") === "dark_mode" ? theme : ""
            }
          >
            <CssBaseline />
            <GlobalStyles
              styles={{
                body: {
                  backgroundColor:
                    sessionStorage.getItem("dark_mode") === "dark_mode"
                      ? "#1c1c1c !important"
                      : "",
                },
                ".MuiDrawer-paperAnchorDockedLeft": {
                  backgroundColor:
                    sessionStorage.getItem("dark_mode") === "dark_mode"
                      ? "#1c1c1c !important"
                      : "",
                },
                ".MuiToolbar-root": {
                  backgroundColor:
                    sessionStorage.getItem("dark_mode") === "dark_mode"
                      ? "#1c1c1c !important"
                      : "",
                },
                ".new_table_v2 table thead tr div": {
                  color:
                    sessionStorage.getItem("dark_mode") === "dark_mode"
                      ? "white !important"
                      : "",
                },
                ".title-cart": {
                  color:
                    sessionStorage.getItem("dark_mode") === "dark_mode"
                      ? "white !important"
                      : "",
                },
                "p , div, .breadcrumbs-general a ": {
                  color:
                    sessionStorage.getItem("dark_mode") === "dark_mode"
                      ? "white !important"
                      : "",
                },
                ".custom-modal-2 , .custom-modal textarea": {
                  backgroundColor:
                    sessionStorage.getItem("dark_mode") === "dark_mode"
                      ? "#e9e9e9 !important"
                      : "",
                  color:
                    sessionStorage.getItem("dark_mode") === "dark_mode"
                      ? "black !important"
                      : "",
                },

                ".Modal-module_modal__FqeFw , option , ": {
                  backgroundColor:
                    sessionStorage.getItem("dark_mode") === "dark_mode"
                      ? "#292929 !important"
                      : "",
                },
                ".custom-details": {
                  backgroundColor:
                    sessionStorage.getItem("dark_mode") === "dark_mode"
                      ? "#4f4f4f !important"
                      : "",
                },
                ".Modal-module_modalTitle__1TtY9 , .bp-25 , h4 , .h1conge, tr:last-child td ":
                  {
                    color:
                      sessionStorage.getItem("dark_mode") === "dark_mode"
                        ? "white !important"
                        : "",
                  },
                ".Modal-module_modalCloseIcon__20QFz svg , .Modal-module_modalCloseIcon__20QFz svg , .Modal-module_modalCloseIcon__20QFz svg":
                  {
                    color:
                      sessionStorage.getItem("dark_mode") === "dark_mode"
                        ? "white !important"
                        : "",
                  },
                "ul .lista > li": {
                  backgroundColor:
                    sessionStorage.getItem("dark_mode") === "dark_mode"
                      ? "#3a3b3c !important"
                      : "",
                },
                ".formulariopointofsale div label , .formulariopointofsale div input , .Tablesectorespos tbody tr td , .Tablecongelador tbody tr td ":
                  {
                    color:
                      sessionStorage.getItem("dark_mode") === "dark_mode"
                        ? "white !important"
                        : "",
                    backgroundColor:
                      sessionStorage.getItem("dark_mode") === "dark_mode"
                        ? "#292929 !important"
                        : "",
                  },
              }}
            />
            <MenuDrawer
              closeSession={closeSession}
              userEmail={userEmail}
              userId={userId}
              onSideBarHide={onSideBarHide}
              onSideBarStatic={onSideBarStatic}
              onSideBarNoStatic={onSideBarNoStatic}
              ROLE_ASSETS={roles.ROLE_ASSETS}
              ROLE_VEHICLES={roles.ROLE_VEHICLES}
              ROLE_ADDENDAS_MARELLI={roles.ROLE_ADDENDAS_MARELLI}
              ROLE_ADDENDAS_VOLKSWAGEN={roles.ROLE_ADDENDAS_VOLKSWAGEN}
              ROLE_ADDENDAS={roles.ROLE_ADDENDAS}
              ROLE_ADMIN={roles.ROLE_ADMIN}
              ROLE_BANK={roles.ROLE_BANK}
              ROLE_HUMAN_RESOURCES={roles.ROLE_HUMAN_RESOURCES}
              ROLE_BUYS={roles.ROLE_BUYS}
              ROLE_EXPENSE={roles.ROLE_EXPENSE}
              ROLE_EXPENSE_ADMINISTRATIVOS={roles.ROLE_EXPENSE_ADMINISTRATIVOS}
              PERMISOVERDETALLESGASTO={roles.PERMISOVERDETALLESGASTO}
              ROLE_EXPENSE_EXTRANJERO={roles.ROLE_EXPENSE_EXTRANJERO}
              ROLE_INVOICINGACCES={roles.ROLE_INVOICINGACCES}
              ROLE_SALES={roles.ROLE_SALES}
              ROLE_UNILEVER={roles.ROLE_UNILEVER}
              ROLE_INVOICING={roles.ROLE_INVOICINGACCES}
              ROLE_EXPORT_INVOICE={roles.ROLE_EXPORT_INVOICE}
              ROLE_CANCEL_INVOICE={roles.ROLE_CANCEL_INVOICE}
              ROLE_POLICY={roles.ROLE_POLICY_ACCESS}
              ROLE_CARTA_PORTE={roles.ROLE_CARTA_PORTE}
              ROLE_COMPLEMENTO_PAGO={roles.ROLE_COMPLEMENTO_PAGO}
              ROLE_USER={roles.ROLE_USER}
              ROLE_BUSINESS_PARTNER={roles.ROLE_BUSINESS_PARTNER}
              ROLE_NOMINA={roles.ROLE_NOMINA}
              ROLE_INVENTORY={roles.ROLE_INVENTORY}
              ROLE_BUSINESS_PARTNER_PROMOTIONS={
                roles.ROLE_BUSINESS_PARTNER_PROMOTIONS
              }
              ROLE_BUSINESS_PARTNER_POINT_OF_SALE={
                roles.ROLE_BUSINESS_PARTNER_POINT_OF_SALE
              }
              ROLE_CABINETS={roles.ROLE_CABINETS}
              ROLE_BUSINESS_PARTNER_SECTOR={roles.ROLE_BUSINESS_PARTNER_SECTOR}
              ROLE_BUSINESS_PARTNER_RECOVER={
                roles.ROLE_BUSINESS_PARTNER_RECOVER
              }
              ROLE_BUSINESS_PARTNER_GENERAL_REGISTRATION={
                roles.ROLE_BUSINESS_PARTNER_GENERAL_REGISTRATION
              }
              ROLE_BUSINESS_PARTNER_DISCOUNT={
                roles.ROLE_BUSINESS_PARTNER_DISCOUNT
              }
              ROLE_REPORT_SALES_MANAGEMENT={roles.ROLE_REPORT_SALES_MANAGEMENT}
              ROLE_FINANCE={roles.ROLE_FINANCE}
              ROLE_CONFIGURATION={roles.ROLE_CONFIGURATION}
              ROLE_REPORT_SALES={roles.ROLE_REPORT_SALES}
              ROLES={roles}
              array3={array3}
            />
            <div id="tc" className="tc">
              {this.props.User === null ? (
                <div> Loading User </div>
              ) : (
                <div className="tc w-100">
                  <Router>
                    <Switch>
                      <Route path="/consultaCFDI" exact component={CfdiTable} />
                      <Route
                        path="/GenerarFactura"
                        exact
                        component={GENERATE_INVOICE}
                      />
                      <Route
                        path="/cartaporte"
                        exact
                        component={CARTA_PORTE_V2}
                      />
                      <Route path="/pago" exact component={PAGO} />
                      <Route path="/updateBP" exact component={UpdateBusnes} />
                      <Route path="/validador" exact component={SetValidador} />
                      <Route
                        path="/FacturasPPD"
                        exact
                        component={SetfacturaPPD}
                      />
                      <Route
                        path="/consultaFacturasProvedores"
                        exact
                        component={SetConsultarFacturasProveedores}
                      />
                      <Route
                        path="/consult_report_bonuses"
                        exact
                        component={SetTableBonuses}
                      />
                      <Route
                        path="/edit_add_bonuses"
                        exact
                        component={SetEditAddBonuses}
                      />
                      <Route
                        path="/edit_add_bonuses_v2"
                        exact
                        component={TableEditAddSales_V2}
                      />
                      <Route
                        path="/consult_taxes"
                        exact
                        component={SetConsultaTaxes}
                      />
                      <Route
                        path="/consult_configure_taxes"
                        exact
                        component={SetConsulConfigureTaxes}
                      />
                      <Route
                        path="/consult_concept"
                        exact
                        component={CONSUL_CONCEPT}
                      />
                      <Route path="/rutas" exact component={SetReportRoutes} />
                      <Route
                        path="/NewDashboard"
                        exact
                        component={NewDashboard}
                      />
                      <Route
                        path="/consult_sectors"
                        exact
                        component={SetSectors}
                      />
                      <Route
                        path="/consult_point_sale_sector"
                        exact
                        component={POINTS_OF_SALE_SECTOR}
                      />
                      <Route
                        path={"/consult_discounts"}
                        exact
                        component={SetDiscounts}
                      ></Route>
                      <Route
                        path={"/consult_promotions"}
                        exact
                        component={SetPromotions}
                      ></Route>
                      <Route
                        path={"/consult_point_of_sale"}
                        exact
                        component={setPointsOfSale}
                      ></Route>
                      <Route
                        path={"/consult_point_of_sale_without_sector"}
                        exact
                        component={POINT_OF_SALE_WITHOUT_SECTOR}
                      ></Route>
                      <Route
                        path="/consult_recover_partner"
                        exact
                        component={RECOVERY_PARTNER}
                      ></Route>
                      <Route
                        path="/register_general"
                        exact
                        component={SetRegisterGeneral}
                      ></Route>
                      <Route
                        path="/cashclosing"
                        exact
                        component={SetBanc}
                      ></Route>
                      <Route
                        path="/Consult_cut_box_sale"
                        exact
                        component={Consult_cut_box_sale}
                      ></Route>
                      <Route
                        path={"/consult_accounting_account"}
                        exact
                        component={TableAccountingAccount}
                      ></Route>
                      <Route
                        path={"/consult_rules_sending_expenses"}
                        exact
                        component={RULES_SE}
                      ></Route>
                      <Route
                        path={"/consult_expense_concept"}
                        exact
                        component={TableExpenseConcept}
                      ></Route>
                      <Route
                        path={"/consult_roles"}
                        exact
                        component={TableRoles}
                      ></Route>

                      <Route
                        path={"/edit_company"}
                        exact
                        component={EditCompany}
                      ></Route>
                      <Route
                        path={"/consult_users"}
                        exact
                        component={TableUsers}
                      ></Route>
                      <Route
                        path={"/consult_driver"}
                        exact
                        component={Consult_drivers}
                      ></Route>
                      <Route
                        path="/expenses"
                        exact
                        component={EXPENSES_NON_DEDUCTIBLE}
                      />
                      <Route
                        path="/expensesExtranjero"
                        exact
                        component={EXPENSES_FOREING}
                      />
                      <Route
                        path="/expensesReport"
                        exact
                        component={REPORT_EXPENSE}
                      />
                      <Route
                        path="/expensesGrup"
                        exact
                        component={CONSULT_GROUP_EXPENSE}
                      />
                      <Route
                        path="/expensesGrupDetail"
                        exact
                        component={EXPENSES_GROUP_DETAIL}
                      />
                      <Route path="/policy" exact component={CONSULT_POLICY} />
                      <Route
                        path="/cut_box_report"
                        exact
                        component={Consult_cut_box_sale_report}
                      />
                      <Route
                        path={"/consult_route_sales_report"}
                        exact
                        component={TableRouteSalesReport}
                      ></Route>
                      <Route
                        path={"/consult_report_sumary"}
                        exact
                        component={Consult_annual_summary}
                      ></Route>
                      <Route
                        path={"/consult_sumary_weektly"}
                        exact
                        component={ConsultSummaryWeektly}
                      ></Route>

                      <Route
                        path="/Consult_route"
                        exact
                        component={ROUTES}
                      ></Route>
                      <Route
                        path="/Consult_report_sale_real"
                        exact
                        component={SalesReal}
                      ></Route>
                      <Route
                        path="/consult_employees"
                        exact
                        component={ConsultEmployees}
                      ></Route>
                      <Route
                        path={"/consult_assigned_concepts"}
                        exact
                        component={ASSIGNED_CONCEPTS}
                      ></Route>
                      <Route
                        path={"/consult_brands"}
                        exact
                        component={BRANDS}
                      ></Route>
                      <Route
                        path={"/TableWarehouse"}
                        exact
                        component={WAREHOUSES}
                      ></Route>
                      <Route
                        path={"/consult_vehicle"}
                        exact
                        component={Consult_vehicle}
                      ></Route>
                      <Route
                        path={"/consult_semaphore"}
                        exact
                        component={TableSemaphore}
                      ></Route>
                      <Route
                        path={"/consult_frezzers"}
                        exact
                        component={TableFreezer}
                      ></Route>
                      <Route
                        path={"/consult_warehouse_movement_cabinets"}
                        exact
                        component={MovementWarehouseCabinets}
                      ></Route>
                      <Route
                        path={"/consult_capacitation"}
                        exact
                        component={Capacitacion}
                      ></Route>
                      <Route
                        path={"/products_line"}
                        exact
                        component={PRODUCTS_LINE}
                      ></Route>
                      <Route
                        path={"/products"}
                        exact
                        component={PRODUCTS}
                      ></Route>
                      <Route
                        path={"/movements"}
                        exact
                        component={MOVEMENTS}
                      ></Route>
                      <Route
                        path={"/werehouse_movements"}
                        exact
                        component={WAREHOUSE_MOVEMENTS}
                      ></Route>
                      <Route path={"/stock"} exact component={STOCK}></Route>
                      <Route
                        path={"/price_list"}
                        exact
                        component={PRICE_LIST}
                      ></Route>
                      <Route
                        path={"/price_list_products"}
                        exact
                        component={CONSULT_DETAIL_PRICE_LIST}
                      ></Route>
                      <Route
                        path={"/price_list_products_v2"}
                        exact
                        component={CONSULT_DETAIL_PRICE_LIST_V2}
                      ></Route>
                      <Route
                        update_price_list
                        path={"/update_price_list"}
                        exact
                        component={UPDATE_PRICE_LIST}
                      ></Route>
                      <Route
                        update_price_list
                        path={"/add_product"}
                        exact
                        component={ADD_PRODUCT}
                      ></Route>
                      <Route
                        update_price_list
                        path={"/add_product_v2"}
                        exact
                        component={ADD_PRODUCT_V2}
                      ></Route>
                      <Route
                        update_price_list
                        path={"/daily_staff"}
                        exact
                        component={DAILY_STAFF}
                      ></Route>
                      <Route
                        path={"/add_sales_bonificaciones"}
                        exact
                        component={ADD_SALES_BONIFICACIONES}
                      ></Route>
                      <Route
                        update_price_list
                        path={"/DEBTS"}
                        exact
                        component={DEBTS}
                      ></Route>
                      <Route
                        update_price_list
                        path={"/PAID"}
                        exact
                        component={PAID}
                      ></Route>

                      <Route
                        update_price_list
                        path={"/supplier"}
                        exact
                        component={SUPPLIER}
                      ></Route>
                      <Route
                        update_price_list
                        path={"/createQr"}
                        exact
                        component={BUSINESS_PARTNER_QR}
                      ></Route>

                      <Route
                        path={"/help_center"}
                        exact
                        component={ViewCases}
                      ></Route>
                      <Route path={"/"} exact component={DASHBOARD}></Route>
                      <Route
                        path={"/commissions"}
                        exact
                        component={COMMISSIONS}
                      ></Route>
                      <Route
                        path={"/profile"}
                        exact
                        component={PROFILE}
                      ></Route>
                      <Route
                        path={"/product_load"}
                        exact
                        component={PRODUCT_LOAD}
                      ></Route>
                      <Route
                        path={"/consult_payment_report"}
                        exact
                        component={PAYMENT_REPORT}
                      ></Route>
                      <Route
                        path={"/map_relation"}
                        exact
                        component={MAP_RELATION_INVOICE}
                      ></Route>

                      <Route
                        path={"/old_balances"}
                        exact
                        component={OLD_BALANCES_REPORT}
                      ></Route>

                      {/* <Route
                      path={"/products_date"}
                      exact
                      component={MERCADO_STUDY}
                    ></Route> */}
                      <Route
                        path={"/consult_DMS"}
                        exact
                        component={CONSULT_DMS}
                      ></Route>

                      <Route
                        path={"/PRICE_LIST_REPORT"}
                        exact
                        component={PRICE_LIST_REPORT}
                      ></Route>
                      <Route
                        path={"/invoice_v2"}
                        exact
                        component={INVOICE_V2}
                      ></Route>

                      <Route
                        path={"/SAMSARA_ENERGY"}
                        exact
                        component={ASSETS_SAMSARA}
                      ></Route>
                      <Route path={"/chat_smit"} exact component={CHAT}></Route>
                      <Route path={"/DMS_V2"} exact component={DMS_V2}></Route>
                      <Route
                        path={"/electronic_acconting"}
                        exact
                        component={ELECTRONIC_ACCOUNTING}
                      ></Route>
                      <Route
                        path={"/consult_accounting_account_v2"}
                        exact
                        component={ACCOUNTING_ACCOUNT_V2}
                      ></Route>
                      <Route
                        path={"/consult_discounts_v2"}
                        exact
                        component={DISCOUNTS_V2}
                      ></Route>
                      <Route
                        path={"/consult_sectors_v2"}
                        exact
                        component={SECTORS_V2}
                      ></Route>
                      <Route
                        path={"/consult_point_of_sale_sector_v2"}
                        exact
                        component={POINT_OF_SALE_SECTOR_V2}
                      ></Route>
                      <Route
                        path={"/consult_promotions_v2"}
                        exact
                        component={PROMOTIONS_V2}
                      ></Route>
                      <Route
                        path={"/consult_recovery_partner_v2"}
                        exact
                        component={RECOVERY_PARTNER_V2}
                      ></Route>
                      <Route
                        path={"/consult_point_of_sale_without_sector_v2"}
                        exact
                        component={POINT_OF_SALE_WITHOUT_SECTOR_V2}
                      ></Route>
                      <Route
                        path={"/consult_employees_v2"}
                        exact
                        component={EMPLOYEES_V2}
                      ></Route>
                      <Route
                        path={"/consult_point_of_sale_v2"}
                        exact
                        component={POINTS_OF_SALE_V2}
                      ></Route>
                      <Route
                        path={"/consult_brands_v2"}
                        exact
                        component={BRANDS_V2}
                      ></Route>
                      <Route
                        path={"/catalog_expense_concept_v2"}
                        exact
                        component={CATALOG_EXPENSE_CONCEPT_V2}
                      ></Route>
                      <Route
                        path={"/consult_assigned_concepts_v2"}
                        exact
                        component={ASSIGNED_CONCEPTS_V2}
                      ></Route>
                      <Route
                        path={"/consult_rules_sending_expenses_v2"}
                        exact
                        component={RULES_SE_V2}
                      ></Route>
                      <Route
                        path={"/commissions_v2"}
                        exact
                        component={COMMISSIONS_V2}
                      ></Route>
                      <Route
                        path={"/consult_route_v2"}
                        exact
                        component={ROUTES_V2}
                      ></Route>
                      <Route
                        path={"/consult_warehouse_v2"}
                        exact
                        component={WAREHOUSES_V2}
                      ></Route>
                      <Route
                        path={"/consult_vehicle_v2"}
                        exact
                        component={VEHICLES_V2}
                      ></Route>
                      <Route
                        path={"/consult_driver_v2"}
                        exact
                        component={DRIVERS_v2}
                      ></Route>
                      <Route
                        path={"/summary_annual_v2"}
                        exact
                        component={SUMMARY_ANNUAL_V2}
                      ></Route>
                      <Route
                        path={"/summary_weekly_v2"}
                        exact
                        component={SUMMARY_WEEKLY_V2}
                      ></Route>
                      <Route
                        path={"/daily_staff_v2"}
                        exact
                        component={DAILY_STAFF_V2}
                      ></Route>
                      <Route
                        path={"/products_v2"}
                        exact
                        component={PRODUCTS_V2}
                      ></Route>
                      <Route
                        path={"/NewDashboard_v2"}
                        exact
                        component={DASHBOARD_CLIENTS_V2}
                      ></Route>
                      <Route
                        path={"/help_center_v2"}
                        exact
                        component={ViewCases_v2}
                      ></Route>
                      <Route
                        path={"/bussines_partnert_v2"}
                        exact
                        component={BUSINESS_PARTNERT_TABLE}
                      ></Route>
                      <Route
                        path={"/consult_frezzers_v2"}
                        exact
                        component={Freezer_v2}
                      ></Route>
                      <Route
                        path={"/consult_warehouse_movement_cabinets_v2"}
                        exact
                        component={MovementWarehouseCabinets_v2}
                      ></Route>
                      <Route
                        path={"/stock_v2"}
                        exact
                        component={STOCK_V2}
                      ></Route>

                      {/* compras */}
                      <Route
                        path="/expenses_v2"
                        exact
                        component={EXPENSES_NON_DEDUCTIBLE_V2}
                      />
                      <Route
                        path="/expensesExtranjero_V2"
                        exact
                        component={EXPENSES_FOREING_V2}
                      />
                      <Route
                        path="/consultaFacturasProvedores_V2"
                        exact
                        component={ConsultarFacturasProvedores_v2}
                      />
                      <Route
                        path="/expenses_v2"
                        exact
                        component={EXPENSES_NON_DEDUCTIBLE_V2}
                      />
                      <Route
                        path="/expensesExtranjero_V2"
                        exact
                        component={EXPENSES_FOREING_V2}
                      />
                      <Route
                        path="/consultaFacturasProvedores_V2"
                        exact
                        component={ConsultarFacturasProvedores_v2}
                      />
                      <Route
                        path="/policy_v2"
                        exact
                        component={CONSULT_POLICY_V2}
                      ></Route>
                      <Route
                        path="/expensesReport_v2"
                        exact
                        component={REPORT_EXPENSE_V2}
                      />
                      <Route
                        path="/expensesGrup_v2"
                        exact
                        component={CONSULT_GROUP_EXPENSE_V2}
                      />
                      <Route
                        path="/expensesGrupDetail_v2"
                        exact
                        component={EXPENSES_GROUP_DETAIL_V2}
                      />
                      <Route
                        path={"/consult_payment_report_v2"}
                        exact
                        component={PAYMENT_REPORT_V2}
                      ></Route>
                      <Route
                        path="/consult_configure_taxes_v2"
                        exact
                        component={TableConfigureTaxes_v2}
                      />
                      <Route
                        path="/consult_concept_v2"
                        exact
                        component={CONSUL_CONCEPT_V2}
                      />

                      <Route
                        path="/consult_taxes_v2"
                        exact
                        component={TableTaxes_V2}
                      />

                      <Route
                        update_price_list
                        path={"/supplier_v2"}
                        exact
                        component={SUPPLIER_v2}
                      ></Route>
                      <Route
                        path="/Consult_report_sale_real_V2"
                        exact
                        component={SalesReal_V2}
                      ></Route>
                      <Route
                        path={"/drive_to_drive"}
                        exact
                        component={Autorization}
                      ></Route>
                      <Route
                        path="/report_sales"
                        exact
                        component={REPORT_SALES}
                      />
                      <Route
                        path="/cashclosing_v2"
                        exact
                        component={CONSULT_CASHCLOSING_V2}
                      />

                      <Route
                        path="/cashclosing_sale_v2"
                        exact
                        component={CONSULT_CASHCLOSING_SALE_V2}
                      />
                      <Route
                        path="/cashclosing_sale_report_v2"
                        exact
                        component={CONSULT_CASHCLOSING_SALE_REPORT_V2}
                      />
                      <Route
                        path="/product_load_v2"
                        exact
                        component={PRODUCT_LOAD_V2}
                      />
                      <Route
                        path="/consult_report_bonuses_V2"
                        exact
                        component={TableBonificaciones_V2}
                      />
                      <Route
                        path={"/add_sales_bonificaciones_v2"}
                        exact
                        component={ADD_SALES_BONIFICACIONES_V2}
                      ></Route>
                      <Route
                        path={"/products_line_v2"}
                        exact
                        component={PRODUCTS_LINE_V2}
                      ></Route>
                      <Route path="/FacturasPPD_V2" exact component={Vppd_v2} />
                      <Route
                        path={"/price_list_v2"}
                        exact
                        component={PRICE_LIST_v2}
                      ></Route>
                      <Route
                        update_price_list
                        path={"/update_price_list_v2"}
                        exact
                        component={UPDATE_PRICE_LIST_V2}
                      ></Route>
                      <Route
                        path={"/movements_v2"}
                        exact
                        component={MOVEMENTS_v2}
                      ></Route>
                      <Route
                        path={"/werehouse_movements_v2"}
                        exact
                        component={WAREHOUSE_MOVEMENTS_V2}
                      ></Route>
                      <Route
                        path={"/warehouse_movements_list"}
                        exact
                        component={WAREHOUSE_MOVEMENTS_LIST}
                      ></Route>
                      <Route
                        path={"/text_extract"}
                        exact
                        component={TEXTEXTRACT}
                      ></Route>

                      <Route path={"/censo"} exact component={CENSO}></Route>

                      {/* PRUEBA DOMY */}
                      <Route
                        path={"/DOMY_DATA"}
                        exact
                        component={DOMY_DATA}
                      ></Route>
                      <Route
                        path={"/datasync"}
                        exact
                        component={DATASYNC}
                      ></Route>
                      <Route
                        path={"/route_management"}
                        exact
                        component={MANAGEMENT_ROUTE}
                      ></Route>
                      <Route
                        path={"/payment_accounts"}
                        exact
                        component={PAYMENT_ACCOUNTS}
                      ></Route>
                      <Route
                        path={"/map_routes"}
                        exact
                        component={ReporteGMaps_v2}
                      ></Route>
                      <Route
                        path={"/USER_MANUAL"}
                        exact
                        component={USER_MANUAL}
                      ></Route>
                      <Route
                        path={"/report_er"}
                        exact
                        component={REPORT_ROUTE_8}
                      ></Route>
                      <Route
                        path={"/report_er_v2"}
                        exact
                        component={REPORT_ROUTE_FULL}
                      ></Route>
                      <Route
                        path={"/consult_bonus"}
                        exact
                        component={REPORT_BONICACIONES}
                      ></Route>
                      <Route
                        path={"/patch_notes"}
                        exact
                        component={PATCH_NOTES_HOME}
                      ></Route>
                      <Route
                        path={"/patch_notes/description_notes"}
                        exact
                        component={VERSION_DESCRIPTION}
                      ></Route>
                      <Route
                        path={"/comparative_reports"}
                        exact
                        component={COMPARATIVE}
                      ></Route>
                      <Route
                        path={"/sales-deliveries"}
                        exact
                        component={SALES_DELIVERIES}
                      ></Route>
                      <Route
                        path={"/pay_condition"}
                        exacts
                        component={PAY_CONDITION}
                      ></Route>

                      <Route
                        path={"/PRODUCT_LOAD_NEW_METHOD"}
                        exacts
                        component={PRODUCT_LOAD_NEW_METHOD}
                      ></Route>

                      <Route
                        path={"/WAYBILL"}
                        exacts
                        component={WAYBILL}
                      ></Route>
                    </Switch>

                    {/* <AnchorTemporaryDrawer></AnchorTemporaryDrawer>
                    <ModalAside_com></ModalAside_com> */}
                    {/* <MODAL_MOVIL></MODAL_MOVIL> */}

                    <h4 className="version">v13.1.10</h4>
                    <CHAT mini={true}></CHAT>
                  </Router>
                </div>
              )}
            </div>
          </ThemeProvider>
        </>
      );
    } else {
      return (
        <div className="container_login">
          <div className="grid-login">
            <div className="formsesion">
              <div className="logoempresa-ingresar">
                <img src={fc} alt=""></img>
              </div>

              <div className="camplogin">
                <br></br>
                <div className="div-lg1">
                  <p className="bienvenido">Bienvenido de nuevo</p>
                  <p className="text-login">Operación Dinámica V.2</p>
                </div>

                <br></br>
                <br></br>

                <div
                  className="boton-ingresar"
                  onClick={(e) => this.props.SetAuthState(AuthState.SignIn)}
                >
                  <a>Ingresar</a>
                </div>
                {/* <img
                src={fcgif}
              ></img>              */}
              </div>
            </div>
            <div className="imglogin">
              <img src={fondo} alt=""></img>
            </div>
          </div>
        </div>

        // <div className="colorfondo">
        //   <div className="parent clearfix">
        //     <div className="bg-illustration"></div>

        //     <div className="login">
        //       <div className="container">
        //         <br></br>
        //         <h2 className="titleOD2"> Operación Dinámica V.2</h2>

        //         <img className="avatar" src={BG} alt="logo" />

        //         <div className="login-form">
        //         <input
        //             type="button"
        //             className="form-control btn btn-primary"
        //             value="Ingresar"
        //             onClick={() => this.props.SetAuthState(AuthState.SignIn)}
        //           />
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </div>
      );
    }
  }
}

export default index;
