import React, { useEffect, useState, useMemo, useRef } from "react";
import TableContainer from "../../main_components/Table/TableContainer";
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/table.css";
import "../../components_cfdi/modal.css";
import NotificationSystem from 'react-notification-system';
import { SelectColumnFilter } from "../../components_cfdi/filters";
import {get_supplier_invoices} from "../../services/expenses/expenses";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import { Box, Button } from "@mui/material";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { formattedCurrentDate, formattedPreviousDate } from "../../main_components/date/day";
import dayjs from "dayjs";
import AlertResponse from "../../main_components/alerts/alertResponse";
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";

const ConsultarFacturasProvedores_v2 = () => {

  
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  const [data, setData] = useState([]);
  const [ff, setff] = useState(dayjs(formattedCurrentDate));
  const [fi, setfi] = useState(dayjs(formattedPreviousDate));
  const [query_filters, setquery_filters] = useState({});
  const [query_filters_dt, setquery_filters_data] = useState("");
  const [open, setOpen] = React.useState(false);
  const[modalT, setmodalT]=useState(false);
  const [total_rows, settotal_rows] = useState("");
  const notificationSystemRef = useRef();

  const handleSubmit = async (query_filters_flag, query_filters_data) => {
  
    setOpen(true);
    setIsLoading(true);
    var d;
    try {
      if(query_filters_flag){
        console.log("entra")
        setfi(dayjs(query_filters_data.di))
        setff(dayjs(query_filters_data.df))
        d = await get_supplier_invoices(query_filters_data.di, query_filters_data.df);
        setquery_filters({
          di: query_filters_data.di,
          df: query_filters_data.df
        });
      }else{
        setquery_filters({
          di: fi.format('YYYY-MM-DD'),
          df: ff.format('YYYY-MM-DD')
        });
        d = await get_supplier_invoices(fi.format('YYYY-MM-DD'),ff.format('YYYY-MM-DD'),);
        setOpen(false);
        setIsLoading(false);
      }
      var ndt = update_null_identifiers(d?.data, update_identifiers);
      setData(ndt);
    } catch (err) {
      console.log(err);
      setOpen(false);
      setData([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
      
      {
        id: "invoice_date",
        header: "Fecha",
        accessorKey: "invoice_date",
      },

      {
        header: "Serie",
        accessorKey: "serie",
        id: "serie",
      },
      {
        id: "folio",
        header: "Folio",
        accessorKey: "folio",
      },
      {
        header: "Cliente",
        accessorKey: "name_business_partner",
        id: "name_business_partner"
      },
      {
        header: "RFC",
        accessorKey: "rfc_business_partner",
        id: "rfc_business_partner"
      },

      {
        id : "subtotal",
        header: "Subtotal",
        accessorKey: "subtotal",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      {
        id: "discount",
        header: "Descuento",
        accessorKey: "discount",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      {
        id: "tax",
        header: "Impuesto",
        accessorKey: "tax",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      {
        id: "total",
        header: "Total",
        accessorKey: "total",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },

      {
        header: "Método de pago",
        accessorKey: "payment_method",
        id: "payment_method", 
        filterVariant: 'multi-select',
      },
      {
        header: "Forma de Pago",
        accessorKey: "forma_pago",
        id: "forma_pago", 
        filterVariant: 'multi-select',
      },
      {
        id: "tipodecomprobante",
        header: "Tipo de comprobante",
        accessorKey: "tipodecomprobante",
        filterVariant: 'multi-select',
        
      },
      {
        id: "cancel",
        header: "Estado",
        accessorKey: "cancel",
        filterVariant: 'multi-select',
        accessorFn: (d) => {
          return d.cancel === true ? "CANCELADO" : "VIGENTE";
        },
      },
      {
        id: "uuid_sat",
        header: "UUID_SAT",
        accessorKey: "uuid_sat",        
      },
      {
        header: "Usuario",
        accessorKey: "username",
        id: "username"
      },
    ],
    []
  );

  const update_identifiers =
    [
      { invoice_date: 'string' },
      { serie: 'string' },
      { folio: 'String' },
      { name_business_partner: 'string' },
      { rfc_business_partner: 'string' },
      { subtotal: 'string' },
      { discount: 'string' },
      { tax: 'string' },
      { total: 'string' },
      { payment_method: 'string' },
      { forma_pago: 'string'},
      { tipodecomprobante: 'string' },
      { cancel: 'string' },
      { uuid_sat: 'string' },
      { username: 'string' }
    ];

    const handleStateChange = (newState) => {
      const filteredRowsCount = newState.filteredRowModel
      settotal_rows(filteredRowsCount);
    };

    const handle_success_filter = (a, b) => {
      handleSubmit(a, b);
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={"Filtro Aplicado"} view={true}></AlertResponse>,
        level: "success",
        position: "br",
        autoDismiss: 10,
      });
    }
    
    useEffect(() => {
      if (total_rows === "") {
      } else {
        const getCurrentPath = () => {
          const search = window.location.search;
          const pathWithoutQuestionMark = search.startsWith('?') ? search.slice(1) : search;
          return pathWithoutQuestionMark;
        };
    
        const pathWithoutQuestionMark = getCurrentPath();
        setquery_filters_data(pathWithoutQuestionMark)
      }
    }, [total_rows])
    
  

  return (
    <div>
       <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
      <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>
      <BREADCRUMBS
        niveles={[
          {
            label: "FACTURAS DE PROVEEDORES",
            path: null,
          },
        ]}
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>
      <div className="new_table_v2">

      <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        onStateChange={handleStateChange}
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            settotal_rows(table);
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderRowActionMenuItems={({ row, closeMenu }) => [
            <RENDER_ROW_ACTION_MENU_ITEMS
             closeMenu={closeMenu}
              setmodalT={setmodalT}
              // setmenssage={setmenssage}
              // setmodalGeneral={setmodalGeneral}
              row={row}
              handleSubmit={handleSubmit}
              setreconsult={setreconsult}
              // refresh_token={refresh_token}
              // alertas={alertas}
            />
          ]}
        renderTopToolbarCustomActions={({ }) => (
          <div className="search-new-container2">
            <div className="div1cn2">

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: "100%" }}
                label="Fecha Inicial"
                value={fi}
                onChange={(newValue) => setfi(newValue)}
              />
            </LocalizationProvider>
            </div>
            <div className="div2cn2">

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: "100%" }}
                label="Fecha Inicial"
                value={ff}
                onChange={(newValue) => setff(newValue)}
              />
            </LocalizationProvider>
           
            </div>

            <div className="div3cn2">

            <Button
            variant="contained"
            title='BUSCAR'
            sx={{ height: '35px', borderRadius: "15px", marginRight: "5px", width: "50%" }}
            onClick={(e) => {
              handleSubmit();
            }}
          >
            <ManageSearchIcon></ManageSearchIcon>
          </Button>

            </div>
          </div>
        )}
        renderToolbarInternalActions={({ table, index }) => (
          <RENDER_TOOLBAR_INTERNAL_ACTIONS
            table={table} 
            file_name={"Consultar Documentos Enviados"}
            update_identifiers={update_identifiers}
            settotal_rows={settotal_rows}
            query_filters={query_filters}
            key={index}
            handle_success_filter={handle_success_filter}
            share_filter={true}
            total_rows={total_rows !== "" ? total_rows : 0}
            zip={true}
          />
         )}
      />
      </div>
    </div>
  );
};

export default ConsultarFacturasProvedores_v2;
