//yarn add react-widgets
//yarn add react-google-maps
import { useCallback, useEffect, useMemo, useState } from 'react';
import {withScriptjs,withGoogleMap,GoogleMap,Marker,Polygon, InfoWindow} from "react-google-maps";
import {TextField } from '@material-ui/core';
import "./ReporteGMaps.css";
import { StickyTable} from "react-sticky-table";
import Tablaimg from "../../imgComponents/tabla.png";
import Mapaimg from "../../imgComponents/direccion.png";
import { refresh_token } from '../../main_components/tokens/tokenrefresh';
import { get_route_list, get_sectors } from '../../services/businesspartners/businesspartners';
import { get_report_gps } from '../../services/reports/reports';
import { BREADCRUMBS } from '../../main_components/pagination/breadcrumbs';
import TableContainer from '../../main_components/Table/TableContainer';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { MaterialReactTable } from 'material-react-table';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from '../../main_components/methods_v2/export';
import MODAL_TABLE from '../../main_components/modal/modal_Table';
import { POINT_ALL } from './point';
import { CustomDialog } from 'react-st-modal';

function MapContainer(props) {

  const [polygon, setPolygon] = useState(null);
  
  const vertices = useCallback((e) => {
    setPolygon(polygon ? [...polygon, e.latLng] : [e.latLng]);
}, [polygon]);
  
  const [coordenadas] = useState({ lat: 18.848893, lng: -97.073271});   
  const [selectedMarker, setSelectedMarker] = useState(null);
  // Actualiza el Polygon cuando se actualiza `verticespoligonodata` en las props
  
  useEffect(() => {
    if (!props.bandera2) {
      // Reset the polygon state when props.bandera2 becomes false
      setPolygon(null);
    }
  }, [props.bandera2]);

  return (
    <GoogleMap
    defaultZoom={10}
    defaultCenter={coordenadas}
    onClick={props.bandera===true?vertices:null}
  >
  {
   props.datamap?.map((item)=>
       (
         <Marker

         key={item.id}
         position={{lat: item.lat, lng: item.lng}}
         title={item?.code+" - "+item.name}
        
         icon = {{
           path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
           fillColor: item.color_route,
           fillOpacity: 1,
           rotation: 0,
           strokeWeight: 1,
           strokeColor: "#ffffff",
           scale: 2, 
          }}
          onClick={() => setSelectedMarker(item)}
        
     >
     </Marker>
     ))   
}
{selectedMarker && (
        <InfoWindow
          position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
          onCloseClick={() => setSelectedMarker(null)}
        >
          <div className='inforwindowpos'>
           <h3>{selectedMarker?.code} - {selectedMarker?.name}</h3>
           <p>DÍA DE VISITA: {selectedMarker?.visit_days}</p>
           <p>SECTOR: {selectedMarker?.number_sector} - {selectedMarker?.name_sector}</p>          
          </div>
        </InfoWindow>
      )}
 
    {props.bandera2===true && polygon && (
          <Polygon
            paths={polygon}
            options={{
              fillColor: '#ff0000',
              fillOpacity: 0.35,
              strokeColor: '#ff0000',
              strokeOpacity: 1,
              strokeWeight: 2,
            }}
            onClick={() => {
               props.poligonomapa();
               props.setverticespoligono(polygon.map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() })));
               props.setbandera(false);
               console.log(polygon.map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() })));

            }}
          />
        )}
        
</GoogleMap>
  );
}

function MapContainer2(props) {

  const [polygon, setPolygon] = useState(props?.verticespoligono);
  
  const vertices = useCallback((e) => {
    setPolygon(polygon ? [...polygon, e.latLng] : [e.latLng]);
}, [polygon]);

  console.log(polygon)
  
  const [coordenadas] = useState({ lat: 18.848893, lng: -97.073271});   
  const [selectedMarker, setSelectedMarker] = useState(null);
  // Actualiza el Polygon cuando se actualiza `verticespoligonodata` en las props
  
  useEffect(() => {
    if (!props.bandera2) {
      // Reset the polygon state when props.bandera2 becomes false
      setPolygon(null);
    }
  }, [props.bandera2]);

  return (
    <GoogleMap
    defaultZoom={10}
    defaultCenter={coordenadas}
    onClick={vertices}
  >
  {
   props.datamap?.map((item)=>
       (
         <Marker

         key={item.id}
         position={{lat: item.lat, lng: item.lng}}
         title={item?.code+" - "+item.name}
        
         icon = {{
           path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
           fillColor: item.color_route,
           fillOpacity: 1,
           rotation: 0,
           strokeWeight: 1,
           strokeColor: "#ffffff",
           scale: 2, 
          }}
          onClick={() => setSelectedMarker(item)}
        
     >
     </Marker>
     ))   
}
{selectedMarker && (
        <InfoWindow
          position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
          onCloseClick={() => setSelectedMarker(null)}
        >
          <div className='inforwindowpos'>
           <h3>{selectedMarker?.code} - {selectedMarker?.name}</h3>
           <p>DÍA DE VISITA: {selectedMarker?.visit_days}</p>
           <p>SECTOR: {selectedMarker?.number_sector} - {selectedMarker?.name_sector}</p>          
          </div>
        </InfoWindow>
      )}
 
    {polygon && (
          <Polygon
            paths={polygon}
            options={{
              fillColor: '#ff0000',
              fillOpacity: 0.35,
              strokeColor: '#ff0000',
              strokeOpacity: 1,
              strokeWeight: 2,
            }}
          />
        )}
        
</GoogleMap>
  );
}


const WrappedMap = withScriptjs(withGoogleMap(MapContainer));

const WrappedMap2 = withScriptjs(withGoogleMap(MapContainer2));

export const ReporteGMaps = () => {

  const [cont, setcont] = useState(0);
  const [mapatabla, setmapatabla]=useState(true);
  const [modalT, setmodalT]=useState(false);
  const [total_rows, settotal_rows] = useState("");
  //data de consultas
  const [data, setData] = useState([]);
  const [dataroute, setdataroute] = useState(0);  
  const [datamap, setdatamap] = useState([]);
  const [sectors, setdatasector] = useState(0);
  const [filsectors, setfilsectors] = useState([]);
  //select 
  const [route, setroute] = useState("all");
  const [date, setdate] = useState("vacio");
  const [sectorselect, setsectorselect] = useState("vacio");
  const [test, settest] = useState(false);
  const [test2, settest2] = useState(false);
  const [seepoly, setseepoly]=useState(false);
  const [datafiltro1, setdatafiltro1]=useState([]);
  const [verticespoligono, setverticespoligono]=useState([]);
  //bandera que dibuja el poligono
  const [bandera, setbandera]=useState(false);
  //bandera que elimina el poligono
  const [bandera2, setbandera2]=useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});

  const [bancar, setbancar]=useState(false);
  
  const update_identifiers =
  [
    { code: 'string' },
    { name: 'string' },
    { direccion: 'string' },
    { ciudad: 'string' },
    { lat: 'string' },
    { lng: 'string' }
  ];

useEffect(() => {
  ConsutarRutas();
  consultdata();
  ConsultarSectores();
}, []);


const polygon = verticespoligono;


const [newjson, setnewjson]=useState([]);

function filtrar2(){
  if(newjson.length===0){
    alert("No ha seleccionado un archivo CSv");
  }else{
    unir();
  }
}
function unir(){
  const jsonunido = [...datafiltro1, ...newjson];
  var sinRepetidos = jsonunido.filter((item, index) => jsonunido.indexOf(item) === index);
  setdatamap(sinRepetidos);

 }

function dibujarpoligono(){
  setverticespoligono([]);
  setbandera(true);
  setbandera2(true);
}
function cancelarpoligono(){
  setseepoly(false);
  setbandera(false);
  setbandera2(false);
  unir();
}
function ocultarpoligono(){
  setseepoly(false);
  setbandera(false);
  setbandera2(false);
  unir();
}
function poligonomapa(){
  setseepoly(true);
}
function conjuntos(){
  console.log("conjuntos");
  const filteredCoordinates = datamap.filter((item) => {
     return isPointInPolygon([item.lat, item.lng], polygon);
  });
  console.log(filteredCoordinates);   
  setdatamap(filteredCoordinates);
}

function isPointInPolygon(point, polygon) {
  let isInside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i].lat, yi = polygon[i].lng;
    const xj = polygon[j].lat, yj = polygon[j].lng;

    const intersect = ((yi >= point[1]) !== (yj >= point[1]))
      && (point[0] <= (xj - xi) * (point[1] - yi) / (yj - yi) + xi);
    if (intersect) {
      isInside = !isInside;
    }
  }
  return isInside;
}

//lectura de csv a json
const handleFileUpload = (event) => {

  const file = event.target.files[0];
  if (!file) return;

  const reader = new FileReader();
  reader.readAsText(file);

  reader.onload = (event) => {
    const csv = event.target.result;
    const rows = csv.split('\n');
    const header = rows.shift().split(',');
    header[header.length-1] = header[header.length-1].replace('\r', '');
    console.log(header);

    const jsonData = rows.map((row) => {
      const obj = {};
      const values = row.split(',');
      header.forEach((headerValue, index) => {
        
        if (headerValue === "lat" || headerValue === "lng") {
          obj[headerValue] = Number(values[index]?.trim());
        }
        else {
          obj[headerValue] = values[index]?.trim();
        }
      });
      obj.color_route = "#5E5E5E";
      return obj;
    });
    setnewjson(jsonData);
    console.log(jsonData);
  };
};

const filtrar = async () => {

//  consultdata();
  var dias = date;
  var rutas = route;
  var sectores = sectorselect;

  console.log(rutas);
  console.log(dias);
  console.log(sectores);
  
//todas las rutas 
if(rutas === "all"){
  setfilsectors(sectors);
  settest(true);
  settest2(false);

  var datacompl=[]; 
  for (var i = 0; i < data.length; i++) {
       datacompl = datacompl.concat(data[i].pointSaleList);
  }

  //todas las rutas todos los dias
  if(dias === "vacio"){
      //todas las rutas todos los dias todos los sectores
      if(sectores === "vacio"){
        console.log(datacompl);
        setdatamap(datacompl);
        setdatafiltro1(datacompl);
      }else{
        //tofas las rutas todos los dias con un sector en especifico
          var datards = datacompl.filter(item=>item.id_sector == sectores);
          console.log(datards);
          setdatamap(datards);
          setdatafiltro1(datards);
      }
  }else{
    //todas las rutas con un dia en especifico    
    var datrd = datacompl.filter(item=>item.visit_days==dias);
      if(sectores === "vacio"){
        console.log(datrd);
        setdatamap(datrd);
        setdatafiltro1(datrd);
      }else{
        //todas las rutas con un dia especifico con un sector especifico
        console.log(datrd.filter(item=>item.id_sector==sectores));
        setdatamap(datrd.filter(item=>item.id_sector==sectores));
        setdatafiltro1(datrd.filter(item=>item.id_sector==sectores));
      }
  }
}
//una ruta en especifico
else{

  settest(false); 
  settest2(false);
  
  //sin rutas no se dibuja en mapa
  if(rutas === "vacio" ){
    console.log("No ha seleccionado ruta");
  }else{
    //Una ruta en especifico todos los dias 
    var pointsaleroute = (data.filter(item=>item.idRoute==rutas));
    
    if(dias === "vacio"){
      //console.log(pointsaleroute[0].pointSaleList);  
      if(sectores=== "vacio" ){
        //una ruta en especifico, todos los dias, todos los sectores
        settest2(true);
        var coloresrutas = pointsaleroute;
        coloresrutas[0]?.pointSaleList?.map ( item => {
          item.color=item.color_route;
          item.color_route=item.color_day;
        });
        setdatamap(coloresrutas[0].pointSaleList);
        setdatafiltro1(coloresrutas[0].pointSaleList);
        await consultdata();
      }else{
        //una ruta en especifico, todos los dias con un sector
        var routsec = pointsaleroute[0].pointSaleList.filter(item=>item.id_sector==sectores); 
        console.log(routsec);
        setdatamap(routsec);
        setdatafiltro1(routsec);
      }
    }else{
      var pointsaledate = (pointsaleroute[0].pointSaleList.filter(item=>item.visit_days==dias));
      console.log(pointsaledate);
      if(sectores=== "vacio"){
      //Una ruta en especifico con un dia especifico
        setdatamap(pointsaledate);
        setdatafiltro1(pointsaledate);
      }else{
        //Una ruta en especifico, un dia en especifico, un sector especifico
        console.log(pointsaledate.filter(item=>item.id_sector==sectores));
        setdatamap(pointsaledate.filter(item=>item.id_sector==sectores));
        setdatafiltro1(pointsaledate.filter(item=>item.id_sector==sectores));
        }
      }
    }
  }
  console.log("data mapas")
  console.log(datamap);
}
useEffect(() => {
  const contador = () => {
      setcont(datamap.length);
  };
  contador();
});


const columns = useMemo(
  () => [
    {
      id: "code",
      header:"Código",
      accessorKey: "code"
    },    
    {
      id: "name",
      header:"Punto de Venta",
      accessorKey: "name"
    },
    {
      id: "direccion",
      header:"Direccion",
      accessorKey:"direccion"
    },
    {
      id: "ciudad",
      header:"Ciudad",
      accessorKey:"ciudad"
    },
    {
      id: "lat",
      header:"Latitud",
      accessorKey:"lat"
    }, 
    {
      id: "lng",
      header:"Longitud",
      accessorKey:"lng"
    }
    ],
    []
);



//Consultar data
const consultdata = async () => {
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_report_gps();
          setData(d === null ? [] : d.data.result);  
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}
  } catch (error) {
    console.log(error);
  }
}
const ConsutarRutas = async () => {
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_route_list();
          setdataroute(d === null ? [] : d);
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}
  } catch (error) {
    console.log(error);
  }
};
const ConsultarSectores = async () =>{
  try {
    setmodalT(true);
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_sectors();
          setdatasector(d === null ? [] : d.data.sectors);
          setfilsectors(d === null ? [] : d.data.sectors);    
          setmodalT(false);
        }catch(err){
          console.log(err);
          setmodalT(false);
        }        
      }, 1000);
    }else{}
  } catch (error) {
    console.log(error);
  }
}

const verticesalrededor =async () =>{

  await CustomDialog(               
    <div>
       <POINT_ALL setverticespoligono={setverticespoligono} setbandera={setbandera} setbandera2={setbandera2} setbancar={setbancar} setseepoly={setseepoly}></POINT_ALL>
    </div>,
  {
    className: "modalTItle",
    title:"Agregar Direccion",
    showCloseIcon: true,
    isCanClose:false,
  }
);

}


const handleStateChange = (newState) => {
  const filteredRowsCount = newState.filteredRowModel
  settotal_rows(filteredRowsCount);
};




return (  
  <>
     <BREADCRUMBS niveles={
                [
                  {label: "MAPA DE RUTAS", path: null},
                ]
              }
              new_version={true}
              modul="MAPA DE RUTAS V2"
              path="/map_routes"  
              cards_dashboard={true}
              total_rows={total_rows !== "" ? total_rows : 0}
              filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
              visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
              total={datamap?.length}  
      ></BREADCRUMBS>

  <MODAL_TABLE
      open={true}
      message={""}
      modalGeneral={false}
      modalT={modalT}
    ></MODAL_TABLE>

  <div className='botonsfilt'>
    <div className='ff1'>
    <TextField
       select
       label="Ruta"
       defaultValue={""}
       InputLabelProps={{shrink: true}}
       SelectProps={{native: true,}}
       sx={{ m: 1.5, width: "100%"}}
       onChange={(event) => setroute(event.target.value) &&
        (event.target.value==="all")? 
        setfilsectors(sectors):
        setfilsectors(sectors?.filter(item=>item?.id_route_fk==event.target.value))
      }  
     >      
        <option
          key={100}
          value={"all"}
        >
           {"TODAS LAS RUTAS"}
        </option>
        {
          dataroute?.data?.parametersList?.map((item)=>(
            <option
              key={item.idRoute}
              value={item.idRoute}
            >
              {item.route}
            </option>
          ))
        }

      </TextField>
    </div>
    <div className='ff2'>
    <TextField
       select
       label="Días de visita"
       defaultValue={""}
       InputLabelProps={{shrink: true}}
       SelectProps={{native: true,}}
       sx={{ m: 1.5, width: "100%"}}
       onChange={(event) => setdate(event.target.value)}  
     >
        <option key={0} value={"vacio"} selected>
          {"Seleccione día"}
        </option>
        <option key={1} value={"LUNES"}>LUNES</option>
        <option key={2} value={"MARTES"}>MARTES</option>
        <option key={3} value={"MIERCOLES"}>MIERCOLES</option>
        <option key={4} value={"JUEVES"}>JUEVES</option>
        <option key={5} value={"VIERNES"}>VIERNES</option>
        <option key={6} value={"SABADO"}>SABADO</option>
        <option key={7} value={"DOMINGO"}>DOMINGO</option>
      </TextField>
    </div>
    <div className='ff3'>
    <TextField
       select
       label="Sectores"
       defaultValue={""}
       InputLabelProps={{shrink: true}}
       SelectProps={{native: true,}}
       sx={{ m: 1.5, width: "100%"}}
       onChange={(event) => setsectorselect(event.target.value)}  
     >
        <option key={0} value={"vacio"} selected>
          {"Seleccione Sector"}
        </option>
         {
          filsectors?.map((item)=>(
            <option
              key={item.id}
              value={item.id}
            >
              {item.number_sector+" - "+item.name_sector}
            </option>
          ))
        } 
      </TextField>
    </div>
    <div className="ff4">
        <button onClick={filtrar}>
          Filtrar
        </button>
      </div>
      <div className='ff5'>
      <input type="file" onChange={handleFileUpload}/>
      </div>
      <div className='ff6'>
      <button onClick={filtrar2}>
          Dibujar CSV
        </button>
      </div>
      <div className='ff7'>
      {
        mapatabla===true? 
        <img
          src={Tablaimg}
          alt=''
          onClick={(event)=>setmapatabla(false)}
          className="centerText"
          title="Mostrar Tabla"
        ></img>:
        <img
          alt=''
          src={Mapaimg}
          onClick={(event)=>setmapatabla(true)}
          className="centerText"
          title="Mostrar Mapa"
        ></img>
      }
     
      </div>
      <div className='ff8'>
      {seepoly===false?
       
       bandera===true?
        <button onClick={cancelarpoligono} style={{"backgroundColor":"red"}}>
          Cancelar
        </button>:
        <button onClick={dibujarpoligono}>
          Dibujar Poligono
        </button>:
        <button onClick={ocultarpoligono}>
          Ocultar Poligono
        </button>
      }
      {/* <button onClick={()=>{verticesalrededor()}}>Puntos de venta alrededor</button> */}
        
      </div>
      <div className='ff9'>
      {
        seepoly===true?
        <button type="submit" onClick={conjuntos}>
          Agrupar
        </button>:""
      }
      
      </div>     
    </div>
      

    <div className='consultposgps'>
      <p>Puntos de venta consultados:{cont}</p>
    </div>


<div className='contenido'>

<div id="map" style={mapatabla===true?{"display":"block"}:{"display":"none"}}>
<div className='simbologia'  style={test===true?{display:"block"}:{display:"none"}}>      
<table>
  <tr>
    <td>
      <label>Ruta 1:</label><button style={{"backgroundColor":"#FFC300"}}></button> 
    </td>
    <td>
      <label>Ruta 2:</label><button style={{"backgroundColor":"#DB29C6"}}></button>
    </td>
    <td>
      <label>Ruta 3:</label><button style={{"backgroundColor":"#D32020"}}></button> 
    </td>
    <td>
      <label>Ruta 4:</label><button style={{"backgroundColor":"#2aa21a"}}></button>
    </td>
    <td>
      <label>Ruta 5:</label><button style={{"backgroundColor":"#3fa5c4"}}></button>
      </td>
    <td>
      <label>Ruta 6:</label><button style={{"backgroundColor":"#b68739"}}></button>      
    </td>
    <td>
      <label>Ruta 7:</label><button style={{"backgroundColor":"#1E58D3"}}></button>
    </td>
  </tr>
</table>
</div>

<div className='simbologiadia'  style={test2===true?{display:"block"}:{display:"none"}}>      
<table>
  <tr>
    <td>
      <label>Lunes:</label><button style={{"backgroundColor":"#FFC300"}}></button> 
    </td>
    <td>
      <label>Martes:</label><button style={{"backgroundColor":"#DB29C6"}}></button>
    </td>
    <td>
      <label>Miercoles:</label><button style={{"backgroundColor":"#D32020"}}></button>
    </td>
    <td>
      <label>Jueves:</label><button style={{"backgroundColor":"#27A615"}}></button>
    </td>
    <td>
      <label>Viernes:</label><button style={{"backgroundColor":"#3AA6C1"}}></button>
    </td>
    <td>
      <label>Sabado:</label><button style={{"backgroundColor":"#B78332"}}></button>      
    </td>
    <td>
      <label>Domingo:</label><button style={{"backgroundColor":"#1E58D3"}}></button>
    </td>
  </tr>
</table>
</div>


  <div style={{ width: '100%', height: '100%', margin: "0 auto" }}>
    {
      bancar===true?
      <WrappedMap2
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyBOh51zw12N4kb7yDY4pp-6gM7xVaFterc`}
        loadingElement={<div style={{ height: '100%'}} />}
        containerElement={<div style={{ height: '100%' }} />}
        mapElement={<div style={{ height: '100%' }} />}
        datamap={datamap}
        verticespoligono={verticespoligono}
        poligonomapa={poligonomapa}
        bandera={bandera}
        setbandera={setbandera}
        bandera2={bandera2}
      />:

      <WrappedMap 
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyBOh51zw12N4kb7yDY4pp-6gM7xVaFterc`}
        loadingElement={<div style={{ height: '100%'}} />}
        containerElement={<div style={{ height: '100%' }} />}
        mapElement={<div style={{ height: '100%' }} />}
        datamap={datamap}
        setverticespoligono={setverticespoligono}
        verticespoligono={verticespoligono}
        car={bancar}
        poligonomapa={poligonomapa}
        bandera={bandera}
        setbandera={setbandera}
        bandera2={bandera2}
      />
    }
      
    </div> 
</div>
<div className='tablaRgps' style={mapatabla===true?{"display":"none"}:{"display":"block"}}>
    
  <MaterialReactTable
        columns={columns}
        data={datamap}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        enableRowSelection
        onStateChange={handleStateChange}
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            settotal_rows(table);
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderToolbarInternalActions={({ table }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
              table={table} 
              file_name={"Consultar Punto de Venta"}
              update_identifiers={update_identifiers}
              settotal_rows={settotal_rows}
            />
          )
         }
      />   
    
    
    
    
    
    
    {/* <StickyTable>
      <TableContainer
        paginado={"Puntos de venta"}
        consultas={"Puntos de venta"}
        exportar={true}
        columns={columns}
        data={datamap}
        style={{ overflowx: 10 }}
      />
    </StickyTable> */}
</div>  
</div>
</>
  
  
  )
}