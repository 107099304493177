import axios from "axios";
import config from "../../config";

const urlunil = `${config?.baseURLUNILEVER}`;



export const get_packages = async (fi, ff) => {
  const url = `${urlunil}/development/packages`;
  try {
    const response = await axios.get(url, {
      params: {
        di: fi,
        df: ff,
      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const dowload_packages = async (id) => {
  const url = `${urlunil}/development/packages/${id}/download`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const send_packages = async (id) => {
  const url = `${urlunil}/development/packages/${id}/send`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const send_packages_bonus = async (id) => {
  const url = `${urlunil}/development/bonification_packages/${id}`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_routes = async (fi, ff) => {
  const url = `${urlunil}/development/routes`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_store = async (fi, ff) => {
  const url = `${urlunil}/development/stores`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_cabinets = async (fi, ff) => {
  const url = `${urlunil}/development/cabinets`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};



export const get_sales = async (fi, ff,route,saletype) => {
  const url = `${urlunil}/development/sales`;
  try {
    const response = await axios.get(url, {
      params: {
        di: fi,
        df: ff,
        route: route==="TODAS"?null:route ,
        saletype: saletype 
      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const get_sales_tickets = async (tickets) => {
  const url = `${urlunil}/development/sales`;
  try {
    const response = await axios.get(url, {
      params: {
        tickets: tickets 
      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_bonus = async (id) => {
  const url = `${urlunil}/development/bonification_packages/${id}`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const get_bonus_report = async (fi, ff, job, tick) => {
  const url = `https://ihl3p5s801.execute-api.us-east-1.amazonaws.com/dev/bonuses`;
  try {
    const response = await axios.get(url, {
      params: {
        start_date: fi,
        end_date: ff, 
        job: job == "TODOS"? null : job,
        ticket: tick === "" || tick === null ? null : tick
      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};



export const get_bonus_images = async (id) => {
  const url = `https://ja5p83t0tc.execute-api.us-east-1.amazonaws.com/development/?directory=${id}`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};