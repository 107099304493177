/* global google */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { refresh_token } from '../../main_components/tokens/tokenrefresh';
import { get_bonus_report } from '../../services/unilever/unilever';
import { BREADCRUMBS } from '../../main_components/pagination/breadcrumbs';
import NotificationSystem from 'react-notification-system';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import MODAL_TABLE from '../../main_components/modal/modal_Table';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from '../../main_components/methods_v2/export';
import SEARCH_FILTERS from './tools/shearch_filter';
import { formattedCurrentDate, formattedPreviousDate } from '../../main_components/date/day';
import dayjs from 'dayjs';
import { update_null_identifiers } from '../../main_components/methods_v2/auxiliar_funtion';
import { CustomDialog } from 'react-st-modal';
import { Box } from '@mui/material';
import lupa from "../../imgComponents/lupa.png";
import imglogo from "../../imgComponents/images.png";
import { DETAILS } from './tools/details';
import { GALLERY_EVIDENCE } from './tools/gallery';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import TocIcon from '@mui/icons-material/Toc';
import { SEARCH_FILTER_WEB_MOVIL } from '../../main_components/methods_v2/search_filter_web_movil';
import { calculate_total } from '../../main_components/methods_v2/auxiliary_functions';
import axios from "axios";

// const CLIENT_ID = '301722987834-pec5a9vlojn6redpcneblrgd2ckqdj05.apps.googleusercontent.com';
const CLIENT_ID = '476966741137-uv2ep99l90b4v7h8d7dlb24mticr4929.apps.googleusercontent.com';


const CLIENT_SECRET = "JhDQNqt9CC0Fvw_8JZ3aZCBg";
const TOKEN_URL = 'https://oauth2.googleapis.com/token';
const URL_API_DRIVE = "https://www.googleapis.com/drive/v3/files";


export const REPORT_BONICACIONES = () => {

    const [data, setData]=useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [modalT, setmodalT] = useState(false);
    const [menssage, setmenssage] = useState("");
    const [modalGeneral, setmodalGeneral] = useState(false);
    const [total_rows, settotal_rows] = useState("");
    const notificationSystemRef = useRef();  
    const [ff, setff] = useState(dayjs(formattedCurrentDate));
    const [fi, setfi] = useState(dayjs(formattedPreviousDate));
    const [job, setjob]=useState("TODOS");
    const [tick, settick]=useState("");
    const [totalreq, settotalreq]=useState([]);
    const [totalacep, settotalacep]=useState([]);
    
    const handlesubmit = async()=>{

      if(tick==="" || tick?.trim()===""){
        settick(null);
      }

      setData([]);
      settotalreq([]);

      setIsLoading(true);
      try{
            const refres_token = await refresh_token();
            if(refres_token){
                setTimeout(async() => {
                    try{
                        setopenmini(false);
                        const d = await get_bonus_report(fi.format('YYYY-MM-DD'), ff.format('YYYY-MM-DD'), job, tick);
                        const ndt = update_null_identifiers(d?.data?.items, update_identifiers);
                        settotalreq(ndt?.map(obj=>obj.requesting_total).filter(d=>d!==null));
                        settotalacep(ndt?.map(obj=>obj.accept_total).filter(d=>d!==null))
                        setData(ndt);
                        settick("");
                        setIsLoading(false);
                    }catch(err){
                        console.log(err);
                        
                        setIsLoading(false);
                        setData([]);
                    }
                }, 1000);
            }
        }catch(err){
            console.log(err);
        }
    }
    const handleStateChange = (newState) => {
        const filteredRowsCount = newState.filteredRowModel
        settotal_rows(filteredRowsCount);
      };

      
  const update_identifiers =
  [
    { job: 'string' },
    { point_of_sale: 'string' },
    { ticket: 'String' },
    { timestamp_requesting: 'string' },
    { reason: 'string' },
    { code_number: 'string' },
    { lote: 'string' },
    { requesting_total: 'string' },
    { requires_authorization: 'string'},
    { status_bonus: 'string' }


  ];

  const [authorized1, setAuthorized1] = useState(false)
  const [authorized2, setAuthorized2] = useState(false)
  const [accessToken2, setAccessToken2] = useState({})
  const [accessToken1, setAccessToken1] = useState({})
  const [sender_email, setSenderemail] = useState('')
  
    
  const columns = useMemo(
    () => [
      {
        id: "actions",
        header: "Acciones",
        accessorKey: "actions",
        enableFilters: false,
        enableEditing: false,
        enableRowOrdering: false,
        enableGrouping: false,
        enableColumnDragging: false,
        enableColumnActions: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilterModes: false,
        enableColumnFilter: false,
        size: 120,
        Cell: ({ cell }) => {  
        return( 
          <div className='icons'>
                    <img
                        alt=""
                        width={"25px"}
                        src={lupa}
                        title="Ver Detalles"
                        className=""
                        onClick={ async () =>{
                          await CustomDialog(
                              <DETAILS
                                  row={cell?.row} 
                              />,
                          {
                              className: "custom-modal-3",
                              title: "Ver Detalles",
                              showCloseIcon: true,
                          }
                        );
                      }}
                    ></img>
                    <img
                        alt=""
                        width={"25px"}
                        src={imglogo}
                        title="Evidencia"
                        className=""
                        onClick={ async () =>{
                          await CustomDialog(
                            <GALLERY_EVIDENCE
                                row={cell?.row} 
                            />,
                          {
                              className: "custom-modal-3",
                              title: "Evidencia de bonificación",
                              showCloseIcon: true,
                          }
                        );
                      }}
                    ></img>
            </div >
          )
        },
        Footer: () => (
          <div>
            <div>Sub Total: </div>
            <div>Gran Total: </div>
          </div>
        ), 
      },
      {
        id: "job",
        header: "Puesto",
        accessorKey: "job",
        muiTableBodyCellProps: {
          align: 'left',
        }
      },
      {
        id: "point_of_sale",
        header: "Punto de Venta",
        accessorKey: "point_of_sale",
        muiTableBodyCellProps: {
          align: 'left',
        }
      },
      {
        id: "ticket",
        header: "Ticket",
        accessorKey: "ticket",
        muiTableBodyCellProps: {
          align: 'center',
        }
      },
      {
        id: "timestamp_requesting",
        header: "Fecha",
        accessorKey: "timestamp_requesting",
        muiTableBodyCellProps:{
          align: "left"
        }
      },
      {
        id: "reason",
        header: "Motivo",
        accessorKey: "reason",
        muiTableBodyCellProps:{
          align: "left"
        }
      },
      {
        id: "code_number",
        header: "Código de caja",
        accessorKey: "code_number",
        accessorFn: (d)=>{
          return d?.code_number === "None" ? "No aplica": d?.code_number
        },
        muiTableBodyCellProps: {
          align: 'center',
        }
      },
      {
        id: "lote",
        header: "Lote",
        accessorKey: "lote",
        muiTableBodyCellProps: {
          align: 'center',
        }
      },
      {
        id: "requesting_total",
        header: "Total Solicitado",
        accessorKey: "requesting_total",
        muiTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => {
          const value = cell.getValue();
          const numericValue = value !== undefined ? parseFloat(value) : 0;
        return(
          <Box>
            {
              numericValue.toLocaleString('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        )},
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
           align: 'right',
        },
        Footer: (d) => {

          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.requesting_total)
          const numericSubtotal = subtotal?.map(value => {
            if(value=== undefined | value === null || value=== ""){
              return 0.0
            }else{
              const number = parseFloat(value);
              return Math.round(number * 100) / 100;
            }  
          });
          const totalreqdata = totalreq?.map(value =>{
            if(value===undefined || value===null || value === ""){
              return 0.0
            }else{
              const numb = parseFloat(value);
              return Math.round(numb * 100) / 100;
            }
          })
          return (
            <div style={{ textAlign: "right", width: "100%" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(numericSubtotal.length === 0 ? 0.0 : calculate_total(numericSubtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(totalreqdata===null || totalreqdata ===undefined ? 0.0 : calculate_total(totalreqdata))
              }</div>
            </div>
          )
        },
      },
      {
        id: "accept_total",
        header: "Total Aceptado",
        accessorKey: "accept_total",
        muiTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => {
          const value = cell.getValue();
          const numericValue = value !== undefined ? parseFloat(value) : 0;
        return(
          <Box>
            {
              numericValue.toLocaleString('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        )},
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
           align: 'right',
        },
        Footer: (d) => {

          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.accept_total)
          const numericSubtotal = subtotal?.map(value => {
            if(value=== undefined | value === null || value=== ""){
              return 0.0
            }else{
              const number = parseFloat(value);
              return Math.round(number * 100) / 100;
            }
          });
          const totalacepdata = totalacep?.map(value =>{
            if(value===undefined || value===null || value === ""){
              return 0.0
            }else{
              const numb = parseFloat(value);
              return Math.round(numb * 100) / 100;
            }
          })
          return (
            <div style={{ textAlign: "right", width: "100%" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(numericSubtotal.length === 0 ? 0.0 : calculate_total(numericSubtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(totalacepdata===null || totalacepdata ===undefined ? 0.0 : calculate_total(totalacepdata))
              }</div>
            </div>
          )
        },
      },
      {
        id: "requires_authorization",
        header: "Autorización",
        accessorKey: "requires_authorization",
        accessorFn: (d)=>{
          return d?.requires_authorization === true ? "✔️": "❌"
        },  
        muiTableBodyCellProps: {
          align: 'center',
        }
      },
      {
        id: "status_bonus",
        header: "Estado",
        accessorKey: "status_bonus",
        accessorFn: (d)=>{
          return d?.status_bonus ? d?.status_bonus : "NO APLICA"
        },
        muiTableBodyCellProps: {
          align: 'center',
        }
      },
     
    ],
    [totalreq, totalacep]
  );

  const [minimenu, setminimenu]=useState(false);
  const [openmini, setopenmini]=useState(false);
   useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 480) {
          console.log('Screen width is less than 480px');
          setminimenu(true);
        } else {
          setminimenu(false);
        }
      };
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    
    
    const authorizeFirstAccount = async () => {
      try { 
        client1.requestCode(); 
      } catch (error) {
         console.error('Error durante la autorización:', error); 
      }
    };


    const client1 = google.accounts.oauth2.initCodeClient({
      client_id: CLIENT_ID,
      scope: 'https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/spreadsheets', 
      callback: async (tokenResponse)=>{ 
  
        if(tokenResponse) 
            await getTokens( 1, tokenResponse.code)   
        else 
          console.log("No trae nada")
      }, 
    });
  
    
  const getTokens = async (client= 0, code = "") => {

    const data = {
      code: code,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      redirect_uri: 'http://localhost:3000',
      grant_type: 'authorization_code',
    };  


    console.log(data);

    try {
      const response = await axios.post(TOKEN_URL, data)
      const expiresIn = response.data.expires_in;
      const currentTime = Date.now();
      const tokenExpiry = new Date(currentTime + expiresIn * 1000);

      console.log(response);

      const credentials = {
        access_token: response.data.access_token,
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        refresh_token: response.data.refresh_token,
        token_expiry: tokenExpiry,
        id_token: response.data.id_token,
        id_token_jwt: null,
        token_response: {
          access_token: response.data.access_token,
          expires_in: response.data.expires_in, 
          refresh_token: response.data.refresh_token,
          scope: "https://www.googleapis.com/auth/drive",
          token_type: response.data.token_type
        },
        scopes: ["https://www.googleapis.com/auth/drive"],
        token_info_uri: "https://oauth2.googleapis.com/tokeninfo",
        invalid: false,
        _class: "OAuth2Credentials",  
        _module: "oauth2client.client"
      }

      const email = await getUserInfo(response.data.access_token)
      console.log("EMAIL", email)

        if(client === 1 ){
          setAuthorized1(true)
          setAccessToken1(credentials)
          setSenderemail(email.email)
        } else if( client === 2){
          setAuthorized2(true)
          setAccessToken2(credentials)
        }

      return Promise.resolve(JSON.stringify(credentials));
    } 
    catch(error){
      console.log("Error al obtener tokens")
      return Promise.reject(error);
    }
  }

  

  const getUserInfo = async (accessToken) => {
    try {
      const response = await axios.get(
        'https://www.googleapis.com/oauth2/v2/userinfo',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Error al obtener la información del usuario', error);
      throw error;
    }
  };



    const filters_custom = (
      <SEARCH_FILTERS
      fi={fi}
      setfi={setfi}
      ff={ff}
      setff={setff}
      job={job}
      setjob={setjob}
      handlesubmit={handlesubmit}
      authorizeFirstAccount={authorizeFirstAccount}
      tick={tick}
      settick={settick}
      mini={true}
></SEARCH_FILTERS>
    )


 return (
    <>
        <BREADCRUMBS niveles={
            [
             { label: "HISTORIAL DE BONIFICACIONES", path: null },
            ]
        }
            cards_dashboard={true}
            total_rows={total_rows !== "" ? total_rows : 0}
            filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
            visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
            total={data?.length}
      ></BREADCRUMBS>
        <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
        <MODAL_TABLE
            open={true}
            message={menssage}
            modalGeneral={modalGeneral}
            modalT={modalT}
        ></MODAL_TABLE>
        <SEARCH_FILTER_WEB_MOVIL
          minimenu={minimenu} 
          setminimenu={setminimenu}
          openmini={openmini} 
          setopenmini={setopenmini}
          filterMovComponent={filters_custom}
          renderTopToolbarCustomActionsFilters={true}
        ></SEARCH_FILTER_WEB_MOVIL> 
        
        <div className='new_table_v2'>
        <MaterialReactTable
              columns={columns}
              data={data}
              enableGrouping
              enableFacetedValues
              enableStickyHeader
              onStateChange={handleStateChange}
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              // enableRowActions
              enableRowSelection
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              enableColumnDragging={false}
              initialState={{ showGlobalFilter: true, showColumnFilters: true }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  enableHiding: true,
                  size: 80
                },
                'mrt-row-actions': {
                  enableHiding: true,
                  size: 80
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
              renderTopToolbarCustomActions={({ }) =>{
                if(minimenu===false){
                  return (
                  <SEARCH_FILTERS
                    fi={fi}
                    setfi={setfi}
                    ff={ff}
                    setff={setff}
                    job={job}
                    setjob={setjob}
                    handlesubmit={handlesubmit}
                    authorizeFirstAccount={authorizeFirstAccount}
                    tick={tick}
                    settick={settick}
                  ></SEARCH_FILTERS>
                
                )
              }}}
              renderToolbarInternalActions={({ table, index }) => (
                 <RENDER_TOOLBAR_INTERNAL_ACTIONS
                   table={table}
                   file_name={"Reporte de Bonificaciones"}
                   update_identifiers={update_identifiers}
                   settotal_rows={settotal_rows}
                   total_rows={total_rows !== "" ? total_rows : 0}
                 />
               )
               }
            />
        </div>
    </>
  )
}
