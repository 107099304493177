import { useState, useEffect } from "react";
import { useDialog } from "react-st-modal";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CircularProgress from '@mui/material/CircularProgress';

import {
  Box,
  Button,
  MenuItem,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import { formattedCurrentDate, formattedPreviousDate, formattedPreviousDate_f, formattedCurrentDate_f } from "../../../../main_components/date/day";

// Componentes
import MODAL_TABLE from "../../../../main_components/modal/modal_Table";
import { get_routes } from "../../../../services/cashclosing/cashclosing";

// Estilos

const SHEARCH_SALES = (props) => {
  const dialog = useDialog();
  const [fi, setfi] = useState(dayjs(formattedCurrentDate));
  const [ff, setff] = useState(dayjs(formattedCurrentDate));
  const [modalT] = useState(false);
  const [select_route, setselect_route] = useState("");
  const [select_vendor, setselect_vendor] = useState("");
  const [select_vendor_name, setselect_vendor_name] = useState("");
  const [select_warehouse, setselect_warehouse] = useState("");
  const [routes, setroutes] = useState([]);
  const [vendor, setvendor] = useState([]);
  const [loading, setLoading] = useState(false);

  const handle_router = async () => {
    try {
      setLoading(true);
      const dt = await get_routes();
      setroutes(dt.data);
    } catch (error) {
      setroutes([]);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  };

  const handle_filter_vendor = (event) => {
    const filter_vendors = routes?.filter((route) => route.route === event?.target?.value).map((user) => user.users);
    setselect_route(event?.target?.value);
    setvendor(filter_vendors[0]);
    setselect_vendor(filter_vendors[0][0].id);
    setselect_warehouse(filter_vendors[0][0].id_warehouse_fk);
    setselect_vendor_name(filter_vendors[0][0].fullusername);
  };

  const handle_select_filter = (event) => {
    setselect_vendor(event?.target?.value);
    const filter_vendors = vendor?.filter((v) => v.id === event?.target?.value).map((d) => d.id_warehouse_fk)[0];
    const filter_vendors_name = vendor?.filter((v) => v.id === event?.target?.value).map((d) => d.fullusername)[0];
    setselect_vendor_name(filter_vendors_name);
    setselect_warehouse(filter_vendors);
  };

  const handleSubmit = (event) => {
    sessionStorage.setItem("dateiSale", fi.format('YYYY-MM-DD'));
    sessionStorage.setItem("datefSale", ff.format('YYYY-MM-DD'));
    sessionStorage.setItem("routeSale", select_route);
    sessionStorage.setItem("selectVendro", select_vendor);
    sessionStorage.setItem("selectwarehouse", select_warehouse);
    sessionStorage.setItem("datawarehose", "");
    sessionStorage.setItem("name_vendor", select_vendor_name);


    window.location = "/cashclosing_sale_v2"
    console.log(vendor);
    console.log(fi.format('YYYY-MM-DD'));
    console.log(ff.format('YYYY-MM-DD'));
    console.log(select_route);
    console.log(select_vendor);
    console.log(select_warehouse);
    console.log(select_vendor_name);

  };

  useEffect(() => {
    handle_router();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (routes?.length > 0) {
      setselect_route(routes[0]?.route);
      setvendor(routes[0]?.users);
      setselect_vendor(routes[0]?.users[0]?.id);
      setselect_warehouse(routes[0]?.users[0]?.id_warehouse_fk);
      setselect_vendor_name(routes[0]?.users[0]?.fullusername);
    }
  }, [routes]);

  return (
    <div>
      <MODAL_TABLE
        open={true}
        message={""}
        modalGeneral={false}
        modalT={modalT}
      ></MODAL_TABLE>

      <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start', margin: "10px" }}>

        <LocalizationProvider dateAdapter={AdapterDayjs} >
          <DatePicker
            sx={{ width: 150 }}
            label="Fecha Inicial"
            value={fi}
            onChange={(event) => setfi(event)}
            format="DD/MM/YYYY"
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} >
          <DatePicker
            sx={{ width: 150 }}
            label="Fecha Final"
            value={ff}
            onChange={(event) => setff(event)}
            format="DD/MM/YYYY"
          />
        </LocalizationProvider>
        <FormControl sx={{ width: 60 }}>
          <InputLabel id="demo-simple-select-required-label">Ruta</InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            sx={{ height: '35px' }}
            value={select_route}
            onChange={(event) => handle_filter_vendor(event)}
          >
            {loading && <MenuItem disabled><CircularProgress size={20} thickness={4} /></MenuItem>}
            {!loading && routes.map((route) => (
              <MenuItem key={route.route} value={route.route}>
                {route.route}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: 200 }}>
          <InputLabel id="demo-simple-select-required-label">Usuario</InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            value={select_vendor}
            sx={{ height: '35px' }}
            onChange={(event) => handle_select_filter(event)}
          >
            {loading && <MenuItem disabled><CircularProgress size={20} thickness={4} /></MenuItem>}
            {!loading && vendor?.map((sale) => (
              <MenuItem key={sale.id} value={sale.id}>
                {sale.fullusername}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl required sx={{ width: 60 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Button
              variant="contained"
              title='CONSULTAR'
              sx={{ height: '35px', borderRadius: "15px", marginRight: "5px", width: "50%" }}
              onClick={(event) => handleSubmit(event)}
            >
              <ManageSearchIcon></ManageSearchIcon>
            </Button>
          </Box>
        </FormControl>
      </Box>
    </div>
  );
};

export default SHEARCH_SALES;
