//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
//COMPONENTES
import TableContainer from "../../../Table_Container_Tax";
import SubTable from "../../detail_expense/datail";
import TableDetailGrupExpensePolicy from "./detail_group_policy";
import { base64toBlob } from "../../../../main_components/donwloadfuncion/download";
import {
  download_expense,
  get_expenses_groups,
} from "../../../../services/expenses/expenses";
import MODAL_TABLE from "../../../../main_components/modal/modal_Table";
import { BREADCRUMBS } from "../../../../main_components/pagination/breadcrumbs";
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";
//ESTILOS
import "../../../expense.css";
//IMAGENES/ICONOS
import PDF from "../../../../imgComponents/pdf.png";
import FormControlLabel from "@mui/material/FormControlLabel";
import { MaterialUISwitch } from "../../../../main_components/material_switch/switch";

const EXPENSES_GROUP_DETAIL = ({ idGrupExpense }) => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(true);
  const [menssage, setMenssage] = useState("");

  const [checkedUpdate, setcheckedUpdate] = React.useState(true);

  const handlecheckedUpdate = (event) => {
    setcheckedUpdate(event.target.checked);
  };

  const handleSubmit = async (event) => {
    event?.preventDefault();

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setOpen(true);
        setMenssage("Consultando grupo de gastos...");
        try {
          var dta = await get_expenses_groups(
            sessionStorage.getItem("IdGrupExpense")
          );
          const data = dta?.data?.sort((a, b) =>
            a.id < b.id ? 1 : a.id > b.id ? -1 : 0
          );
          console.log(data);
          setData(data);
          setOpen(false);
          setMenssage("");
        } catch (err) {
          console.log("error", JSON.stringify(err.response));
          setData([]);
          setOpen(false);
          setMenssage("");
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handle_download = async (id, file_name) => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setOpen(true);
        try {
          var dta = await download_expense(id);
          const data = dta.data;
          var blob = base64toBlob(data.file);
          const blobUrl = URL.createObjectURL(blob);
          const downloadLink = document.createElement("a");
          downloadLink.href = blobUrl;
          var arr = file_name.split("/");
          downloadLink.download = arr[4] + ".pdf";
          downloadLink.click();
          setOpen(false);
        } catch (err) {
          setOpen(true);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  
  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Ac.",
        Cell: ({ valor, row }) => {
          return (
            <div>
              <img
                title="Descargar pdf"
                alt=""
                src={PDF}
                onClick={() =>
                  handle_download(row.original.id, row.original.file_name)
                }
                
              ></img>
            </div>
          );
        },
      },

      {
        Header: "No.",
        accessor: "spend_folio_number",
      },
      {
        Header: "F. Documento",
        accessor: "date",
      },
      {
        Header: "Concepto",
        accessor: "concept",
      },
      {
        Header: "Descripción",
        accessor: "description",
      },
      {
        Header: "Importe",
        accessor: (d) => {
          return d.ammount
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.ammount)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.ammount);
        },
      },
      {
        Header: "Usuario",
        accessor: "user_name",
      },
      {
        Header: "Origen",
        accessor: "origin",
      },

      { Header: "Proveedor", accessor: "business_partner_name" },
    ],
    []
  );

  return (
    <div >
      <MODAL_TABLE open={true} modalT={open}></MODAL_TABLE>

      <BREADCRUMBS
        niveles={[
          { label: "REPORTE DE GASTOS", path: "/expensesReport" },
          { label: "REPORTE DE GASTOS AGRUPADOS", path: null },
        ]}
      ></BREADCRUMBS>
      <div className="switchRegister">
        <FormControlLabel
          control={
            <MaterialUISwitch
              sx={{ m: 1 }}
              onChange={handlecheckedUpdate}
              checked={checkedUpdate}
            />
          }
          label={
            checkedUpdate === true
              ? "Visualizar grupo de gastos"
              : "Ocultar grupo de gastos"
          }
        />
      </div>
      <div style={{ display: checkedUpdate === true ? "none" : "" }}>
        <div>
          <h3> DETALLES DEL GRUPO DE GASTOS</h3>
        </div>
        <br></br>

        <div className="TableReport GrupExpenseDetail">
          <div style={{ overflowX: "auto" }}>
            <TableContainer
              visible={false}
              exportEx={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              renderRowSubComponent={(row) => {
                return (
                  <div style={{ padding: "20px" }}>
                    <SubTable verData={true} />
                  </div>
                );
              }}
            />
          </div>
        </div>
      </div>

      <TableDetailGrupExpensePolicy></TableDetailGrupExpensePolicy>
    </div>
  );
};

export default EXPENSES_GROUP_DETAIL;
