//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
//COMPONENTES
import TableContainer from "../../../Table_Container_Tax";
import SubTable from "../../detail_expense/datail";
import CREATE_DETAIL_POLICY from "./tools/create_detail_policy";
import UPDATE_DETAIL_POLICY from "./tools/update_detail_policy";
import {
  get_expenses_groups_detail_policy,
  delete_expenses_groups_detail_policy,
} from "../../../../services/expenses/expenses";
import MODAL_TABLE from "../../../../main_components/modal/modal_Table";
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";
//ESTILOS
import "../../../expense.css";
//IMAGENES/ICONOS
import SAVE from "../../../../imgComponents/save.png";
import DELETE from "../../../../imgComponents/borrar.png";
import CREATE from "../../../../imgComponents/create.png";

const TableDetailGrupExpensePolicyDetail = ({
  idGrupExpense,
  codModificable,
}) => {
  console.log(codModificable);

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [menssage, setMenssage] = useState("");
  const [equality, setEquality] = useState("");
  const [success_msg, setSuccess_msg] = useState("none");
  const [error_msg, setError_msg] = useState("none");
  const [Reconsulta, setReconsulta] = useState(false);

  const handleSubmit = async (event) => {
    event?.preventDefault();

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setMenssage("Consultando grupo de gastos...");
        console.log(idGrupExpense);
        setReconsulta(true);

        try {
          var dta = await get_expenses_groups_detail_policy(
            sessionStorage.getItem("IdGrupExpense"),
            sessionStorage.getItem("idPolicy")
          );
          const data = dta?.data;
          var datac = data.filter((d) => d.effect === "CARGO");
          var dataa = data.filter((d) => d.effect !== "CARGO");
          var data2 = datac.sort((a, b) =>
            a.id > b.id ? 1 : a.id < b.id ? -1 : 0
          );
          setData(data2.concat(dataa));
          setOpen(false);
          setMenssage("");
          const cargos = data.filter((item) => item.effect === "CARGO");
          const abonos = data.filter((item) => item.effect === "ABONO");
          const sumCargos = cargos
            .map((cargoSum) => parseFloat(cargoSum.ammount))
            .reduce((previous, current) => {
              return previous + current;
            }, 0);
          const sumAbonos = abonos
            .map((abonoSum) => parseFloat(abonoSum.ammount))
            .reduce((previous, current) => {
              return previous + current;
            }, 0);
          sessionStorage.setItem("totalPoliza", sumAbonos);
          if (sumCargos?.toFixed(2) - sumAbonos?.toFixed(2) === 0) {
            setEquality("Los datos cuadran");
            setSuccess_msg("");
            setError_msg("none");
          } else {
            setEquality("Los datos no cuadran");
            setError_msg("");
            setSuccess_msg("none");
          }
          setReconsulta(false);
        } catch (err) {
          setData([]);
          setOpen(false);
          setReconsulta(false);
          setMenssage("");
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleReconsulta = (reconsultar) => {
    if (reconsultar === true) {
      handleSubmit();
    } else {
      console.log("no se reconsulto");
    }
  };

  const handle_delete_detail_policy = async (id) => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setOpen(true);
        try {
          await delete_expenses_groups_detail_policy(
            sessionStorage.getItem("IdGrupExpense"),
            sessionStorage.getItem("idPolicy"),
            id
          );
          setOpen(false);
          handleSubmit();
        } catch (err) {
          setOpen(false);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (idGrupExpense !== "") {
      handleSubmit();
    } else {
      setTimeout(() => {
        if (idGrupExpense !== "") {
          handleSubmit();
        } else {
          console.log("esta vacio");
        }
      }, 6000);
    }
  }, [idGrupExpense]);

  const columns = useMemo(
    () => [
      {
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {
          return (
            <img
              title="Crear Detalle"
              src={CREATE}
              alt=""
              onClick={async () => {
                const result = await CustomDialog(
                  <CREATE_DETAIL_POLICY
                    codIdPolicy={sessionStorage.getItem("idPolicy")}
                  ></CREATE_DETAIL_POLICY>,
                  {
                    className: "modalTItle",
                    title: "Crear Detale",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );
                // eslint-disable-next-line no-lone-blocks
                {
                  handleReconsulta(result);
                }
              }}
            ></img>
          );
        },
        id: "Acciones",

        Cell: ({ valor, row }) => {
          return (
            <div>
              <img
                title="Editar Detalle"
                src={SAVE}
                alt=""
                onClick={async () => {
                  const result = await CustomDialog(
                    <UPDATE_DETAIL_POLICY
                      codIdPolicy={sessionStorage.getItem("idPolicy")}
                      id_detail={row.original.id}
                      codaccounts={row.original.accounting_account}
                      codconcept={row.original.concept}
                      codamount={row.original.ammount}
                      codeffect={row.original.effect}
                      codidConcept={row.original.id_account}
                      codIdDetail={row.original.id}
                    ></UPDATE_DETAIL_POLICY>,
                    {
                      title: "Editar detalle del gasto",
                      className: "modalTItle",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  // eslint-disable-next-line no-lone-blocks
                  {
                    handleReconsulta(result);
                  }
                }}
                className="centerText, taxes"
              ></img>

              <img
                title="Eliminar Detalle"
                src={DELETE}
                alt=""
                className="centerText, taxes"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar este detalle?",
                    "Eliminar Detalle",
                    "Si",
                    "No"
                  );
                  if (result) {
                    handle_delete_detail_policy(row.original.id);
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
      },

      {
        Header: "Gasto",
        accessor: "gasto",
      },
      {
        Header: "Concepto",
        accessor: "concept",
      },
      {
        Header: "Cuenta",
        accessor: "accounting_account",
      },
      {
        Header: "Importe",
        accessor: (d) => {
          return d.ammount
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.ammount)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.ammount);
        },
      },
      {
        Header: "Efecto",
        accessor: "effect",
      },
      {
        Header: "RFC",
        accessor: "rfc",
      },
      {
        Header: "UUID",
        accessor: "uuid",
      },
    ],
    []
  );

  if (Reconsulta === false) {
    return (
      <div >
        <MODAL_TABLE open={true} modalT={open}></MODAL_TABLE>

        <div>
          <h3>DETALLES DE POLIZA</h3>
        </div>
        <br></br>

        <div className="TableConsultarTodo2">
          <div class="success-msg" style={{ display: success_msg }}>
            <h2>
              <i class="fa fa-check"></i>
              {equality}
            </h2>
          </div>
          <div class="error-msg" style={{ display: error_msg }}>
            <h2>
              <i class="fa fa-times-circle"></i>
              {equality}
            </h2>
          </div>
        </div>

        <div className="TableReport DetailPolicy">
          <div style={{ overflowX: "auto" }}>
            <TableContainer
              exportEx={true}
              columns={columns}
              visible={false}
              data={data}
              style={{ overflowx: 10 }}
              renderRowSubComponent={(row) => {
                return (
                  <div style={{ padding: "20px" }}>
                    <SubTable verData={true} />
                  </div>
                );
              }}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return <div className="loader"></div>;
  }
};

export default TableDetailGrupExpensePolicyDetail;
