//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";

import Box from "@mui/material/Box";

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import GroupSharpIcon from '@mui/icons-material/GroupSharp';
import MonetizationOnSharpIcon from '@mui/icons-material/MonetizationOnSharp';
import IsoSharpIcon from '@mui/icons-material/IsoSharp';
import ProductionQuantityLimitsSharpIcon from '@mui/icons-material/ProductionQuantityLimitsSharp';
//COMPONENTES
import NewDashboardClient from "./client/clients_Dashboard";
import NewDashboardBonus from "./bonus/bonus_Dashboard";
import NewDashboardProducts from "./products/products_Dashboard";
import NewDashboardDetailSales from "./sales/sales_Dasboard";
import NewDashboardNoSale from "./no_sales/no_Sales";
//ESTILOS
import "../components_cfdi/estilo.css";
import "../components_cfdi/estilo.css";
import "../components_cfdi/modal.css";
import "./dashboar.css";
import { BREADCRUMBS } from "../main_components/pagination/breadcrumbs";


const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const TableDashboardCliente = () => {
  const [value, setValue] = React.useState(0);
  const [clients, setclients] = React.useState("");
  const [detailSale, setdetailSale] = React.useState("none");
  const [bonus, setbonus] = React.useState("none");
  const [product, setproduct] = React.useState("none");
  const [noSale, setnoSale] = React.useState("none");



  const handelClient = () =>{
    setclients("");
    setdetailSale("none");
    setbonus("none");
    setproduct("none");
    setnoSale("none");
  }

  const handelDetailSales = () =>{
    setclients("none");
    setdetailSale("");
    setbonus("none");
    setproduct("none");
    setnoSale("none");
  }

  const handelBonus = () =>{
    setclients("none");
    setdetailSale("none");
    setbonus("");
    setproduct("none");
    setnoSale("none");
  }

  const handelProduct = () =>{
    setclients("none");
    setdetailSale("none");
    setbonus("none");
    setproduct("");
    setnoSale("none");
  }

  const handelNoSale = () =>{
    setclients("none");
    setdetailSale("none");
    setbonus("none");
    setproduct("none");
    setnoSale("");
  }

  useEffect(() => {
    setclients(clients);
    setdetailSale(detailSale);
    setbonus(bonus);
    setproduct(product);
    setnoSale(noSale);
  }, [setclients,setdetailSale,setbonus,setproduct,setnoSale]);



  
  return (
    <div >
        <BREADCRUMBS niveles={
      [
        {label:"DETALLES DE VENTAS", path: null}, 
      ]
    }
        new_version={true}
        modul="CONSULTAR DETALLES DE VENTAS V2"
        path="/NewDashboard_v2"  
    ></BREADCRUMBS>

      <Box sx={{ margin: "0 auto" ,width: 500 }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction label="Clientes" icon={<GroupSharpIcon onClick={handelClient} />}  onClick={handelClient}/>
        <BottomNavigationAction label="Detalles de Venta" icon={<MonetizationOnSharpIcon onClick={handelDetailSales}/>} onClick={handelDetailSales}/>
        <BottomNavigationAction label="Detalles de Bonificación" icon={<IsoSharpIcon onClick={handelBonus} />} onClick={handelBonus}/>
        <BottomNavigationAction label="Productos" icon={<ProductionQuantityLimitsSharpIcon onClick={handelProduct} />} onClick={handelProduct}/>
        <BottomNavigationAction label="Puntos de Venta Sin Venta" icon={<LocationOnIcon  onClick={handelNoSale} />} onClick={handelNoSale}/>

      </BottomNavigation>
    </Box>

    <div  style={{display:clients}}>
    <NewDashboardClient></NewDashboardClient>
    </div>
    <div  style={{display:detailSale}}>
    <NewDashboardDetailSales></NewDashboardDetailSales>
    </div>
    <div  style={{display:bonus}}>
    <NewDashboardBonus></NewDashboardBonus>
    </div>
    <div  style={{display:product}}>
    <NewDashboardProducts></NewDashboardProducts>
    </div>
    <div  style={{display:noSale}}>
    <NewDashboardNoSale></NewDashboardNoSale>
    </div>

    </div>
  );
};

export default TableDashboardCliente;
