//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
//import TableContainer from "./TableContainerReport";

import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';

//ESTILOS
import "../../components_cfdi/estilo.css";
import '../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import "./routes.css";
import TextField from "@mui/material/TextField";
import { Bar,XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, Brush, ReferenceLine, Line, ComposedChart } from 'recharts';
import { Checkbox } from "@material-ui/core";
import DetailSales from "./DetaIlSales";
import { CustomDialog } from "react-st-modal";
import TableGoal from "./componentgoals/TableGoal";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { get_goals, get_sales_by_route_all, get_sales_by_route_ind } from "../../services/reports/reports";
import { get_route_list } from "../../services/businesspartners/businesspartners";  
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { Box } from "@mui/material";
import { calculate_total } from "../../main_components/methods_v2/auxiliary_functions";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import TableGoal_V2 from "./componentgoals/TableGoal_v2";


const TableRouteSalesReport = () => { 

  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [datagrap, setdatagrap]=useState([]);
  const [datatotales, setdatatotales]=useState([]);
  const [datameta, setdatameta]=useState([]);

  const [my, setmy]=useState(null);
  const [open, setopen]=useState(false);
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);  

  const [nameroute, setnameroute]=useState("vacio");
  const [fi, setfi]=useState(null);
  const [ff, setff]=useState(null);
  const [routedata, setroutedata]=useState(null);
  const [totales, settotales]=useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [actualizacionmeta, setactualizacionmeta]=useState(false);
  const [banderafiltro, setbanderafiltro]=useState(false);
  const [nuevafecha, setnuevafecha]=useState(false);
  const [total_rows, settotal_rows] = useState("");

  const [gtotalventa, setgtotalventa]=useState([]);
  const [gboni, setgboni]=useState([]);
  const [gdev, setgdev]=useState([]);
  const [gtotal, setgtotal]=useState([]);


useEffect(()=>{
    handleroute();
 },[]);


function alertas(msj,status){

  if(status==true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}
const handleSubmit = () => {
 
console.log(nameroute);
console.log(ff);

 if(fi===null || ff===null || fi==='' || ff===''){
  alertas("Falta asignar Rango de Fechas", false);
  return null
 }
 if(nameroute==="vacio"){
    setbanderafiltro(false);
    routeall();
  }else{
    setbanderafiltro(true);
    routeind();
  }
};
const routeind = async () => {

  setfiltro1(false);
  setfiltro2(false);
  setfiltro3(false);
  setfiltro4(false);
  setfiltro5(false);
  setfiltro6(false);
  setfiltro7(true);
  setfiltrometas(false);
  
  setmodalT(true);
  setopen(true);
  setData([]);
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      try{
        var d = await get_sales_by_route_ind(nameroute, fi, ff);
        handlefiltro1(d ===null ? [] : d.data.sales);
        handletotales(d === null ? [] : d.data.sales);
        settotales(d === null ? [] : d.data.total);
        setData(d === null ? [] : d.data.sales);  
        setopen(false);
      setmodalGeneral(false);
      setmodalT(false);  
      }catch(err){
        console.log(err);
        setData([]);
        setopen(false);
        setmodalGeneral(false);
        setmodalT(false);
      }
    }else{}   
    } catch (err) {
    console.log(err);
  }
}
const routeall= async ()=>{
  
  var ttt = await tendencia(fi);
  var tt2 = await countDaysWithoutSundays(fi, ff);
  
  const formattedDate = ff.replace(/-/g, '/');
  const date = new Date(formattedDate);
  var año = date.getFullYear();
  var mes = date.getMonth()+1;
  setmy(año+"-"+(mes<10?"0"+mes:mes));
 
  setfiltro1(false);
  setfiltro2(false);
  setfiltro3(false);
  setfiltro4(false);
  setfiltro5(false);
  setfiltro6(false);
  setfiltro7(true);

  if(actualizacionmeta===true){

  }else{
    setmodalT(true);  
    setfiltrometas(false);
  }    
  setopen(true);
  setData([]);  

  try {
    var d = await get_sales_by_route_all(fi, ff);
    const sortedRoutes = d === null ? [] :[...d.data.routes].sort((a, b) => parseInt(a.route) - parseInt(b.route));
        
    //consulta las metas
    var datam = await handlemetas(año, mes);
    console.log(datam);
    setData(d === null ? [] : sortedRoutes);
    analisismetas(d === null ? [] : datam, sortedRoutes, ttt, tt2);
    handletotales(d === null ? [] : sortedRoutes);

  } catch (error) {
    console.log(error);
    setData([]);
    setopen(false);
    setmodalGeneral(false);
    setmodalT(false);  
  } finally {
    setactualizacionmeta(false);
    setopen(false);
    setmodalGeneral(false);
    setmodalT(false);  
  }
}

const tendencia = (fi)=> {
  
  const formattedDate = fi.replace(/-/g, '/');
  const date = new Date(formattedDate);
  const year = date.getFullYear();
  const month = date.getMonth();
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  let count = 0;
  for (let day = 1; day <= daysInMonth; day++) {
    const currentDate = new Date(year, month, day);
    if (currentDate.getDay() !== 0) {
      count++;
    }
  }
  return count; 
  //setcounttendencia(count);
}
function countDaysWithoutSundays(startDateStr, endDateStr) {
  const fi = startDateStr.replace(/-/g, '/');
  const ff = endDateStr.replace(/-/g, '/');
  const startDate = new Date(fi);
  const endDate = new Date(ff);
  let numDays = 0;
  for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
    if (d.getDay() !== 0) { // 0 = Sunday
      numDays++;
    }
  }
  return numDays;
}

const handleroute = async () => {
  try {
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          var d = await get_route_list();
          setroutedata(d === null ? [] : d);  
        }catch(err){
          console.log(err);
          alertas("error al obtener data de rutas", false);
        }
      }, 1000);
    }else{}
  } catch (err) {
    console.log(err);
  }
}
const handlemetas = async (year, month)=>{
  try {
    var d = await get_goals(year, month);
    return d===null?[]:d.data;          
  } catch (error) {
    console.log(error);
    return [];
  }
}


const [maxgrapmeta, setmaxgrapmeta]=useState(0.0);
const [maxgraptotal, setmaxgraptotal]=useState(0.0);
const [maxtendencia, setmaxtendencia]=useState(0.0);

const analisismetas=(datam, d, ttt, tt2)=>{
  const result = d.map(item => {
   const metaItem = datam.find(meta => meta.route === item.route);
    return metaItem ? { ...item, tendencia: (item.total/tt2)*(ttt), goal: metaItem.goal, porcentaje: (item.total / metaItem.goal)*100} : { ...item, tendencia: (item.total/tt2)*(ttt)};
  });
  console.log(result);
  setdatagrap(result);
  const filtro1 = result.filter(item => !isNaN(item.goal));
  setmaxgrapmeta(Math.max(...filtro1.map((item) => item.goal)));  
  const filtro2 = result.filter(item => !isNaN(item.total));
  setmaxgraptotal(Math.max(...filtro2.map((item) => item.total)));
  const filtro3 = result.filter(item=> !isNaN(item.tendencia));
  setmaxtendencia(Math.max(...filtro3.map((item)=>item.tendencia)));
}



const [tabla, settabla]=useState(false);
const [grafica, setgrafica]=useState(true);

const handletabla = ()=>{
  settabla(true);
  setgrafica(false);
}
const handlegrafica = () =>{
  settabla(false);
  setgrafica(true);
}

const crudmetas=()=>{

  const result = CustomDialog(               
    <div>
      <TableGoal_V2 my={my} setdatameta={setdatameta} setfiltrometas={setfiltrometas} setactualizacionmeta={setactualizacionmeta}></TableGoal_V2>
    </div>,
  {
    className: "modalTItle",
    title:"Metas",
    showCloseIcon: true,
    isCanClose:false,
  })
}


const handlefiltrometas= async ()=>{

  if(actualizacionmeta===true){
    routeall(); 
    setmodalT(true);
    setfiltrometas(!filtrometas);
    setTimeout(() => {
      setmodalT(false);       
    }, 2000);

  }else if(nuevafecha===true){

    const partes = my.split("-"); // Divide la cadena en dos partes: año y mes
    var year = parseInt(partes[0]); // Convierte el año en un número entero
    var month = parseInt(partes[1]); // Convierte el mes en un número entero
    var datam = await handlemetas(year, month);
    var ttt = await tendencia(fi);
    var tt2 = await countDaysWithoutSundays(fi, ff);  
    analisismetas(datam, data, ttt, tt2); 
    setmodalT(true);
    setTimeout(() => {
      setnuevafecha(false);
      setfiltrometas(true);
      setmodalT(false);    
    }, 2000);
  }else {
    console.log("entra opcion else");
    setfiltrometas(!filtrometas);
  }
}

function handleBarClick(data){
 if(banderafiltro===true)
 {
   const result = CustomDialog(               
     <div>
       <DetailSales data={data} 
         filtro1={filtro1}
         filtro2={filtro2}
         filtro3={filtro3}
         filtro4={filtro4}
         filtro5={filtro5}
         filtro7={filtro7}
       ></DetailSales>
     </div>,
     {
       className: "modalpuntosventa",
       title:"Detalles de Venta",
       showCloseIcon: true,
       isCanClose:false,
     }
   )
 }
}

const [filtro1, setfiltro1]=useState(false);
const [filtro2, setfiltro2]=useState(false);
const [filtro3, setfiltro3]=useState(false);
const [filtro4, setfiltro4]=useState(false);
const [filtro5, setfiltro5]=useState(false);
const [filtro6, setfiltro6]=useState(false);
const [filtro7, setfiltro7]=useState(true);
const [filtrometas, setfiltrometas]=useState(false);
const [filtrotendencia, setfiltrotendencia]=useState(false);


const handletotales=(datanew)=>{
  
  //console.log(datanew);

  const result = datanew.reduce((acc, item) =>{
    acc.discount +=item.discount;
    acc.tax += item.tax;
    acc.subtotal += item.subtotal;
    acc.sale_total += item.sale_total;
    acc.cost += item.cost;
    acc.conversion += item.conversion;
    acc.delivery_commission += item.delivery_commission;
    acc.profit += item.profit;
    acc.bonus_total +=item.bonus_total;
    acc.returns_total += item.returns_total;
    acc.total +=item.total;
    return acc;
  }, {
    discount:0, 
    tax:0, 
    subtotal:0,
    sale_total:0,
    cost:0,
    conversion:0,
    delivery_commission:0,
    profit:0,
    returns_total:0,
    bonus_total:0,
    total:0
  });
  setdatatotales(result);
}
const handlefiltro1=(datanew)=>{
  var newArray = [];    
    const groupedByDate = datanew.reduce((acc, obj) => {
      const date = obj.date.split(" ")[0];
      if (!acc[date]) {
        acc[date] = {
          date: date,
          discount:0, 
          tax:0, 
          subtotal:0,
          sale_total:0,
          cost:0,
          conversion:0,
          delivery_commission:0,
          profit:0,
          bonus_total:0,
          returns_total:0,
          total:0,
          tax_returns:0,
          socios:[],
        }
      }
      acc[date].discount += obj.discount;
      acc[date].tax += obj.tax;
      acc[date].subtotal += obj.subtotal;
      acc[date].sale_total += obj.sale_total;
      acc[date].cost += obj.cost;
      acc[date].conversion += obj.conversion;
      acc[date].delivery_commission += obj.delivery_commission;
      acc[date].profit += obj.profit;
      acc[date].returns_total += obj.returns_total;
      acc[date].bonus_total += obj.bonus_total;
      acc[date].total+= obj.total;
      acc[date].socios.push({
        name_partner: obj.name_partner,
        code_partner: obj.code_partner,
        discount: obj.discount,
        tax: obj.tax,
        subtotal: obj.subtotal,
        sale_total: obj.sale_total,
        returns_total: obj.returns_total,
        total: obj.total,
      }) 
      return acc;
    }, {});
    
    for (let date in groupedByDate) {
    newArray.push({
      date: groupedByDate[date].date,
      discount: (groupedByDate[date].discount).toFixed(2),
      tax: (groupedByDate[date].tax).toFixed(2),
      subtotal: (groupedByDate[date].subtotal).toFixed(2),
      sale_total: (groupedByDate[date].sale_total).toFixed(2),
      cost: (groupedByDate[date].cost).toFixed(2),
      conversion: (groupedByDate[date].conversion).toFixed(2),
      delivery_commission: (groupedByDate[date].delivery_commission).toFixed(2),
      profit: (groupedByDate[date].profit).toFixed(2),
      bonus_total: (groupedByDate[date].bonus_total).toFixed(2),
      returns_total: (groupedByDate[date].returns_total).toFixed(2),
      total: (groupedByDate[date].total).toFixed(2),
      socios: (groupedByDate[date].socios)
    });
  }
  setdatagrap(newArray);
  console.log(newArray);
}

const columns = useMemo(
  () => [
    {
      id: "ticket",
      header: "Ticket",
      accessorKey:"ticket",
    },
    {
      id: "name_partner",
      header:"Socio de Negocios",
      accessorKey:"name_partner"
    },
    {
      id: "name_pos",
      header:"Punto de Venta",
      accessorKey: "name_pos",
      Footer: () => (
        <div>
          <div>Sub Total: </div>
          <div>Gran Total: </div>
        </div>
      ), 
    },
    {
      id: "discount",
      header:"Descuentos",
      accessorKey: "discount",
      Cell: ({ cell }) => (
        <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: "right"
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.discount)
          return (
            <div style={{ textAlign: "right", width: "100%" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(datatotales?.discount===null || datatotales?.discount===undefined ? 0.0 : datatotales?.discount)
              }</div>
            </div>
          )
        },
           
     },
    {
      id: "tax",
      header:"Impuestos",
      accessorKey: "tax",
      Cell: ({ cell }) => (
        <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: "right"
        },     
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.tax)
          return (
            <div style={{ textAlign: "right", width: "100%"}}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(datatotales?.tax === null || datatotales?.tax === undefined ? 0.0 : datatotales?.tax )
              }</div>
            </div>
          )
        }, 
     },
     {
       id: "subtotal",
       header:"Subtotal",
       accessorKey: "subtotal",
       Cell: ({ cell }) => (
        <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: "right"
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.subtotal)
          return (
            <div style={{ textAlign: "right", width: "100%" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(datatotales?.subtotal === null || datatotales?.subtotal === undefined ? 0.0 : datatotales?.subtotal)
              }</div>
            </div>
          )
        },
     },
    {
      id: "sale_total",
       header:"Total Venta",
       accessorKey: "sale_total",
       Cell: ({ cell }) => (
        <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: "right"
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.sale_total)
          return (
            <div style={{ textAlign: "right", width: "100%" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(datatotales?.sale_total === null || datatotales?.sale_total === undefined ? 0.0 : datatotales?.sale_total)
              }</div>
            </div>
          )
        },
     },
    {
      id: "cost",
       header:"Costo",
       accessorKey: "cost",
       Cell: ({ cell }) => (
        <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: "right"
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.cost)
          return (
            <div style={{ textAlign: "right", width: "100%" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(datatotales?.cost === null || datatotales?.cost === undefined ? 0.0 : datatotales?.cost)
              }</div>
            </div>
          )
        },
     },
    {
      id: "conversion",
       header:"Conversión",
       accessorKey:"conversion",
       muiTableBodyCellProps:{
        align: "right"
       },
       Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.conversion)
        return (
          <div style={{ textAlign: "right", width: "100%" }}>
            <div>{calculate_total(subtotal)?.toFixed(2)}</div>
            <div>{datatotales?.delivery_commission === null || datatotales?.delivery_commission === undefined ? 0.0 : datatotales?.delivery_commission?.toFixed(2)}</div>
          </div>
        )
      },
     },
    {
      id: "delivery_commission",
       header:"Comisión",
       accessorKey: "delivery_commission",
       Cell: ({ cell }) => (
        <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: "right"
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.delivery_commission)
          return (
            <div style={{ textAlign: "right", width: "100%" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(datatotales?.delivery_commission === null || datatotales?.delivery_commission === undefined ? 0.0 : datatotales?.delivery_commission)
              }</div>
            </div>
          )
        },
     },
     {
      id :"bonus_total",
       header:"Total Bonificaciones",
       accessorKey: "bonus_total",
       Cell: ({ cell }) => (
        <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: "right"
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.bonus_total)
          return (
            <div style={{ textAlign: "right", width: "100%" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(datatotales?.bonus_total === null || datatotales?.bonus_total === undefined ? 0.0 : datatotales?.bonus_total)
              }</div>
            </div>
          )
        },
     },
     {
      id: "returns_total",
       header:"Total Devoluciones",
       accessorKey: "returns_total",
       Cell: ({ cell }) => (
        <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: "right"
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.returns_total)
          return (
            <div style={{ textAlign: "right", width: "100%" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(datatotales?.returns_total === null || datatotales?.returns_total === undefined ? 0.0 : datatotales?.returns_total)
              }</div>
            </div>
          )
        },
     },
     {
      id: "total",
       header:"Total",
       accessorKey: "total",
       Cell: ({ cell }) => (
        <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: "right"
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.total)
          return (
            <div style={{ textAlign: "right", width: "100%" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(datatotales?.total === null || datatotales?.total === undefined ? 0.0 : datatotales?.total)
              }</div>
            </div>
          )
        },
     }
    ],
    [datatotales]
);


const columns2 = useMemo(
  () => [
    {
      id: "route",
      header:"Ruta",
      accessorKey:"route",
      muiTableBodyCellProps:{
        align: "center"
      }, 
      size: 150,
      Footer: () => (
        <div>
          <div>Sub Total: </div>
          <div>Gran Total: </div>
        </div>
      ), 
    },
    {
      id: "sale_total",
      header:"Total Venta",
      accessorKey: "sale_total",
      size: 250,
      Cell: ({ cell }) => (
        <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: "right"
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.sale_total)
          return (
            <div style={{ textAlign: "right"}}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(datatotales?.sale_total===null || datatotales?.sale_total === undefined ? 0.0 : datatotales?.sale_total)}</div>
            </div>
          )
        },
    },
    {
      id: "bonus_total",
      header:"Total Bonificaciones",
      accessorKey: "bonus_total",
      size: 250,
      Cell: ({ cell }) => (
        <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: "right"
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.bonus_total)
          return (
            <div style={{ textAlign: "right"}}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(datatotales?.bonus_total === null || datatotales?.bonus_total === undefined ? 0.0 : datatotales?.bonus_total)}</div>
            </div>
          )
        },
    },
    {
      id: "returns_total",
      header:"Total Devoluciones",
      accessorKey: "returns_total",
      size: 250,
      Cell: ({ cell }) => (
        <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: "right"
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.returns_total)
          return (
            <div style={{ textAlign: "right"}}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(datatotales?.returns_total === null || datatotales?.returns_total ? 0.0 : datatotales?.returns_total)}</div>
            </div>
          )
        },
    },
    {
      id: "total",
      header:"Total",
      accessorKey: "total",
      Cell: ({ cell }) => (
        <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: "right"
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.total)
          return (
            <div style={{ textAlign: "right"}}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(datatotales?.total === null || datatotales?.total === undefined ? 0.0 : datatotales?.total)}</div>
            </div>
          )
        },
               
    }
    ],
    [datatotales]
);


const handleStateChange = (newState) => {
  const filteredRowsCount = newState.filteredRowModel
  settotal_rows(filteredRowsCount);
};


const update_identifiers =
[
  { route: 'string' },
  { sale_total: 'number' },
  { bonus_total: 'number' },
  { returns_total: 'number' },
  { total: 'number' }
];

const update_identifiers2 =
[
  { ticket: 'string' },
  { name_partner: 'number' },
  { name_pos: 'number' },
  { discount: 'number' },
  { tax: 'number' },
  { subtotal: 'number'},
  { sale_total: 'number' },
  { cost: 'number' },
  { conversion: 'number' },
  { delivery_commission: 'number' },
  { bonus_total: 'number' },
  { returns_total: 'number' },
  { total: 'number' }
];


return (
    <>
<div className="TableReportSaleRoute">
  <div >         
  <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <MODAL_TABLE
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></MODAL_TABLE>
    <>
    <BREADCRUMBS niveles={
      [
        {label:"REPORTE VENTAS POR RUTA", path: null}, 
      ]
    }
      cards_dashboard={true}
      total_rows={total_rows !== "" ? total_rows : 0}
      filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
      visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
      total={data?.length}  
    ></BREADCRUMBS>

    <div className="gridfiltros">
      <div className="fil1">
        <input
          type="text"
          className="fi"
          onFocus={(e) => {
            e.currentTarget.type = "date";
            e.currentTarget.focus();
          }}
          placeholder="Fecha inicial"
        onChange={(event) => setfi(event.target.value)}
        ></input>
      </div>
      <div className="fil2">
        <input
          type="text"
          className="ff"
          onFocus={(e) => {
            e.currentTarget.type = "date";
            e.currentTarget.focus();
          }}
          placeholder="Fecha Final"
          onChange={(event) => setff(event.target.value)}
        ></input>
      </div>
      <div className="fil3">
      <TextField
          select
          required
          label="Ruta"
          InputLabelProps={{shrink: true,}}
          SelectProps={{native: true,}}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setnameroute(event.target.value)}
      >
          <option key={0} value={"vacio"} selected>
              {"TODAS LAS RUTAS"}
          </option>
          {
            routedata?.data?.parametersList?.map((item)=>(
              <option key={item.idRoute} value={item.route}>
                {item.route}
              </option>
            ))
          }
      </TextField>
      </div>
      <div className="fil4">
        <button className="button" id="buttonpackageb" onClick={handleSubmit} type="button">
            Buscar
        </button>
        <button className="button" id="buttonpackageb" onClick={handletabla} type="button">
            Tabla
        </button>
        <button className="button" id="buttonpackageb" onClick={handlegrafica} type="button">
            Gráfica
        </button>
        <button className="button" id="buttonpackageb" onClick={crudmetas} type="button">
            Metas
        </button>
      </div>
    </div>
      <div  className={banderafiltro===true?"tableg new_table_v2":"tableg2 new_table_v2"} style={tabla===true?{"display":"block"}:{"display":"none"}}>            
        {/* <StickyTable>
            <TableContainer
              paginado={"Ventas"}
              consultas={"Ventas Consultadas"}
              exportar={true}
              columns={banderafiltro===true?columns:columns2}
              data={data}
              style={{ overflowx: 10 }}
              Gmodal={open}
              banderafiltro={banderafiltro}
              Grantotal={true}
              GrantotalCabeceras={["Socio de Negocios", "Punto de Venta"]}
              nametable={"Reporte de Ventas por Ruta"}
            />
          </StickyTable> */}

<MaterialReactTable
        columns={banderafiltro===true?columns:columns2}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        onStateChange={handleStateChange}
        enableColumnFilterModes
        enableRowActions={false}
        enableRowSelection={false}
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            settotal_rows(table);
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        //  renderRowActionMenuItems={({ row, closeMenu }) => [
        //    <RENDER_ROW_ACTION_MENU_ITEMS
        //      closeMenu={closeMenu}
        //      setmodalT={setmodalT}
        //      setmenssage={setmenssage}
        //      setmodalGeneral={setmodalGeneral}
        //      row={row}
        //      handleSubmit={handleSubmit}
        //      setreconsult={setreconsult}
        //      refresh_token={refresh_token}
        //      alertas={alertas}
        //    />
        //  ]}
        renderToolbarInternalActions={({ table }) => (
          <RENDER_TOOLBAR_INTERNAL_ACTIONS
             table={table}
             file_name={"Cuentas Contables"}
             update_identifiers={banderafiltro===true?update_identifiers:update_identifiers2}
             settotal_rows={settotal_rows}
           />
         )
         }
      />


      </div>
      <div className="graphict" style={grafica===true?{"display":"block"}:{"display":"none"}}>

      <div className="filtrosgraphic">
      <div className="fg1" style={banderafiltro===true?{"display":"block"}:{"display":"none"}}>
          <label className="labelcheck">Descuentos
          </label>
            <Checkbox
              checked={filtro1}
              onChange={(event)=>setfiltro1(!filtro1)}
              inputProps={{"aria-label": "primary checkbox"}}
              style={{"padding":"10px", "transform":"scale(1.7)"}}
            />
            <p>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
              }).format((datatotales?.discount>0? (datatotales?.discount)?.toFixed(2):0.0))
              }
            </p>
        </div>
        <div className="fg2" style={banderafiltro===true?{"display":"block"}:{"display":"none"}}>
          <label className="labelcheck">Impuestos 
          </label>
            <Checkbox
              checked={filtro2}
              onChange={(event)=>setfiltro2(!filtro2)}
              inputProps={{"aria-label": "primary checkbox"}}
              style={{"padding":"10px", "transform":"scale(1.7)"}}
            />
          <p>
            {Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format((datatotales?.tax>0 ? (datatotales?.tax)?.toFixed(2):0.0))
            }
          </p>  
        </div>
        <div className="fg3" style={banderafiltro===true?{"display":"block"}:{"display":"none"}}>
          <label className="labelcheck">Subtotal  
          </label>
            <Checkbox
              checked={filtro3}
              onChange={(event)=>setfiltro3(!filtro3)}
              inputProps={{"aria-label": "primary checkbox"}}
              style={{"padding":"10px", "transform":"scale(1.7)"}}
            />
          <p>
            {Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
              }).format((datatotales?.subtotal>0? (datatotales?.subtotal)?.toFixed(2):0.0))
            }
          </p>
        </div>
        <div className={banderafiltro===true?"fg4":"fg1"}>
          <label className="labelcheck">Total Ventas  
          </label>
            <Checkbox
              checked={filtro4}
              onChange={(event)=>setfiltro4(!filtro4)}
              inputProps={{"aria-label": "primary checkbox"}}
              style={{"padding":"10px", "transform":"scale(1.7)"}}
            />
          <p>
            {Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
              }).format((datatotales?.sale_total>0? (datatotales?.sale_total)?.toFixed(2):0.0))
            }
          </p>
        </div>
        <div className="fg2" style={banderafiltro===false?{"display":"block"}:{"display":"none"}}>
          <label className="labelcheck">
            Total Bonificaciones  
          </label>
            <Checkbox
              checked={filtro6}
              onChange={(event)=>setfiltro6(!filtro6)}
              inputProps={{"aria-label": "primary checkbox"}}
              style={{"padding":"10px", "transform":"scale(1.7)"}}
            />
          <p>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
              }).format(datatotales?.bonus_total>0 ? (datatotales?.bonus_total)?.toFixed(2):0.0)  
            }
          </p>
        </div>
        <div className={banderafiltro===true?"fg5":"fg3"}>
          <label className="labelcheck">Total Devoluciones
          </label>
            <Checkbox
              checked={filtro5}
              onChange={(event)=>setfiltro5(!filtro5)}
              inputProps={{"aria-label": "primary checkbox"}}
              style={{"padding":"10px", "transform":"scale(1.7)"}}
            />
          <p>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
              }).format((datatotales?.returns_total>0 ? (datatotales?.returns_total)?.toFixed(2):0.0))  
            }
          </p>
        </div>
        
        <div className={banderafiltro===true?"fg6":"fg4"}>
          <label className="labelcheck">Gran Total 
          </label>
            <Checkbox
              checked={filtro7}
              onChange={()=>setfiltro7(!filtro7)}
              inputProps={{"aria-label": "primary checkbox"}}
              style={{"padding":"10px", "transform":"scale(1.7)"}}
            />
          <p>
            {
            Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format((datatotales?.total>0 ? (datatotales?.total)?.toFixed(2) : 0.0))
            }
          </p>
        </div>
        {
        banderafiltro===false?
        <div className="fg5">
          <label className="labelcheck">Metas 
          </label>
            <Checkbox
              checked={filtrometas}
              onChange={handlefiltrometas}
              inputProps={{"aria-label": "primary checkbox"}}
              style={{"padding":"10px", "transform":"scale(1.7)"}}
            />
            <div className="check-input-meta">
            < input 
              type="month" 
              value={my} 
              onChange={(e)=>{
                setmy(e.target.value);
                setfiltrometas(false);
                setnuevafecha(true);
              }} 
            />  
          </div>    
        </div>:<></>
        }
        {
          banderafiltro===false?
          <div className="fg6">
          <label className="labelcheck">Tendencia 
          </label>
            <Checkbox
              checked={filtrotendencia}
              onChange={()=>setfiltrotendencia(!filtrotendencia)}
              inputProps={{"aria-label": "primary checkbox"}}
              style={{"padding":"10px", "transform":"scale(1.7)"}}
            />
        </div>:<></>
        }
        
        <div className="fg8">
        </div>
      </div>

      <ResponsiveContainer width="95%" maxHeight={450} minHeight={500}>
        <ComposedChart
        data={datagrap}
        margin={{
          top: 20,
          right: 50,
          left: 50,
          bottom: 10
        }}
      > 
        <CartesianGrid strokeDasharray="3 3" />
        {
          banderafiltro===true?
          <>
            <XAxis dataKey="date"> 
              <Label value="date" offset={-10} position="insideBottom"/>
            </XAxis>
            <YAxis dataKey="total" label={{value:"Total $", angle:90}} 
              tickFormatter={(value) => `$${value.toLocaleString()}`}
              domain={[0, (Math.max(...datagrap?.map(item=>(item.total))))]}     
            />
            <Brush dataKey="date" height={30} stroke="#8884d8" />  
            <Tooltip
             formatter={(value) => `$${value.toLocaleString('es-MX', { useGrouping: true, minimumFractionDigits: 0 })}`}
            />
          </>
          :
          <>
          <XAxis dataKey="route"> 
            <Label value="route" offset={-10} position="insideBottom"/>
          </XAxis>
            <YAxis dataKey="total" label={{value:"Total $", angle:90}} 
             tickFormatter={(value) => `$${value.toLocaleString()}`}
             domain={
              filtrometas===true && filtrotendencia===true?(maxgrapmeta>maxtendencia)?[0,maxgrapmeta]:[0,maxtendencia]:
              filtrometas===true?[0,maxgrapmeta]:filtrotendencia===true?[0,maxtendencia]:[0,maxgraptotal]
            }
            />
            <Brush dataKey="route" height={30} stroke="#8884d8" />  
            <Tooltip
             // formatter={(value) => `$${value.toLocaleString('es-MX', { useGrouping: true, minimumFractionDigits: 0 })}`}
             formatter={(value, name, props) => {
              if (name === 'Meta' && props.payload.porcentaje !== undefined) {
                const formattedValue = value.toLocaleString('es-MX', { useGrouping: true, minimumFractionDigits: 0 }); // formatear el valor a formato monetario
                const porcentaje = props.payload.porcentaje.toFixed(2); // obtener el valor de porcentaje y formatearlo a 2 decimales
                return `$${formattedValue} - Porcentaje Avance: ${porcentaje}%`; // retornar el texto personalizado
              }
              return  `$${value.toLocaleString('es-MX', { useGrouping: true, minimumFractionDigits: 0 })}` // utilizar el formatter por defecto para las demás barras
            }}
            />
          </>
          
        }
       
        <Legend verticalAlign="top" height={50}/>
        <ReferenceLine y={0} stroke="#000" />         
        {
          filtro1===true?<Bar dataKey="discount" stackId="a" name="Descuentos" fill="#00CFC3" onClick={handleBarClick}/>:""
        }
        {
          filtro2===true?<Bar dataKey="tax" stackId="b" name="Impuestos" fill="red" onClick={handleBarClick}/>:""
        }
        {
          filtro3===true?<Bar dataKey="subtotal" stackId="e" name="Subtotal" fill="#7121AA" onClick={handleBarClick}/>:""
        } 
        {
          filtro4===true?<Bar dataKey="sale_total" stackId="g" name="Total Ventas" fill="#9C4701" onClick={handleBarClick}/>:"" 
        }
        {
          filtro5===true?<Bar dataKey="returns_total" stackId="h" name="Total Devoluciones" fill="#00CFC3" onClick={handleBarClick}/>:"" 
        }
        {
          filtro6===true?<Bar dataKey="bonus_total" stackId="d" name="Total Bonificaciones" fill="#20CC10" onClick={handleBarClick}/>:""
        }
        {
          filtro7===true?<Bar dataKey="total" stackId="f" name="Total" fill="#3f87a6" onClick={handleBarClick}/>:"" 
        }
        {
          filtrometas===true? <Line type="monotone" name="Meta" dataKey="goal" stroke="red" strokeWidth={3}/>:<></>
        } 
        {
          filtrotendencia===true?<Line type="monotone" name="Tendencia" dataKey="tendencia" stroke="purple" strokeWidth={3}/>:<></>
        }
      </ComposedChart>
      </ResponsiveContainer>
      </div>
      </>           
    </div>
   </div>
    </>
  );
}

export default TableRouteSalesReport;
