//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

//COMPONENTES
import SubTable from "../sales/Get_datail_Sales";
import Rutas from "../../dataDahsboard/routes.json";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh.jsx";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "../dashboar.css";
import "./clients.css";
//IMAGENES/ICONOS
import { get_dashboard1 } from "../../services/reports/dashboard";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import { calculate_total } from "../../main_components/methods_v2/auxiliary_functions";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  opacity: ".1 !important",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const NewDashboardClient_v2 = () => {


  
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);


  var date = new Date();
  var dia = `${date.getDate()}`.padStart(2, "0");
  var mes = `${date.getMonth() + 1}`.padStart(2, "0");
  var año = date.getFullYear();

  var mesantes = Number(mes) - 1;

  if (mesantes == 0) {
    mesantes = `${12}`.padStart(2, "0");
  } else {
    mesantes = `${mesantes}`.padStart(2, "0");
  }

  const recuperarPPD = `${año}-${mes}-${dia}`;
  const recuperarPPDantes = `${año}-${mesantes}-${dia}`;
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openTotal, setopenTotal] = useState(true);
  const [menssage, setMenssage] = useState("");
  const [ff, setff] = useState(recuperarPPD);
  const [fi, setfi] = useState(recuperarPPDantes);
  const [selectRutas, setselectRutas] = useState("all");


  const [sales, setsales]=useState([]);
  const [participation, setparticipation] = useState([]);
  const [customers, setcustomers] = useState([]);
  const [pcustomers, setpcustomers] = useState([]);
  const [withsale, setwithsale] = useState([]);
  const [pwithsale, setpwithsale] = useState([]);
  const [withoutsale, setwithoutsale] = useState([]);
  const [pwithoutsale, setpwithoutsale] = useState([]);
  const [freezers, setfreezers] = useState([]);
  const [pfreezers, setpfreezers] = useState([]);
  const [cost, setcost] = useState([]);
  const [profit, setprofit] = useState([]);


  const update_identifiers =
  [
    { customer: 'string' },
    { sales: 'number' },
    { participation: 'number' },
    { customers: 'number' },
    { pcustomers: 'number' },
    { withsale: 'number'},
    { pwithsale: 'number' },
    { withoutsale: 'number' },
    { pwithoutsale: 'number' },
    { freezers: 'number' },
    { pfreezers: 'number' },
    { cost: 'number' },
    { profit: 'number' }
  ];


  const handleSubmit = async(event) => {
    event?.preventDefault();
    setOpen(true);
    setMenssage("Consultando...");
    console.log(fi);
    console.log(ff);
    setopenTotal(false);
    setData([]);
    setIsLoading(false);

    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await get_dashboard1(fi, ff, selectRutas);
            const data = d===null?[]:d.data;
            const data2 = data.filter((dash) => dash.customer !== "Grand Total");
            var cust = data2
              .filter((sale) => !isNaN(parseFloat(sale.customers)))
              .map((sale) => parseFloat(sale.customers))
              .reduce((previous, current) => previous + current, 0);
  
              var withs = data2
              .filter((sale) => !isNaN(parseFloat(sale.withsale)))
              .map((sale) => parseFloat(sale.withsale))
              .reduce((previous, current) => previous + current, 0);
  
              var witho = data2
              .filter((sale) => !isNaN(parseFloat(sale.withoutsale)))
              .map((sale) => parseFloat(sale.withoutsale))
              .reduce((previous, current) => previous + current, 0);
              var freez = data2
              .filter((sale) => !isNaN(parseFloat(sale.freezers)))
              .map((sale) => parseFloat(sale.freezers))
              .reduce((previous, current) => previous + current, 0);
  
            const ndata = data2.map((d) => {
              var json = {
                customer: d.customer,
                sales: d.sales,
                participation: d.participation,
                customers: d.customers,
                pcustomers:(((d.customers) /(cust))*(100)).toFixed(2),
                withsale: d.withsale,
                pwithsale: (((d.withsale) /(withs))*(100)).toFixed(2),
                withoutsale: d.withoutsale,
                pwithoutsale: (((d.withoutsale) /(witho))*(100)).toFixed(2),
                freezers: d.freezers,
                pfreezers: (((d.freezers) /(freez))*(100)).toFixed(2),
                cost: d.cost,
                profit: d.profit,
              };
  
              return json;
            });
            handleCacular()
            console.log(
              data
                .filter((sale) => !isNaN(parseFloat(sale.customers)))
                .map((sale) => parseFloat(sale.customers))
                .reduce((previous, current) => previous + current, 0)
            );
            
            setsales(ndata?.map(obj=>obj.sales).filter(d=>d!==null));
            setparticipation(ndata?.map(obj => obj.participation).filter(d => d !== null));
            setcustomers(ndata?.map(obj => obj.customers).filter(d => d !== null));
            setpcustomers(ndata?.map(obj => Number(obj.pcustomers)).filter(d => !isNaN(d) && d !== null));
            setwithsale(ndata?.map(obj => obj.withsale).filter(d => d !== null));
            setpwithsale(ndata?.map(obj =>  Number(obj.pwithsale)).filter(d => !isNaN(d) && d !== null));
            setwithoutsale(ndata?.map(obj => obj.withoutsale).filter(d => d !== null));
            setpwithoutsale(ndata?.map(obj => Number(obj.pwithoutsale)).filter(d => !isNaN(d) && d !== null));
            setfreezers(ndata?.map(obj => obj.freezers).filter(d => d !== null));
            setpfreezers(ndata?.map(obj => Number(obj.pfreezers)).filter(d => !isNaN(d) && d !== null));
            setcost(ndata?.map(obj => obj.cost).filter(d => d !== null));
            setprofit(ndata?.map(obj => obj.profit).filter(d => d !== null));
            setData(ndata);
            setopenTotal(true);
            setOpen(false);
            setMenssage("");
            setIsLoading(false);
          }catch(err){
            console.log("error", err);
            setData([]);
            setOpen(false);
            setopenTotal(true);
            setMenssage("");
            setIsLoading(false);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }

    /*
    refresh_token ();
    setTimeout(() => {
      axios
        .get(
          `https://zaqp4ihdn9.execute-api.us-east-1.amazonaws.com/prod/dashboard1`,
          {
            params: {
              datei: fi,
              datef: ff,
              route: selectRutas,
            },
            headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const data = response.data;
          const data2 = data.filter((dash) => dash.customer !== "Grand Total");
          var cust = data2
            .filter((sale) => !isNaN(parseFloat(sale.customers)))
            .map((sale) => parseFloat(sale.customers))
            .reduce((previous, current) => previous + current, 0);

            var withs = data2
            .filter((sale) => !isNaN(parseFloat(sale.withsale)))
            .map((sale) => parseFloat(sale.withsale))
            .reduce((previous, current) => previous + current, 0);

            var witho = data2
            .filter((sale) => !isNaN(parseFloat(sale.withoutsale)))
            .map((sale) => parseFloat(sale.withoutsale))
            .reduce((previous, current) => previous + current, 0);
            var freez = data2
            .filter((sale) => !isNaN(parseFloat(sale.freezers)))
            .map((sale) => parseFloat(sale.freezers))
            .reduce((previous, current) => previous + current, 0);

          const ndata = data2.map((d) => {
            console.log(cust);
            var json = {
              customer: d.customer,
              sales: d.sales,
              participation: d.participation,
              customers: d.customers,
              pcustomers:(((d.customers) /(cust))*(100)).toFixed(2),
              withsale: d.withsale,
              pwithsale: (((d.withsale) /(withs))*(100)).toFixed(2),
              withoutsale: d.withoutsale,
              pwithoutsale: (((d.withoutsale) /(witho))*(100)).toFixed(2),
              freezers: d.freezers,
              pfreezers: (((d.freezers) /(freez))*(100)).toFixed(2),
              cost: d.cost,
              profit: d.profit,
            };

            return json;
          });
          handleCacular()
          console.log(
            data
              .filter((sale) => !isNaN(parseFloat(sale.customers)))
              .map((sale) => parseFloat(sale.customers))
              .reduce((previous, current) => previous + current, 0)
          );

          setData(ndata);
          setopenTotal(true);
          setOpen(false);
          setMenssage("");
        })
        .catch((err) => {
          console.log("error", err);
          setData([]);
          setOpen(false);
          setopenTotal(true);
          setMenssage("");
        });
    }, 2000)*/;
  };


  const handleCacular = (a,b) => {

  };

  const handleRutas = (event) => {
    setselectRutas(event.target.value);
  };

  const handleReconsulta = (reconsultar) => {
    if (reconsultar === true) {
      handleSubmit();
    } else {
      console.log("no se reconsulto");
    }
  };


  const columns = useMemo(
    () => [
      {
        id: "customer",
        header: "Clasificación",
        accessorKey: "customer",
        Footer: () => (
          <div>
            <div>Sub Total: </div>
            <div>Gran Total: </div>
          </div>
        ),
      },
      {
        id: "sales",
        header: "Ventas",
        accessorKey: "sales",
        Cell: ({ cell }) => {
          return(
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        )},
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.sales)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(sales))
              }</div>
            </div>
          )
        },  
      },
      {
        id: "participation",
        header: "Participación",
        accessorKey: "participation",
        Cell: ({ cell }) => {
          return(
          <Box
            component="span"
          >
            {cell.getValue() === undefined ?
            <div className="">
              {0}%
            </div>:
            <div className="">
              {cell.getValue()}%
            </div>}
          </Box>
        )},
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.participation)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{(calculate_total(subtotal)).toFixed(2)}%</div>
              <div>{(calculate_total(participation)).toFixed(1)}%</div>
            </div>
          )
        },
      },
      {
        id: "customers",
        header: "Puntos de venta",
        accessorKey: "customers",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.customers);
          return (
            <div style={{ textAlign: "right" }}>
              <div>{calculate_total(subtotal)}</div>
              <div>{calculate_total(customers)}</div>
            </div>
          )
        },

      },

      {
        id: "pcustomers",
        header: "Puntos de venta%",
        accessorKey: "pcustomers",
        Cell: ({ cell }) => {
          return(
          <Box
            component="span"
          >
            {cell.getValue() === undefined ?
            <div className="">
              {0}%
            </div>:
            <div className="">
              {cell.getValue()}%
            </div>}
          </Box>
        )},
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.pcustomers).map(value => Number(value));
          return (
            <div style={{ textAlign: "right" }}>
              <div>{calculate_total(subtotal).toFixed(2)}%</div>
              <div>{calculate_total(pcustomers).toFixed(1)}%</div>
            </div>
          )
        },
      },
      {
        id: "withsale",
        header: "Con venta",
        accessorKey: "withsale",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.withsale);
          return (
            <div style={{ textAlign: "right" }}>
              <div>{calculate_total(subtotal)}</div>
              <div>{calculate_total(withsale)}</div>
            </div>
          )
        },
      },
      {
        id: "pwithsale",
        header: "Con venta %",
        accessorKey: "pwithsale",
        Cell: ({ cell }) => {
          return(
          <Box
            component="span"
          >
            {cell.getValue() === undefined ?
            <div className="">
              {0}%
            </div>:
            <div className="">
              {cell.getValue()}%
            </div>}
          </Box>
        )},
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.pwithsale).map(value => Number(value));
          return (
            <div style={{ textAlign: "right" }}>
              <div>{(calculate_total(subtotal)).toFixed(2)}%</div>
              <div>{calculate_total(pwithsale).toFixed(1)}%</div>
            </div>
          )
        },
      },
      {
        id: "withoutsale",
        header: "Sin venta",
        accessorKey: "withoutsale",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.withoutsale);
          return (
            <div style={{ textAlign: "right" }}>
              <div>{calculate_total(subtotal)}</div>
              <div>{calculate_total(withoutsale)}</div>
            </div>
          )
        },
      },
      {
        id: "pwithoutsale",
        header: "Sin venta%",
        accessorKey: "pwithoutsale",
        Cell: ({ cell }) => {
          return(
          <Box
            component="span"
          >
            {cell.getValue() === undefined ?
            <div className="">
              {0}%
            </div>:
            <div className="">
              {cell.getValue()}%
            </div>}
          </Box>
        )},
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.pwithoutsale).map(value => Number(value));
          return (
            <div style={{ textAlign: "right" }}>
              <div>{calculate_total(subtotal).toFixed(2)}%</div>
              <div>{calculate_total(pwithoutsale).toFixed(1)}%</div>
            </div>
          )
        },
      },
      {
        id: "freezers",
        header: "Congeladores con venta",
        accessorKey: "freezers",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.freezers);
          return (
            <div style={{ textAlign: "right" }}>
              <div>{calculate_total(subtotal)}</div>
              <div>{calculate_total(freezers)}</div>
            </div>
          )
        },
      },
      {
        id: "pfreezers",
        header: "Congeladores con venta%",
        accessorKey: "pfreezers",
        Cell: ({ cell }) => {
          return(
          <Box
            component="span"
          >
            {cell.getValue() === undefined ?
            <div className="">
              {0}%
            </div>:
            <div className="">
              {cell.getValue()}%
            </div>}
          </Box>
        )},
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.pfreezers).map(value => Number(value));
          return (
            <div style={{ textAlign: "right" }}>
              <div>{(calculate_total(subtotal)).toFixed(2)}%</div>
              <div>{calculate_total(pfreezers).toFixed(1)}%</div>
            </div>
          )
        },
      },
      {
        id: "cost",
        header: "Costo",     
        accessorKey: "cost",
        enableHiding: sessionStorage.getItem("ROLE_REPORT_SALES_MANAGEMENT") === "ROLE_REPORT_SALES_MANAGEMENT" ? true : false,
        Cell: ({ cell }) => {
          return(
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        )},
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.cost)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(cost))
              }</div>
            </div>
          )
        },  
      },
      {
        id: "profit",
        header: "Ganancia",
        accessorKey: "profit",
        enableHiding: sessionStorage.getItem("ROLE_REPORT_SALES_MANAGEMENT") === "ROLE_REPORT_SALES_MANAGEMENT" ? true : false,
        Cell: ({ cell }) => {
          return(
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        )},
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj?.profit)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(profit))
              }</div>
            </div>
          )
        }, 
      },
    ],
    [
      sales, 
      participation,
      customers,
      pcustomers, 
      withsale, 
      pwithsale, 
      withoutsale, 
      pwithoutsale, 
      freezers, 
      pfreezers, 
      cost, 
      profit
    ]
  );

  return (
    <div className="new_table_v2">
      <div>
        <Modal
          open={open}
          className="nModalNew"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <div className="centermodaldescarga nModalNew">
              <Typography
                id="modal-modal-title"
                
                variant="h6"
                component="h2"
              ></Typography>
              <Typography
                id="modal-modal-description"
                
                sx={{ mt: 2 }}
              ></Typography>
            </div>
          </Box>
        </Modal>
      </div>

      

      <div className="">
      <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        // enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={
          { 
            showGlobalFilter: true, 
            columnVisibility:{
              cost: sessionStorage.getItem("ROLE_REPORT_SALES_MANAGEMENT") === "ROLE_REPORT_SALES_MANAGEMENT" ? true : false,
              profit: sessionStorage.getItem("ROLE_REPORT_SALES_MANAGEMENT") === "ROLE_REPORT_SALES_MANAGEMENT" ? true : false,
            }
          }
        }
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderTopToolbarCustomActions={({ }) => (
          <div className="dashboard-acctions">
                  <div className="dashacc1">
                    <input
                      type="text"
                      className="fExpense"
                      onFocus={(e) => {
                        e.currentTarget.type = "date";
                        e.currentTarget.focus();
                      }}
                      placeholder="Fecha inicial"
                      onChange={(event) => setfi(event.target.value)}
                    ></input>
                  </div>

                  <div className="dashacc2">
                    <input
                      type="text"
                      className="fExpense"
                      onFocus={(e) => {
                        e.currentTarget.type = "date";
                        e.currentTarget.focus();
                      }}
                      placeholder="Fecha Final"
                      onChange={(event) => setff(event.target.value)}
                    ></input>
                  </div>

                  <div className="dashacc3">
                    <TextField
                      select
                      label="Ruta"
                      value={selectRutas}
                      sx={{ width: "50%" }}
                      onChange={handleRutas}
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      SelectProps={{
                        native: true,
                      }}
                    >
                      {Rutas.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </TextField>
                  </div>

                  <div className="">
                    <Button
                      sx={{ width: "100%" }}
                      variant="contained"
                      onClick={handleSubmit}
                    >
                      Buscar
                    </Button>
                  </div>
                </div>


        )}
        //  renderRowActionMenuItems={({ row, closeMenu }) => [
        //    <RENDER_ROW_ACTION_MENU_ITEMS
        //      closeMenu={closeMenu}
        //      setmodalT={setmodalT}
        //      setmenssage={setmenssage}
        //      setmodalGeneral={setmodalGeneral}
        //      row={row}
        //      handleSubmit={handleSubmit}
        //      setreconsult={setreconsult}
        //      refresh_token={refresh_token}
        //      alertas={alertas}
        //    />
        //  ]}
         renderToolbarInternalActions={({ table }) => (
           <RENDER_TOOLBAR_INTERNAL_ACTIONS
             table={table} 
             file_name={"Clientes"}
             update_identifiers={update_identifiers}
           />
         )
         }
      />
          
      </div>
    </div>
  );
};

export default NewDashboardClient_v2;
