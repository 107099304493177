//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import NotificationSystem from 'react-notification-system';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
//COMPONENTES
import { CustomDialog } from "react-st-modal";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { get_sales_deliveries } from "../../../services/reports/reports";
import { SEARCH_FILTER_WEB_MOVIL } from "../../../main_components/methods_v2/search_filter_web_movil";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import 'dayjs/locale/es-mx';
import {Box, Button} from '@mui/material';
import { formattedCurrentDate } from "../../../main_components/date/day";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import AlertResponse from "../../../main_components/alerts/alertResponse";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";

export const DETAILS_SALES = (props) => {
  
    const notificationSystemRef = useRef();
    const [open, setopen] = useState(false);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [modalT, setmodalT] = useState(false);
    const [menssage, setmenssage] = useState("");
    const [modalGeneral, setmodalGeneral] = useState(false);
    const [total_rows, settotal_rows] = useState("");
    const [date, setdate] = useState(dayjs(formattedCurrentDate));
  
    useEffect(() => {
      handlejoin();
    }, [])


    const handlejoin = () =>{

      var repd = [];
      var pred = [];
      var ext = [];
      
      if(props?.row?.original?.hours_d){
        repd = props?.row?.original?.hours_d.map(item => {
          return {
              ...item,
              type: "Reparto"
          };
        });
      }
      if(props?.row?.original?.hours_s){
        pred = props?.row?.original?.hours_s.map(item => {
          return {
              ...item,
              type: "Preventa"
          };
        });
      }
      ext = [...repd, ...pred];
      ext.sort((a, b) => {
          const dateA = new Date(`${a.date} ${a.hour}`);
          const dateB = new Date(`${b.date} ${b.hour}`);
          return dateB - dateA;
      });
      console.log(ext);

      setData(ext);
    }
    const columns = useMemo(
        () => [
          {
            id: "sector",
            header: "Sector",
            accessorKey: "sector",
            muiTableBodyCellProps: {
              align: 'left',
            }
          },
          {
            id: "code_pos",
            header: "Código Punto de Venta",
            accessorKey: "code_pos",
            muiTableBodyCellProps: {
              align: 'center',
            }
          },
          {
            id: "type",
            header: "Tipo",
            accessorKey: "type",
            muiTableBodyCellProps: {
              align: 'center',
            },
            filterVariant: 'select',
          },
          {
            id: "ticket",
            header: "Ticket",
            accessorKey: "ticket",
            muiTableBodyCellProps: {
              align: 'center',
            }
          },
          {
            id: "date",
            header: "Fecha",
            accessorKey: "date",
            muiTableBodyCellProps: {
              align: 'left',
            }
          },
          {
            id: "hour",
            header: "Hora",
            accessorKey: "hour",
            muiTableBodyCellProps: {
              align: 'left',
            }
          }
          
        ], []);
        const handleStateChange = (newState) => {
            const filteredRowsCount = newState.filteredRowModel
            settotal_rows(filteredRowsCount);
          };

        
    
  const update_identifiers =
  [
    { sector: 'string' },
    { code_pos: 'string' },
    { type: 'string' },
    { ticket: 'string' },
    { date: 'string' },
    { hour: 'string' }
  ];


    
  
  return (
    <>
    <div className="" style={{marginTop: "20px", position: "relative", width: "98%"}}>
    <BREADCRUMBS 

      niveles={
        [
          { label: "DETALLES", path: null },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>
    </div>
     
    
    <div className="new_table_v2" style={{"width":"96%", margin: "0 auto"}}>

        <MaterialReactTable
              columns={columns}
              data={data}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyHeader
              onStateChange={handleStateChange}
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              enableRowSelection
              // enableRowActions
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              enableColumnDragging={false}
              initialState={{ showGlobalFilter: true, showColumnFilters: true }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  enableHiding: true,
                },
                'mrt-row-actions': {
                  enableHiding: true,
                  size: 80
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
            //   renderTopToolbarCustomActions={({ }) => {
            //     if(minimenu===false){
            //       return(
            //       <>
            //         {filters_custom}
            //       </>
            //       )
            //     }
            //   }}
            
              renderToolbarInternalActions={({ table, index }) => (
                <RENDER_TOOLBAR_INTERNAL_ACTIONS
                  table={table}
                  file_name={"Detalles de ventas y entregas"}
                  update_identifiers={update_identifiers}
                  key={index}
                  settotal_rows={settotal_rows}
                  total_rows={total_rows !== "" ? total_rows : 0}
                />
              )
              }
            />
    </div>
    </>
  )
}
