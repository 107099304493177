import React from 'react'
import "./bandeja.css";

export const BANDEJA = (props) => {


    const date = props?.item?.conversacion?.fecha
    const fechaActual = new Date();
    const year = fechaActual.getFullYear();
    const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
    const day = String(fechaActual.getDate()).padStart(2, '0');
    const fechaFormateada = `${year}-${month}-${day}`; 
  
    const d = props?.item?.name_destino;
    const primeraLetra = d.charAt(0);
  
    
  
  return (
    <div className='bandeja-container-mini'>
      <div className='circle-mini'>
        <p>{primeraLetra}</p>
      </div>
      <div className='container-history-mini'>
        <div className='info-contact-list-mini'>
          <div className='name-contact-mini'>
            <p className=''>{props?.item?.name_destino}</p>
          </div>
          <div className='tiempo-contact-last-mss-mini'>
            <p className=''>{date===fechaFormateada?props?.item?.hora : props?.item?.fecha}</p>
          </div>
        </div>
        <div className='info-last-mss-mini'>
          <p className=''>{
              props?.item?.tipo==="text"? props?.item?.mensaje :
                  props?.item?.tipo==="audio" ? "Audio" : 
                    props?.item?.tipo==="archivo" ? "Archivo" : "imagen"
            }</p>
        </div>
      </div>
    </div>
  )
}
